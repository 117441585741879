import amber from "@material-ui/core/colors/amber";
import green from "@material-ui/core/colors/green";
import IconButton from "@material-ui/core/IconButton";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import { WithStyles, withStyles } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import { SvgIconProps } from "@material-ui/core/SvgIcon";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CloseIcon from "@material-ui/icons/Close";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import WarningIcon from "@material-ui/icons/Warning";
import classNames from "classnames";
import React, { ComponentType } from "react";

const variantIcon: {
    success: ComponentType<SvgIconProps>;
    warning: ComponentType<SvgIconProps>;
    error: ComponentType<SvgIconProps>;
    info: ComponentType<SvgIconProps>;
} = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon
};

const styles = (theme: any) =>
    createStyles({
        root: {
            flexWrap: "nowrap"
        },
        success: {
            backgroundColor: green[600]
        },
        error: {
            backgroundColor: theme.palette.error.dark
        },
        info: {
            backgroundColor: theme.palette.primary.dark
        },
        warning: {
            backgroundColor: amber[700]
        },
        icon: {
            fontSize: 20
        },
        iconVariant: {
            opacity: 0.9,
            marginRight: theme.spacing(1)
        },
        message: {
            display: "flex",
            alignItems: "center"
        },
        close: {}
    });
const SnackbarContentWrapper = ({
    classes,
    className,
    message,
    onClose,
    variant
}: WithStyles<typeof styles> & {
    className?: string;
    message: any;
    onClose: () => void;
    variant: "success" | "warning" | "error" | "info";
}) => {
    const Icon: ComponentType<SvgIconProps> = variantIcon[variant];

    return (
        <SnackbarContent
            className={classNames(classes[variant], classes.root, className)}
            aria-describedby="snackbar-content-wrapper"
            message={
                <span className={classes.message}>
                    <Icon className={classNames(classes.icon, classes.iconVariant)} />
                    {message}
                </span>
            }
            action={[
                <IconButton key="close" aria-label="Close" color="inherit" onClick={onClose}>
                    <CloseIcon className={classes.icon} />
                </IconButton>
            ]}
        />
    );
};

export default withStyles(styles)(SnackbarContentWrapper);
