import { BASE_DOMAIN } from "../constants/app";
import { makeGet } from "./main-api";

const API_URL = {
    GET_ORG_ROLES: () => `${BASE_DOMAIN}/v1/roles/organization`
};

export default function getOrganizationRoles() {
    return makeGet(API_URL.GET_ORG_ROLES());
}
