import { validate as emailValidator } from "email-validator";
// copy from 'node-service', as node-service can not be imported with webpack
// @todo refactor

export const EMPTY = "EMPTY";
export const INVALID_EMAIL = "INVALID_EMAIL";
export const INVALID_PASSWORD = "Invalid Password (password should be at least 8 chars)";

export function validateFullname(fullName: string) {
    let isValid = true;
    const errors = {
        fullNameError: ""
    };
    if (!fullName || !fullName.trim()) {
        isValid = false;
        errors.fullNameError = "Please enter your full name";
    }
    return {
        isValid,
        errors
    };
}

export function validateEmail(email: string) {
    let isValid = true;
    const errors = {
        emailError: ""
    };
    if (!email || !email.trim()) {
        isValid = false;
        errors.emailError = "Please enter your email";
    } else if (!emailValidator(email)) {
        isValid = false;
        errors.emailError = "Invalid Email";
    }
    return {
        isValid,
        errors
    };
}
export function validateRequire(value: string) {
    return value && value.trim() !== "";
}

export function validateUsername(username: string) {
    let isValid = true;
    const errors = {
        usernameError: ""
    };
    const regex = /^[a-z][a-z0-9]+$/i;
    if (!username || !username.trim()) {
        isValid = false;
        errors.usernameError = "Please enter Username";
    } else if (username.trim().length < 5 || !regex.test(username.trim())) {
        isValid = false;
        errors.usernameError =
            "Username should be minimum 5 characters, only numbers and letters with first letter character.";
    }
    return {
        isValid,
        errors
    };
}

export function validatePassword(password: string) {
    if (!password || !password.trim()) {
        return EMPTY;
    }
    if (password.trim().length < 8) {
        return INVALID_PASSWORD;
    }
    return true;
}
export function validateTwoFactorAuth(twoFactorAut: string) {
    let isValid = true;
    const errors = {
        twoFactorAuthStrError: ""
    };
    if (twoFactorAut.trim() === "") {
        isValid = false;
        errors.twoFactorAuthStrError = "Please enter digits";
    } else if (twoFactorAut.trim().length !== 6) {
        isValid = false;
        errors.twoFactorAuthStrError = "Two factor authentitaction should be 6 digits";
    }
    return {
        isValid,
        errors
    };
}
