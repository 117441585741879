import { Theme } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";

import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelActions from "@material-ui/core/ExpansionPanelActions";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import createStyles from "@material-ui/core/styles/createStyles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import Switch from "@material-ui/core/Switch";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { regenerateTwoFactorAuthQr, toggleTwoFactorAuth } from "../../../../api/main-api";
import { AppDispatch, UserContext } from "../../../../context/main-context";

const styles = (theme: Theme) =>
    createStyles({
        twoFactorAuthPanelDetails: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            position: "relative",
            margin: "0 auto"
        },
        heading: {
            fontSize: theme.typography.pxToRem(15)
        }
    });
const TwoFactorAuthenticationOptions = ({
    classes,
    expanded,
    handleClose,
    handleExpansionChange
}: WithStyles<typeof styles> & {
    expanded: string | false;
    handleClose: () => void;
    handleExpansionChange: (panel: string) => (event: any, newExpand: boolean) => void;
}) => {
    const dispatch = useContext(AppDispatch)!;
    const loggedInUser = useContext(UserContext) || { twoFactorAuth: null };
    const [toggleTwoFactorAuthLoading, setToggleTwoFactorAuthLoading] = useState<boolean>(false);
    const [regenerateTwoFactorAuthQRLoading, setRegenerateTwoFactorAuthQRLoading] = useState<boolean>(false);
    const [enabled, setEnabled] = useState<boolean>(
        !!(loggedInUser && loggedInUser.twoFactorAuth && loggedInUser.twoFactorAuth.enabled)
    );
    const [toggleTwoFactor, setToggleTwoFactor] = useState<boolean | null>(null);
    const [regenerateTwoFactor, setRegenerateTwoFactor] = useState<boolean | null>(null);

    useEffect(() => {
        let canceled = false;
        if (typeof toggleTwoFactor === "boolean") {
            toggleTwoFactorAuth(toggleTwoFactor)
                .then(d => {
                    if (!canceled) {
                        setToggleTwoFactorAuthLoading(false);
                        dispatch({ type: "UPDATE_TWO_FACTOR_AUTH", payload: d.data });
                    }
                })
                // tslint:disable-next-line:no-empty
                .catch(event => {
                    dispatch({ type: "SHOW_MESSAGE_ERROR", payload: event });
                });
        }
        return () => {
            canceled = true;
        };
    }, [dispatch, toggleTwoFactor]);

    useEffect(() => {
        let canceled = false;
        if (typeof regenerateTwoFactor === "boolean") {
            regenerateTwoFactorAuthQr()
                .then(d => {
                    if (!canceled) {
                        setRegenerateTwoFactorAuthQRLoading(false);
                        dispatch({ type: "UPDATE_TWO_FACTOR_AUTH", payload: d.data });
                    }
                })
                // tslint:disable-next-line:no-empty
                .catch(e => {});
        }
        return () => {
            canceled = true;
        };
    }, [dispatch, regenerateTwoFactor]);

    const handleSwichChange = (e: { target: { checked: React.SetStateAction<boolean> } }) => {
        setToggleTwoFactorAuthLoading(true);
        setEnabled(e.target.checked);
        setToggleTwoFactor(!!e.target.checked);
    };

    const handleRegenerateTwoFactorAuthQR = () => {
        setRegenerateTwoFactorAuthQRLoading(true);
        setRegenerateTwoFactor(!regenerateTwoFactor);
    };

    return (
        <ExpansionPanel expanded={expanded === "authentication"} onChange={handleExpansionChange("authentication")}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <div>
                    <Typography className={classes.heading}>Two Factor Authentication Options</Typography>
                </div>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <div className={classes.twoFactorAuthPanelDetails}>
                    <FormControlLabel
                        control={
                            <Switch
                                disabled={toggleTwoFactorAuthLoading || regenerateTwoFactorAuthQRLoading}
                                checked={enabled}
                                onChange={handleSwichChange}
                                color="primary"
                                inputProps={{
                                    "aria-label": "primary checkbox"
                                }}
                            />
                        }
                        label={enabled ? "Disable" : "Enable"}
                        labelPlacement="start"
                    />
                    {loggedInUser.twoFactorAuth && loggedInUser.twoFactorAuth.qrUrl && (
                        <>
                            <img alt="QR" src={loggedInUser.twoFactorAuth.qrUrl} />
                            <Button
                                disabled={regenerateTwoFactorAuthQRLoading || toggleTwoFactorAuthLoading}
                                color="primary"
                                onClick={handleRegenerateTwoFactorAuthQR}
                            >
                                ReGenerate QR
                            </Button>
                        </>
                    )}
                </div>
            </ExpansionPanelDetails>
            <Divider />
            <ExpansionPanelActions>
                <Button size="small" onClick={handleClose}>
                    Close
                </Button>
            </ExpansionPanelActions>
        </ExpansionPanel>
    );
};

export default withStyles(styles)(TwoFactorAuthenticationOptions);
