import { IDeviceConfigurationItem } from "../interfaces/daos";

export const initialDeviceConfigs: IDeviceConfigurationItem[] = [
    {
        id: 0,
        configKey: "checkHDMICable",
        type: "ckeck",
        label: "Show HDMI cable warning",
        value: true
    },
    {
        id: 1,
        configKey: "checkActiveSouce",
        type: "ckeck",
        label: "Show active souce warning",
        value: false
    },
    {
        id: 2,
        configKey: "checkTVPower",
        type: "ckeck",
        label: "Show TV power warning",
        value: true
    }
];

(window as any).initialDeviceConfigs = initialDeviceConfigs;
