import { BASE_DOMAIN } from "../constants/app";
import { makeDelete, makeGet, makePatch, makePost } from "./main-api";
import { ISimpleDataResponse, IGroupsWithMeta, INameable } from "../interfaces/dto";

const API_URL = {
    GET_ADD: () => `${BASE_DOMAIN}/v1/play-list-group`,
    UPDATE_DELETE: (id: string) => `${BASE_DOMAIN}/v1/play-list-group/${id}`
};

export const getPlayListGroup = () => {
    return makeGet<ISimpleDataResponse<IGroupsWithMeta>>(API_URL.GET_ADD()).then(d => d.data);
};
export const addPlayListGroup = (name: string) => {
    return makePost<ISimpleDataResponse<INameable>>(API_URL.GET_ADD(), { name }).then(d => d.data);
};
export const deletePlayListGroup = (id: string) => {
    return makeDelete<void>(API_URL.UPDATE_DELETE(id));
};
export const updatePlayListGroup = (id: string, name: string) => {
    return makePatch<ISimpleDataResponse<INameable>>(API_URL.UPDATE_DELETE(id), { name }).then(() => ({
        _id: id,
        name
    }));
};
