export const REPEAT_ARR = [
    { id: "daily", name: "Daily" },
    { id: "weekly", name: "Weekly" },
    { id: "monthly", name: "Monthly" }
];
export const WEEK_DAYS = [
    { id: "0", name: "Sunday" },
    { id: "1", name: "Monday" },
    { id: "2", name: "Tuesday" },
    { id: "3", name: "Wednesday" },
    { id: "4", name: "Thursday" },
    { id: "5", name: "Friday" },
    { id: "6", name: "Saturday" }
];
export const EVENT_COLORS = [
    "#4172ff",
    "#ef6105",
    "#56d59f",
    "#f4bb03",
    "#8F2D56",
    "#218380",
    "#1bb7c7",
    "#5f8348",
    "#b8860b"
];
