import React from "react";
import { Action, IUser } from "../interfaces/daos";

export const UserContext = React.createContext<IUser | null>(null);

export const LoaderContext = React.createContext<boolean>(false);
export const LoaderUpdateContext = React.createContext<((value: boolean) => void) | null>(null);
export const AppDispatch = React.createContext<React.Dispatch<Action> | null>(null);
export const InsufficientlyAuthenticationDetailsContext = React.createContext<string[]>([]);
export const UploadInfoContext = React.createContext<Resumable.ResumableFile[]>([]);
