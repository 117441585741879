import { TextField } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { lighten } from "@material-ui/core/styles/colorManipulator";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import createStyles from "@material-ui/core/styles/createStyles";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import React from "react";

const styles = (theme: Theme) =>
    createStyles({
        root: {
            paddingRight: 5,
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            borderBottom: "1px solid rgba(224, 224, 224, 1)"
        },
        highlight:
            theme.palette.type === "light"
                ? {
                      color: theme.palette.primary.dark,
                      backgroundColor: lighten(theme.palette.primary.main, 0.85)
                  }
                : {
                      color: theme.palette.text.primary,
                      backgroundColor: theme.palette.secondary.dark
                  },
        spacer: {
            flex: "1 1 100%"
        },
        actions: {
            color: theme.palette.text.secondary,
            display: "flex"
        },
        title: {
            flex: "0 0 auto"
        },
        searchTextField: {
            margin: "0 20px",
            minWidth: 200
        }
    });

const GridToolbar = ({
    classes,
    numSelected,
    title = "",
    searchValue,
    onSearchValueChange,
    selectedGridToolbarElements
}: {
    classes: Partial<ClassNameMap<string>>;
    numSelected: number;
    title: string | undefined;
    searchValue: string;
    onSearchValueChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    selectedGridToolbarElements?: React.ReactNode;
}) => {
    return (
        <Toolbar
            className={classNames(classes.root, {
                [classes.highlight as string]: numSelected > 0
            })}
        >
            <div className={classes.title}>
                {numSelected > 0 ? (
                    <Typography color="inherit" variant="subtitle1">
                        {numSelected} selected
                    </Typography>
                ) : (
                    <Typography variant="h6" id="tableTitle">
                        {title}
                    </Typography>
                )}
            </div>
            <TextField
                label="Search"
                className={classes.searchTextField}
                value={searchValue}
                onChange={onSearchValueChange}
            />
            <div className={classes.spacer} />
            {numSelected > 0 && <div className={classes.actions}>{selectedGridToolbarElements}</div>}
        </Toolbar>
    );
};

export default withStyles(styles)(GridToolbar);
