import { Event } from "react-big-calendar";
import { IEventDTO } from "../../interfaces/dto";

export const getCalendarEvents: (events: IEventDTO[], playlists: any[]) => Event[] = (events, playlists) => {
    let result: Event[] = [];
    for (const e of events) {
        const playlistName = playlists.find(p => p._id === e.playListId).name;
        const repeatType = e.repeat.type;
        const repeatOptions = e.repeat.options;
        const current = new Date(e.fromDate);
        let curTime = new Date(current).getTime();
        const endTime = new Date(e.toDate!).getTime();
        const res = [];
        if (repeatType === "none" || repeatType === "daily") {
            result.push({
                title: playlistName,
                start: current,
                end: new Date(e.toDate!),
                resource: e._id
            });
        } else {
            if (repeatType && e.repeat.options.length) {
                switch (repeatType) {
                    case "weekly": {
                        while (curTime <= endTime) {
                            if (repeatOptions.indexOf(String(current.getDay())) > -1) {
                                res.push(new Date(current.getTime()));
                            }
                            current.setDate(current.getDate() + 1);
                            curTime += 1000 * 60 * 60 * 24;
                        }
                        result = result.concat(
                            res.map(d => {
                                return {
                                    title: playlistName,
                                    start: d,
                                    end: d,
                                    resource: e._id
                                };
                            })
                        );
                        break;
                    }
                    case "monthly": {
                        while (curTime <= endTime) {
                            if (repeatOptions.indexOf(String(current.getDate())) > -1) {
                                res.push(new Date(current.getTime()));
                            }
                            current.setDate(current.getDate() + 1);
                            curTime += 1000 * 60 * 60 * 24;
                        }
                        result = result.concat(
                            res.map(d => {
                                return {
                                    title: playlistName,
                                    start: d,
                                    end: d,
                                    resource: e._id
                                };
                            })
                        );
                        break;
                    }
                }
            }
        }
    }
    return result;
};
