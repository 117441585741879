import { Theme, WithStyles } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from "@material-ui/core/InputLabel";
import createStyles from "@material-ui/core/styles/createStyles";
import withStyles from "@material-ui/core/styles/withStyles";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import React, { useState } from "react";

const styles = (theme: Theme) =>
    createStyles({
        formControl: {
            marginTop: 8,
            marginBottom: 8
        }
    });
const PasswordField = ({
    classes,
    isPasswordRequired,
    label,
    type,
    autoComplete,
    error,
    password,
    onChange,
    onEnter
}: WithStyles<typeof styles> & {
    isPasswordRequired: boolean;
    label: any;
    type: string;
    autoComplete: string;
    error: string;
    password: string;
    onChange: (value: string) => void;
    onEnter: () => void;
}) => {
    const [showPassword, setShowPassword] = useState(false);
    // const [disabled, setDisabled] = useState(false);

    const handleChange = (e: any) => {
        onChange(e.target.value);
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };
    const handleMouseDownPassword = (e: any) => {
        e.preventDefault();
    };
    const onKeyDown = (e: any) => {
        switch (e.keyCode) {
            case 13: {
                if (typeof onEnter === "function") {
                    onEnter();
                }
                break;
            }
            default:
                break;
        }
    };

    return (
        <FormControl required={isPasswordRequired} className={classes.formControl} disabled={false}>
            <InputLabel htmlFor={`${type}-password-field`}>{label}</InputLabel>
            <Input
                id={`${type}-password-field`}
                type={showPassword ? "text" : "password"}
                autoComplete={autoComplete}
                value={password}
                onChange={handleChange}
                onKeyDown={onKeyDown}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            onClick={handleClickShowPassword}
                            tabIndex={-1}
                            onMouseDown={handleMouseDownPassword}
                        >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                }
            />
            {error && <FormHelperText error>{error}</FormHelperText>}
        </FormControl>
    );
};

export default withStyles(styles)(PasswordField);
