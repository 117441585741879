import React, { useContext, useEffect, useState } from "react";
import { createStyles, WithStyles } from "@material-ui/core/styles";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import withStyles from "@material-ui/core/styles/withStyles";
import { TextField } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";

import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Divider from "@material-ui/core/Divider";
import ExpansionPanelActions from "@material-ui/core/ExpansionPanelActions";
import Button from "@material-ui/core/Button";
import { updateDeviceConfig } from "../../../../api/device";
import { AppDispatch } from "../../../../context/main-context";
const defaultProxy = {
    port: "",
    username: "",
    proxyPassword: "",
    host: ""
};
const styles = createStyles({
    rootWrapper: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    root: {
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box",
        flexGrow: "inherit",
        padding: "15px 20px 0px 20px",
        width: 800,
        alignItems: "stretch"
    },
    column: {
        flex: 1
    },
    details: {
        flexDirection: "row"
    },
    enabledSwitch: {},
    heading: {},
    fieldset: {
        flexDirection: "column",
        display: "flex",
        flex: 1,
        border: "white"
    },
    form: {
        flexDirection: "column",
        display: "flex",
        flex: 1
    },
    secondaryHeading: {}
});
const ProxyPanel = ({
    classes,
    device,
    expanded,
    saveAction,
    handleChange
}: WithStyles<typeof styles> & {
    device: any;
    expanded: string | false;
    saveAction: () => void;
    handleChange: (panel: string) => (event: any, newExpand: boolean) => void;
}) => {
    const dispatch = useContext(AppDispatch)!;

    const [port, setPort] = useState<string>(defaultProxy.port);
    const [username, setUsername] = useState<string>(defaultProxy.username);
    const [proxyPassword, setProxyPassword] = useState<string>(defaultProxy.proxyPassword);
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [host, setHost] = useState<string>(defaultProxy.host);

    useEffect(() => {
        (async () => {
            try {
                if (device.data.config.proxyNetwork) {
                    if (device.data.config.proxyNetwork.port !== undefined) {
                        setPort(device.data.config.proxyNetwork.port);
                    }
                    if (device.data.config.proxyNetwork.username !== undefined) {
                        setUsername(device.data.config.proxyNetwork.username);
                    }
                    if (device.data.config.proxyNetwork.proxyPassword !== undefined) {
                        setProxyPassword(device.data.config.proxyNetwork.proxyPassword);
                    }
                    if (device.data.config.proxyNetwork.host !== undefined) {
                        setHost(device.data.config.proxyNetwork.host);
                    }
                }
            } catch (e) {
                console.log(e.message);
            }
        })();
    }, [device]);
    const onChangePort = (e: any) => {
        const onlyNums = e.target.value.replace(/[^0-9]/g, "");
        if (onlyNums.length < 6 && onlyNums <= 65535) {
            setPort(onlyNums);
        }
    };
    const onChangeUsername = (e: any) => {
        setUsername(e.target.value);
    };
    const onChangeProxyPassword = (e: any) => {
        setProxyPassword(e.target.value);
    };
    const onClickShowPassword = () => {
        showPassword ? setShowPassword(false) : setShowPassword(true);
    };
    const onChangeHost = (e: any) => {
        setHost(e.target.value);
    };
    const handleSubmit = (e: any) => {
        e.preventDefault();
        const dataSend: any = {
            ...device.data.config,
            proxyNetwork: {
                port: port,
                username: username,
                proxyPassword: proxyPassword,
                host: host
            }
        };
        (async () => {
            try {
                await updateDeviceConfig(device.data.deviceId, dataSend).then(() => {
                    dispatch({ type: "SHOW_MESSAGE_SUCCESS", payload: "Proxy configuration updated successfully" });
                });
                saveAction();
            } catch (e) {
                dispatch({ type: "SHOW_MESSAGE_ERROR", payload: e });
            }
        })();
        //do smth
    };
    const handleCancel = (e: any) => {
        autoFill();
    };
    function autoFill() {
        if (device.data.config.proxyNetwork.port !== undefined) {
            setPort(device.data.config.proxyNetwork.port);
        } else {
            setPort(defaultProxy.port);
        }
        if (device.data.config.proxyNetwork.username !== undefined) {
            setUsername(device.data.config.proxyNetwork.username);
        } else {
            setUsername(defaultProxy.username);
        }
        if (device.data.config.proxyNetwork.proxyPassword !== undefined) {
            setProxyPassword(device.data.config.proxyNetwork.proxyPassword);
        } else {
            setProxyPassword(defaultProxy.proxyPassword);
        }
        if (device.data.config.proxyNetwork.host !== undefined) {
            setHost(device.data.config.proxyNetwork.host);
        } else {
            setHost(defaultProxy.host);
        }
    }
    return (
        <ExpansionPanel square expanded={expanded === "panel4"} onChange={handleChange("panel4")}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1c-content" id="panel1c-header">
                <div className={classes.column}>
                    <Typography className={classes.heading}>Proxy</Typography>
                </div>
            </ExpansionPanelSummary>

            <ExpansionPanelDetails className={classes.details}>
                <form className={classes.form} onSubmit={handleSubmit}>
                    <fieldset className={classes.fieldset}>
                        <TextField id="Proxy-name" label="Host" value={host} onChange={onChangeHost} required={true} />
                        <TextField id="Proxy-port" label="Port" value={port} onChange={onChangePort} required={true} />
                        <TextField
                            label="Username"
                            value={username}
                            onChange={onChangeUsername}
                            required={true}
                            autoComplete="off"
                        />
                        <FormControl required>
                            <InputLabel>Password</InputLabel>
                            <Input
                                type={showPassword ? "text" : "password"}
                                autoComplete="new-password"
                                value={proxyPassword}
                                onChange={onChangeProxyPassword}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton onClick={onClickShowPassword} onMouseDown={e => e.preventDefault()}>
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                    </fieldset>
                </form>
            </ExpansionPanelDetails>

            <Divider />
            <ExpansionPanelActions>
                <Button size="small" onClick={handleCancel}>
                    Cancel
                </Button>
                <Button size="small" onClick={handleSubmit} color="primary">
                    Save
                </Button>
            </ExpansionPanelActions>
        </ExpansionPanel>
    );
};

export default withStyles(styles)(ProxyPanel);
