import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import createStyles from "@material-ui/core/styles/createStyles";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import React, { ChangeEvent, useEffect, useState } from "react";
import { INameable } from "../../../interfaces/dto";

const styles = (theme: Theme) => createStyles({});

const GroupDialog = ({
    add,
    mode,
    group,
    handleClose
}: {
    classes: Partial<ClassNameMap<string>>;
    mode: "add" | "edit" | null;
    add: (name: string, id?: string) => void;
    handleClose: () => void;
    group?: INameable;
}) => {
    const [name, setName] = useState<string>("");

    useEffect(() => {
        if (group && group.name && mode === "edit") {
            setName(group.name);
        }
    }, [group, mode]);
    const onChange = function(e: ChangeEvent<HTMLInputElement>) {
        setName(e.target.value);
    };
    const handelAdd = function() {
        add(name, mode === "edit" ? group && group._id : undefined);
    };
    const handleSubmit = function(e: any) {
        e.preventDefault();
        handelAdd();
    };
    return (
        <Dialog open={true} onClose={handleClose} aria-labelledby="form-dialog-title">
            <form onSubmit={handleSubmit}>
                <DialogTitle id="form-dialog-title"> {mode === "edit" ? "Update " : "Add "} Group</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        value={name}
                        onChange={onChange}
                        label="Group Name"
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button type="submit" color="primary">
                        Save
                    </Button>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default withStyles(styles)(GroupDialog);
