import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from "@material-ui/core";
import Button, { ButtonTypeMap } from "@material-ui/core/Button";
import { ExtendButtonBase } from "@material-ui/core/ButtonBase";
import { withStyles } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import createStyles from "@material-ui/core/styles/createStyles";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import { WithMobileDialog } from "@material-ui/core/withMobileDialog";
import withMobileDialog from "@material-ui/core/withMobileDialog/withMobileDialog";
import React, { ChangeEvent, useEffect, useState } from "react";
import { formatBytes } from "../../utils/file";
const styles = (theme: Theme) =>
    createStyles({
        field: {
            marginBottom: 10
        },
        uploadInput: {
            display: "none"
        },
        fileName: {
            maxWidth: "55%",
            wordWrap: "break-word",
            minWidth: 0,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            paddingTop: 7
        },
        fileSize: {
            maxWidth: "30%",
            paddingTop: 7
        },
        chooseFile: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between"
        }
    });

const MediaAddDialog = ({
    classes,
    add,
    mode,
    media,
    handleClose,
    fullScreen
}: {
    classes: Partial<ClassNameMap<string>>;
    mode: "add" | "edit" | null;
    add: (name: string, type: string, description: string, url: File | string, id?: string) => void;
    handleClose: () => void;
    media?: { name: string; type: string; _id: string; description: string };
} & WithMobileDialog) => {
    const [name, setName] = useState<string>("");
    const [type, setType] = useState<string>("");
    const [description, setDescription] = useState<string>("");
    const [file, setFile] = useState<File | null>(null);
    const [url, setUrl] = useState<string>("");
    const [fileName, setFileName] = useState<string>("");
    const [fileSize, setFileSize] = useState<string>("");

    const UploadButton: ExtendButtonBase<ButtonTypeMap<any, "button">> = Button;

    useEffect(() => {
        if (media && media.name && mode === "edit") {
            setName(media.name);
            setDescription(media.description);
        }
    }, [media, mode]);

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value);
    };

    const handleTypeChange = (e: ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
        setType(e.target.value as string);
    };

    const handleDescriptionChange = (e: ChangeEvent<HTMLInputElement>) => {
        setDescription(e.target.value);
    };

    const handleUploadChange = (e: ChangeEvent<HTMLInputElement>) => {
        setFile(e.target.files![0]);
        if (e.target.files![0]) {
            setFileName(e.target.files![0].name);
            setFileSize(formatBytes(e.target.files![0].size, 2));
        } else {
            setFileName("");
            setFileSize("");
        }
    };

    const handleUrlChange = (e: ChangeEvent<HTMLInputElement>) => {
        setUrl(e.target.value);
    };

    const handelAdd = () => {
        const originUrl = type === "web" ? url : file;
        add(name, type, description, originUrl!, mode === "edit" ? media && media._id : undefined);
    };

    const isBtnDisabled =
        mode === "add"
            ? !name || !type || (type === "web" && !url) || ((type === "video" || type === "image") && !file)
            : !name;
    return (
        <Dialog fullScreen={fullScreen} open={true} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{mode === "add" ? "Add Media" : "Edit Media"}</DialogTitle>
            <DialogContent>
                <TextField
                    className={classes.field}
                    autoFocus
                    margin="dense"
                    id="name"
                    value={name}
                    onChange={onChange}
                    label="Media Name"
                    fullWidth
                    required
                />
                <TextField
                    className={classes.field}
                    fullWidth
                    label="Description"
                    rows="3"
                    value={description}
                    onChange={handleDescriptionChange}
                />
                {mode === "add" ? (
                    <FormControl fullWidth required className={classes.field}>
                        <InputLabel htmlFor="media-type">Type</InputLabel>
                        <Select value={type} onChange={handleTypeChange}>
                            {["video", "image", "web"].map(t => (
                                <MenuItem key={t} value={t}>
                                    {t}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                ) : (
                    ""
                )}
                {(type === "video" || type === "image") && (
                    <FormControl fullWidth className={`${classes.field} ${classes.chooseFile}`}>
                        <input
                            className={classes.uploadInput}
                            id="choose-button"
                            type="file"
                            onChange={handleUploadChange}
                        />
                        <label htmlFor="choose-button">
                            <UploadButton variant="contained" component="span">
                                Choose file
                            </UploadButton>
                        </label>
                        <div className={classes.fileName}>{fileName}</div>
                        <div className={classes.fileSize}>{fileSize}</div>
                    </FormControl>
                )}
                {type === "web" && (
                    <TextField
                        className={classes.field}
                        margin="dense"
                        value={url}
                        onChange={handleUrlChange}
                        label="Url"
                        fullWidth
                        required
                    />
                )}
            </DialogContent>
            <DialogActions>
                <Button disabled={isBtnDisabled} onClick={handelAdd} color="primary">
                    Save
                </Button>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default withMobileDialog()(withStyles(styles)(MediaAddDialog));
