import { WithStyles } from "@material-ui/core";
import createStyles from "@material-ui/core/styles/createStyles";
import withStyles from "@material-ui/core/styles/withStyles";
import React, { useContext } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Dashboard from "../dashboard/dashboard";
import Device from "../devices/device";
import Devices from "../devices/devices";
import Header from "../header/header";
import Media from "../media/media";
import Playlist from "../playlists/playlist";
import Playlists from "../playlists/playlists";
import Schedule from "../schedules/schedule";
import Schedules from "../schedules/schedules";
import { UserContext } from "../../context/main-context";
import OrgUsers from "../organization";

const styles = createStyles({
    appRootWrapper: {
        flex: 1,
        display: "flex",
        flexDirection: "column",
        width: "100%"
    }
});

const Main = (props: WithStyles<typeof styles>) => {
    const {
        classes: { appRootWrapper }
    } = props;
    const loggedInUser = useContext(UserContext)!;
    const currentOrganization = loggedInUser.orgs.find((f: any) => f.default)._id;
    return (
        <div className={appRootWrapper}>
            <Header />
            <Switch>
                <Route path="/dashboard/">
                    <Dashboard />
                </Route>
                <Route path="/devices/">
                    <Devices />
                </Route>
                <Route path="/device/:id">
                    <Device />
                </Route>
                <Route path="/media/">
                    <Media key={currentOrganization} />
                </Route>
                <Route path="/playlists/">
                    <Playlists />
                </Route>
                <Route path="/playlist/:id">
                    <Playlist />
                </Route>
                <Route path="/playlist/">
                    <Playlist />
                </Route>
                <Route path="/schedules/">
                    <Schedules />
                </Route>
                <Route path="/schedule/:id">
                    <Schedule />
                </Route>
                <Route path="/schedule/">
                    <Schedule />
                </Route>
                <Route path="/users/">
                    <OrgUsers />
                </Route>
                <Route exact path="/">
                    <Dashboard />
                </Route>
                <Redirect to="/" />
            </Switch>
        </div>
    );
};

export default withStyles(styles)(Main);
