// import { distanceInWordsToNow } from "date-fns";
import distanceInWordsToNow from "date-fns/formatDistanceToNow";
import { useEffect, useState } from "react";
import { getDevicesStatuses } from "../../api/main-api";
import { warn } from "../../utils/console-logs";

export function determineAvailability(lastSeen: number) {
    if (typeof lastSeen !== "number" || isNaN(lastSeen) || !isFinite(lastSeen)) {
        return "Offline";
    }
    const newDate = Date.now();
    const diffMs = newDate - lastSeen;
    const diffMins = Math.round(diffMs / (1000 * 60));
    if (diffMins > 10) {
        return "Offline";
    }
    if (diffMins > 3) {
        return "Away";
    }
    return "Online";
}

function determineLastSeen(lastSeen: number) {
    if (typeof lastSeen !== "number" || isNaN(lastSeen) || !isFinite(lastSeen)) {
        return "N/A";
    }
    const res = distanceInWordsToNow(new Date(lastSeen));
    if (res === "1 minute") {
        return "less than a minute";
    }
    return res;
}

function determineUpTime(deviceUptime: number | string) {
    const uptime = Number(deviceUptime);
    if (!isNaN(uptime)) {
        const mins = Math.floor((uptime % (60 * 60)) / 60);
        const minsZero = mins < 10 ? "0" + mins : "" + mins;
        const hours = Math.floor((uptime % (60 * 60 * 24)) / (60 * 60));
        const days = Math.floor(uptime / (60 * 60 * 24));
        const daysStr = days > 1 ? `${days} days, ` : days === 1 ? "1 day, " : "";
        const hoursStr = hours > 0 ? `${hours}:` : "";
        const minsStr = hours === 0 ? `${mins} min` : minsZero;
        return (daysStr + hoursStr + minsStr).replace(", 0 min", "");
    }
    return deviceUptime;
}

export function updateStatusesOfDevices(statuses: any, d: any[]) {
    return d.map((device: any) => {
        const id = device._id.toString();
        const msg = statuses[id];
        const defaultStatuses = {
            assignedSchedule: "",
            actions: "",
            status: "offline",
            syncStatus: "out of sync",
            lastSeen: "never",
            uptime: "N/A",
            originUptime: null,
            additionalUsbDevicesInfo: [],
            temprature: "N/A",
            totalMem: "",
            usedMem: "",
            wifiSpeed: "No Data"
        };
        const now: any = new Date();
        let res: any = {};
        if ((now - statuses.time) / (1000 * 60) > 5) {
            res = {
                ...device,
                ...defaultStatuses
            };
        } else {
            res = {
                ...defaultStatuses,
                ...device
            };
        }

        if (msg) {
            let lastPingMessage;
            try {
                lastPingMessage = JSON.parse(msg);
            } catch (e) {
                warn(`Invalid ping message for device id: ${id}: message ${msg}`);
            }
            if (lastPingMessage) {
                res.status = determineAvailability(lastPingMessage.lastSeen);
                res.syncStatus = lastPingMessage.status;
                res.lastSeen = determineLastSeen(lastPingMessage.lastSeen);
                res.uptime = determineUpTime(lastPingMessage.uptime);
                res.originUptime = lastPingMessage.uptime;
                res.ip = determineUpTime(lastPingMessage.ip);
                res.synced = determineUpTime(lastPingMessage.playListSynced);
                res.rawStatus = lastPingMessage;
                res.additionalUsbDevicesInfo = lastPingMessage.additionalUsbDevicesInfo;
                res.temprature = lastPingMessage.temprature;
                res.totalMem = lastPingMessage.totalMem;
                res.usedMem = lastPingMessage.usedMem;
                res.wifiSpeed = lastPingMessage.wifiSpeed;
            }
        }
        return res;
    });
}

export default function useStatuses(deviceId?: string) {
    const [statuses, setStatuses] = useState<any>({});
    const [initial, setInitial] = useState(true);

    useEffect(() => {
        let canceled = false;
        const getStatusses = async () => {
            try {
                const res = await getDevicesStatuses(deviceId);
                if (!canceled) {
                    setInitial(false);
                    setStatuses({ ...res.data, time: new Date() });
                }
            } catch (e) {
                setStatuses({ time: statuses.time });
            }
        };
        if (initial) {
            getStatusses();
        }
        const timer = setTimeout(getStatusses, 60000);
        return () => {
            canceled = true;
            clearTimeout(timer);
        };
    }, [statuses, deviceId, initial]);

    return { statuses };
}
