import React, { useEffect, useReducer, useState, StrictMode } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import { Route, Switch, useHistory } from "react-router-dom";
import { clearAuthToken, getAuthToken, getLoggedInUser } from "./api/main-api";
import "./App.css";
import Login from "./components/authentication/login";
import SignUp from "./components/authentication/signup";
import Main from "./components/main/main";
import SnackbarContentWrapper from "./components/snackbar/snackbar-content-wrapper";
import WithAuthGuard from "./components/with-authentication-guard";
import {
    AppDispatch,
    InsufficientlyAuthenticationDetailsContext,
    LoaderContext,
    LoaderUpdateContext,
    UploadInfoContext,
    UserContext
} from "./context/main-context";
import reducer, { initialState } from "./reducer/main-reducer";
import WithHelmet from "./components/WithHelmet";
import mainImagePath from "./assets/monitorsync.jpg";
const withAuthGuard = WithAuthGuard({ authenticatedGuard: true })(Main);
const LoginWithHelmet = WithHelmet({
    title: "- Login",
    meta: [{ name: "og:url", content: "/login" }],
    image: {
        src: mainImagePath,
        width: 1200,
        height: 630,
        type: "image/jpeg"
    },
    description: "OR · Digital Signage - Login to control the content on your screens across the World."
});
const SignupWithHelmet = WithHelmet({
    title: "- Sign up",
    meta: [{ name: "og:url", content: "/signup" }],
    image: {
        src: mainImagePath,
        width: 1200,
        height: 630,
        type: "image/jpeg"
    },
    description:
        "OR · Digital Signage - Create an account to upload media files, create playlists and schedules, and sync them with players."
});
const login = WithAuthGuard({ authenticatedGuard: false })(LoginWithHelmet(Login));
const signup = WithAuthGuard({ authenticatedGuard: false })(SignupWithHelmet(SignUp));

function App() {
    const [state, dispatch] = useReducer(reducer, initialState);
    const [loading, setLoading] = useState(false);
    const { replace } = useHistory();
    const {
        loggedInUser,
        errorMessage,
        successMessage,
        loadingInitialData,
        insufficientlyAuthenticationDetails,
        uploadMediaInfo
    } = state;

    useEffect(() => {
        (async () => {
            const authToken = getAuthToken();
            if (!authToken) {
                dispatch({ type: "INITIAL_DATA_READY" });
            } else {
                try {
                    const response = await getLoggedInUser();
                    dispatch({ type: "LOGGED_IN_USER", payload: response.data });
                } catch (e) {
                    clearAuthToken();
                    dispatch({ type: "SHOW_MESSAGE_ERROR", payload: e });
                    replace("/login");
                }
            }
        })();
    }, [replace]);

    if (loadingInitialData) {
        return null;
    }

    const onErrorSnackBarClose = () => {
        dispatch({ type: "CLOSE_MESSAGE_ERROR" });
    };
    const onSuccessSnackBarClose = () => {
        dispatch({ type: "CLOSE_MESSAGE_SUCCESS" });
    };

    const errorSnackbar = (
        <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            autoHideDuration={5000}
            transitionDuration={{ exit: 0 }}
            open={errorMessage !== ""}
            onClose={onErrorSnackBarClose}
        >
            <SnackbarContentWrapper onClose={onErrorSnackBarClose} variant="error" message={errorMessage} />
        </Snackbar>
    );

    const succesSnackbar = (
        <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            autoHideDuration={5000}
            transitionDuration={{ exit: 0 }}
            open={successMessage !== ""}
            onClose={onSuccessSnackBarClose}
        >
            <SnackbarContentWrapper onClose={onSuccessSnackBarClose} variant="success" message={successMessage} />
        </Snackbar>
    );
    return (
        <StrictMode>
            <AppDispatch.Provider value={dispatch}>
                <UserContext.Provider value={loggedInUser}>
                    <InsufficientlyAuthenticationDetailsContext.Provider value={insufficientlyAuthenticationDetails}>
                        <LoaderContext.Provider value={loading}>
                            <LoaderUpdateContext.Provider value={setLoading}>
                                <UploadInfoContext.Provider value={uploadMediaInfo}>
                                    <Switch>
                                        <Route path="/login" component={login} />
                                        <Route path="/signup" component={signup} />
                                        <Route path="/" component={withAuthGuard} />
                                    </Switch>
                                    {errorSnackbar}
                                    {succesSnackbar}
                                </UploadInfoContext.Provider>
                            </LoaderUpdateContext.Provider>
                        </LoaderContext.Provider>
                    </InsufficientlyAuthenticationDetailsContext.Provider>
                </UserContext.Provider>
            </AppDispatch.Provider>
        </StrictMode>
    );
}
export default App;
