import { BASE_DOMAIN } from "../constants/app";
import { IScheduleDTO } from "../interfaces/dto";
import { makeDelete, makeGet, makePatch, makePost } from "./main-api";

const API_URL = {
    ADD_SHEDULE: () => `${BASE_DOMAIN}/v1/schedule`,
    GET_SCHEDULES: (query: string) => `${BASE_DOMAIN}/v1/schedule?${query}`,
    RESOURCE: (id: string) => `${BASE_DOMAIN}/v1/schedule/${id}`,
    CHANGE_SCHEDULES_GROUP: (groupId: string) => `${BASE_DOMAIN}/v1/schedule/groups/${groupId}`
};

export const addSchedule = (data: IScheduleDTO) => {
    return makePost(API_URL.ADD_SHEDULE(), { schedule: data });
};

export const getSchedules = ({
    searchValue,
    start = 0,
    limit = 20,
    filters
}: {
    searchValue?: string;
    start?: number;
    limit?: number;
    filters?: { property: string; value?: string | string[] | boolean }[] | null;
}) => {
    let query = `start=${start}&limit=${limit}`;
    if (searchValue) {
        query += `&search=${searchValue}`;
    }
    if (filters && Array.isArray(filters) && filters.length) {
        for (let i = 0; i < filters.length; i++) {
            query += `&filters[${i}][property]=${filters[i].property}`;
            if (filters[i].value) {
                query += `&filters[${i}][value]=${filters[i].value}`;
            }
        }
    }
    return makeGet(API_URL.GET_SCHEDULES(query));
};
export const getSchedule = (id: string) => {
    return makeGet(API_URL.RESOURCE(id));
};
export const deleteSchedule = (id: string) => {
    return makeDelete(API_URL.RESOURCE(id));
};
export const updateSchedule = (id: string, data: any) => {
    return makePatch(API_URL.RESOURCE(id), { name: data });
};

export const changeSchedulesGroup = (groupId: string, schedules: string[]) => {
    return makePost(API_URL.CHANGE_SCHEDULES_GROUP(groupId), schedules);
};
