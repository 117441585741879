import { BASE_DOMAIN } from "../constants/app";
import { makeGet, makePost, makePatch, makeDelete } from "./main-api";
import { IGroupsWithMeta, ISimpleDataResponse, IMediaGroup } from "../interfaces/dto";

const API_URL = {
    GET_ADD: () => `${BASE_DOMAIN}/v1/media-group`,
    UPDATE_DELETE: (id: string) => `${BASE_DOMAIN}/v1/media-group/${id}`
};

export const getMediaGroups = () => {
    return makeGet<ISimpleDataResponse<IGroupsWithMeta>>(API_URL.GET_ADD()).then(d => d.data);
};
export const addMediaGroup = (name: string) => {
    return makePost<ISimpleDataResponse<IMediaGroup>>(API_URL.GET_ADD(), { name }).then(d => d.data);
};
export const deleteMediaGroup = (id: string) => {
    return makeDelete<void>(API_URL.UPDATE_DELETE(id));
};
export const updateMediaGroup = (id: string, name: string) => {
    return makePatch<ISimpleDataResponse<IMediaGroup>>(API_URL.UPDATE_DELETE(id), { name }).then(() => ({
        _id: id,
        name
    }));
};
