import { Divider, Drawer, IconButton, MenuItem, Typography, WithStyles } from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import MenuIcon from "@material-ui/icons/Menu";

import React, { useState } from "react";

import { createStyles, withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";

const styles = () =>
    createStyles({
        flexGrow: {
            flex: 1
        },
        selectedList: {
            fontSize: "16px",
            textTransform: "capitalize"
        },
        drawerPaper: {
            position: "relative",
            flex: 1
        },
        drawerHeader: {
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            padding: "0 8px 0 16px",
            minHeight: "64px",
            height: "64px"
        },
        flex1: {
            flex: 1
        },
        link: {
            color: "inherit",
            textDecoration: "none"
        }
    });

const NavigationMobile = ({
    classes,
    mobileRout,
    onMobileNavChange,
    orgSelector,
    upload
}: WithStyles<typeof styles> & {
    mobileRout: string;
    onMobileNavChange: (rout: string) => void;
    orgSelector: React.ReactNode;
    upload: React.ReactNode;
}) => {
    const [openDrawer, setOpenDrawer] = useState<boolean>(false);
    const onOpenDrawer = () => {
        setOpenDrawer(true);
    };
    const onDrawerClose = () => {
        setOpenDrawer(false);
    };
    const onChange = (rout: string) => {
        setOpenDrawer(false);
        onMobileNavChange(rout);
    };
    return (
        <>
            <IconButton color="inherit" aria-label="open drawer" onClick={onOpenDrawer}>
                <MenuIcon />
            </IconButton>
            <Typography variant="subtitle1" color="inherit">
                OR Digital Signage
            </Typography>
            <Typography className={classes.selectedList} color="inherit">
                &nbsp;-{" "}
                <Link className={classes.link} href={`/${mobileRout}`} to={`/${mobileRout}`}>
                    {mobileRout}
                </Link>
            </Typography>
            <div className={classes.flexGrow} />
            {upload}
            <Drawer
                variant="temporary"
                anchor="left"
                open={openDrawer}
                classes={{
                    paper: classes.drawerPaper
                }}
            >
                <div className={classes.drawerHeader}>
                    <Typography variant="subtitle1" color="inherit">
                        OR Digital Signage
                    </Typography>
                    <Typography className={classes.selectedList} color="inherit">
                        &nbsp;- {mobileRout}
                    </Typography>
                    <div className={classes.flex1} />
                    <IconButton onClick={onDrawerClose}>
                        <ChevronLeftIcon />
                    </IconButton>
                </div>
                <Divider />
                <MenuItem onClick={() => onChange("dashboard")}>Dashboard</MenuItem>
                <MenuItem onClick={() => onChange("devices")}>Devices</MenuItem>
                <MenuItem onClick={() => onChange("media")}>Media</MenuItem>
                <MenuItem onClick={() => onChange("playlists")}>Playlists</MenuItem>
                <MenuItem onClick={() => onChange("schedules")}>Schedules</MenuItem>
                <Divider />
                {orgSelector}
            </Drawer>
        </>
    );
};
export default withStyles(styles)(NavigationMobile);
