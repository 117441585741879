import { useEffect, useState } from "react";
import { getDevices } from "../../api/device";
import useStatuses, { updateStatusesOfDevices } from "./statuses";

export default function useDevicesWithStatuses(searchValue = "", group: string | null = null, reload = false) {
    const [devices, setDevices] = useState<any>([]);
    const { statuses } = useStatuses();
    const [isDeviceLoading, setIsDeviceLoading] = useState<boolean>(false);

    useEffect(() => {
        let canceled = false;
        (async () => {
            try {
                setIsDeviceLoading(true);
                const devicesAndStatuses = await getDevices({
                    searchValue
                });
                if (!canceled) {
                    let {
                        data: { devices: d }
                    } = devicesAndStatuses;
                    const {
                        data: { statuses: s }
                    } = devicesAndStatuses;
                    if (group) {
                        d = d.filter((device: { group: string }) => device.group === group);
                    }
                    d.sort((a: any, b: any) => a.order - b.order);
                    setDevices(updateStatusesOfDevices(s, d));
                    setIsDeviceLoading(false);
                }
                // tslint:disable-next-line:no-empty
            } catch (e) {
                if (!canceled) {
                    setIsDeviceLoading(false);
                }
            }
        })();
        return () => {
            canceled = true;
        };
    }, [searchValue, group, reload]);

    function replaceDevice(newDevice: any) {
        setDevices(
            devices.map((device: any) => {
                if (device._id === newDevice._id) {
                    return { ...device, ...newDevice };
                }
                return device;
            })
        );
    }

    useEffect(() => {
        setDevices((d: any) => updateStatusesOfDevices(statuses, d));
    }, [statuses]);

    return { devices, setDevices, replaceDevice, isDeviceLoading };
}
