import { WithStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import createStyles from "@material-ui/core/styles/createStyles";
import withStyles from "@material-ui/core/styles/withStyles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
// tslint:disable-next-line:no-implicit-dependencies
import moment from "moment";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Calendar, Event, EventPropGetter, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { getPlayList } from "../../api/play-list";
import { addSchedule, changeSchedulesGroup, getSchedule, updateSchedule } from "../../api/schedule";
import { EVENT_COLORS } from "../../constants/schedule-event";
import { GREEN_COLOR, RED_COLOR } from "../../constants/style-constants";
import { AppDispatch } from "../../context/main-context";
import { IEventDTO, IScheduleDTO } from "../../interfaces/dto";
import useScheduleGroup from "../hooks/schedule-groups";
import AddEvents from "./add-events";
import { getCalendarEvents } from "./calendar-events";
import { add as dateAdd, endOf as dateEndOf } from "./utils";

import "./schedule.css";

const styles = createStyles({
    root: {
        display: "flex",
        padding: 20,
        flexDirection: "column",
        overflowY: "auto",
        height: "calc(100vh - 72px)",
        boxSizing: "border-box"
    },
    body: {
        flex: 1,
        overflowY: "auto",
        "@media (min-width: 760px)": {
            display: "flex",
            flexDirection: "row",
            alignItems: "stretch",
            justifyContent: "stretch",
            overflowScrolling: "touch",
            WebkitOverflowScrolling: "touch"
        }
    },
    calendarWrapper: {
        height: 500,
        flex: 1,
        "&>div>div:first-child": {
            "@media (max-width: 1024px)": {
                flexDirection: "column"
            }
        }
    },
    scheduleForm: {
        display: "flex",
        flexDirection: "column"
    },
    scheduleFormActions: {
        display: "flex"
    },
    saveScheduleButton: {
        margin: 5,
        backgroundColor: GREEN_COLOR,
        color: "white"
    },
    cancelScheduleButton: {
        margin: 5,
        backgroundColor: RED_COLOR,
        color: "white"
    },
    scheduleWrapper: {
        // flex: 1,
        minWidth: 260,
        display: "flex",
        flexDirection: "column",
        paddingRight: 20
    },
    eventsWrapper: {
        padding: 5,
        flex: 1,
        overflowY: "auto",
        minWidth: 300,
        "@media (max-width: 760px)": {
            maxHeight: 800
        }
    },
    addEventActionWrapper: {
        padding: 5
    },
    addEventsButton: {
        backgroundColor: GREEN_COLOR,
        color: "white"
        // width: "80%"
    },
    playlistItem: {
        border: "none",
        boxShadow: "none",
        padding: "2px 5px",
        backgroundColor: "#3174ad",
        borderRadius: 5,
        color: "#fff",
        width: "80%",
        textAlign: "left",
        margin: "5px 0",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap"
    }
});
// moment.locale("ko", {
//     week: {
//         dow: 1,
//         doy: 1
//     }
// });
const localizer = momentLocalizer(moment);
export interface IAddedPlaylist {
    id: string;
    name: string;
    order: number;
}
const Schedule = ({
    classes,
    location: { pathname },
    history,
    match: { params }
}: WithStyles<typeof styles> & RouteComponentProps) => {
    const scheduleId = (params as any).id;
    const title = pathname.split("/")[2] ? "Edit schedule" : "Add schedule";
    const { group: storageGroup } = useScheduleGroup();
    const dispatch = useContext(AppDispatch)!;
    const [name, setName] = useState<string>("");
    const [description, setDescription] = useState<string>("");
    const [events, setEvents] = useState<IEventDTO[]>([]);
    const [isAddEditEvendsDialogOpen, setIsAddEditEvendsDialogOpen] = useState<boolean>(false);
    const [playlists, setPlaylists] = useState<any[]>([]);
    const [addedPlaylists, setAddedPlaylists] = useState<IAddedPlaylist[]>([]);
    const [schedule, setSchedule] = useState<IScheduleDTO | null>(null);
    const [calendarEvents, setCalendarEvents] = useState<Event[]>([]);
    const [saveButtonDisable, setSaveButtonDisable] = useState<boolean>(true);
    const [nameToColorMap, setNameToColorMap] = useState<{ [key: string]: string }>({});
    const [eventForEdit, setEventForEdit] = useState<IEventDTO | null>(null);
    const [myLatestTap, setMyLatestTap] = useState<number>(0);

    useEffect(() => {
        if (addedPlaylists.length) {
            const map: { [playlistName: string]: string } = {};
            for (const addedPlaylist of addedPlaylists) {
                map[addedPlaylist.name] = EVENT_COLORS[addedPlaylists.indexOf(addedPlaylist)];
            }
            setNameToColorMap(map);
        }
    }, [addedPlaylists]);

    const eventStyleGetter = useCallback<EventPropGetter<Event>>(
        (e: Event) => {
            const eventsStyle = {
                backgroundColor: `${nameToColorMap[e.title || ""]}` || "#4172ff"
            };
            return {
                style: eventsStyle,
                className: "calendar-event"
            };
        },
        [nameToColorMap]
    );
    useEffect(() => {
        if (playlists.length && events.length) {
            setCalendarEvents(getCalendarEvents(events, playlists));
        }
    }, [events, playlists]);
    useEffect(() => {
        let canceled = false;
        getPlayList({ limit: 1000 })
            .then(res => {
                if (!canceled) {
                    setPlaylists(res.data.data);
                }
            })
            .catch();
        return () => {
            canceled = true;
        };
    }, []);
    useEffect(() => {
        if (scheduleId) {
            setSaveButtonDisable(false);
        }
        let canceled = false;
        if (scheduleId) {
            getSchedule(scheduleId)
                .then(({ data }) => {
                    if (!canceled) {
                        setSchedule(data);
                        setEvents(data.events);
                        setName(data.name);
                        setDescription(data.description);
                    }
                })
                // tslint:disable-next-line:no-empty
                .catch(() => {});
        }
        return () => {
            canceled = true;
        };
    }, [scheduleId]);

    useEffect(() => {
        if (playlists.length) {
            // tslint:disable-next-line:no-shadowed-variable
            const mergeAddedPlaylists = (existingAddedPlaylists: IAddedPlaylist[], usedPlaylistUniqueIds: string[]) => {
                const result: IAddedPlaylist[] = [];
                for (const usedPlaylistUniqueId of usedPlaylistUniqueIds) {
                    const find = existingAddedPlaylists.find(p => p.id === usedPlaylistUniqueId);
                    if (find) {
                        result.push({ ...find });
                    } else {
                        result.push({
                            id: usedPlaylistUniqueId,
                            name: playlists.find(p => p._id === usedPlaylistUniqueId).name,
                            order: result.length
                        });
                    }
                }
                result.sort((a, b) => a.order - b.order);
                return result.map((item, i) => {
                    return { ...item, order: i };
                });
            };
            const usedPlaylistIds = events.map(e => e.playListId);
            const usedPlaylistUniqueIds = usedPlaylistIds.filter((id, i, arr) => arr.indexOf(id) === i);
            setAddedPlaylists(p => mergeAddedPlaylists([...p], usedPlaylistUniqueIds));
        }
    }, [events, playlists]);
    const onNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.target.value === "" ? setSaveButtonDisable(true) : setSaveButtonDisable(false);
        setName(e.target.value);
    };
    const onDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setDescription(e.target.value);
    };
    const onAddEventsClick = () => {
        setEventForEdit(null);
        setIsAddEditEvendsDialogOpen(true);
    };
    const ColoredDateCellWrapper = ({ children }: { children: any }) =>
        React.cloneElement(React.Children.only(children), {
            style: {
                backgroundColor: "lightblue"
            }
        });

    const onCancelBtnClick = () => {
        history.replace("/schedules");
    };
    const onAddEditEvent = (data: IEventDTO, event: IEventDTO | null) => {
        if (!event) {
            setEvents([...events, data]);
        } else {
            const eventIndex = events.indexOf(event);
            const newEvents = [...events];
            newEvents.splice(eventIndex, 1, data);
            setEvents(newEvents);
        }
    };
    const deleteEvent = (event: IEventDTO) => {
        const eventIndex = events.indexOf(event);
        const newEvents = [...events];
        newEvents.splice(eventIndex, 1);
        if (events.filter(e => e.playListId === event.playListId).length === 1) {
            const addedPlaylistIndex = addedPlaylists.indexOf(addedPlaylists.find(pl => pl.id === event.playListId)!);
            const newAddedPlaylists = [...addedPlaylists];
            newAddedPlaylists.splice(addedPlaylistIndex, 1);
            setAddedPlaylists(newAddedPlaylists);
        }
        setEvents(newEvents);
    };
    const onSaveSchedule = () => {
        const playLists = addedPlaylists.reduce(
            (prev, current) => [...prev, { playListId: current.id, order: current.order }],
            [] as { playListId: string; order: number }[]
        );
        const scheduleData: IScheduleDTO = {
            name,
            description: description && description.trim(),
            playLists,
            events
        };
        if (!schedule) {
            addSchedule(scheduleData)
                .then(d => {
                    if (storageGroup && storageGroup !== "-1") {
                        changeSchedulesGroup(storageGroup, [d.data._id])
                            .then(() => {
                                onCancelBtnClick();
                                dispatch({ type: "SHOW_MESSAGE_SUCCESS", payload: "Schedule added successfully" });
                            })
                            .catch(() => {
                                onCancelBtnClick();
                                dispatch({ type: "SHOW_MESSAGE_SUCCESS", payload: "Schedule added successfully" });
                            });
                    } else {
                        onCancelBtnClick();
                        dispatch({ type: "SHOW_MESSAGE_SUCCESS", payload: "Schedule added successfully" });
                    }
                })
                .catch(e => {
                    dispatch({ type: "SHOW_MESSAGE_ERROR", payload: e });
                });
        } else {
            updateSchedule(scheduleId, scheduleData)
                .then(() => onCancelBtnClick())
                // tslint:disable-next-line:no-empty
                .catch(e => {});
        }
    };
    const onDoubleClickEvent = (event: Event) => {
        if (isAddEditEvendsDialogOpen) {
            return;
        }
        setEventForEdit(events.find(e => e._id === event.resource) || null);
        setIsAddEditEvendsDialogOpen(true);
    };
    const handleCustomDoubleClick = (event: Event) => {
        if (isAddEditEvendsDialogOpen) {
            return;
        }
        const now = new Date().getTime();
        const timeSince = now - myLatestTap;
        if (timeSince < 600 && timeSince > 0) {
            setEventForEdit(events.find(e => e._id === event.resource) || null);
            setIsAddEditEvendsDialogOpen(true);
        }
        setMyLatestTap(new Date().getTime());
    };
    const onAddEditEventClose = () => {
        setEventForEdit(null);
        setIsAddEditEvendsDialogOpen(false);
    };
    return (
        <div className={classes.root}>
            <Typography>{title}</Typography>
            <div className={classes.body}>
                <div className={classes.scheduleWrapper}>
                    <div className={classes.scheduleForm}>
                        <TextField label="Name" margin="normal" value={name} onChange={onNameChange} />
                        <TextField
                            label="Description"
                            margin="normal"
                            value={description}
                            onChange={onDescriptionChange}
                        />
                        <div className={classes.scheduleFormActions}>
                            <Button
                                disabled={saveButtonDisable}
                                className={classes.saveScheduleButton}
                                variant="contained"
                                onClick={onSaveSchedule}
                            >
                                Save
                            </Button>
                            <Button
                                className={classes.cancelScheduleButton}
                                variant="contained"
                                onClick={onCancelBtnClick}
                            >
                                Cancel
                            </Button>
                        </div>
                    </div>
                    <div className={classes.addEventActionWrapper}>
                        <Button size="large" className={classes.addEventsButton} onClick={onAddEventsClick}>
                            <AddIcon />
                            Add Events
                        </Button>
                        <Typography>Playlists</Typography>
                    </div>
                    <div className={classes.eventsWrapper}>
                        {addedPlaylists.map(p => (
                            <div
                                key={p.id}
                                className={`${classes.playlistItem} playlistItem`}
                                style={{ backgroundColor: nameToColorMap[p.name] }}
                            >
                                {p.name}
                            </div>
                        ))}
                    </div>
                </div>
                <div className={classes.calendarWrapper}>
                    <Calendar
                        events={calendarEvents}
                        views={{
                            work_week: true,
                            day: true,
                            agenda: true,
                            month: true,
                            week: true
                        }}
                        step={60}
                        showMultiDayTimes
                        max={dateAdd(dateEndOf(new Date(2015, 17, 1), "day"), -1, "hours")}
                        defaultDate={new Date()}
                        components={{
                            timeSlotWrapper: ColoredDateCellWrapper as any
                        }}
                        localizer={localizer}
                        eventPropGetter={eventStyleGetter}
                        onDoubleClickEvent={onDoubleClickEvent}
                        onSelectEvent={handleCustomDoubleClick}
                    />
                </div>
            </div>
            {isAddEditEvendsDialogOpen && (
                <AddEvents
                    playlists={playlists}
                    onAddEditEvent={onAddEditEvent}
                    onClose={onAddEditEventClose}
                    event={eventForEdit}
                    deleteEvent={deleteEvent}
                />
            )}
        </div>
    );
};
export default withStyles(styles)(withRouter(Schedule));
