import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
import React from "react";

const SelectField = ({
    value,
    label,
    onChange,
    options
}: {
    options: { value: string; label: string }[];
    value: number | string | boolean | unknown;
    label: string;
    onChange: (event: React.ChangeEvent<{ name?: string; value: unknown }>, child: React.ReactNode) => void;
}) => (
    <FormControl style={{ margin: 10 }}>
        <Select value={value} onChange={onChange}>
            {options.map((option, index) => (
                <MenuItem key={index} value={option.value}>
                    {option.label}
                </MenuItem>
            ))}
        </Select>
        <InputLabel>{label}</InputLabel>
    </FormControl>
);

export default SelectField;
