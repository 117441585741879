import { BASE_DOMAIN } from "../constants/app";
import { makeGet, makePost, makePatch, makeDelete } from "./main-api";

const API_URL = {
    GET_ADD: () => `${BASE_DOMAIN}/v1/schedule-group`,
    UPDATE_DELETE: (id: string) => `${BASE_DOMAIN}/v1/schedule-group/${id}`
};

export const getScheduleGroup = () => {
    return makeGet(API_URL.GET_ADD());
};
export const addScheduleGroup = (name: string) => {
    return makePost(API_URL.GET_ADD(), { name });
};
export const deleteScheduleGroup = (id: string) => {
    return makeDelete(API_URL.UPDATE_DELETE(id));
};
export const updateScheduleGroup = (id: string, name: string) => {
    return makePatch(API_URL.UPDATE_DELETE(id), { name });
};
