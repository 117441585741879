import { Theme } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";

import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Divider from "@material-ui/core/Divider";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelActions from "@material-ui/core/ExpansionPanelActions";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from "@material-ui/core/InputLabel";
import createStyles from "@material-ui/core/styles/createStyles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import CheckedIcon from "@material-ui/icons/CheckOutlined";
import ErrorIcon from "@material-ui/icons/Error";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { checkUsernameExisting, getGravatarUrl, hasGravatar, patchUser } from "../../../../api/main-api";
import { AppDispatch, UserContext } from "../../../../context/main-context";
import { validateFullname, validateUsername } from "../../../../utils/validationsUtil";

const styles = (theme: Theme) =>
    createStyles({
        contentWrap: {
            display: "flex",
            flexDirection: "row",
            alignItems: "stretch",
            width: "100%"
        },
        avatarWrap: {
            width: "100px"
        },
        infoWrap: {
            flex: "1 1 0%",
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
            justifyContent: "space-between"
        },
        column33: {
            flexBasis: "33.33%"
        },
        heading: {
            fontSize: theme.typography.pxToRem(15)
        },
        details: {
            alignItems: "center"
        },
        formControl: {
            marginTop: 8,
            marginBottom: 8
        },
        userNameAdornment: {
            marginRight: 12
        }
    });
const GeneralOptions = ({
    classes,
    expanded,
    handleClose,
    handleExpansionChange
}: WithStyles<typeof styles> & {
    expanded: string | false;
    handleClose: () => void;
    handleExpansionChange: (panel: string) => (event: any, newExpand: boolean) => void;
}) => {
    const userData = useContext(UserContext);

    const [profilePic, setProfilePic] = useState<string | null>(null);
    const [fullName, setFullname] = useState<string | undefined>(userData!.fullName);
    const [fullNameError, setFullnameError] = useState<string>("");
    const [username, setUserName] = useState<string>(userData!.username);
    const [usernameError, setUsernameError] = useState<string>("");
    const [isCheckingUsername, setIsCheckingUsername] = useState<boolean>(false);
    const [userNameNotExists, setUserNameNotExists] = useState<boolean>(true);
    const dispatch = useContext(AppDispatch)!;

    useEffect(() => {
        let canceled = false;
        let timerId: NodeJS.Timeout;
        if (username && userData!.username !== username) {
            timerId = setTimeout(() => {
                setIsCheckingUsername(true);
                setUserNameNotExists(false);
                checkUsernameExisting({
                    username
                })
                    .then((d: any) => {
                        if (!canceled) {
                            if (d && !d.data) {
                                setUserNameNotExists(true);
                                setUsernameError("");
                            } else {
                                setUsernameError("Username exists");
                            }
                            setIsCheckingUsername(false);
                        }
                    })
                    .catch(e => {
                        dispatch({ type: "SHOW_MESSAGE_ERROR", payload: e });
                        if (!canceled) {
                            setIsCheckingUsername(false);
                        }
                    });
            }, 100);
        }
        return () => {
            if (timerId) {
                canceled = true;
                clearTimeout(timerId);
            }
        };
    }, [username, dispatch, userData]);

    const handleFullNameChange = (e: any) => {
        setFullname(e.target.value);
    };
    useEffect(() => {
        const { profilePic: originProfilePic } = userData!;
        if (!originProfilePic) {
            setProfilePic("");
        } else {
            const { type, url } = originProfilePic;
            if (type === "gravatar") {
                const { email } = userData!;
                hasGravatar(email)
                    .then((data: any) => {
                        if (data) {
                            setProfilePic(getGravatarUrl(email));
                        } else {
                            setProfilePic("");
                        }
                    })
                    .catch(() => {
                        setProfilePic("");
                    });
            } else if (url) {
                setProfilePic(url);
            } else {
                setProfilePic("");
            }
        }
    }, [profilePic, userData]);
    const handleUsernameChange = (e: any) => {
        setUserName(e.target.value);
    };
    const validGeneral = (setToState = true) => {
        let errors = {
            fullNameError: "",
            userNameError: ""
        };
        let isValid = true;
        const { isValid: isValidFullname, errors: fullNameErrors } = validateFullname(fullName!);
        isValid = isValid && isValidFullname;
        errors = {
            ...errors,
            ...fullNameErrors
        };
        const { isValid: isValidUserName, errors: userNameErrors } = validateUsername(username);
        isValid = isValid && isValidUserName;
        errors = {
            ...errors,
            ...userNameErrors
        };
        if (setToState && Object.keys(errors).length) {
            setFullnameError(errors.fullNameError);
            setUsernameError(errors.userNameError);
        }
        return isValid;
    };
    const handleSubmitData = () => {
        if (
            userNameNotExists &&
            validGeneral() &&
            (userData!.fullName !== fullName || userData!.username !== username)
        ) {
            const objSend = userData;
            objSend!.fullName = fullName;
            objSend!.username = username;
            patchUser(objSend)
                .then(response => {
                    // TODO
                })
                .catch(event => {
                    dispatch({ type: "SHOW_MESSAGE_ERROR", payload: event });
                });
        }
    };
    const onKeyDown = (e: any) => {
        switch (e.keyCode) {
            case 13: {
                if (typeof handleSubmitData === "function") {
                    handleSubmitData();
                }
                break;
            }
            default:
                break;
        }
    };
    return (
        <ExpansionPanel expanded={expanded === "general"} onChange={handleExpansionChange("general")}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <div className={classes.column33}>
                    <Typography className={classes.heading}>General Options</Typography>
                </div>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.details}>
                <div className={classes.contentWrap}>
                    <div className={classes.avatarWrap}>
                        {profilePic ? <Avatar src={profilePic} /> : <Avatar>{fullName!.charAt(0)}</Avatar>}
                    </div>
                    <div className={classes.infoWrap}>
                        <FormControl className={classes.formControl} required error={!!fullNameError}>
                            <InputLabel htmlFor="profile-fullName-field">Full Name</InputLabel>
                            <Input
                                id="profile-fullName-field"
                                autoComplete="name"
                                value={fullName}
                                autoFocus
                                onChange={handleFullNameChange}
                                onKeyDown={onKeyDown}
                            />
                            {fullNameError && <FormHelperText error>{fullNameError}</FormHelperText>}
                        </FormControl>
                        <FormControl className={classes.formControl} required error={!!usernameError}>
                            <InputLabel htmlFor="profile-username-field">Username</InputLabel>
                            <Input
                                id="profile-username-field"
                                autoComplete="off"
                                value={username}
                                onChange={handleUsernameChange}
                                onKeyDown={onKeyDown}
                                endAdornment={
                                    username && (
                                        <InputAdornment className={classes.userNameAdornment} position="end">
                                            {isCheckingUsername ? (
                                                <CircularProgress size={18} />
                                            ) : userNameNotExists ? (
                                                <CheckedIcon color="primary" />
                                            ) : (
                                                <ErrorIcon color="error" />
                                            )}
                                        </InputAdornment>
                                    )
                                }
                            />
                            {usernameError && <FormHelperText error>{usernameError}</FormHelperText>}
                        </FormControl>
                    </div>
                </div>
            </ExpansionPanelDetails>
            <Divider />
            <ExpansionPanelActions>
                <Button size="small" onClick={handleClose}>
                    Cancel
                </Button>
                <Button size="small" color="primary" onClick={handleSubmitData}>
                    Save
                </Button>
            </ExpansionPanelActions>
        </ExpansionPanel>
    );
};

export default withStyles(styles)(GeneralOptions);
