import { FormControl, InputLabel, MenuItem, Select, TextField, FormControlLabel } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Input from "@material-ui/core/Input";
import CheckBox from "@material-ui/core/Checkbox";
import { withStyles } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import { updateDevice } from "../../../../api/device";
import { getDevicesStatuses, getGeoLocation } from "../../../../api/main-api";
import { getPlayList } from "../../../../api/play-list";
import { getSchedules } from "../../../../api/schedule";
import { GREEN_COLOR } from "../../../../constants/style-constants";
import { AppDispatch, LoaderUpdateContext } from "../../../../context/main-context";
import { DeviceContext, DeviceUpdaterContext } from "../context";

const styles = () =>
    createStyles({
        field: {
            marginBottom: 20,
            whiteSpace: "nowrap",
            textOverflow: "eleepses",
            overflow: "hidden"
        },
        row: {
            display: "flex",
            alignItems: "center",
            "@media (max-width: 1280px)": {
                display: "block"
            }
        },
        latitudeField: {
            margin: 10,
            "@media (max-width: 1280px)": {
                margin: "10px 0"
            }
        },
        longitudeField: {
            margin: 10,
            "@media (max-width: 1280px)": {
                margin: "10px 0"
            }
        },
        detectLocationBtn: {
            backgroundColor: GREEN_COLOR,
            color: "white"
        },
        actions: {
            display: "flex",
            justifyContent: "center"
        }
    });

const DeviceGeneralForm = ({
    classes,
    handleCancel
}: {
    classes: Partial<ClassNameMap<string>>;
    handleCancel: () => void;
}) => {
    const device = useContext(DeviceContext);
    const updateDeviceAPI = useContext(DeviceUpdaterContext)!;
    const dispatch = useContext(AppDispatch)!;

    const [playLists, setPlayLists] = useState<any[]>([]);
    const [schedules, setSchedules] = useState<any[]>([]);
    const [playlistId, setPlaylistId] = useState<string>("");
    const [presentationPlayListId, setPresentationPlayListId] = useState<string>("");
    const [scheduleId, setScheduleId] = useState<string>("");
    const [saveInProcess, setSaveInProcess] = useState<boolean>(false);
    const setLoading = useContext(LoaderUpdateContext)!;

    const { description, name, latitude: lat, longitude, additionalOptions = {} } = device;

    const latitude = lat || "";

    useEffect(() => {
        let canceled = false;
        (async () => {
            let playlistData = [];
            let scheduletData = [];
            try {
                playlistData = await getPlayList({ limit: 1000 });
                playlistData = playlistData.data.data || [];
                scheduletData = await getSchedules({ limit: 1000 });
                scheduletData = scheduletData.data.data || [];
            } catch (e) {
                playlistData = [];
                scheduletData = [];
            }
            if (!canceled) {
                setPlayLists(playlistData);
                setSchedules(scheduletData);
                setPlaylistId(device.playlistId);
                setPresentationPlayListId(device.presentationPlayListId);
                setScheduleId(device.scheduleId || "none");
            }
        })();
        return () => {
            canceled = true;
        };
    }, [device]);

    const handleDetectLocation = () => {
        getGeoLocation({ ip: device.ip })
            .then(data => {
                updateDeviceAPI.updateLocation(data.data.lat, data.data.lon);
            })
            // tslint:disable-next-line:no-empty
            .catch(() => {});
    };

    const handleDescriptionChange = (e: ChangeEvent<HTMLInputElement>) => {
        updateDeviceAPI.updateDescription(e.target.value);
    };

    const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
        updateDeviceAPI.updateName(e.target.value);
    };

    const handlePlaylistChange = (e: any) => {
        setPlaylistId(e.target.value);
    };
    const handlePresentationPlaylistChange = (e: any) => {
        setPresentationPlayListId(e.target.value);
    };
    const handleScheduleChange = (e: any) => {
        setScheduleId(e.target.value);
    };

    const handleLatitudeChange = (e: any) => {
        updateDeviceAPI.updateLocation(e.target.value, longitude);
    };

    const handleLongitudeChange = (e: any) => {
        updateDeviceAPI.updateLocation(latitude, e.target.value);
    };

    const handleVerticalOrientationChange = (e: any) => {
        const checked = e.target.checked;
        if (checked) {
            updateDeviceAPI.updateaVerticalMode({ ...additionalOptions, verticalOrientation: e.target.checked });
        } else {
            updateDeviceAPI.updateaVerticalMode({
                ...additionalOptions,
                verticalOrientation: e.target.checked,
                rotateDirection: undefined
            });
        }
    };
    const handleVerticalWiseChange = (e: any) => {
        updateDeviceAPI.updateaVerticalMode({ ...additionalOptions, rotateDirection: e.target.value });
    };

    const onSaveDevice = (d: any) => {
        setSaveInProcess(true);
        setLoading(true);
        updateDevice(d)
            .then(data => {
                getDevicesStatuses(data.data._id)
                    .then(status => {
                        updateDeviceAPI.updateStatusses(status.data, data.data);
                        // tslint:disable-next-line:no-empty
                    })
                    // tslint:disable-next-line:no-empty
                    .catch(() => {});
                setSaveInProcess(false);
                setLoading(false);
                dispatch({
                    type: "SHOW_MESSAGE_SUCCESS",
                    payload: "Device updated  successfully"
                });
            })
            .catch(e => {
                setSaveInProcess(false);
                setLoading(false);
                dispatch({ type: "SHOW_MESSAGE_ERROR", payload: e });
            });
    };

    const handleSaveClick = () => {
        if (!name) {
            return;
        }
        const data: any = {
            _id: device._id,
            name,
            description,
            latitude,
            longitude,
            playlistId,
            scheduleId: scheduleId === "none" ? null : scheduleId,
            presentationPlayListId: presentationPlayListId === "none" ? null : presentationPlayListId,
            additionalOptions
        };
        onSaveDevice(data);
    };

    const onSubmit = (e: any) => {
        e.preventDefault();
        handleSaveClick();
    };

    return (
        <form onSubmit={onSubmit} style={{ padding: 10 }} noValidate>
            <TextField
                className={classes.field}
                // autoFocus
                margin="dense"
                id="name"
                value={name}
                onChange={handleNameChange}
                label="Name"
                fullWidth
                required
            />
            <TextField
                className={classes.field}
                margin="dense"
                id="description"
                value={description}
                onChange={handleDescriptionChange}
                label="Description"
                fullWidth
                multiline
                rows={4}
            />
            <FormControl fullWidth disabled={playLists.length === 0} className={classes.field}>
                <InputLabel htmlFor="playlist">Playlist</InputLabel>
                <Select value={playlistId} onChange={handlePlaylistChange}>
                    {playLists.map(list => (
                        <MenuItem key={list._id} value={list._id}>
                            {list.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <FormControl fullWidth disabled={schedules.length === 0} className={classes.field}>
                <InputLabel htmlFor="schedule">Schedule</InputLabel>
                <Select value={scheduleId} onChange={handleScheduleChange}>
                    <MenuItem key={"none"} value={"none"}>
                        None
                    </MenuItem>
                    {schedules.map(s => (
                        <MenuItem key={s._id} value={s._id}>
                            {s.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <FormControl fullWidth disabled={playLists.length === 0} className={classes.field}>
                <InputLabel htmlFor="presentationPlaylist">Presentation Playlist</InputLabel>
                <Select value={presentationPlayListId || "none"} onChange={handlePresentationPlaylistChange}>
                    <MenuItem key={"none"} value={"none"}>
                        None
                    </MenuItem>
                    {playLists.map(p => (
                        <MenuItem key={p._id} value={p._id}>
                            {p.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <div className={classes.row}>
                <FormControlLabel
                    style={{ flex: 1, margin: 5 }}
                    control={
                        <CheckBox
                            color="primary"
                            id="vertical-orientation-checkbox"
                            onChange={handleVerticalOrientationChange}
                            checked={additionalOptions && additionalOptions.verticalOrientation}
                        />
                    }
                    label="Vertical Orientation"
                />
                {additionalOptions && additionalOptions.verticalOrientation && (
                    <FormControl style={{ flex: 1, margin: 5 }}>
                        <InputLabel htmlFor="vertical-orientation-wise">Rotate</InputLabel>
                        <Select
                            id="vertical-orientation-wise"
                            value={additionalOptions && additionalOptions.rotateDirection}
                            onChange={handleVerticalWiseChange}
                        >
                            <MenuItem value={"clockwise"}>Clockwise (CW)</MenuItem>
                            <MenuItem value={"counterclockwise"}>Counterclockwise (CCW)</MenuItem>
                        </Select>
                    </FormControl>
                )}
            </div>
            <div className={classes.row}>
                <FormControl className={classes.latitudeField} fullWidth>
                    <InputLabel htmlFor="device-update-latitude">Latitude</InputLabel>
                    <Input
                        id="device-update-latitude"
                        autoComplete="off"
                        value={latitude}
                        onChange={handleLatitudeChange}
                        autoFocus
                        type="number"
                        inputProps={{ step: "0.0000000000000000001" }}
                    />
                </FormControl>
                <FormControl className={classes.longitudeField} fullWidth>
                    <InputLabel htmlFor="device-update-longitude">Longitude</InputLabel>
                    <Input
                        id="device-update-longitude"
                        autoComplete="off"
                        value={longitude}
                        onChange={handleLongitudeChange}
                        autoFocus
                        type="number"
                        inputProps={{ step: "0.000000000000000001" }}
                    />
                </FormControl>
                <Button
                    color="inherit"
                    variant="contained"
                    className={classes.detectLocationBtn}
                    onClick={handleDetectLocation}
                >
                    Detect
                </Button>
            </div>
            <div className={classes.actions}>
                <Button type="submit" disabled={saveInProcess || !name} color="primary">
                    Save
                </Button>
                <Button onClick={handleCancel} color="primary">
                    Cancel
                </Button>
            </div>
        </form>
    );
};

export default withStyles(styles)(DeviceGeneralForm);
