import { FormControl, InputLabel, Input } from "@material-ui/core";
import React from "react";

const TextField = ({
    value,
    label,
    onChange
}: {
    value: number | string | boolean | unknown;
    label: string;
    onChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
}) => (
    <FormControl style={{ margin: 10 }}>
        <Input value={value} onChange={onChange} />
        <InputLabel>{label}</InputLabel>
    </FormControl>
);

export default TextField;
