import React from "react";

export function parseErrorMessage(error: any) {
    return (error.response && error.response.error) || error.message || error;
}

export function constractGlobalErrorMessage(error: string | Error[]) {
    if (typeof error === "string") {
        return error;
    }
    if (error.length === 0) {
        return "";
    }
    if (error.length === 1) {
        return error[0].message;
    }
    if (error.length > 1) {
        return (
            <ul>
                {error.map(err => {
                    const index = error.indexOf(err);
                    return <li key={index}>{err.message}</li>;
                })}
            </ul>
        );
    }
}
