export function warn(message: string) {
    console.warn(`
        ******************************
        *    WARNING IN OR · Digital Signage
        ******************************
        MESSAGE:

        ${message}
    `);
}
