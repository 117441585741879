import { useEffect, useState } from "react";
import { getDevice } from "../../api/device";
import useStatuses, { updateStatusesOfDevices } from "./statuses";

export default function useDeviceWithStatus(deviceId = "") {
    const [device, setDevice] = useState<any>(null);
    const { statuses } = useStatuses(deviceId);

    useEffect(() => {
        let canceled = false;
        (async () => {
            try {
                const deviceWithStatus = await getDevice(deviceId);
                if (!canceled) {
                    const { data: d } = deviceWithStatus;
                    if (!d) {
                        if (process.env.NODE_ENV === "development") {
                            throw new Error(`Device with id: ${deviceId} is not present`);
                        }
                        // TODO:: redirect back to /
                    }
                    const status = d.status;
                    setDevice(
                        updateStatusesOfDevices(
                            {
                                [d._id]: status
                            },
                            [d]
                        )[0]
                    );
                }
                // tslint:disable-next-line:no-empty
            } catch (e) {}
        })();
        return () => {
            canceled = true;
        };
    }, [deviceId]);

    useEffect(() => {
        setDevice((d: any) => updateStatusesOfDevices(statuses, d ? [d] : [])[0]);
    }, [statuses]);

    return { device, setDevice };
}
