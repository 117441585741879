import { FormControlLabel, Checkbox } from "@material-ui/core";
import React from "react";
const CheckboxField = ({
    checked,
    label,
    onChange
}: {
    checked: boolean;
    label: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
}) => (
    <FormControlLabel
        style={{ margin: 5 }}
        control={<Checkbox color="primary" checked={checked} onChange={onChange} />}
        label={label}
    />
);

export default CheckboxField;
