import React, { useEffect, useState } from "react";
import DateFnsUtils from "@date-io/date-fns";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    MenuItem,
    Select
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { WithMobileDialog } from "@material-ui/core/withMobileDialog";
import withMobileDialog from "@material-ui/core/withMobileDialog/withMobileDialog";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { KeyboardDatePicker, KeyboardTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import "date-fns";
// tslint:disable-next-line:no-implicit-dependencies
import moment from "moment";
import { v1 } from "uuid";
import { REPEAT_ARR, WEEK_DAYS } from "../../constants/schedule-event";
import { GREEN_COLOR, RED_COLOR } from "../../constants/style-constants";
import { IEventDTO } from "../../interfaces/dto";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            minWidth: 120,
            width: `calc(100% - ${2 * theme.spacing(1)}px)`
        },
        picker: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            minWidth: 120,
            flex: 1
        },
        fromTo: { display: "flex" },
        startEnd: { display: "flex" },
        monthlyDaysBtnGroup: {
            width: 500,
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            margin: "5px auto"
        },
        weeklyDaysBtnGroup: {
            margin: "5px auto"
        },
        monthlyDaysBtn: {
            width: 50,
            border: "1px solid rgba(0, 0, 0, 0.12)!important",
            marginLeft: "-1px!important",
            color: "black",
            "&.Mui-selected": {
                backgroundColor: GREEN_COLOR,
                color: "white",
                marginLeft: "-1px!important"
            },
            "&:hover": {
                backgroundColor: "#F0F9EF"
            },
            "&.Mui-selected:hover": {
                backgroundColor: "#59AC48"
            },
            "& .MuiTouchRipple-child": {
                backgroundColor: "#C5E7BF"
            }
        },
        weeklyDaysBtn: {
            width: 50,
            color: "black",
            "&.Mui-selected": {
                backgroundColor: GREEN_COLOR,
                color: "white",
                marginLeft: "-1px!important"
            },
            "&:hover": {
                backgroundColor: "#F0F9EF"
            },
            "&.Mui-selected:hover": {
                backgroundColor: "#59AC48"
            },
            "& .MuiTouchRipple-child": {
                backgroundColor: "#C5E7BF"
            }
        },
        canceAddEditEventButton: {
            color: RED_COLOR
        }
    })
);
interface IAddEventsProps {
    onClose: () => void;
    onAddEditEvent: (data: IEventDTO, event: IEventDTO | null) => void;
    deleteEvent: (event: IEventDTO) => void;
    playlists: any[];
    event: IEventDTO | null;
}
interface IRepeatItem {
    id: string;
    name: string;
}
const AddEvents = (props: IAddEventsProps & WithMobileDialog) => {
    const classes = useStyles();
    const { onClose, onAddEditEvent, deleteEvent, playlists, event, fullScreen } = props;
    const [playlist, setPlaylist] = useState<string>("");
    const [repeat, setRepeat] = useState<"weekly" | "none" | "daily" | "monthly">("none");
    const [from, setFrom] = useState<Date | null>(null);
    const [to, setTo] = useState<Date | null>(null);
    const [start, setStart] = useState<Date | null>(null);
    const [end, setEnd] = useState<Date | null>(null);
    const [repeatWeeklyDays, setRepeatWeeklyDays] = useState<string[]>([]);
    const [repeatMonthlyDays, setrepeatMonthlyDays] = useState<string[]>([]);

    useEffect(() => {
        if (event) {
            setPlaylist(event.playListId);
            setRepeat(event.repeat.type);
            setFrom(event.fromDate);
            setTo(event.toDate || null);
            const startTime = new Date();
            startTime.setHours(Number(event.start.split(":")[0]));
            startTime.setMinutes(Number(event.start.split(":")[1]));
            setStart(startTime);
            const endTime = new Date();
            endTime.setHours(Number(event.end.split(":")[0]));
            endTime.setMinutes(Number(event.end.split(":")[1]));
            setEnd(endTime);
            if (event.repeat.type === "monthly") {
                setrepeatMonthlyDays(event.repeat.options);
            } else if (event.repeat.type === "weekly") {
                setRepeatWeeklyDays(event.repeat.options);
            }
        }
    }, [event]);

    const onAddEditEventClick = () => {
        if (!start || !end || !from || !end || !playlist || !repeat) {
            alert("Please fill all fields");
            return;
        }
        const startTime = moment(start.toLocaleTimeString(), ["h:mm A"]).format("HH:mm");
        const endTime = moment(end.toLocaleTimeString(), ["h:mm A"]).format("HH:mm");
        let options: any = null;
        if (repeatMonthlyDays.length) {
            options = repeatMonthlyDays;
        }
        if (repeatWeeklyDays.length) {
            options = repeatWeeklyDays;
        }
        const repeatData = {
            type: repeat,
            options
        };
        const data: IEventDTO = {
            start: startTime,
            end: endTime,
            fromDate: from,
            toDate: to as Date,
            playListId: playlist,
            repeat: repeatData,
            _id: v1()
        };
        onAddEditEvent(data, event);
        onClose();
    };
    const onDeleteEventClick = () => {
        deleteEvent(event!);
        onClose();
    };
    const handleClose = () => {
        onClose();
    };
    const handlePlaylistsChange = (e: React.ChangeEvent<{ name?: string; value: unknown }>) => {
        setPlaylist(e.target.value as string);
    };
    const handleFromChange = (date: Date | null) => setFrom(date);
    const handleToChange = (date: Date | null) => setTo(date);
    const handleStartChange = (date: Date | null) => setStart(date);
    const handleEndChange = (date: Date | null) => setEnd(date);
    const handleRepeatChange = (e: React.ChangeEvent<{ name?: string; value: unknown }>) => {
        setRepeat(e.target.value as "weekly" | "none" | "daily" | "monthly");
        setRepeatWeeklyDays([]);
        setrepeatMonthlyDays([]);
    };
    // tslint:disable-next-line:no-shadowed-variable
    const handlerepeatWeeklyDaysChange = (event: React.MouseEvent<HTMLElement>, value: any) => {
        const index = repeatWeeklyDays.indexOf(value as string);
        const newDays = [...repeatWeeklyDays];
        if (index === -1) {
            newDays.push(value as string);
        } else {
            newDays.splice(index, 1);
        }
        setRepeatWeeklyDays(newDays);
    };
    // tslint:disable-next-line:no-shadowed-variable
    const handlerepeatMonthlyDaysChange = (event: React.MouseEvent<HTMLElement>, value: any) => {
        const index = repeatMonthlyDays.indexOf(value as string);
        const newDays = [...repeatMonthlyDays];
        if (index === -1) {
            newDays.push(value as string);
        } else {
            newDays.splice(index, 1);
        }
        setrepeatMonthlyDays(newDays);
    };
    const getRepeatMonthlyDaysButtons = () => {
        const data: React.ReactNode[] = [];
        for (let i = 1; i < 32; i++) {
            data.push(
                <ToggleButton className={classes.monthlyDaysBtn} key={String(i)} value={String(i)}>
                    {i}
                </ToggleButton>
            );
        }
        return data;
    };
    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Dialog open fullScreen={fullScreen} onClose={handleClose}>
                <DialogTitle>Events</DialogTitle>
                <DialogContent>
                    <FormControl className={classes.formControl}>
                        <InputLabel htmlFor="playlist-select">Playlist</InputLabel>
                        <Select
                            fullWidth
                            value={playlist}
                            onChange={handlePlaylistsChange}
                            inputProps={{
                                name: "playlist",
                                id: "playlist-select"
                            }}
                        >
                            {playlists.map((p: any) => (
                                <MenuItem key={p._id} value={p._id}>
                                    {p.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <div className={classes.fromTo}>
                        <KeyboardDatePicker
                            className={classes.picker}
                            disableToolbar
                            variant="inline"
                            format="dd MMM yyyy"
                            margin="normal"
                            id="from"
                            label="From"
                            value={from}
                            onChange={handleFromChange}
                            KeyboardButtonProps={{
                                "aria-label": "change date"
                            }}
                        />
                        <KeyboardDatePicker
                            className={classes.picker}
                            disableToolbar
                            variant="inline"
                            format="dd MMM yyyy"
                            margin="normal"
                            id="to"
                            label="To"
                            value={to}
                            onChange={handleToChange}
                            KeyboardButtonProps={{
                                "aria-label": "change date"
                            }}
                        />
                    </div>
                    <div className={classes.startEnd}>
                        <KeyboardTimePicker
                            className={classes.picker}
                            margin="normal"
                            id="start"
                            label="Start"
                            value={start}
                            onChange={handleStartChange}
                            KeyboardButtonProps={{
                                "aria-label": "change time"
                            }}
                        />
                        <KeyboardTimePicker
                            className={classes.picker}
                            margin="normal"
                            id="end"
                            label="End"
                            value={end}
                            onChange={handleEndChange}
                            KeyboardButtonProps={{
                                "aria-label": "change time"
                            }}
                        />
                    </div>
                    <FormControl className={classes.formControl}>
                        <InputLabel htmlFor="repeat-select">Repeat</InputLabel>
                        <Select
                            fullWidth
                            value={repeat}
                            onChange={handleRepeatChange}
                            inputProps={{
                                name: "repeat",
                                id: "repeat-select"
                            }}
                        >
                            <MenuItem key={"none"} value={"none"}>
                                None
                            </MenuItem>
                            {REPEAT_ARR.map((r: IRepeatItem) => (
                                <MenuItem key={r.id} value={r.id}>
                                    {r.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    {repeat === "weekly" && (
                        <div style={{ textAlign: "center" }}>
                            <ToggleButtonGroup
                                className={classes.weeklyDaysBtnGroup}
                                value={repeatWeeklyDays}
                                exclusive
                                onChange={handlerepeatWeeklyDaysChange}
                            >
                                {WEEK_DAYS.map((d: { id: string; name: string }) => (
                                    <ToggleButton className={classes.weeklyDaysBtn} key={d.id} value={d.id}>
                                        {d.name[0]}
                                    </ToggleButton>
                                ))}
                            </ToggleButtonGroup>
                        </div>
                    )}
                    {repeat === "monthly" && (
                        <div>
                            <ToggleButtonGroup
                                className={classes.monthlyDaysBtnGroup}
                                value={repeatMonthlyDays}
                                exclusive
                                onChange={handlerepeatMonthlyDaysChange}
                            >
                                {getRepeatMonthlyDaysButtons()}
                            </ToggleButtonGroup>
                        </div>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={onAddEditEventClick} color="primary" autoFocus>
                        {event ? "Save" : "Add"}
                    </Button>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    {event && (
                        <Button className={classes.canceAddEditEventButton} onClick={onDeleteEventClick} autoFocus>
                            Delete
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
        </MuiPickersUtilsProvider>
    );
};
export default withMobileDialog()(AddEvents);
