import { Theme, WithStyles, Tooltip } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import createStyles from "@material-ui/core/styles/createStyles";
import withStyles from "@material-ui/core/styles/withStyles";
import Toolbar from "@material-ui/core/Toolbar";
import UploadIcon from "@material-ui/icons/CloudUpload";
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { changeOrg, getGravatarUrl, hasGravatar, logout, saveAuthToken } from "../../api/main-api";
import { ROUTES_MAP } from "../../constants/routes";
import { BLUE_COLOR, GREEN_COLOR } from "../../constants/style-constants";
import { UserContext } from "../../context/main-context";
import AddOrganization from "../organization/organization-add-dialog";
import Navigation from "./navigation";
import NavigationMobile from "./navigation-mobile";
import Profile from "./profile/profile";
import { UploadInfoContext } from "../../context/main-context";
import "./helper.css";
import Settings from "./settings/settings";
import useUnmounted from "../hooks/use-unmount";
import { createOrganization } from "../../api/organization-api";

const styles = ({ palette }: Theme) =>
    createStyles({
        toolbar: {
            backgroundColor: BLUE_COLOR,
            color: "white",
            minHeight: 72
        },
        tabs: {
            flex: 1
        },
        menuIcon: {
            color: "#fff"
        },
        purpleAvatar: {
            margin: 10,
            color: "#fff",
            backgroundColor: "white"
        },
        avatarButton: {
            color: "blue"
        },
        orgSelector: {
            width: 200,
            "@media (min-width: 960px)": {
                margin: "16px 0 0 0",
                backgroundColor: "#3886EE"
            },
            "@media (max-width: 959px)": {
                margin: "5px 0 0 5px"
            }
        },
        uploadInfoWrapper: {
            width: 50,
            height: 35,
            textAlign: "center",
            overflow: "hidden",
            position: "relative",
            marginRight: 20,
            "@media (max-width: 600px)": {
                marginRight: 0
            }
        },
        uploadInfoWrapperTooltip: {
            width: 300,
            overflow: "hidden",
            position: "relative",
            margin: "10px 0",
            height: 18
        },
        uploadInfoProgress: {
            height: 5,
            backgroundColor: "white",
            position: "absolute",
            width: "100%",
            bottom: 0,
            opacity: 0.8,
            whiteSpace: "nowrap",
            overflow: "hidden"
        },
        uploadInfoProgressPercent: {
            height: 5,
            backgroundColor: GREEN_COLOR,
            position: "absolute",
            bottom: 0,
            transition: "width 1s",
            transitionTimingFunction: "ease-in",
            whiteSpace: "nowrap",
            overflow: "hidden"
        },
        uploadInfoProgressTooltip: {
            color: "black",
            height: 18,
            display: "flex"
        },
        uploadInfoProgressPercentTooltip: {
            color: "black",
            height: 18,
            display: "flex"
        },
        uploadInfoTooltipFileName: {
            minWidth: 220,
            padding: "2px 0 2px 5px",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis"
        },
        uploadInfoTooltipProgress: {
            minWidth: 70,
            padding: "2px 0 2px 5px"
        }
    });

const AppHeader = ({ classes }: WithStyles<typeof styles>) => {
    let initialRouteIndex = 0;
    const history = useHistory();
    const location = useLocation();
    const user = useContext(UserContext)!;
    if (ROUTES_MAP.indexOf(location.pathname) !== -1) {
        initialRouteIndex = ROUTES_MAP.indexOf(location.pathname);
    } else if (ROUTES_MAP.indexOf("/" + location.pathname.split("/")[1] + "s") !== -1) {
        initialRouteIndex = ROUTES_MAP.indexOf("/" + location.pathname.split("/")[1] + "s");
    }
    const initialRoute = ROUTES_MAP[initialRouteIndex] && ROUTES_MAP[initialRouteIndex].slice(1);
    const { email, orgs } = user;
    // const [value, setValue] = useState(initialRouteIndex);
    const [anchorEl, setAnchorEl] = useState(null);
    const [mod, setMod] = useState<"profile" | "settings" | "about" | "addOrganization" | "">("");
    const [orgVal, setOrgValue] = useState<any>(orgs.find((f: any) => f.default)._id);
    const [profilePic, setProfilePic] = useState<string>("");
    // const [mobileRout, setMobileRout] = useState<string>(initialRoute);
    const [showUploadInfo, setShowUploadInfo] = useState<boolean>(false);
    const [uploadFinishedClass, setUploadFinishedClass] = useState<string>("");
    const uploadInfo = useContext(UploadInfoContext);
    const unMounted = useUnmounted();

    useEffect(() => {
        let timerForClose: NodeJS.Timeout;
        let timerForAnimate: NodeJS.Timeout;
        if (uploadInfo.length) {
            setShowUploadInfo(true);
        } else {
            timerForClose = setTimeout(() => {
                setUploadFinishedClass("uploadInfoWrapperFinished");
            }, 1000);
            timerForAnimate = setTimeout(() => {
                setUploadFinishedClass("");
                setShowUploadInfo(false);
            }, 10000);
        }
        return () => {
            clearTimeout(timerForClose);
            clearTimeout(timerForAnimate);
        };
    }, [uploadInfo.length]);

    const handleChange = (event: React.ChangeEvent<{}>, v: number) => {
        history.replace(ROUTES_MAP[v]);
        // setValue(v);
    };
    const onMobileNavChange = (rout: string) => {
        history.replace(`/${rout}`);
        // setMobileRout(rout);
    };
    let avatarCmp;
    const handleProfileSet = (m: "profile" | "settings" | "about" | "addOrganization" | "") => {
        setMod(m);
    };
    const handleModClose = () => {
        setMod("");
    };
    const handleMenuClick = (e: any) => {
        setAnchorEl(e.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        setAnchorEl(null);
        logout();
    };
    const handleChangeOrgClick = (e: any) => {
        setOrgValue(e.target.value);
        changeOrg(e.target.value)
            .then(resp => {
                saveAuthToken(resp.data.authToken);
                window.location.reload();
            })
            .catch(() => {
                // TODO
            });
    };

    useEffect(() => {
        let canceled = false;
        hasGravatar(email)
            .then((data: any) => {
                if (canceled) {
                    return;
                }
                if (data.data) {
                    setProfilePic(getGravatarUrl(email));
                } else {
                    setProfilePic("");
                }
            })
            .catch(() => {
                if (!canceled) {
                    setProfilePic("");
                }
            });
        return () => {
            canceled = true;
        };
    }, [email]);

    const handleAddOrganization = (name: string, description: string) => {
        createOrganization({ name, description }).then((data: any) => {
            if (unMounted.current) {
                return;
            }
            if (data && data.data) {
                setOrgValue(data.data._id);
                changeOrg(data.data._id)
                    .then(resp => {
                        saveAuthToken(resp.data.authToken);
                        window.location.reload();
                    })
                    .catch(() => {
                        // TODO
                    });
            }
        });
    };

    const orgSelector =
        orgs.length < 2 ? (
            ""
        ) : (
            <FormControl className={classes.orgSelector}>
                <InputLabel htmlFor="age-helper" style={{ color: "white" }}>
                    Organization
                </InputLabel>
                <Select value={orgVal} onChange={handleChangeOrgClick} input={<Input name="age" id="age-helper" />}>
                    {orgs.map((r: any) => (
                        <MenuItem key={r._id} value={r._id}>
                            {r.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        );
    if (profilePic) {
        avatarCmp = (
            <IconButton
                disableRipple
                aria-owns={anchorEl ? "admin-menu" : ""}
                aria-haspopup="true"
                onClick={handleMenuClick}
                className={classes.avatarButton}
            >
                <Avatar src={profilePic} alt={user.fullName!.charAt(0)} />
            </IconButton>
        );
    } else {
        avatarCmp = (
            <Avatar className={classes.purpleAvatar}>
                <Button
                    aria-label="More"
                    aria-owns={anchorEl ? "admin-menu" : ""}
                    aria-haspopup="true"
                    onClick={handleMenuClick}
                    className={classes.avatarButton}
                >
                    {user.fullName!.charAt(0)}
                </Button>
            </Avatar>
        );
    }

    const upload: React.ReactNode = showUploadInfo ? (
        <Tooltip
            title={
                uploadInfo.length
                    ? uploadInfo.map(i => (
                          <div key={i.uniqueIdentifier} className={`${classes.uploadInfoWrapperTooltip}`}>
                              <div className={`${classes.uploadInfoProgress} ${classes.uploadInfoProgressTooltip}`}>
                                  <div className={classes.uploadInfoTooltipFileName}>{i.file.name}</div>
                                  <div className={classes.uploadInfoTooltipProgress}>{` ${Math.floor(
                                      i.progress(true) * 100
                                  )}% complete`}</div>
                              </div>
                              <div
                                  className={`${classes.uploadInfoProgressPercent} ${classes.uploadInfoProgressPercentTooltip}`}
                                  style={{
                                      width: `${Math.floor(i.progress(true) * 100)}%`
                                  }}
                              >
                                  <div className={classes.uploadInfoTooltipFileName}>{i.file.name}</div>
                                  <div className={classes.uploadInfoTooltipProgress}>{` ${Math.floor(
                                      i.progress(true) * 100
                                  )}% complete`}</div>
                              </div>
                          </div>
                      ))
                    : "Upload complete"
            }
        >
            <div className={`${classes.uploadInfoWrapper} uploadInfoWrapper ${uploadFinishedClass}`}>
                <UploadIcon />
                <div className={classes.uploadInfoProgress}></div>
                <div
                    className={classes.uploadInfoProgressPercent}
                    style={{
                        width: `${Math.min(
                            ...uploadInfo.map((i: Resumable.ResumableFile) => Math.floor(i.progress(true) * 100))
                        )}%`
                    }}
                ></div>
            </div>
        </Tooltip>
    ) : (
        <></>
    );
    return (
        <Toolbar color="primary" className={classes.toolbar}>
            <Hidden smDown>
                <Navigation
                    value={initialRouteIndex == 5 ? false : initialRouteIndex}
                    handleChange={handleChange}
                    orgSelector={orgSelector}
                    upload={upload}
                />
            </Hidden>
            <Hidden mdUp>
                <NavigationMobile
                    mobileRout={initialRoute}
                    onMobileNavChange={onMobileNavChange}
                    orgSelector={orgSelector}
                    upload={upload}
                />
            </Hidden>
            {avatarCmp}
            <Menu id="admin-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
                <MenuItem
                    onClick={() => {
                        handleProfileSet("profile");
                        handleMenuClose();
                    }}
                >
                    <div>Profile</div>
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        handleProfileSet("settings");
                        handleMenuClose();
                    }}
                >
                    <div>Settings</div>
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        handleMenuClose();
                        history.replace("/users");
                    }}
                >
                    Manage users
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        handleProfileSet("addOrganization");
                        handleMenuClose();
                    }}
                >
                    <div>Add Organization</div>
                </MenuItem>
                <MenuItem onClick={handleLogout}>
                    <div>Logout</div>
                </MenuItem>
            </Menu>
            {mod === "profile" && <Profile handleClose={handleModClose} />}
            {mod === "settings" && <Settings handleClose={handleModClose} />}
            {mod === "addOrganization" && <AddOrganization close={handleModClose} onSave={handleAddOrganization} />}
        </Toolbar>
    );
};
export default withStyles(styles)(AppHeader);
