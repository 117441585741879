import { Hidden, Fab } from "@material-ui/core";
import { WithStyles, withStyles } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import React, { useEffect, useState, useContext } from "react";
import { deleteOrganizationUser, getOrganizationUser, inviteOrgUser, updateOrgUserAccess } from "../../api/user-api";
import AlertDialog from "../shared/alert-delete-dialog";
import OrganizationUsers from "./organization-users";
import AddIcon from "@material-ui/icons/Add";
import EditInviteUser from "./user-edit-invite-dialog";
import { AppDispatch } from "../../context/main-context";
import useUnmounted from "../hooks/use-unmount";

const styles = () =>
    createStyles({
        root: {
            display: "flex",
            flexDirection: "column",
            maxHeight: `calc(100vh - 176px)`,
            height: `calc(100vh - 176px)`,
            width: "100vw"
        },
        addButton: {
            position: "fixed",
            right: 16,
            bottom: 16
        },
        addButtonMobile: {
            position: "fixed",
            right: 5,
            bottom: 5
        }
    });

const Organization = ({ classes }: WithStyles<typeof styles>) => {
    const dispatch = useContext(AppDispatch)!;
    const unMounted = useUnmounted();
    const [doGetOrganizationUsers, setDoGetOrganizationUsers] = useState<boolean>(true);
    const [removedUserEmail, setRemovedUserEmail] = useState<string | null>(null);
    const [isInviteUserDialogOpen, setIsInviteUserDialogOpen] = useState<boolean>(false);
    const [organizationUsers, setOrganizationUsers] = useState<any[]>([]);
    const [editInviteUserPopUpMode, setEditInviteUserPopUpMode] = useState<"invite" | "edit" | "">("");
    const [userForEdit, setUserForEdit] = useState<any>({});

    useEffect(() => {
        let canceled = false;
        getOrganizationUser()
            .then((data: { data: React.SetStateAction<any[]> }) => {
                if (!canceled) {
                    setOrganizationUsers(data.data);
                }
            })
            // tslint:disable-next-line:no-empty
            .catch(() => {});
        return () => {
            canceled = true;
        };
    }, [doGetOrganizationUsers]);

    const onInviteUserSave = (data: any) => {
        setIsInviteUserDialogOpen(false);
        inviteOrgUser(data)
            .then(data => {
                if (data && data.data && data.data.user && !unMounted.current) {
                    setDoGetOrganizationUsers(!doGetOrganizationUsers);
                }
            })
            .catch(e => {
                dispatch({ type: "SHOW_MESSAGE_ERROR", payload: e });
            });
    };
    const handelDeleteOrgUser = (email: string) => {
        setRemovedUserEmail(email);
    };

    const onDeleteOrgUser = () => {
        if (removedUserEmail) {
            deleteOrganizationUser(removedUserEmail)
                .then(() => {
                    if (!unMounted.current) {
                        setRemovedUserEmail(null);
                        setDoGetOrganizationUsers(oldState => !oldState);
                    }
                })
                .catch(e => {
                    dispatch({ type: "SHOW_MESSAGE_ERROR", payload: e });
                });
        }
    };

    const onEditOrgUserClick = (user: any) => {
        setUserForEdit(user);
        setEditInviteUserPopUpMode("edit");
        setIsInviteUserDialogOpen(true);
    };

    const onEditOrgUserSave = (data: any) => {
        setEditInviteUserPopUpMode("");
        setIsInviteUserDialogOpen(false);
        setUserForEdit({});
        updateOrgUserAccess({ userId: userForEdit._id, ...data })
            .then()
            .catch(e => {
                dispatch({ type: "SHOW_MESSAGE_ERROR", payload: e });
            });
    };

    return (
        <div className={classes.root}>
            <OrganizationUsers
                organizationUsers={organizationUsers}
                onInviteUserSave={onInviteUserSave}
                handelDeleteOrgUser={handelDeleteOrgUser}
                onEditOrgUserClick={onEditOrgUserClick}
            />
            <Hidden mdUp>
                <Fab
                    color="primary"
                    aria-label="Add"
                    className={classes.addButtonMobile}
                    onClick={() => {
                        setEditInviteUserPopUpMode("invite");
                        setIsInviteUserDialogOpen(true);
                    }}
                >
                    <AddIcon />
                </Fab>
            </Hidden>
            <Hidden smDown>
                <Fab
                    color="primary"
                    aria-label="Add"
                    className={classes.addButton}
                    onClick={() => {
                        setEditInviteUserPopUpMode("invite");
                        setIsInviteUserDialogOpen(true);
                    }}
                >
                    <AddIcon />
                </Fab>
            </Hidden>
            {isInviteUserDialogOpen && (
                <EditInviteUser
                    userForEdit={userForEdit}
                    mode={editInviteUserPopUpMode}
                    onInvite={onInviteUserSave}
                    onEdit={onEditOrgUserSave}
                    close={() => {
                        setEditInviteUserPopUpMode("");
                        setUserForEdit({});
                        setIsInviteUserDialogOpen(false);
                    }}
                />
            )}
            {removedUserEmail && (
                <AlertDialog
                    del={onDeleteOrgUser}
                    handleCloseAlert={() => {
                        setRemovedUserEmail(null);
                    }}
                    message="Are you sure you want to remove user?"
                />
            )}
        </div>
    );
};
export default withStyles(styles)(Organization);
