import { IGroupsWithMeta } from "../../../interfaces/dto";

export function calculateSelectedGroupId(
    hideAllTab: boolean,
    groupsWithMeta: IGroupsWithMeta,
    selectedGroupId: string | undefined
) {
    const { groups = [], dataCountWithoutGroup = 0 } = groupsWithMeta;
    if (selectedGroupId === "-1") {
        if (!hideAllTab) {
            return "-1";
        }
        //fix it.
        // 1. we have group show first one
        if (groups.length > 0) {
            return groups[0]._id;
        }
        // all is closed, no groups, just show without group tag
        return "-2";
    }

    if (selectedGroupId === "-2") {
        if (dataCountWithoutGroup > 0 && hideAllTab) {
            return "-2";
        }
        // fix it;
        // 1. we have group show last one
        if (groups.length > 0) {
            return groups[groups.length - 1]._id;
        }
        // we have "no group"
        if (!hideAllTab) {
            return "-1";
        }

        // all is closed, no groups, just show without group tag
        return "-2";
    }

    const idx = groups.findIndex(gr => gr._id === selectedGroupId);
    if (idx !== -1) {
        return selectedGroupId;
    }
    // we have full new groups, render All.
    if (!hideAllTab) {
        return "-1";
    }
    if (groups.length > 0) {
        return groups[0]._id;
    }
    // all is closed, no groups, just show without group tag
    return "-2";
}
