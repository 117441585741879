import React, { useState } from "react";
import { createStyles, WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { TextField } from "@material-ui/core";
import InputMask from "./input-mask";

const styles = createStyles({
    rootWrapper: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    root: {
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box",
        flexGrow: "inherit",
        padding: "15px 20px 0px 20px",
        width: 800,
        alignItems: "stretch"
    },
    column: {
        flex: 1
    },
    details: {
        flexDirection: "row"
    },
    enabledSwitch: {},
    heading: {},
    fieldset: {
        flexDirection: "column",
        display: "flex",
        flex: 1,
        border: "white"
    },
    form: {
        flexDirection: "column",
        display: "flex",
        flex: 1
    },
    secondaryHeading: {},
    between: {
        paddingBottom: 15
    }
});
const EthernetFields = ({
    classes,
    device,
    address,
    netMask,
    gateway,
    dns,
    onChangeAddress,
    onChangeDNS,
    onChangeNetMask,
    onChangeGateWay
}: WithStyles<typeof styles> & {
    device: any;
    address: string;
    netMask: string;
    gateway: string;
    dns: string;
    onChangeAddress: (e: any) => void;
    onChangeDNS: (e: any) => void;
    onChangeNetMask: (e: any) => void;
    onChangeGateWay: (e: any) => void;
}) => {
    const [netMaskErrorText, setNetMaskErrorText] = useState<string>("");

    const [dnsErrorText, setDnsErrorText] = useState<string>("");
    const [autoGateWay, setAutoGateWay] = useState<boolean>(true);

    function autoGateConfig() {
        const check = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
        if (check.test(address) && netMask === "255.255.255.0") {
            const newGateWay = address.slice(0, address.lastIndexOf(".") + 1) + "1";
            onChangeGateWay({ target: { value: newGateWay } });
        }
    }
    const onBlurMask = (e: any) => {
        const mask = /^(((255\.){3}(255|254|252|248|240|224|192|128|0+))|((255\.){2}(255|254|252|248|240|224|192|128|0+)\.0)|((255\.)(255|254|252|248|240|224|192|128|0+)(\.0+){2})|((255|254|252|248|240|224|192|128|0+)(\.0+){3}))$/;
        const checkValue = e.target.value;
        if (mask.test(checkValue)) {
            setNetMaskErrorText("");
        } else {
            setNetMaskErrorText("Invalid format : ###.###.###.###");
        }
    };
    const onBlurDNS = (e: any) => {
        const DNS = /^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9])\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9-]*[A-Za-z0-9])$/;
        const checkValue = e.target.value;
        if (DNS.test(checkValue)) {
            setDnsErrorText("");
        } else {
            setDnsErrorText("Invalid format!");
        }
    };
    const handleFocus = (name: string) => (e: any) => {
        if (name === "DNS") {
            setDnsErrorText("");
        }
        if (name === "Netmask") {
            setNetMaskErrorText("");
        }
    };

    const handleAddress = (e: any) => {
        if (autoGateWay) {
            autoGateConfig();
        }
        onChangeAddress(e);
    };
    const onFocusGateWay = (e: any) => {
        setAutoGateWay(false);
    };
    return (
        <>
            <TextField
                className={classes.between}
                label="Address"
                InputProps={{
                    inputComponent: InputMask,
                    value: address,
                    onChange: handleAddress
                }}
                autoComplete="off"
            />
            <TextField
                className={classes.between}
                label="Netmask"
                value={netMask}
                onChange={onChangeNetMask}
                onBlur={onBlurMask}
                onFocus={handleFocus("Netmask")}
                helperText={netMaskErrorText}
                error={!netMaskErrorText}
                autoComplete="off"
                InputProps={{ name: +new Date() + "s" }}
            />
            <TextField
                className={classes.between}
                label="Gateway"
                value={gateway}
                onChange={onChangeGateWay}
                onFocus={onFocusGateWay}
                autoComplete="off"
                InputProps={{ name: +new Date() + "sssdsdsa" }}
            />
            <TextField
                className={classes.between}
                label="DNS"
                value={dns}
                onBlur={onBlurDNS}
                onChange={onChangeDNS}
                onFocus={handleFocus("DNS")}
                helperText={dnsErrorText}
                error={!dnsErrorText}
                autoComplete="off"
                InputProps={{ name: +new Date() + "s1ssaw" }}
            />
        </>
    );
};

export default withStyles(styles)(EthernetFields);
