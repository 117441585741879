import React, { useContext, useState, useMemo } from "react";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

import AssignToGroupIcon from "@material-ui/icons/SwapHoriz";
import { changeMediasGroup } from "../../api/media";
import { addMediaGroup, deleteMediaGroup, updateMediaGroup } from "../../api/media-group";
import { AppDispatch, UserContext } from "../../context/main-context";
import AlertDialog from "../shared/alert-delete-dialog";
import GroupDialog from "../shared/group/Dialog";
import AppToolbar from "../shared/toolbar";
import { IGroupsWithMeta, IMediaGroup, INameable } from "../../interfaces/dto";
import useUnmounted from "../hooks/use-unmount";
import { IGroupsReducerAction } from "../hooks/groups/groups-reducer";
import { useMutateGroup } from "../hooks/groups/groups-hooks";

export interface MediaHeaderProps {
    selectedGroupId?: string;
    selectedMediaIds?: string[];
    groupsWithMeta: IGroupsWithMeta;
    groupsDispatch: React.Dispatch<IGroupsReducerAction>;

    handleDeleteMediasIconClick: () => any;
    mediasGroupChanged: (groupId?: string) => void;
}

function MediaHeader(props: MediaHeaderProps) {
    const {
        groupsWithMeta,
        selectedGroupId,
        groupsDispatch,
        handleDeleteMediasIconClick,
        mediasGroupChanged,
        selectedMediaIds = []
    } = props;
    const appDispatch = useContext(AppDispatch)!;
    const loggedInUser = useContext(UserContext)!;
    const unMounted = useUnmounted();
    const hideAllTab = !!loggedInUser.options.hideAllTab;
    const { setCreatedGroup, setUpdatedGroup, setDeletedGroupId } = useMutateGroup(hideAllTab, groupsDispatch);

    const [assignToGroupAnchorEl, setAssignToGroupAnchorEl] = useState<null | HTMLElement>(null);
    const [groupMode, setGroupMode] = useState<"add" | "edit" | "delete" | null>(null);

    const hideWithoutGroupsTab = useMemo(() => {
        const { groups = [], dataCountWithoutGroup = 0 } = groupsWithMeta;
        return !(hideAllTab && ((groups.length > 0 && dataCountWithoutGroup > 0) || groups.length === 0));
    }, [hideAllTab, groupsWithMeta]);
    const { groups = [] } = groupsWithMeta;

    const activeTab = useMemo(() => {
        let index = -1;
        if (selectedGroupId === "-2") {
            index = groups.length;
        } else if (selectedGroupId !== "-1") {
            index = groups.findIndex(gr => gr._id === selectedGroupId);
        }
        if (!hideAllTab) {
            // ALL tab is the first tab, keep it sync with the render
            index += 1;
        }
        if (index === -1) {
            // reducer will fix it shortly, just not getting any errors
            index = 0;
        }
        return index;
    }, [hideAllTab, selectedGroupId, groups]);

    const selectedGroup = groups.find(gr => gr._id === selectedGroupId);

    const handleCloseGroupWindow = () => {
        setGroupMode(null);
    };

    const handleAddGroup = () => {
        setGroupMode("add");
    };

    const handleEditGroup = () => {
        setGroupMode("edit");
    };

    const handleDeleteGroup = () => {
        setGroupMode("delete");
    };

    async function createGroup(name: string) {
        try {
            const group = await addMediaGroup(name);
            if (unMounted.current) {
                return;
            }
            setCreatedGroup(group);
            appDispatch({
                type: "SHOW_MESSAGE_SUCCESS",
                payload: `Group ${name} added successfully`
            });
        } catch (e) {
            appDispatch({ type: "SHOW_MESSAGE_ERROR", payload: "Unable to create group" });
        }
    }

    async function updateGroup(group: IMediaGroup) {
        const { _id, name } = group;
        if (_id === "-1") {
            return;
        }
        try {
            await updateMediaGroup(_id, name);
            const newGroup = {
                ...group,
                name
            };

            if (unMounted.current) {
                return;
            }
            setUpdatedGroup(newGroup);

            appDispatch({
                type: "SHOW_MESSAGE_SUCCESS",
                payload: `Group ${name} updated successfully`
            });
        } catch (e) {
            appDispatch({ type: "SHOW_MESSAGE_ERROR", payload: "Unable to update group name to " + name });
        }
    }

    async function deleteGroup(id: string) {
        if (id === "-1" || id === "-2") {
            return;
        }
        try {
            await deleteMediaGroup(id);
            if (unMounted.current) {
                return;
            }
            setDeletedGroupId(id);

            appDispatch({
                type: "SHOW_MESSAGE_SUCCESS",
                payload: `Group deleted successfully`
            });
        } catch (e) {
            appDispatch({
                type: "SHOW_MESSAGE_ERROR",
                payload: "Move media(s) to other groups before deleting the group."
            });
        }
    }

    const addOrUpdateGroup = async (name: string, id?: string) => {
        if (id) {
            await updateGroup({
                name,
                _id: id
            });
        } else {
            await createGroup(name);
        }
        handleCloseGroupWindow();
    };

    const onDeleteGroup = async () => {
        if (selectedGroup) {
            await deleteGroup(selectedGroup._id);
        }
        handleCloseGroupWindow();
    };

    const menuItems = [
        {
            name: "Add Group",
            handler: handleAddGroup,
            hiddenIndex: -1000
        }
    ];
    if (selectedGroup) {
        menuItems.push(
            {
                name: "Edit Group",
                handler: handleEditGroup,
                hiddenIndex: 0
            },
            {
                name: "Delete Group",
                handler: handleDeleteGroup,
                hiddenIndex: 0
            }
        );
    }

    const tabChangeHandler = (event: React.ChangeEvent<{}>, index: number, value: INameable) => {
        groupsDispatch({
            type: "SET_SELECTED_GROUP_ID",
            payload: value._id,
            hideAllTab
        });
    };

    function handleAssignToGroupIconClick(event: React.MouseEvent<HTMLElement>) {
        setAssignToGroupAnchorEl(event.currentTarget);
    }
    function handleAssignToGroupMenuClose() {
        setAssignToGroupAnchorEl(null);
    }
    function assignToGroup(groupId: string) {
        changeMediasGroup(groupId, selectedMediaIds).then(() => {
            mediasGroupChanged(groupId);
        });
        handleAssignToGroupMenuClose();
    }
    function getSelectedGridToolbarElements() {
        const availableGroups = groups.filter(group => group._id !== (selectedGroup && selectedGroup._id));
        const selectedGridToolbarElements: React.ReactNode = (
            <>
                {!!selectedMediaIds.length && (
                    <Tooltip title="Delete medias">
                        <IconButton aria-label="Delete medias" onClick={handleDeleteMediasIconClick}>
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                )}
                {!!(selectedMediaIds.length && availableGroups.length) && (
                    <>
                        <Tooltip title="Assign to group">
                            <IconButton aria-label="Assign To Group" onClick={handleAssignToGroupIconClick}>
                                <AssignToGroupIcon />
                            </IconButton>
                        </Tooltip>
                        <Menu
                            id="groups-to-assign"
                            anchorEl={assignToGroupAnchorEl}
                            keepMounted
                            open={!!assignToGroupAnchorEl}
                            onClose={handleAssignToGroupMenuClose}
                        >
                            {availableGroups.map(g => (
                                <MenuItem key={g._id} onClick={() => assignToGroup(g._id)}>
                                    {g.name}
                                </MenuItem>
                            ))}
                        </Menu>
                    </>
                )}
            </>
        );
        return selectedGridToolbarElements;
    }

    function tabItems() {
        const tabs = [];
        if (!hideAllTab) {
            tabs.push({
                _id: "-1",
                name: "All"
            });
        }
        tabs.push(...groups);
        if (!hideWithoutGroupsTab) {
            tabs.push({
                _id: "-2",
                name: "Without group"
            });
        }
        return tabs;
    }

    return (
        <>
            <AppToolbar
                activeTab={activeTab}
                tabChangeHandler={tabChangeHandler}
                tabs={tabItems()}
                menuItems={menuItems}
                selectedGridToolbarElements={getSelectedGridToolbarElements()}
            />
            {groupMode === "add" && (
                <GroupDialog add={addOrUpdateGroup} mode={groupMode} handleClose={handleCloseGroupWindow} />
            )}
            {groupMode === "edit" && selectedGroup && (
                <GroupDialog
                    add={addOrUpdateGroup}
                    mode={groupMode}
                    handleClose={handleCloseGroupWindow}
                    group={selectedGroup}
                />
            )}
            {groupMode === "delete" && (
                <AlertDialog
                    del={onDeleteGroup}
                    message="Are you sure you want to delete group?"
                    handleCloseAlert={handleCloseGroupWindow}
                />
            )}
        </>
    );
}

export default MediaHeader;
