import React, { useContext } from "react";
import createStyles from "@material-ui/core/styles/createStyles";
import { WithStyles } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { UserContext, AppDispatch } from "../../context/main-context";
import { IUser } from "../../interfaces/daos";
import { sendAccountActivationEmail } from "../../api/main-api";

const styles = createStyles({
    root: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        alignSelf: "center",
        margin: "0 auto"
    },
    button: {
        margin: 8
    }
});

const ActivateAccount = (props: WithStyles<typeof styles>) => {
    const { classes } = props;
    const loggedInUser = useContext(UserContext);
    const { fullName, email } = loggedInUser as IUser;
    const dispatch = useContext(AppDispatch)!;
    const onSendActivationEmailButtonClick = () => {
        sendAccountActivationEmail()
            .then((res: any) => {
                if (res && res.data) {
                    dispatch({
                        type: "SHOW_MESSAGE_SUCCESS",
                        payload: "Account Activation Email sent."
                    });
                }
            })
            .catch((e: any) => dispatch({ type: "SHOW_MESSAGE_ERROR", payload: e }));
    };
    return (
        <div className={classes.root}>
            <Typography align="center">Dear {fullName}, the last step is left: Verify your identity</Typography>
            <Typography align="center">
                We have sent activation link to your email: {email}. Please check your spam/trash folder. If still there
                is no email, please contact our administartor
                <a href="mailto:admin@tunnelin.com">admin [at] tunnelin dot com</a>
            </Typography>
            <Button className={classes.button} onClick={onSendActivationEmailButtonClick}>
                Resend Activation Link
            </Button>
        </div>
    );
};
export default withStyles(styles)(ActivateAccount);
