import React, { useContext } from "react";
import { Redirect, useLocation } from "react-router-dom";

import { UserContext } from "../context/main-context";
import ActivateAccount from "./main/activate-account";

export default (
    options = {
        authenticatedGuard: true
    }
) => (Component: any) => {
    function WithAuthGuard(props: any) {
        const { authenticatedGuard } = options;
        const user = useContext(UserContext);
        const location = useLocation();
        if (authenticatedGuard) {
            if (user) {
                if (user.emailVerified) {
                    return <Component {...props} />;
                } else {
                    return <ActivateAccount />;
                }
            }
            return (
                <Redirect
                    to={{
                        pathname: "/login",
                        state: { redirectTo: location }
                    }}
                />
            );
        }
        if (!user) {
            return <Component {...props} />;
        }
        return <Redirect to="/" />;
    }

    WithAuthGuard.displayName = `WithAuthGuard(${Component.displayName || Component.name})`;
    return WithAuthGuard;
};
