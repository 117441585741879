import React, { useContext } from "react";
import { getPlayListGroup } from "../../api/play-list-group";
import { AppDispatch, UserContext } from "../../context/main-context";

import PlaylistsBody from "./playlists-body";
import PlaylistsHeader from "./playlists-header";
import { useGroupsState, useGroupsWithMeta } from "../hooks/groups/groups-hooks";

const Playlists = () => {
    const appDispatch = useContext(AppDispatch)!;
    const loggedInUser = useContext(UserContext)!;
    const { groupsState, groupsDispatch } = useGroupsState("activePlaylistGroupId", loggedInUser);

    const { groupsWithMeta, selectedGroupId } = groupsState;
    useGroupsWithMeta(getPlayListGroup, appDispatch, groupsDispatch, !!loggedInUser.options.hideAllTab);

    if (!groupsWithMeta) {
        return null;
    }

    return (
        <div>
            <PlaylistsHeader
                selectedGroupId={selectedGroupId}
                groupsDispatch={groupsDispatch}
                groupsWithMeta={groupsWithMeta}
            />
            {selectedGroupId && <PlaylistsBody selectedGroupId={selectedGroupId} groupsWithMeta={groupsWithMeta} />}
        </div>
    );
};

export default Playlists;
