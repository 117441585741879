import { WithStyles } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import React, { useState } from "react";

import createStyles from "@material-ui/core/styles/createStyles";
import withStyles from "@material-ui/core/styles/withStyles";
import withMobileDialog, { WithMobileDialog } from "@material-ui/core/withMobileDialog/withMobileDialog";
import EmailOptions from "./options/email-options";
import GeneralOptions from "./options/general-options";
import PasswordOptions from "./options/password-options";
import TwoFactorAuthenticationOptions from "./options/two-factor-options";
const styles = () =>
    createStyles({
        paper: {
            "@media (min-width: 737px)": {
                width: "600px",
                minWidth: "600px"
            }
        },
        contentWrap: {
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
            paddingTop: 5
        }
    });
const Profile = ({
    classes,
    fullScreen,
    handleClose
}: WithStyles<typeof styles> &
    WithMobileDialog & {
        fullScreen: boolean;
        handleClose: () => void;
    }) => {
    const [expanded, setExpanded] = useState<string | false>("general");
    const onGeneralExpPanelChange = (panel: string) => (event: any, newExpand: boolean) => {
        setExpanded(newExpand ? panel : false);
    };

    return (
        <Dialog
            fullScreen={fullScreen}
            open={true}
            onClose={handleClose}
            classes={{
                paper: classes.paper
            }}
            aria-labelledby="dialog-profile"
        >
            <DialogTitle id="profile-dialog-title">Profile</DialogTitle>
            <DialogContent>
                <div className={classes.contentWrap}>
                    <GeneralOptions
                        handleClose={handleClose}
                        expanded={expanded}
                        handleExpansionChange={onGeneralExpPanelChange}
                    />
                    <EmailOptions
                        handleClose={handleClose}
                        expanded={expanded}
                        handleExpansionChange={onGeneralExpPanelChange}
                    />
                    <PasswordOptions
                        handleClose={handleClose}
                        expanded={expanded}
                        type="account"
                        title="Password Options"
                        handleExpansionChange={onGeneralExpPanelChange}
                    />
                    <TwoFactorAuthenticationOptions
                        handleClose={handleClose}
                        expanded={expanded}
                        handleExpansionChange={onGeneralExpPanelChange}
                    />
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default withMobileDialog()(withStyles(styles)(Profile));
