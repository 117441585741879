import { WithStyles } from "@material-ui/core";
import createStyles from "@material-ui/core/styles/createStyles";
import withStyles from "@material-ui/core/styles/withStyles";
import { LeafletMouseEvent } from "leaflet";
import React, { useContext } from "react";
import { Map, Marker, Popup, TileLayer } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-markercluster";
import { DeviceContext, DeviceUpdaterContext } from "./context";
import useWindowWidth from "../../hooks/window-size";

const styles = createStyles({
    map: {
        minHeight: 300,
        "@media (min-width: 960px)": {
            minHeight: "230px"
        },
        "@media (min-width: 1280px)": {
            minHeight: "265px"
        },
        "@media (min-width: 1920px)": {
            minHeight: "330px"
        }
    }
});

const DeviceMap = ({ classes }: WithStyles<typeof styles>) => {
    const { windowWidth } = useWindowWidth();
    const position: any = [40.1775, 44.51265];
    const mapProps: any =
        windowWidth < 960
            ? {
                  dragging: false
              }
            : {};
    const device = useContext(DeviceContext);
    const updateDeviceAPI = useContext(DeviceUpdaterContext)!;
    if (!device) {
        // default map initialization
        mapProps.center = position;
        mapProps.zoom = 15;
    } else {
        mapProps.bounds = [
            [device.latitude || position[0], device.longitude || position[1]],
            [device.latitude || position[0], device.longitude || position[1]]
        ];
        mapProps.boundsOptions = { maxZoom: 16 };
    }
    const onMapClick = (e: LeafletMouseEvent) => {
        updateDeviceAPI.updateLocation(e.latlng.lat, e.latlng.lng);
    };
    return (
        <Map {...mapProps} onclick={onMapClick} useFlyTo className={classes.map}>
            <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            />
            <MarkerClusterGroup>
                {device && (
                    <Marker position={[device.latitude || position[0], device.longitude || position[1]]}>
                        <Popup>
                            <>
                                {device.name}
                                <br />
                                Status:{device.status}
                            </>
                        </Popup>
                    </Marker>
                )}
            </MarkerClusterGroup>
        </Map>
    );
};

export default withStyles(styles)(DeviceMap);
