import { Theme, WithStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from "@material-ui/core/InputLabel";
import createStyles from "@material-ui/core/styles/createStyles";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
// tslint:disable-next-line:no-implicit-dependencies
import PropTypes from "prop-types";
import React, { memo, useCallback, useContext, useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";

import { handlersFor401, login, saveAuthToken } from "../../api/main-api";
import { AppDispatch, InsufficientlyAuthenticationDetailsContext } from "../../context/main-context";
import { useFormInput } from "../../utils/hooks";
import {
    EMPTY,
    INVALID_PASSWORD,
    validateEmail,
    validatePassword,
    validateTwoFactorAuth
} from "../../utils/validationsUtil";

const styles = ({ spacing }: Theme) =>
    createStyles({
        main: {
            display: "flex",
            width: "100%",
            alignItems: "center",
            justifyContent: "center"
        },
        card: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center"
        },
        cardContent: {
            padding: "20px"
        },
        cardContentBody: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center"
        },
        actionsBar: {
            display: "flex",
            flexDirection: "column",
            padding: "5px 0px",
            height: "70px",
            alignItems: "inherit"
        },
        flex1: {
            flex: "1"
        },
        formControl: {
            marginTop: 5,
            marginBottom: 5
        },
        mainActions: {
            display: "flex",
            flexDirection: "row",
            height: "36px",
            padding: "5px 20px"
        },
        passwordRecoveryLink: {
            padding: "0",
            fontSize: "11px",
            height: "30px",
            minHeight: "30px",
            marginRight: "16px",
            marginTop: "4px"
        },
        passwordRecoveryLinkWrapper: {
            display: "flex",
            flexDirection: "row"
        }
    });

function setPasswordValidationError(value: string, setter: (a: string) => void) {
    const isValid = validatePassword(value);
    if (isValid === EMPTY) {
        setter("Please enter your password");
    } else if (isValid === INVALID_PASSWORD) {
        setter("Passwords should be min 8 length");
    } else {
        setter("");
    }
    return isValid === true;
}

const Login = (props: WithStyles<typeof styles>) => {
    const {
        classes: { main, card, cardContent, cardContentBody, formControl, actionsBar, mainActions, flex1 }
    } = props;

    const [loginInProgress, setLoginInProgress] = useState(false);
    const email = useFormInput("");
    const password = useFormInput("");
    const [showPassword, setShowPassword] = useState(false);
    const [emailError, setEmailError] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const twoFactorAuthStr = useFormInput("");
    const [twoFactorAuthStrError, setTwoFactorAuthStrError] = useState("");
    const [showTwoFactorAuthField, setShowTwoFactorAuthField] = useState(false);
    const dispatch = useContext(AppDispatch)!;
    const insufficientlyAuthenticationDetails = useContext(InsufficientlyAuthenticationDetailsContext);

    useEffect(() => {
        if (insufficientlyAuthenticationDetails.indexOf("twoFactorAuthStr") > -1) {
            setShowTwoFactorAuthField(true);
        }
    }, [insufficientlyAuthenticationDetails]);

    // tslint:disable-next-line:no-empty
    const handlerFor401 = useCallback(() => {}, []);

    useEffect(() => {
        handlersFor401.push(handlerFor401);
        return () => {
            const index = handlersFor401.indexOf(handlerFor401);
            handlersFor401.splice(index, 1);
        };
    }, [handlerFor401]);

    useEffect(() => {
        if (loginInProgress) {
            const validateFormFields = () => {
                const { value: emailValue } = email;
                const { value: passwordValue } = password;
                const { value: twoFactorAuthStrValue } = twoFactorAuthStr;
                const isValidEmail = validateEmail(emailValue);
                setEmailError(isValidEmail.errors.emailError);
                let isValid = isValidEmail.isValid && setPasswordValidationError(passwordValue, setPasswordError);
                if (showTwoFactorAuthField) {
                    const isValidTwoFactorAuthStr = validateTwoFactorAuth(twoFactorAuthStrValue);
                    setTwoFactorAuthStrError(isValidTwoFactorAuthStr.errors.twoFactorAuthStrError);
                    isValid = isValid && isValidTwoFactorAuthStr.isValid;
                }
                return isValid;
            };
            if (!validateFormFields()) {
                setLoginInProgress(false);
                return;
            }
            login(email.value, password.value, twoFactorAuthStr.value)
                .then(response => {
                    const { data } = response;
                    saveAuthToken(data.authToken);
                    setLoginInProgress(false);
                    dispatch({ type: "LOGGED_IN_USER", payload: data.user });
                    dispatch({
                        type: "SET_INSUFFICIENTLY_AUTHENTICATION_DETAILS",
                        payload: []
                    });
                })
                .catch(e => {
                    if (e.message !== "412") {
                        dispatch({ type: "SHOW_MESSAGE_ERROR", payload: e });
                    } else {
                        dispatch({
                            type: "SET_INSUFFICIENTLY_AUTHENTICATION_DETAILS",
                            payload: e && e.missingFields
                        });
                    }
                    setLoginInProgress(false);
                });
        }
    }, [loginInProgress, dispatch, email, password, twoFactorAuthStr, showTwoFactorAuthField]);

    function onKeyDown(event: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>) {
        switch (event.keyCode) {
            case 13: {
                setLoginInProgress(true);
                break;
            }
            default:
                break;
        }
    }

    return (
        <div className={main}>
            <Card className={card}>
                <CardContent className={cardContent}>
                    <Typography variant="h6" color="inherit">
                        Sign in
                    </Typography>
                    <div className={cardContentBody}>
                        {!showTwoFactorAuthField && (
                            <>
                                <FormControl className={formControl} required error={!!emailError}>
                                    <InputLabel htmlFor="login-email-field">Email</InputLabel>
                                    <Input
                                        id="login-email-field"
                                        autoComplete="email"
                                        value={email.value}
                                        onChange={email.onChange}
                                        autoFocus
                                        type="email"
                                        onKeyDown={onKeyDown}
                                    />
                                    {emailError && <FormHelperText error>{emailError}</FormHelperText>}
                                </FormControl>
                                <FormControl required className={formControl}>
                                    <InputLabel htmlFor="login-password-field">Password</InputLabel>
                                    <Input
                                        id="login-password-field"
                                        type={showPassword ? "text" : "password"}
                                        autoComplete="current-password"
                                        value={password.value}
                                        onChange={password.onChange}
                                        onKeyDown={onKeyDown}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={() => setShowPassword(!showPassword)}
                                                    tabIndex={-1}
                                                    onMouseDown={e => e.preventDefault()}
                                                >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                    {passwordError && <FormHelperText error>{passwordError}</FormHelperText>}
                                </FormControl>
                            </>
                        )}
                        {showTwoFactorAuthField && (
                            <FormControl className={formControl} error={!!twoFactorAuthStrError}>
                                <InputLabel htmlFor="login-two-factor-auth-field">Two Factor Auth</InputLabel>
                                <Input
                                    autoFocus
                                    id="login-two-factor-auth-field"
                                    value={twoFactorAuthStr.value}
                                    type="text"
                                    onChange={twoFactorAuthStr.onChange}
                                    onKeyDown={onKeyDown}
                                />
                                {twoFactorAuthStrError && (
                                    <FormHelperText error>{twoFactorAuthStrError}</FormHelperText>
                                )}
                            </FormControl>
                        )}
                    </div>
                </CardContent>
                <CardActions className={actionsBar}>
                    <div className={mainActions}>
                        {!showTwoFactorAuthField && (
                            <Button color="primary" component={RouterLink} to="/signup">
                                Sign Up
                            </Button>
                        )}
                        {showTwoFactorAuthField && (
                            <Button
                                color="primary"
                                disabled={loginInProgress}
                                onClick={() => {
                                    setShowTwoFactorAuthField(false);
                                    twoFactorAuthStr.setinputvalue("");
                                }}
                            >
                                Back
                            </Button>
                        )}
                        <div className={flex1} />
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={loginInProgress}
                            onClick={() => setLoginInProgress(true)}
                        >
                            Login
                        </Button>
                    </div>
                </CardActions>
            </Card>
        </div>
    );
};

Login.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(memo(Login));
