import { WithStyles, Button } from "@material-ui/core";
import createStyles from "@material-ui/core/styles/createStyles";
import withStyles from "@material-ui/core/styles/withStyles";
import React, { useEffect, useState } from "react";
import { updateDeviceConfig } from "../../../../api/device";
import { IDeviceConfigurationItem } from "../../../../interfaces/daos";
import { initialDeviceConfigs } from "../../../../configs/device-configuration";
import CheckboxField from "./checkbox-field";
import SelectField from "./select-field";
import TextField from "./text-field";

const styles = createStyles({
    root: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
    },
    fields: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        marginTop: 20,
        mawWidth: 500
    }
});

const Configurations = ({
    classes,
    deviceId,
    deviceConfigs,
    updateAction
}: WithStyles<typeof styles> & {
    deviceId: string;
    deviceConfigs: any;
    updateAction: () => void;
}) => {
    const [statusConfigData, setStatusConfigData] = useState<IDeviceConfigurationItem[]>([]);

    useEffect(() => {
        if (
            deviceConfigs &&
            deviceConfigs.data &&
            deviceConfigs.data.config &&
            deviceConfigs.data.config.warningNotificationsConfigs
        ) {
            const savedConfigs = deviceConfigs.data.config.warningNotificationsConfigs.map((c: any) => {
                const currentInitialConfig = initialDeviceConfigs.find(iC => iC.id === c.id);
                return {
                    ...c,
                    type: currentInitialConfig?.type,
                    label: currentInitialConfig?.label
                };
            });
            setStatusConfigData(savedConfigs);
        } else {
            setStatusConfigData(initialDeviceConfigs);
        }
    }, [deviceConfigs]);

    const onDataChange = (id: IDeviceConfigurationItem["id"], value: IDeviceConfigurationItem["value"]) => {
        const item = statusConfigData.find(i => i.id === id);
        if (!item) {
            return;
        }
        const index = statusConfigData.indexOf(item);
        const d = [...statusConfigData];
        d.splice(index, 1, { ...item, value });
        setStatusConfigData(d);
    };

    const onSaveConfigs = () => {
        updateDeviceConfig(deviceId, {
            warningNotificationsConfigs: statusConfigData.map(c => ({
                id: c.id,
                value: c.value,
                configKey: c.configKey
            }))
        }).then(() => {
            updateAction();
        });
    };

    return (
        <div className={classes.root}>
            {!!statusConfigData.length && (
                <div className={classes.fields}>
                    {statusConfigData.map((d, i) => {
                        switch (d.type) {
                            case "ckeck":
                                return (
                                    <CheckboxField
                                        key={i}
                                        checked={d.value as boolean}
                                        onChange={e => onDataChange(d.id, e.target.checked)}
                                        label={d.label}
                                    />
                                );
                            case "select":
                                return (
                                    <SelectField
                                        key={i}
                                        onChange={e => onDataChange(d.id, e.target.value)}
                                        label={d.label}
                                        options={d.options || []}
                                        value={d.value}
                                    />
                                );
                            case "text":
                                return (
                                    <TextField
                                        key={i}
                                        value={d.value}
                                        label={d.label}
                                        onChange={e => onDataChange(d.id, e.target.value)}
                                    />
                                );
                            default:
                                return <></>;
                        }
                    })}
                </div>
            )}
            <Button color="primary" onClick={onSaveConfigs}>
                Save
            </Button>
        </div>
    );
};

export default withStyles(styles)(Configurations);
