import { Hidden, IconButton } from "@material-ui/core";
import { WithStyles, withStyles } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import RefreshIcon from "@material-ui/icons/Refresh";
import React from "react";
import { TableCellProps } from "react-virtualized";
import Grid, { IGridColumn } from "../shared/grid/grid";
import EditIcon from "@material-ui/icons/Edit";

const styles = () =>
    createStyles({
        gridWrapper: {
            flex: 1,
            overflowX: "auto"
        },
        actionIcon: {
            marginRight: 5
        },
        actionIconMobile: {
            padding: 3,
            marginRight: 3
        },
        cellRenderer: {
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap"
        }
    });

const OrganizationUsers = ({
    classes,
    organizationUsers,
    onInviteUserSave,
    handelDeleteOrgUser,
    onEditOrgUserClick
}: WithStyles<typeof styles> & {
    organizationUsers: any[];
    onInviteUserSave: (data: any) => void;
    handelDeleteOrgUser: (email: string) => void;
    onEditOrgUserClick: (user: any) => void;
}) => {
    const gridActionsContextRenderer: (cellRendererProps: TableCellProps, mobile?: boolean) => React.ReactNode = (
        contextProps,
        mobile
    ) => {
        return (
            <div>
                <IconButton
                    className={`${classes.actionIcon} ${mobile ? classes.actionIconMobile : ""}`}
                    onClick={() => onEditOrgUserClick(contextProps.rowData)}
                >
                    <EditIcon color="primary" aria-label="Edit" />
                </IconButton>
                <IconButton
                    className={`${classes.actionIcon} ${mobile ? classes.actionIconMobile : ""}`}
                    onClick={() => handelDeleteOrgUser(contextProps.rowData.email)}
                >
                    <DeleteIcon color="primary" aria-label="Delete" />
                </IconButton>
                {contextProps.rowData.status !== "Accepted" ? (
                    <IconButton
                        onClick={() =>
                            onInviteUserSave({ email: contextProps.rowData.email, roleId: contextProps.rowData.roleId })
                        }
                        className={`${classes.actionIcon} ${mobile ? classes.actionIconMobile : ""}`}
                    >
                        <RefreshIcon color="primary" aria-label="Resend Invitation" />
                    </IconButton>
                ) : (
                    ""
                )}
            </div>
        );
    };

    const gridEmailContextRenderer: (cellRendererProps: TableCellProps) => React.ReactNode = contextProps => {
        const { invitationEmail } = contextProps.rowData;
        const data = (
            <div className={classes.cellRenderer}>
                {contextProps.cellData}
                {invitationEmail && invitationEmail !== contextProps.cellData && <div>{invitationEmail}</div>}
            </div>
        );
        if (window.innerWidth < 768) {
            return (
                <Tooltip title={contextProps.cellData || ""} placement="bottom-end">
                    {data}
                </Tooltip>
            );
        }
        return data;
    };

    const gridDefaultContextRenderer: (cellRendererProps: TableCellProps) => React.ReactNode = contextProps => {
        const data = <div className={classes.cellRenderer}>{contextProps.cellData}</div>;
        if (window.innerWidth < 768) {
            return (
                <Tooltip title={contextProps.cellData || ""} placement="bottom-end">
                    {data}
                </Tooltip>
            );
        }
        return data;
    };

    const columns: IGridColumn[] = [
        {
            dataKey: "email",
            width: 300,
            label: "Email",
            flexGrow: 1,
            flexShrink: 1,
            cellContentRenderer: gridEmailContextRenderer
        },
        {
            dataKey: "username",
            width: 180,
            label: "Username",
            flexGrow: 1,
            flexShrink: 1,
            cellContentRenderer: gridDefaultContextRenderer
        },
        {
            dataKey: "status",
            width: 160,
            label: "Status",
            flexGrow: 1,
            flexShrink: 1,
            cellContentRenderer: gridDefaultContextRenderer
        },
        {
            dataKey: "role",
            width: 250,
            label: "Role",
            flexGrow: 1,
            flexShrink: 1,
            cellContentRenderer: gridDefaultContextRenderer
        },
        {
            dataKey: "actions",
            width: 150,
            label: "Actions",
            flexGrow: 1,
            flexShrink: 0,
            cellContentRenderer: gridActionsContextRenderer
        }
    ];

    const columnsMobile: IGridColumn[] = [
        {
            dataKey: "email",
            width: 200,
            label: "Email",
            flexGrow: 1,
            flexShrink: 1,
            cellContentRenderer: gridEmailContextRenderer
        },
        {
            dataKey: "username",
            width: 130,
            label: "Username",
            flexGrow: 1,
            flexShrink: 1,
            cellContentRenderer: gridDefaultContextRenderer
        },
        {
            dataKey: "status",
            width: 140,
            label: "Status",
            flexGrow: 1,
            flexShrink: 1,
            cellContentRenderer: gridDefaultContextRenderer
        },
        {
            dataKey: "role",
            width: 250,
            label: "Role",
            flexGrow: 1,
            flexShrink: 1,
            cellContentRenderer: gridDefaultContextRenderer
        },
        {
            dataKey: "actions",
            width: 120,
            label: "Actions",
            flexGrow: 1,
            flexShrink: 0,
            cellContentRenderer: (p: TableCellProps) => gridActionsContextRenderer(p, true)
        }
    ];

    return (
        <div className={classes.gridWrapper}>
            <Hidden smDown>
                <Grid
                    isDataLoading={false}
                    rowsPerPageOptions={[10, 25, 50]}
                    data={organizationUsers}
                    columns={columns}
                    paper={false}
                    enableToolbar={false}
                    style={{
                        height: "100%",
                        width: "100%"
                    }}
                />
            </Hidden>
            <Hidden mdUp>
                <Grid
                    isDataLoading={false}
                    rowsPerPageOptions={[10, 25, 50]}
                    data={organizationUsers}
                    columns={columnsMobile}
                    paper={false}
                    enableToolbar={false}
                    style={{
                        height: "100%",
                        width: "100%",
                        minWidth: "600px"
                    }}
                />
            </Hidden>
        </div>
    );
};
export default withStyles(styles)(OrganizationUsers);
