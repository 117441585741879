import {
    Card,
    CardContent,
    Checkbox,
    IconButton,
    Menu,
    MenuItem,
    Tooltip,
    Typography,
    WithStyles
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { createStyles, withStyles } from "@material-ui/core/styles";
import {
    DateRange as DateRangeIcon,
    PlaylistPlay as PlaylistPlayIcon,
    SwapHoriz as AssignToGroupIcon,
    Sync as SyncIcon
} from "@material-ui/icons";
import classNames from "classnames";
import React, { useEffect, useMemo, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { bulkAssignToDevices, updateDevice } from "../../../api/device";
import { getDevicesStatuses, syncDevices } from "../../../api/main-api";
import { getPlayList } from "../../../api/play-list";
import { getSchedules } from "../../../api/schedule";
import { BLUE_COLOR } from "../../../constants/style-constants";
import useDevicesWithStatuses from "../../hooks/devices";
import { updateStatusesOfDevices } from "../../hooks/statuses";
import Presentation from "../presentation";
import RemoteControl from "../remote-contol";
import ActionsRenderer from "../renderers/actions";
import PlaylistRenderer from "../renderers/playlist";
import ScheduleRenderer from "../renderers/schedule";
import StatusRenderer from "../renderers/status";

const styles = () =>
    createStyles({
        card: {
            margin: 10
        },
        selectedCard: {
            opacity: 0.8,
            border: "2px dashed #3f51b5",
            boxSizing: "border-box"
        },
        notSelectedCard: {
            opacity: 0.4
        },
        cardTitle: {
            display: "flex",
            justifyContent: "space-between"
        },
        cardInfoWrapper: {
            display: "flex",
            flexDirection: "column",
            padding: 5
        },
        infoItem: {
            display: "flex"
        },
        infoLabel: {
            flex: 1
        },
        infoValue: {
            flex: 2,
            overflow: "hidden"
        },
        cardContent: {
            padding: 5,
            paddingBottom: "0px!important"
        },
        cardActions: {
            margin: "2px auto 0px auto"
        },
        selectCheckbox: {},
        selectedModeToolbar: {
            position: "fixed",
            width: "100%",
            bottom: 0,
            backgroundColor: BLUE_COLOR,
            zIndex: 9,
            display: "flex",
            color: "white"
        },
        selectedModeToolbarIconButtons: {
            color: "white"
        },
        loaderWrapper: {
            display: "flex",
            justifyContent: "center",
            padding: "30px"
        }
    });

interface ICardsProps {
    groups: { name: string; _id: string }[];
    activeGroup?: string | null;
}

const DevicesCards = ({
    classes,
    groups,
    activeGroup,
    history
}: WithStyles<typeof styles> & RouteComponentProps & ICardsProps) => {
    // const [page, setPage] = useState<number>(0);
    // const [count, setCount] = useState<number>(30);
    const [reloadData, setReloadData] = useState<boolean>(false);
    const { devices, setDevices, isDeviceLoading } = useDevicesWithStatuses("", activeGroup, reloadData);
    const [playlists, setPlaylists] = useState<any[]>([]);
    const [schedules, setSchedules] = useState<any[]>([]);
    const [assignMenuAnchorEl, setAssignMenuAnchorEl] = useState<null | HTMLElement>(null);
    const [bulkMode, setBulkMode] = useState<"playlist" | "schedule" | "group" | null>(null);
    const [selectedPlaylistId, setSelectedPlaylistId] = useState<string | undefined>(undefined);
    const [selectedScheduleId, setSelectedScheduleId] = useState<string | undefined>(undefined);
    const [selectedGroupId, setSelectedGroupId] = useState<string | undefined>(undefined);
    const [doSyncDevices, setDoSyncDevices] = useState<boolean>(false);
    const [deviceForPresentationMode, setDeviceForPresentationMode] = useState<any>(null);
    const [deviceForRemote, setDeviceForRemote] = useState<any>(null);
    const [updatePlaylistsAndSchedules, setUpdatePlaylistsAndSchedules] = useState<boolean>(false);
    const [dataForUpdateDevicePlaylist, setDataForUpdateDevicePlaylist] = useState<{
        device: any;
        playlistId: string;
    } | null>(null);
    const [dataForUpdateDeviceSchedule, setDataForUpdateDeviceSchedule] = useState<{
        device: any;
        scheduleId: string | null;
    } | null>(null);
    const [deviceIdForSync, setDeviceIdForSync] = useState<string>("");
    const [selectedCardIds, setSelectedCardIds] = useState<string[]>([]);
    const dataIds = useMemo(() => devices.reduce((prev: any, item: any) => (prev += item.id), ""), [devices]);
    useEffect(() => {
        setSelectedCardIds([]);
    }, [dataIds]);

    useEffect(() => {
        let canceled = false;
        let tId: any;
        (async () => {
            let playlistData = [];
            let scheduletData = [];
            try {
                playlistData = await getPlayList({ limit: 1000 });
                playlistData = playlistData.data.data || [];
                scheduletData = await getSchedules({ limit: 1000 });
                scheduletData = scheduletData.data.data || [];
            } catch (e) {
                playlistData = [];
                scheduletData = [];
            }
            if (!canceled) {
                setPlaylists(playlistData);
                setSchedules(scheduletData);
            }
            tId = setTimeout(() => {
                if (!canceled) {
                    setUpdatePlaylistsAndSchedules(!updatePlaylistsAndSchedules);
                }
            }, 60000);
        })();
        return () => {
            if (tId) {
                clearTimeout(tId);
            }
            canceled = true;
        };
    }, [updatePlaylistsAndSchedules]);
    useEffect(() => {
        let canceled = false;
        if (dataForUpdateDevicePlaylist) {
            const { device, playlistId } = dataForUpdateDevicePlaylist;
            updateDevice({ ...device, playlistId })
                .then(() => {
                    if (!canceled) {
                        setDataForUpdateDevicePlaylist(null);
                        setReloadData(old => !old);
                    }
                })
                .catch(() => {
                    if (!canceled) {
                        setDataForUpdateDevicePlaylist(null);
                    }
                });
        }
        return () => {
            canceled = true;
        };
    }, [dataForUpdateDevicePlaylist]);
    useEffect(() => {
        let canceled = false;
        if (dataForUpdateDeviceSchedule) {
            const { device, scheduleId } = dataForUpdateDeviceSchedule;
            updateDevice({ ...device, scheduleId })
                .then(() => {
                    if (!canceled) {
                        setDataForUpdateDeviceSchedule(null);
                        setReloadData(old => !old);
                    }
                })
                .catch(() => {
                    if (!canceled) {
                        setDataForUpdateDeviceSchedule(null);
                    }
                });
        }
        return () => {
            canceled = true;
        };
    }, [dataForUpdateDeviceSchedule]);
    useEffect(() => {
        let canceled = false;
        if (deviceIdForSync) {
            syncDevices([deviceIdForSync])
                .then(() => {
                    if (!canceled) {
                        getDevicesStatuses()
                            .then(res => {
                                if (!canceled) {
                                    setDevices(updateStatusesOfDevices(res.data, devices));
                                    setDeviceIdForSync("");
                                }
                            })
                            .catch(() => setDeviceIdForSync(""));
                    }
                })
                .catch(() => setDeviceIdForSync(""));
        }
        return () => {
            canceled = true;
        };
    }, [deviceIdForSync, devices, setDevices]);
    useEffect(() => {
        let canceled = false;
        if (selectedPlaylistId && selectedCardIds.length !== 0) {
            const doBulkAssignPlaylistToDevices = () =>
                new Promise((resolve, reject) => {
                    if (selectedCardIds.length === 0) {
                        resolve(true);
                    }
                    bulkAssignToDevices({
                        devices: selectedCardIds,
                        playListId: selectedPlaylistId
                    })
                        .then(d => {
                            resolve(d);
                        })
                        .catch(e => {
                            reject(e);
                        });
                });
            doBulkAssignPlaylistToDevices()
                .then(() => {
                    if (!canceled) {
                        setReloadData(oldState => !oldState);
                        setSelectedPlaylistId(undefined);
                    }
                })
                .catch(() => {
                    setSelectedPlaylistId(undefined);
                });
        }
        return () => {
            canceled = true;
        };
    }, [selectedPlaylistId, selectedCardIds]);

    useEffect(() => {
        let canceled = false;
        if (selectedScheduleId && selectedCardIds.length !== 0) {
            const doBulkAssignScheduleToDevices = () =>
                new Promise((resolve, reject) => {
                    if (selectedCardIds.length === 0) {
                        resolve(true);
                    }
                    bulkAssignToDevices({
                        devices: selectedCardIds,
                        scheduleId: selectedScheduleId
                    })
                        .then(d => {
                            resolve(d);
                        })
                        .catch(e => {
                            reject(e);
                        });
                });
            doBulkAssignScheduleToDevices()
                .then(() => {
                    if (!canceled) {
                        setReloadData(oldState => !oldState);
                        setSelectedScheduleId(undefined);
                    }
                })
                .catch(() => {
                    setSelectedScheduleId(undefined);
                });
        }
        return () => {
            canceled = true;
        };
    }, [selectedScheduleId, selectedCardIds]);

    useEffect(() => {
        let canceled = false;
        if (selectedGroupId && selectedCardIds.length !== 0) {
            const doBulkAssignToGroupDevices = () =>
                new Promise((resolve, reject) => {
                    if (selectedCardIds.length === 0) {
                        resolve(true);
                    }
                    bulkAssignToDevices({
                        devices: selectedCardIds,
                        groupId: selectedGroupId
                    })
                        .then(d => {
                            resolve(d);
                        })
                        .catch(e => {
                            reject(e);
                        });
                });
            doBulkAssignToGroupDevices()
                .then(() => {
                    if (!canceled) {
                        setReloadData(oldState => !oldState);
                        setSelectedGroupId(undefined);
                    }
                })
                .catch(() => {
                    setSelectedGroupId(undefined);
                });
        }
        return () => {
            canceled = true;
        };
    }, [selectedGroupId, selectedCardIds]);

    useEffect(() => {
        let canceled = false;
        if (doSyncDevices) {
            const bulkSyncDevices = () =>
                new Promise((resolve, reject) => {
                    if (selectedCardIds.length === 0) {
                        resolve(true);
                    }
                    syncDevices(selectedCardIds)
                        .then(d => {
                            resolve(d);
                        })
                        .catch(e => {
                            reject(e);
                        });
                });
            bulkSyncDevices()
                .then(() => {
                    if (!canceled) {
                        setReloadData(oldState => !oldState);
                        setDoSyncDevices(false);
                    }
                })
                .catch(() => {
                    setDoSyncDevices(false);
                });
        }
        return () => {
            canceled = true;
        };
    }, [doSyncDevices, selectedCardIds]);
    const updatePlaylistForDevice = (deviceId: string, playlistId: string) => {
        setDataForUpdateDevicePlaylist({
            device: devices.find((d: any) => d._id === deviceId),
            playlistId
        });
    };
    const updateScheduleForDevice = (deviceId: string, scheduleId: string) => {
        const id = scheduleId === "none" ? null : scheduleId;
        setDataForUpdateDeviceSchedule({
            device: devices.find((d: any) => d._id === deviceId),
            scheduleId: id
        });
    };
    const handelEditClick = (id: string) => {
        history.replace(`/device/${id}`);
    };
    const handleSyncClick = (id: string) => {
        setDeviceIdForSync(id);
    };
    const onPresentationClick = (id: string) => {
        const device = devices.find((d: any) => d._id === id);
        setDeviceForPresentationMode(device);
    };
    const onRemoteControlClick = (id: string) => {
        const device = devices.find((d: any) => d._id === id);
        setDeviceForRemote(device);
    };
    const handleClosePresentation = () => {
        setDeviceForPresentationMode(null);
    };
    const handleCloseRemote = () => {
        setDeviceForRemote(null);
    };
    const selectCard = (id: string) => {
        const index = selectedCardIds.indexOf(id);
        const newSelected = [...selectedCardIds];
        if (index === -1) {
            newSelected.push(id);
        } else {
            newSelected.splice(index, 1);
        }
        setSelectedCardIds(newSelected);
    };
    const handleAssignPlaylistIconClick = (event: React.MouseEvent<HTMLElement>) => {
        setBulkMode("playlist");
        setAssignMenuAnchorEl(event.currentTarget);
    };

    const handleAssignScheduleIconClick = (event: React.MouseEvent<HTMLElement>) => {
        setBulkMode("schedule");
        setAssignMenuAnchorEl(event.currentTarget);
    };

    const handleAssignToGroupIconClick = (event: React.MouseEvent<HTMLElement>) => {
        setBulkMode("group");
        setAssignMenuAnchorEl(event.currentTarget);
    };
    const handleSyncDevicesClick = () => {
        setDoSyncDevices(true);
    };
    const handleAssignMenuClose = () => {
        setAssignMenuAnchorEl(null);
        setBulkMode(null);
    };
    const assignToGroup = (groupId: string) => {
        handleAssignMenuClose();
        setSelectedGroupId(groupId);
    };

    const assignPlaylistToDevices = (playListId: string) => {
        handleAssignMenuClose();
        setSelectedPlaylistId(playListId);
    };

    const assignScheduleToDevices = (scheduleId: string) => {
        handleAssignMenuClose();
        setSelectedScheduleId(scheduleId);
    };

    return (
        <>
            {!!selectedCardIds.length && !!groups.filter(group => group._id !== activeGroup).length && (
                <div className={classes.selectedModeToolbar}>
                    <Tooltip title="Assign playlist">
                        <IconButton
                            className={classes.selectedModeToolbarIconButtons}
                            aria-label="Assign playlist"
                            onClick={handleAssignPlaylistIconClick}
                        >
                            <PlaylistPlayIcon />
                        </IconButton>
                    </Tooltip>

                    <Tooltip title="Assign schedule">
                        <IconButton
                            className={classes.selectedModeToolbarIconButtons}
                            aria-label="Assign schedule"
                            onClick={handleAssignScheduleIconClick}
                        >
                            <DateRangeIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Sync">
                        <IconButton
                            className={classes.selectedModeToolbarIconButtons}
                            aria-label="Sync"
                            onClick={handleSyncDevicesClick}
                        >
                            <SyncIcon className="rotated" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Assign to group">
                        <IconButton
                            className={classes.selectedModeToolbarIconButtons}
                            aria-label="Assign to group"
                            onClick={handleAssignToGroupIconClick}
                        >
                            <AssignToGroupIcon />
                        </IconButton>
                    </Tooltip>
                    <Menu
                        id="groups-to-assign"
                        anchorEl={assignMenuAnchorEl}
                        keepMounted
                        open={!!assignMenuAnchorEl}
                        onClose={handleAssignMenuClose}
                    >
                        {bulkMode === "group" &&
                            groups
                                .filter(group => group._id !== activeGroup)
                                .map(group => (
                                    <MenuItem key={group._id} onClick={() => assignToGroup(group._id)}>
                                        {group.name}
                                    </MenuItem>
                                ))}
                        {bulkMode === "schedule" &&
                            schedules.map(s => (
                                <MenuItem key={s._id} onClick={() => assignScheduleToDevices(s._id)}>
                                    {s.name}
                                </MenuItem>
                            ))}
                        {bulkMode === "playlist" &&
                            playlists.map(pl => (
                                <MenuItem key={pl._id} onClick={() => assignPlaylistToDevices(pl._id)}>
                                    {pl.name}
                                </MenuItem>
                            ))}
                    </Menu>
                    <div style={{ padding: 15 }}>{selectedCardIds.length} Selected</div>
                </div>
            )}
            {isDeviceLoading ? (
                <div className={classes.loaderWrapper}>
                    <CircularProgress size={50} />
                </div>
            ) : (
                devices.map((d: any) => {
                    const { presentationPlayListId, playlistId, scheduleId } = d;
                    const presentationPlaylist =
                        presentationPlayListId && playlists.find(p => p._id === presentationPlayListId);
                    const playlist = playlistId && playlists.find(p => p._id === playlistId);
                    const schedule = scheduleId && schedules.find(s => s._id === scheduleId);
                    const presentationPlaylistTranscodingStatus =
                        presentationPlaylist && presentationPlaylist.transcodingStatus;
                    const playlistTranscodingStatus = playlist && playlist.transcodingStatus;
                    const scheduleTranscodingStatus = schedule && schedule.transcodingStatus;
                    let syncDisabled = false;
                    if (
                        !syncDisabled &&
                        presentationPlaylistTranscodingStatus &&
                        (presentationPlaylistTranscodingStatus.length > 1 ||
                            (presentationPlaylistTranscodingStatus.length === 1 &&
                                presentationPlaylistTranscodingStatus[0] !== "Complete"))
                    ) {
                        syncDisabled = true;
                    }
                    if (
                        !syncDisabled &&
                        playlistTranscodingStatus &&
                        (playlistTranscodingStatus.length > 1 ||
                            (playlistTranscodingStatus.length === 1 && playlistTranscodingStatus[0] !== "Complete"))
                    ) {
                        syncDisabled = true;
                    }
                    if (
                        !syncDisabled &&
                        scheduleTranscodingStatus &&
                        (scheduleTranscodingStatus.length > 1 ||
                            (scheduleTranscodingStatus.length === 1 && scheduleTranscodingStatus[0] !== "Complete"))
                    ) {
                        syncDisabled = true;
                    }
                    return (
                        <Card
                            key={d._id}
                            className={classNames(classes.card, {
                                [classes.selectedCard as string]: selectedCardIds.indexOf(d._id) > -1,
                                [classes.notSelectedCard as string]:
                                    selectedCardIds.length && selectedCardIds.indexOf(d._id) === -1
                            })}
                        >
                            <CardContent className={classes.cardContent}>
                                <div className={classes.cardTitle}>
                                    <Typography variant="h6">{d.name}</Typography>
                                    <Checkbox
                                        checked={selectedCardIds.indexOf(d._id) > -1}
                                        onChange={() => selectCard(d._id)}
                                        value="select"
                                        color="primary"
                                        inputProps={{
                                            "aria-label": "select card"
                                        }}
                                        className={classes.selectCheckbox}
                                    />
                                </div>
                                <div className={classes.cardInfoWrapper}>
                                    <div className={classes.infoItem}>
                                        <Typography color="textSecondary" className={classes.infoLabel}>
                                            Statuses
                                        </Typography>
                                        <div
                                            className={classes.infoValue}
                                            style={{ display: "flex", justifyContent: "space-around" }}
                                        >
                                            {<StatusRenderer device={d} mode="card" />}
                                        </div>
                                    </div>
                                    <div className={classes.infoItem}>
                                        <Typography color="textSecondary" className={classes.infoLabel}>
                                            Uptime
                                        </Typography>
                                        <div className={classes.infoValue}>{d.uptime}</div>
                                    </div>
                                    <div className={classes.infoItem}>
                                        <Typography color="textSecondary" className={classes.infoLabel}>
                                            Last Seen
                                        </Typography>
                                        <div className={classes.infoValue}>{d.lastSeen}</div>
                                    </div>
                                    <div className={classes.infoItem}>
                                        <Typography color="textSecondary" className={classes.infoLabel}>
                                            Playlist
                                        </Typography>
                                        <div className={classes.infoValue}>
                                            <PlaylistRenderer
                                                deviceId={d._id}
                                                playlistId={d.playlistId}
                                                playlists={playlists}
                                                updatePlaylistForDevice={updatePlaylistForDevice}
                                                mode="card"
                                            />
                                        </div>
                                    </div>
                                    <div className={classes.infoItem}>
                                        <Typography color="textSecondary" className={classes.infoLabel}>
                                            Schedule
                                        </Typography>
                                        <div className={classes.infoValue}>
                                            <ScheduleRenderer
                                                deviceId={d._id}
                                                scheduleId={d.scheduleId}
                                                schedules={schedules}
                                                updateScheduleForDevice={updateScheduleForDevice}
                                                mode="card"
                                            />
                                        </div>
                                    </div>
                                    <div className={classes.cardActions}>
                                        <ActionsRenderer
                                            deviceId={d._id}
                                            handleSyncClick={handleSyncClick}
                                            handelEditClick={handelEditClick}
                                            onPresentationClick={onPresentationClick}
                                            presentationPlayListId={d.presentationPlayListId}
                                            onRemoteControlClick={onRemoteControlClick}
                                            syncDisabled={syncDisabled}
                                        />
                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                    );
                })
            )}
            {!!deviceForPresentationMode && (
                <Presentation
                    device={deviceForPresentationMode}
                    playlist={playlists.find((p: any) => p._id === deviceForPresentationMode.playlistId)}
                    handleClose={handleClosePresentation}
                />
            )}
            {!!deviceForRemote && <RemoteControl device={deviceForRemote} handleClose={handleCloseRemote} />}
        </>
    );
};
export default withStyles(styles)(withRouter(DevicesCards));
