import { Dialog, DialogActions, DialogContent, DialogTitle, Hidden, WithStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import PlayIcon from "@material-ui/icons/PlayArrow";
import NextIcon from "@material-ui/icons/SkipNext";
import PreviousIcon from "@material-ui/icons/SkipPrevious";
import StopIcon from "@material-ui/icons/Stop";
import React, { useEffect, useState } from "react";
import { presentationAction } from "../../api/device";
const styles = () =>
    createStyles({
        presentationActionButton: {
            margin: 5,
            width: 128
        },
        mobileViewButtonsWrapper: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
        }
    });

interface IPresentationProps {
    device: any;
    handleClose: () => void;
    playlist: any;
}

const PresentationDialog = ({
    classes,
    device,
    playlist,
    handleClose
}: WithStyles<typeof styles> & IPresentationProps) => {
    const [presentationActionName, setPresentationActionName] = useState<"start" | "stop" | "next" | "previous" | "">(
        ""
    );
    useEffect(() => {
        let canceled = false;
        if (presentationActionName) {
            presentationAction(device._id, presentationActionName)
                .then(() => {
                    if (!canceled) {
                        setPresentationActionName("");
                    }
                })
                .catch(() => {
                    if (!canceled) {
                        setPresentationActionName("");
                    }
                });
        }
        return () => {
            canceled = true;
        };
    }, [presentationActionName, device._id]);
    const onStartBtnClick = () => setPresentationActionName("start");
    const onPreviousBtnClick = () => setPresentationActionName("previous");
    const onNextBtnClick = () => setPresentationActionName("next");
    const onStopBtnClick = () => setPresentationActionName("stop");
    const dialogContent: React.ReactNode = (
        <>
            <DialogTitle id="form-dialog-title">Presenting {playlist.name}</DialogTitle>
            <DialogContent>
                <Hidden smDown>
                    <Button className={classes.presentationActionButton} variant="contained" onClick={onStartBtnClick}>
                        <PlayIcon />
                        Start
                    </Button>
                    <Button
                        className={classes.presentationActionButton}
                        variant="contained"
                        onClick={onPreviousBtnClick}
                    >
                        <PreviousIcon />
                        Previous
                    </Button>
                    <Button className={classes.presentationActionButton} variant="contained" onClick={onNextBtnClick}>
                        <NextIcon />
                        Next
                    </Button>
                    <Button className={classes.presentationActionButton} variant="contained" onClick={onStopBtnClick}>
                        <StopIcon />
                        Stop
                    </Button>
                </Hidden>
                <Hidden mdUp>
                    <div className={classes.mobileViewButtonsWrapper}>
                        <Button
                            className={classes.presentationActionButton}
                            variant="contained"
                            onClick={onStartBtnClick}
                        >
                            <PlayIcon />
                            Start
                        </Button>
                        <div>
                            <Button
                                className={classes.presentationActionButton}
                                variant="contained"
                                onClick={onPreviousBtnClick}
                            >
                                <PreviousIcon />
                                Previous
                            </Button>
                            <Button
                                className={classes.presentationActionButton}
                                variant="contained"
                                onClick={onNextBtnClick}
                            >
                                Next
                                <NextIcon />
                            </Button>
                        </div>
                        <Button
                            className={classes.presentationActionButton}
                            variant="contained"
                            onClick={onStopBtnClick}
                        >
                            <StopIcon />
                            Stop
                        </Button>
                    </div>
                </Hidden>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
            </DialogActions>
        </>
    );
    return (
        <>
            <Hidden smDown>
                <Dialog open onClose={handleClose} aria-labelledby="form-dialog-title">
                    {dialogContent}
                </Dialog>
            </Hidden>
            <Hidden mdUp>
                <Dialog open fullScreen onClose={handleClose} aria-labelledby="form-dialog-title">
                    {dialogContent}
                </Dialog>
            </Hidden>
        </>
    );
};

export default withStyles(styles)(PresentationDialog);
