import { Tooltip, Typography, WithStyles } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import createStyles from "@material-ui/core/styles/createStyles";
import withStyles from "@material-ui/core/styles/withStyles";
import SyncDoneIcon from "@material-ui/icons/DoneRounded";
import CircleIcon from "@material-ui/icons/FiberManualRecord";
import MonitorIcon from "@material-ui/icons/LiveTv";
import SyncIcon from "@material-ui/icons/Sync";
import SyncDisabledIcon from "@material-ui/icons/SyncDisabled";
import SyncProblemIcon from "@material-ui/icons/SyncProblem";
import USBIcon from "@material-ui/icons/Usb";
import WarnindIcon from "@material-ui/icons/Warning";
import classNames from "classnames";
import distanceInWordsToNow from "date-fns/formatDistanceToNow";
import React, { useEffect, useState } from "react";
import { getScreenshot, getScreenshots } from "../../../api/device";
import { GREEN_COLOR, RED_COLOR, YELLOW_COLOR } from "../../../constants/style-constants";
import { getRotatedSrc } from "../../../utils/rotateImage";
import { IDeviceConfigurationItem } from "../../../interfaces/daos";
import { initialDeviceConfigs } from "../../../configs/device-configuration";
interface IStatusRendererProps {
    device: any;
    mode: "grid" | "card";
}
const styles = createStyles({
    statusIconClass: {
        // marginRight: 5
    },
    colorGreen: {
        fill: GREEN_COLOR
    },
    colorYellow: {
        fill: YELLOW_COLOR
    },
    colorRed: {
        fill: RED_COLOR
    },
    storageIconsWrapper: {
        // marginRight: 5,
        position: "relative",
        height: 20,
        width: 20,
        overflow: "hidden"
    },
    iconWrapper: {
        marginRight: 2,
        flex: 1
    }
});

const getWarningIconTooltips = (
    rawStatus?: { loadavg?: string; checkHDMICable?: boolean; checkActiveSouce?: boolean; checkTVPower?: boolean },
    config?: { warningNotificationsConfigs?: IDeviceConfigurationItem[] }
) => {
    const res: string[] = [];
    if (!rawStatus) {
        return res;
    }
    if (
        typeof rawStatus.loadavg === "string" &&
        rawStatus.loadavg.split(",").some((n: string) => Number(n.trim()) >= 1)
    ) {
        res.push("High load detected");
    }
    const warningNotificationsConfigs: IDeviceConfigurationItem[] | undefined =
        config && config.warningNotificationsConfigs;
    const c: { [key: string]: number | string | boolean | unknown } = {};
    if (Array.isArray(warningNotificationsConfigs)) {
        warningNotificationsConfigs.forEach(n => {
            c[n.configKey] = n.value;
        });
    }
    if (
        rawStatus.checkHDMICable &&
        (c.checkHDMICable ||
            (c.checkHDMICable === undefined && initialDeviceConfigs.find(c => c.configKey === "checkHDMICable")?.value))
    ) {
        res.push("Check HDMI cable");
    }
    if (
        rawStatus.checkActiveSouce &&
        (c.checkActiveSouce ||
            (c.checkActiveSouce === undefined &&
                initialDeviceConfigs.find(c => c.configKey === "checkActiveSouce")?.value))
    ) {
        res.push("Check active source");
    }
    if (
        rawStatus.checkTVPower &&
        (c.checkTVPower ||
            (c.checkTVPower === undefined && initialDeviceConfigs.find(c => c.configKey === "checkTVPower")?.value))
    ) {
        res.push("Check TV power");
    }
    return res;
};

const StatusRenderer = ({ classes, device, mode }: WithStyles<typeof styles> & IStatusRendererProps) => {
    const {
        _id: deviceId,
        status,
        syncStatus,
        lastSeen,
        totalMem,
        usedMem,
        additionalUsbDevicesInfo,
        wifiSpeed,
        rawStatus,
        additionalOptions,
        config
    } = device;
    const [deviceSnapshot, setDeviceSnapshot] = useState<{
        snapshotId: string;
        imgSrc?: string;
        date: string;
        isLoading: boolean;
    }>({
        snapshotId: "",
        imgSrc: "",
        date: "",
        isLoading: false
    });
    const [displayStatusOpen, setDisplayStatusOpen] = useState<boolean>(false);
    const [mustGetData, setMustGetData] = useState<boolean>(false);
    const [firstTime, setFirstTime] = useState<boolean>(true);

    const verticalOrientation = !!(additionalOptions && additionalOptions.verticalOrientation);
    const rotateDirection = additionalOptions && additionalOptions.rotateDirection === "counterclockwise" ? -90 : 90;
    useEffect(() => {
        let canceled = false;
        if (firstTime) return;
        (async () => {
            try {
                setDeviceSnapshot(old => ({
                    ...old,
                    isLoading: true
                }));
                const ds = await getScreenshots({ id: deviceId, limit: 1 });
                const snap = ds.data[0];
                if (snap) {
                    if (snap._id !== deviceSnapshot.snapshotId) {
                        getScreenshot(snap._id)
                            .then(async response => {
                                let src: any = URL.createObjectURL(response);
                                if (verticalOrientation) {
                                    src = await getRotatedSrc(src, rotateDirection);
                                }
                                if (!canceled) {
                                    setDeviceSnapshot({
                                        date: distanceInWordsToNow(snap.timestamps),
                                        snapshotId: snap._id,
                                        imgSrc: src,
                                        isLoading: false
                                    });
                                }
                            })
                            .catch(e => {
                                setDeviceSnapshot(old => ({
                                    ...old,
                                    isLoading: false
                                }));
                            });
                    } else {
                        if (!canceled) {
                            setDeviceSnapshot(old => ({
                                ...old,
                                date: distanceInWordsToNow(snap.timestamps),
                                isLoading: false
                            }));
                        }
                    }
                } else {
                    setDeviceSnapshot(old => ({
                        ...old,
                        isLoading: false
                    }));
                }
            } catch (e) {
                setDeviceSnapshot(old => ({
                    ...old,
                    isLoading: false
                }));
            }
        })();
        return () => {
            canceled = true;
        };
    }, [firstTime, mustGetData, deviceSnapshot.snapshotId, deviceId, verticalOrientation, rotateDirection]);
    useEffect(() => {
        setDeviceSnapshot({
            snapshotId: "",
            imgSrc: "",
            date: "",
            isLoading: false
        });
    }, [deviceId]);
    const onDisplayStatusClose = () => {
        setDisplayStatusOpen(false);
    };
    const onDisplayStatusOpen = async () => {
        if (!displayStatusOpen) {
            setFirstTime(false);
            setDisplayStatusOpen(true);
            setMustGetData(!mustGetData);
        }
    };
    const freePercent = 100 - Math.round((usedMem / totalMem) * 100) || 0;
    const storageIconColor = freePercent > 20 ? GREEN_COLOR : RED_COLOR;
    const warningIconTooltips: string[] = getWarningIconTooltips(rawStatus, config);
    const passedMinutesFromLastScreenSHot =
        rawStatus && rawStatus.timestamps && Math.round((new Date().getTime() - rawStatus.timestamps) / (1000 * 60));
    return (
        <>
            <Tooltip
                enterTouchDelay={400}
                title={
                    <>
                        <strong>{status}</strong>
                        {mode === "grid" && <div>{lastSeen}</div>}
                    </>
                }
                placement="bottom-end"
            >
                <div className={classes.iconWrapper}>
                    <CircleIcon
                        fontSize="small"
                        className={classNames(classes.statusIconClass, {
                            [classes.colorRed as string]: status.toLowerCase() === "offline",
                            [classes.colorGreen as string]: status.toLowerCase() === "online",
                            [classes.colorYellow as string]: status.toLowerCase() === "away"
                        })}
                    />
                </div>
            </Tooltip>
            {syncStatus && syncStatus.toLowerCase() === "synced" && (
                <Tooltip enterTouchDelay={400} title="Synced" placement="bottom-end">
                    <div className={classes.iconWrapper}>
                        <SyncDoneIcon fontSize="small" className={`${classes.statusIconClass} ${classes.colorGreen}`} />
                    </div>
                </Tooltip>
            )}
            {syncStatus && syncStatus.toLowerCase() === "out of sync" && (
                <Tooltip enterTouchDelay={400} title="Out of sync" placement="bottom-end">
                    <div className={classes.iconWrapper}>
                        <SyncDisabledIcon fontSize="small" className={`${classes.statusIconClass} rotated`} />
                    </div>
                </Tooltip>
            )}
            {syncStatus && syncStatus.toLowerCase() === "syncing" && (
                <Tooltip enterTouchDelay={400} title="Syncing" placement="bottom-end">
                    <div className={classes.iconWrapper}>
                        <SyncIcon
                            fontSize="small"
                            className={`${classes.statusIconClass} ${classes.colorYellow} rotating`}
                        />
                    </div>
                </Tooltip>
            )}
            {syncStatus && syncStatus.toLowerCase() === "error" && (
                <Tooltip enterTouchDelay={400} title="Syncing error" placement="bottom-end">
                    <div className={classes.iconWrapper}>
                        <SyncProblemIcon fontSize="small" className={`${classes.statusIconClass} rotated`} />
                    </div>
                </Tooltip>
            )}
            <Tooltip enterTouchDelay={400} title={wifiSpeed || "No Data"} placement="bottom-end">
                <div className={classes.iconWrapper}>
                    <svg
                        focusable="false"
                        height="20"
                        width="20"
                        viewBox="0 0 24 24"
                        aria-hidden="true"
                        role="presentation"
                    >
                        <radialGradient
                            id={`wifi-${wifiSpeed && typeof wifiSpeed === "string" && wifiSpeed.split(" ")[0]}`}
                            cx="50%"
                            cy="50%"
                            r="100%"
                            fx="50%"
                            fy="100%"
                        >
                            <stop
                                offset={
                                    wifiSpeed === "Fast"
                                        ? "100%"
                                        : wifiSpeed === "Medium"
                                        ? "50%"
                                        : wifiSpeed === "Slow"
                                        ? "25%"
                                        : "0%"
                                }
                                stopColor="black"
                            />
                            <stop
                                offset={
                                    wifiSpeed === "Fast"
                                        ? "100%"
                                        : wifiSpeed === "Medium"
                                        ? "50%"
                                        : wifiSpeed === "Slow"
                                        ? "25%"
                                        : "0%"
                                }
                                stopColor="#c4c4c4"
                                stopOpacity="1"
                            />
                        </radialGradient>
                        <path
                            d="M1 9l2 2c4.97-4.97 13.03-4.97 18 0l2-2C16.93 2.93 7.08 2.93 1 9zm8 8l3 3 3-3c-1.65-1.66-4.34-1.66-6 0zm-4-4l2 2c2.76-2.76 7.24-2.76 10 0l2-2C15.14 9.14 8.87 9.14 5 13z"
                            fill={`url(#wifi-${wifiSpeed && typeof wifiSpeed === "string" && wifiSpeed.split(" ")[0]})`}
                        ></path>
                    </svg>
                </div>
            </Tooltip>
            <Tooltip
                enterTouchDelay={400}
                title={
                    usedMem && totalMem ? (
                        <>
                            <strong>
                                {Math.round(totalMem / (1024 * 1024)) - Math.round(usedMem / (1024 * 1024))}
                            </strong>{" "}
                            GB available of <strong>{Math.round(totalMem / (1024 * 1024))}</strong> GB
                        </>
                    ) : (
                        "No info"
                    )
                }
                placement="bottom-end"
            >
                <div className={classes.iconWrapper}>
                    <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 24 24">
                        <defs>
                            <linearGradient
                                xmlns="http://www.w3.org/2000/svg"
                                id={`half_grad${freePercent}`}
                                x1="0%"
                                y1="0%"
                                x2="0%"
                                y2="100%"
                            >
                                <stop offset={`${freePercent - 10}%`} stopColor={storageIconColor} />
                                <stop offset={`${freePercent + 10}%`} stopColor="black" stopOpacity="1" />
                            </linearGradient>
                        </defs>
                        <path
                            d="M18 2h-8L4.02 8 4 20c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-6 6h-2V4h2v4zm3 0h-2V4h2v4zm3 0h-2V4h2v4z"
                            fill={`url(#half_grad${freePercent})`}
                        ></path>
                    </svg>
                </div>
            </Tooltip>
            <Tooltip
                enterTouchDelay={400}
                title={
                    <div style={verticalOrientation && deviceSnapshot.imgSrc ? { width: 170 } : { width: 300 }}>
                        {deviceSnapshot.isLoading ? (
                            <CircularProgress style={{ margin: "80px 0" }} />
                        ) : deviceSnapshot.imgSrc ? (
                            <>
                                <img style={{ width: "100%" }} src={deviceSnapshot.imgSrc} alt="Screenshot" />
                                <Typography variant={"subtitle1"}>{deviceSnapshot.date} ago</Typography>
                            </>
                        ) : (
                            <div style={{ padding: 30, textAlign: "center", fontSize: 24 }}>No Screenshot</div>
                        )}
                    </div>
                }
                placement="bottom-end"
                open={displayStatusOpen}
                onClose={onDisplayStatusClose}
                onOpen={onDisplayStatusOpen}
            >
                <div className={classes.iconWrapper}>
                    <MonitorIcon
                        fontSize="small"
                        className={classNames(classes.statusIconClass, {
                            [classes.colorYellow as string]:
                                passedMinutesFromLastScreenSHot > 20 && passedMinutesFromLastScreenSHot <= 30,
                            [classes.colorRed as string]: passedMinutesFromLastScreenSHot > 30
                        })}
                    />
                </div>
            </Tooltip>
            {additionalUsbDevicesInfo && !!additionalUsbDevicesInfo.length && (
                <Tooltip
                    enterTouchDelay={400}
                    title={
                        (additionalUsbDevicesInfo &&
                            additionalUsbDevicesInfo.length &&
                            additionalUsbDevicesInfo.map((i: any, index: number) => {
                                const fStep = i.split(":");
                                fStep.splice(0, 2);
                                const result = fStep
                                    .join(":")
                                    .slice(4)
                                    .trim();
                                return (
                                    <div key={`device-info-${index}`}>{result !== "" ? result : "Unknown device"}</div>
                                );
                            })) ||
                        ""
                    }
                    placement="bottom-end"
                >
                    <div className={classes.iconWrapper}>
                        <USBIcon fontSize="small" className={`${classes.statusIconClass} rotated`} />
                    </div>
                </Tooltip>
            )}
            {!!warningIconTooltips.length && (
                <Tooltip
                    enterTouchDelay={400}
                    title={
                        <>
                            {warningIconTooltips.map((t, i) => (
                                <p key={i}>{t}</p>
                            ))}
                        </>
                    }
                    placement="bottom-end"
                >
                    <div className={classes.iconWrapper}>
                        <WarnindIcon fontSize="small" className={`${classes.statusIconClass} ${classes.colorYellow}`} />
                    </div>
                </Tooltip>
            )}
        </>
    );
};
export default withStyles(styles)(StatusRenderer);
