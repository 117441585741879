import { Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import React, { ChangeEvent, useState } from "react";
const styles = () =>
    createStyles({
        container: {
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            marginRight: -5,
            marginLeft: -5
        },
        textField: {
            marginLeft: 5,
            marginRight: 5
        },
        dialogActions: {
            padding: "13px 20px",
            margin: 0
        }
    });

const AddOrganization = ({
    classes,
    close,
    onSave
}: {
    classes: Partial<ClassNameMap<string>>;
    close: () => void;
    onSave: (name: string, description: string) => void;
}) => {
    const [name, setName] = useState<string>("");
    const [description, setDescription] = useState<string>("");
    const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);
    };
    const handleDescriptionChange = (event: ChangeEvent<HTMLInputElement>) => {
        setDescription(event.target.value);
    };
    const handleSave = () => {
        onSave(name, description);
    };
    return (
        <Dialog
            fullScreen={false}
            open
            onClose={close}
            classes={{
                paper: classes.paper
            }}
            aria-labelledby="profile-dialog-title"
        >
            <DialogTitle id="profile-dialog-title">Add Organization</DialogTitle>
            <DialogContent>
                <div className={classes.contentWrap}>
                    <form className={classes.container} noValidate autoComplete="off">
                        <TextField
                            label="Name"
                            className={classes.textField}
                            value={name}
                            onChange={handleNameChange}
                            margin="normal"
                            required
                        />
                        <TextField
                            label="Description"
                            className={classes.textField}
                            onChange={handleDescriptionChange}
                            value={description}
                            margin="normal"
                        />
                    </form>
                </div>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                <Button onClick={handleSave} color="primary" disabled={!name}>
                    Save
                </Button>
                <Button onClick={close} color="primary">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default withStyles(styles)(AddOrganization);
