import { WithStyles } from "@material-ui/core";
import Fab from "@material-ui/core/Fab";
import createStyles from "@material-ui/core/styles/createStyles";
import withStyles from "@material-ui/core/styles/withStyles";
import DeleteIcon from "@material-ui/icons/CloseOutlined";
import classNames from "classnames";
import React from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { GREEN_COLOR, RED_COLOR } from "../../constants/style-constants";
import { IMasonryItem } from "../../interfaces/daos";
import FloatGrid from "./float-grid";

const styles = createStyles({
    currentPlaylist: {
        display: "flex",
        alignItems: "stretch",
        overflowX: "auto",
        marginLeft: 10,
        flex: 1,
        height: 190,
        overflowY: "hidden",
        border: "2px solid #ececec",
        "@media (max-width: 600px)": {
            height: 130,
            marginLeft: 0
        },
        overflowScrolling: "touch",
        WebkitOverflowScrolling: "touch"
        // backgroundColor: "lightGray"
    },
    currentPlaylistDragOver: {
        backgroundColor: "lightBlue"
    },
    currentPlaylistItemWrapper: {
        width: 150,
        // height: 150,
        margin: "7px 5px",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        "&:hover $deleteIconFab": {
            visibility: "visible"
        }
    },
    currentPlaylistItem: {
        width: 150,
        // height: 160,
        // margin: 2,
        backgroundSize: "auto",
        backgroundPositionX: "center",
        backgroundRepeat: "no-repeat",
        position: "relative",
        // flex: "1 0 100px",
        flex: 1,
        display: "flex",
        flexDirection: "column"
    },
    image: {
        width: "100%",
        height: "100%"
    },
    deleteIconFab: {
        visibility: "hidden",
        position: "absolute",
        top: 5,
        right: 5,
        width: 28,
        height: 28,
        minHeight: 28,
        opacity: 0.8,
        "&:hover": {
            opacity: 1
        }
        // color: "#eb332a",
        // backgrounr: 'green'
    },
    currentPlaylistActions: {
        position: "absolute",
        bottom: 0,
        left: 0,
        right: 0,
        // opacity: 0.9,
        backgroundColor: "#ececec",
        display: "flex",
        justifyContent: "space-around"
    },
    count: {
        fontWeight: 500,
        padding: 5
    },
    addCount: { color: GREEN_COLOR },
    removeCount: { color: RED_COLOR }
});

const CurrentPlaylist = ({
    classes,
    data,
    onDurationChange,
    handleDeleteMedia,
    onItemDetail,
    isDragging
}: WithStyles<typeof styles> & {
    data: IMasonryItem[];
    onDurationChange?: (index: number, value: number) => void;
    handleDeleteMedia: (media: IMasonryItem) => void;
    onItemDetail: (media: IMasonryItem | null) => void;
    isDragging?: boolean;
}) => {
    const onEditNumber = (index: number, duration: number) => {
        if (onDurationChange) {
            onDurationChange(index, duration);
        }
    };

    return (
        <Droppable droppableId="droppable" direction="horizontal">
            {(provided, snapshot) => (
                <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    className={classNames(classes.currentPlaylist, {
                        [classes.currentPlaylistDragOver]: snapshot.isDraggingOver || isDragging
                    })}
                >
                    {data.map((item, index) => {
                        return (
                            <Draggable key={item.uuid} draggableId={item.uuid!} index={index}>
                                {pro => (
                                    <div
                                        ref={pro.innerRef}
                                        className={classes.currentPlaylistItemWrapper}
                                        style={{ ...pro.draggableProps.style }}
                                        {...pro.draggableProps}
                                        {...pro.dragHandleProps}
                                        onMouseOver={() => onItemDetail(item)}
                                        onMouseLeave={() => onItemDetail(null)}
                                    >
                                        <div
                                            className={classes.currentPlaylistItem}
                                            style={{ backgroundImage: `url("${item.thumb || item.image}")` }}
                                        >
                                            {/*<img className={classes.image} src={item.image} />*/}
                                            <Fab
                                                size="small"
                                                onClick={() => handleDeleteMedia(item)}
                                                color="secondary"
                                                aria-label="remove"
                                                className={classes.deleteIconFab}
                                            >
                                                <DeleteIcon fontSize="small" />
                                            </Fab>
                                            {/*<DeleteIcon*/}
                                            {/*    onClick={() => handleDeleteMedia(item)}*/}
                                            {/*    className={classes.deleteIcon}*/}
                                            {/*/>*/}
                                            <div className={classes.currentPlaylistActions}>
                                                <FloatGrid
                                                    defaultDuration={item.defaultDuration}
                                                    index={index}
                                                    edit={onEditNumber}
                                                    type={item.type}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </Draggable>
                        );
                    })}
                    {provided.placeholder}
                </div>
            )}
        </Droppable>
    );
};

export default withStyles(styles)(CurrentPlaylist);
