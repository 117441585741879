import { Tab, Tabs, Theme, WithStyles } from "@material-ui/core";
import { createStyles, withStyles } from "@material-ui/core/styles";
import {
    Airplay as AirplayIcon,
    Dashboard as DashboardIcon,
    DateRange as DateRangeIcon,
    PlaylistPlay as PlaylistPlayIcon,
    VideoLibraryOutlined as VideoLibraryOutlinedIcon
} from "@material-ui/icons";
import React from "react";
import BrandIcon from "./brand-icon";

const styles = ({ palette }: Theme) =>
    createStyles({
        tabs: {
            flex: 1
        }
    });
const Navigation = ({
    classes,
    value,
    handleChange,
    orgSelector,
    upload
}: WithStyles<typeof styles> & {
    value: number | false;
    handleChange: (event: React.ChangeEvent<{}>, value: any) => void;
    orgSelector: React.ReactNode;
    upload: React.ReactNode;
}) => {
    return (
        <>
            <BrandIcon />
            <Tabs
                className={classes.tabs}
                indicatorColor="secondary"
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="on"
            >
                <Tab icon={<DashboardIcon />} label="Dashboard" />
                <Tab icon={<AirplayIcon />} label="Devices" />
                <Tab icon={<VideoLibraryOutlinedIcon />} label="Media" />
                <Tab icon={<PlaylistPlayIcon />} label="Playlists" />
                <Tab icon={<DateRangeIcon />} label="Schedules" />
            </Tabs>
            {upload}
            {orgSelector}
        </>
    );
};
export default withStyles(styles)(Navigation);
