import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Checkbox,
    ListItemText,
    FormControlLabel,
    withMobileDialog
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import Select from "@material-ui/core/Select";
import { withStyles } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import React, { useEffect, useState, useContext } from "react";
import getOrganizationRoles from "../../api/role-api";
import { getDevices } from "../../api/main-api";
import { AppDispatch } from "../../context/main-context";
const styles = () =>
    createStyles({
        container: {
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap"
            // minWidth: 500
        },
        formControl: {
            minWidth: 250,
            flex: 1,
            marginLeft: 5,
            marginRight: 5
        },
        dialogActions: {
            padding: "13px 20px",
            margin: 0
        }
    });

const InviteUser = ({
    classes,
    fullScreen,
    close,
    mode,
    onInvite,
    onEdit,
    userForEdit
}: {
    classes: Partial<ClassNameMap<string>>;
    fullScreen?: boolean;
    close: () => void;
    mode: string;
    onInvite: (data: any) => void;
    onEdit: (data: any) => void;
    userForEdit: any;
}) => {
    const dispatch = useContext(AppDispatch)!;

    const [email, setEmail] = useState<string>(userForEdit.email || "");
    const [roles, setRoles] = useState<any[]>([]);
    const [role, setRole] = useState<string>(userForEdit.roleId || "");
    const [devices, setDevices] = useState<any[]>([]);
    const [selectedDevices, setSelectedDevices] = useState<any[]>(userForEdit.devices || []);
    const [accessAllDevices, setAccessAllDevices] = useState<boolean>(userForEdit.accessAllDevices || false);
    useEffect(() => {
        let canceled = false;
        getOrganizationRoles()
            .then(data => {
                if (!canceled) {
                    setRoles(data.data);
                }
            })
            .catch(e => {
                dispatch({ type: "SHOW_MESSAGE_ERROR", payload: e });
            });
        return () => {
            canceled = true;
        };
    }, [dispatch]);
    useEffect(() => {
        let canceled = false;
        getDevices()
            .then(data => {
                if (!canceled) {
                    setDevices(data.data.devices);
                }
            })
            .catch(e => {
                dispatch({ type: "SHOW_MESSAGE_ERROR", payload: e });
            });
        return () => {
            canceled = true;
        };
    }, [dispatch]);

    const handleEmailChange = (event: any) => {
        setEmail(String(event.target.value));
    };

    const renderDevicesMenuItems = () => {
        const allItem = (
            <MenuItem key="all">
                <Checkbox color="primary" checked={selectedDevices.length === devices.length} />
                <ListItemText primary="All" />
            </MenuItem>
        );
        const items = devices.map(d => (
            <MenuItem key={d._id} value={d._id}>
                <Checkbox color="primary" checked={selectedDevices.indexOf(d._id) > -1} />
                <ListItemText primary={d.name} />
            </MenuItem>
        ));
        items.unshift(allItem);
        return items;
    };

    const handleSave = () => {
        if (mode === "invite") {
            onInvite({
                email,
                roleId: role,
                devices: selectedDevices,
                tunnels: [],
                accessAllDevices,
                accessAllTunnels: false
            });
        } else {
            onEdit({
                tunnels: [],
                devices: selectedDevices,
                accessAllDevices,
                accessAllTunnels: false
            });
        }
    };

    return (
        <Dialog
            fullScreen={fullScreen}
            open
            disableEnforceFocus={true}
            onClose={close}
            aria-labelledby="profile-dialog-title"
        >
            <DialogTitle id="profile-dialog-title">{mode === "invite" ? `Invite user` : `Edit user`}</DialogTitle>
            <DialogContent>
                <div>
                    <form className={classes.container} noValidate autoComplete="off">
                        <FormControl className={classes.formControl}>
                            <TextField
                                disabled={mode === "edit"}
                                label="Email"
                                value={email}
                                onChange={handleEmailChange}
                                margin="normal"
                            />
                        </FormControl>
                        <FormControl
                            className={classes.formControl}
                            style={fullScreen ? { marginBottom: 16, marginTop: 16 } : { marginTop: 16 }}
                        >
                            <InputLabel htmlFor="role-helper">Role</InputLabel>
                            <Select
                                value={role}
                                onChange={e => setRole(String(e.target.value))}
                                input={<Input name="Role" id="role-helper" />}
                            >
                                {roles.map(r => (
                                    <MenuItem key={r._id} value={r._id}>
                                        {r.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </form>
                    <div className={classes.container}>
                        <FormControl className={classes.formControl} style={fullScreen ? { marginBottom: 16 } : {}}>
                            <InputLabel htmlFor="devices">Devices</InputLabel>
                            <Select
                                input={<Input id="devices" />}
                                multiple
                                value={selectedDevices}
                                renderValue={selected => {
                                    if (Array.isArray(selected)) {
                                        return `${selected.length} devices selected`;
                                    }
                                }}
                                onChange={(e, child) => {
                                    if ((child as any).key === "all") {
                                        if (selectedDevices.length !== devices.length) {
                                            setSelectedDevices(devices.map(d => d._id));
                                        } else {
                                            setSelectedDevices([]);
                                        }
                                    } else {
                                        setSelectedDevices(e.target.value as string[]);
                                    }
                                }}
                            >
                                {renderDevicesMenuItems()}
                            </Select>
                        </FormControl>
                        <FormControlLabel
                            style={fullScreen ? { marginTop: 16 } : {}}
                            className={classes.formControl}
                            control={
                                <Checkbox
                                    checked={accessAllDevices}
                                    onChange={(e, checked) => {
                                        setAccessAllDevices(checked);
                                        if (checked) {
                                            setSelectedDevices(devices.map(d => d._id));
                                        }
                                    }}
                                    name="checkedB"
                                    color="primary"
                                />
                            }
                            label="Access to all devices"
                        />
                    </div>
                </div>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                <Button onClick={handleSave} color="primary">
                    Save
                </Button>
                <Button onClick={close} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default withMobileDialog()(withStyles(styles)(InviteUser));
