import { createStyles, WithStyles } from "@material-ui/core/styles";
import React, { useState } from "react";

import withStyles from "@material-ui/core/styles/withStyles";

import CellularPanel from "./cellular-network";
import EthernetPanel from "./ethernet-network";
import ProxyPanel from "./proxy-network";
import WiFiPanel from "./wifi-network";

const styles = createStyles({
    rootWrapper: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    root: {
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box",
        flexGrow: "inherit",
        padding: "15px 20px 0px 20px",
        width: "80%",
        alignItems: "stretch",
        "@media (max-width: 600px)": {
            width: "100%",
            padding: 0
        }
    },
    column: {
        flex: 1
    },
    details: {
        flexDirection: "row"
    },
    enabledSwitch: {},
    heading: {},
    fieldset: {
        flexDirection: "column",
        display: "flex",
        flex: 1,
        border: "white"
    },
    form: {
        flexDirection: "column",
        display: "flex",
        flex: 1
    },
    secondaryHeading: {}
});

const NetworkPanel = ({
    classes,
    device,
    updateAction
}: WithStyles<typeof styles> & { device: any; updateAction?: any }) => {
    const [expanded, setExpanded] = useState<string | false>("panel1");
    const handleChange = (panel: string) => (event: any, newExpand: boolean) => {
        setExpanded(newExpand ? panel : false);
    };

    return (
        <div className={classes.rootWrapper}>
            <div className={classes.root}>
                <WiFiPanel device={device} saveAction={updateAction} handleChange={handleChange} expanded={expanded} />
                <CellularPanel
                    device={device}
                    saveAction={updateAction}
                    handleChange={handleChange}
                    expanded={expanded}
                />

                <EthernetPanel
                    device={device}
                    saveAction={updateAction}
                    handleChange={handleChange}
                    expanded={expanded}
                />
                <ProxyPanel device={device} saveAction={updateAction} handleChange={handleChange} expanded={expanded} />
            </div>
        </div>
    );
};
export default withStyles(styles)(NetworkPanel);
