import React, { useEffect, useState } from "react";
import { WithStyles } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import createStyles from "@material-ui/core/styles/createStyles";
import withStyles from "@material-ui/core/styles/withStyles";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import AssignToGroupIcon from "@material-ui/icons/SwapHoriz";
import classNames from "classnames";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { TableCellProps } from "react-virtualized";
import { changeSchedulesGroup, deleteSchedule, getSchedules } from "../../api/schedule";
import { BLUE_COLOR, GREEN_COLOR, RED_COLOR } from "../../constants/style-constants";
import { determineUpdatedAt } from "../../utils/date-parser-util";
import AlertDeleteDialog from "../shared/alert-delete-dialog";
import Grid, { IGridColumn, IGridDataItem } from "../shared/grid/grid";

const styles = createStyles({
    gridDefaultContextRenderer: {
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis"
    },
    actionIcon: {
        marginRight: 10
    },
    editButton: {
        color: BLUE_COLOR
    },
    copyButton: {
        color: GREEN_COLOR
    },
    deleteButton: {
        color: RED_COLOR
    }
});

const ScheduleGrid = (
    props: WithStyles<typeof styles> &
        RouteComponentProps & {
            groups: { name: string; _id: string }[];
            selectedGroupId: string | null;
            isSmallViewport?: boolean;
        }
) => {
    const { classes, groups, selectedGroupId, history, isSmallViewport } = props;

    const [page, setPage] = useState<number>(0);
    const [schedules, setSchedules] = useState<any[]>([]);
    const [rowsPerPage, setRowsPerPage] = useState<number>(10);
    const [count, setCount] = useState<number>(30);
    const [searchValue, setSearchValue] = useState<string>("");
    const [isDataLoading, setIsDataLoading] = useState<boolean>(false);
    const [assignToGroupAnchorEl, setAssignToGroupAnchorEl] = useState<null | HTMLElement>(null);
    const [selectedRows, setSelectedRows] = useState<IGridDataItem[]>([]);
    const [reloadData, setReloadData] = useState<boolean>(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
    const [idScheduleForDelete, setIdScheduleForDelete] = useState<string>("");

    useEffect(() => {
        setIsDataLoading(true);
        const filters = [];
        if (selectedGroupId && selectedGroupId !== "-1") {
            if (selectedGroupId === "-2") {
                filters.push({ property: "groupId" });
            } else {
                filters.push({ property: "groupId", value: selectedGroupId });
            }
        }
        getSchedules({ searchValue, limit: rowsPerPage, start: page * rowsPerPage, filters })
            .then(data => {
                setSchedules(data.data.data);
                setCount(data.data.totalCount);
                setSelectedRows([]);
                setIsDataLoading(false);
            })
            .catch(() => {
                setSelectedRows([]);
                setIsDataLoading(false);
            });
    }, [reloadData, searchValue, page, rowsPerPage, selectedGroupId]);
    const searchValueChange = (value: string) => {
        setPage(0);
        setSearchValue(value);
    };
    const onChangePage = (e: React.MouseEvent<HTMLButtonElement> | null, p: number) => {
        setPage(p);
    };
    const onChangeRowsPerPage = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value));
    };
    const handelEdit = (id: string) => () => {
        history.replace(`/schedule/${id}`);
    };
    const handelDelete = (id: string) => () => {
        setIdScheduleForDelete(id);
        setShowDeleteDialog(true);
    };
    const deleteScheduleById = (id: string) => {
        setShowDeleteDialog(false);
        deleteSchedule(id)
            .then(() => setReloadData(!reloadData))
            // tslint:disable-next-line:no-empty
            .catch(() => {});
    };
    const onCloseDeleteDialog = () => {
        setShowDeleteDialog(false);
    };

    const gridActionsContextRenderer: (cellRendererProps: TableCellProps) => React.ReactNode = p => {
        return (
            <div className={classes.gridDefaultContextRenderer}>
                <Tooltip title="Edit">
                    <IconButton
                        aria-label="edit"
                        color="primary"
                        className={classes.editButton}
                        onClick={handelEdit(p.rowData._id)}
                    >
                        <EditIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                    <IconButton
                        color="primary"
                        className={classes.deleteButton}
                        aria-label="delete"
                        onClick={handelDelete(p.rowData._id)}
                    >
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            </div>
        );
    };

    const gridMobileActionsContextRenderer: (cellRendererProps: TableCellProps) => React.ReactNode = p => {
        return (
            <div className={classes.gridDefaultContextRenderer}>
                <Tooltip title="Edit">
                    <EditIcon
                        aria-label="edit"
                        color="primary"
                        className={classNames(classes.actionIcon, {
                            [classes.editButton as string]: true
                        })}
                        onClick={handelEdit(p.rowData._id)}
                    />
                </Tooltip>
                <Tooltip title="Delete">
                    <DeleteIcon
                        color="primary"
                        className={classNames(classes.actionIcon, {
                            [classes.deleteButton as string]: true
                        })}
                        aria-label="delete"
                        onClick={handelDelete(p.rowData._id)}
                    />
                </Tooltip>
            </div>
        );
    };

    const gridDefaultContextRenderer: (cellRendererProps: TableCellProps) => React.ReactNode = properties => {
        const data = <div className={classes.gridDefaultContextRenderer}>{properties.cellData || ""}</div>;
        if (window.innerWidth < 768) {
            return (
                <Tooltip title={properties.cellData || ""} placement="bottom-end">
                    {data}
                </Tooltip>
            );
        }
        return data;
    };

    const gridLastChangedContextRenderer: (cellRendererProps: TableCellProps) => React.ReactNode = properties => {
        let content = null;
        if (properties.cellData) {
            content = determineUpdatedAt(properties.cellData);
        }
        content = content || null;
        const data = <div className={classes.gridDefaultContextRenderer}>{content}</div>;
        if (window.innerWidth < 768) {
            return (
                <Tooltip title={properties.cellData || "N/A"} placement="bottom-end">
                    {data}
                </Tooltip>
            );
        }
        return data;
    };

    const columns: IGridColumn[] = [
        {
            dataKey: "name",
            width: 200,
            label: "Name",
            flexGrow: 1,
            flexShrink: 1,
            cellContentRenderer: gridDefaultContextRenderer
        },
        {
            dataKey: "description",
            width: 200,
            label: "Description",
            flexGrow: 1,
            flexShrink: 1,
            cellContentRenderer: gridDefaultContextRenderer
        },
        {
            dataKey: "updatedAt",
            width: 200,
            label: "Last changed",
            flexGrow: 1,
            flexShrink: 1,
            cellContentRenderer: gridLastChangedContextRenderer
        },
        {
            dataKey: "actions",
            width: 150,
            label: "Actions",
            flexGrow: 1,
            flexShrink: 1,
            cellContentRenderer: gridActionsContextRenderer
        }
    ];

    const mobileColumns: IGridColumn[] = [
        {
            dataKey: "name",
            width: 200,
            label: "Name",
            flexGrow: 1,
            flexShrink: 1,
            cellContentRenderer: gridDefaultContextRenderer
        },
        {
            dataKey: "actions",
            width: 150,
            label: "Actions",
            flexGrow: 1,
            flexShrink: 1,
            cellContentRenderer: gridMobileActionsContextRenderer
        }
    ];

    function handleAssignToGroupIconClick(event: React.MouseEvent<HTMLElement>) {
        setAssignToGroupAnchorEl(event.currentTarget);
    }
    function handleAssignToGroupMenuClose() {
        setAssignToGroupAnchorEl(null);
    }
    function assignToGroup(groupId: string) {
        const selectedSchedulesIds = selectedRows.map(row => row._id);
        changeSchedulesGroup(groupId, selectedSchedulesIds)
            .then(() => {
                setReloadData(!reloadData);
                setSelectedRows([]);
                // tslint:disable-next-line:no-empty
            })
            // tslint:disable-next-line:no-empty
            .catch(() => {});
        handleAssignToGroupMenuClose();
    }
    function getSelectedGridToolbarElements() {
        const availableGroups = groups.filter(group => group._id !== selectedGroupId);
        if (!availableGroups.length) {
            return <></>;
        }
        const selectedGridToolbarElements: React.ReactNode = (
            <>
                <Tooltip title="Assign to group">
                    <IconButton aria-label="assign to group" onClick={handleAssignToGroupIconClick}>
                        <AssignToGroupIcon />
                    </IconButton>
                </Tooltip>
                <Menu
                    id="groups-to-assign"
                    anchorEl={assignToGroupAnchorEl}
                    keepMounted
                    open={!!assignToGroupAnchorEl}
                    onClose={handleAssignToGroupMenuClose}
                >
                    {groups
                        .filter(group => group._id !== selectedGroupId)
                        .map(group => (
                            <MenuItem key={group._id} onClick={() => assignToGroup(group._id)}>
                                {group.name}
                            </MenuItem>
                        ))}
                </Menu>
            </>
        );
        return selectedGridToolbarElements;
    }
    const rowSelectionChange = (selectedRowsFromGrid: IGridDataItem[]) => {
        setSelectedRows(selectedRowsFromGrid);
    };
    return (
        <div style={{ height: "calc(100vh - 200px)" }}>
            <Grid
                enablePagination
                page={page}
                onChangePage={onChangePage}
                onChangeRowsPerPage={onChangeRowsPerPage}
                rowsPerPage={rowsPerPage}
                searchValueChange={searchValueChange}
                rowsPerPageOptions={[10, 25, 50]}
                showCheckBoxes
                data={schedules.map(s => {
                    return { ...s, id: s._id };
                })}
                columns={isSmallViewport ? mobileColumns : columns}
                allDataCount={count}
                gridToolbarTitle="Schedules"
                isDataLoading={isDataLoading}
                selectedGridToolbarElements={getSelectedGridToolbarElements()}
                rowSelectionChange={rowSelectionChange}
                selected={selectedRows}
            />
            {showDeleteDialog && (
                <AlertDeleteDialog
                    del={() => {
                        deleteScheduleById(idScheduleForDelete);
                    }}
                    handleCloseAlert={onCloseDeleteDialog}
                    message={"Do you really want to delete this schedule?"}
                />
            )}
        </div>
    );
};
export default withStyles(styles)(withRouter(ScheduleGrid));
