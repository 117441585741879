import { IconButton, Tooltip, WithStyles } from "@material-ui/core";
import createStyles from "@material-ui/core/styles/createStyles";
import withStyles from "@material-ui/core/styles/withStyles";
import EditIcon from "@material-ui/icons/Edit";
import PresentationIcon from "@material-ui/icons/PlayCircleFilled";
import RemoteIcon from "@material-ui/icons/SettingsRemote";
import SyncIcon from "@material-ui/icons/Sync";
import React from "react";
import { BLUE_COLOR, RED_COLOR } from "../../../constants/style-constants";
interface IActionsRendererProps {
    deviceId: string;
    handleSyncClick: (deviceId: string) => void;
    handelEditClick: (deviceId: string) => void;
    onPresentationClick: (deviceId: string) => void;
    presentationPlayListId: string | undefined;
    onRemoteControlClick: (deviceId: string) => void;
    syncDisabled?: boolean;
}
const styles = createStyles({
    editButton: {
        color: BLUE_COLOR
    },
    deleteButton: {
        color: RED_COLOR
    },
    syncIconWrapper: {
        float: "left"
    }
});
const ActionsRenderer = ({
    classes,
    deviceId,
    handleSyncClick,
    handelEditClick,
    presentationPlayListId,
    onPresentationClick,
    onRemoteControlClick,
    syncDisabled
}: WithStyles<typeof styles> & IActionsRendererProps) => {
    return (
        <>
            <Tooltip
                title={
                    syncDisabled
                        ? "System transcoding some media files, Sync options will became available after transcoding completes"
                        : "Sync device"
                }
            >
                <div className={classes.syncIconWrapper}>
                    <IconButton
                        disabled={!!syncDisabled}
                        color="primary"
                        aria-label="sync"
                        onClick={() => handleSyncClick(deviceId)}
                    >
                        <SyncIcon className="rotated" />
                    </IconButton>
                </div>
            </Tooltip>
            <Tooltip title="Device details">
                <IconButton
                    onClick={() => handelEditClick(deviceId)}
                    color="primary"
                    className={classes.editButton}
                    aria-label="edit"
                >
                    <EditIcon />
                </IconButton>
            </Tooltip>
            {/* <Tooltip title="Delete" aria-label="delete">
                <IconButton color="primary" className={classes.deleteButton} aria-label="Delete">
                    <DeleteIcon />
                </IconButton>
            </Tooltip> */}
            <Tooltip title="Remote control">
                <IconButton onClick={() => onRemoteControlClick(deviceId)} color="primary" aria-label="remote-control">
                    <RemoteIcon />
                </IconButton>
            </Tooltip>
            <Tooltip title="Presentation">
                <span>
                    <IconButton
                        disabled={!presentationPlayListId}
                        onClick={() => onPresentationClick(deviceId)}
                        color="primary"
                        aria-label="presentation"
                    >
                        <PresentationIcon />
                    </IconButton>
                </span>
            </Tooltip>
        </>
    );
};
export default withStyles(styles)(ActionsRenderer);
