import { Theme } from "@material-ui/core";
import React, { useContext, useState } from "react";

import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelActions from "@material-ui/core/ExpansionPanelActions";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";

import createStyles from "@material-ui/core/styles/createStyles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { changePassword, logout } from "../../../../api/main-api";
import { AppDispatch } from "../../../../context/main-context";
import ChangePasswordForm from "./ChangePasswordForm";

const styles = (theme: Theme) =>
    createStyles({
        contentWrap: {
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
            justifyContent: "space-between"
        },
        column33: {
            flexBasis: "33.33%"
        },
        heading: {
            fontSize: theme.typography.pxToRem(15)
        }
    });
const defaultErr: any = {
    oldPassword: "",
    newPassword: "",
    confirmNewPassword: ""
};
const PasswordOptions = ({
    classes,
    expanded,
    handleClose,
    handleExpansionChange,
    userData,
    changeUserData,
    type,
    title
}: WithStyles<typeof styles> & {
    expanded: string | false;
    handleClose: () => void;
    handleExpansionChange: (panel: string) => (event: any, newExpand: boolean) => void;
    userData?: any;
    changeUserData?: any;
    type: string;
    title: string;
}) => {
    const [oldPassword, setOldPassword] = useState<string>("");
    const [newPassword, setNewPassword] = useState<string>("");
    const [confirmNewPassword, setConfirmPassword] = useState<string>("");
    const [err, setErr] = useState<any>(defaultErr);
    // const [isPasswordRequired, setIsPasswordRequired] = useState<boolean>(true);
    const dispatch = useContext(AppDispatch)!;
    const handleChangePasswordFormFieldsChange = (old: string, newP: string, confirmNew: string) => {
        setOldPassword(old);
        setNewPassword(newP);
        setConfirmPassword(confirmNew);
    };

    const handleSaveButtonClick = () => {
        if (validatePasswords() && oldPassword !== newPassword) {
            changePassword(oldPassword, newPassword)
                .then(response => {
                    logout();
                })
                .catch(e => {
                    dispatch({ type: "SHOW_MESSAGE_ERROR", payload: e });
                });
        }
    };
    const validatePasswords = (setToState = true) => {
        const error: any = {
            oldPassword: "",
            newPassword: "",
            confirmNewPassword: ""
        };
        let isValid: any = true;

        if (oldPassword.trim() === "") {
            isValid = false;
            error.oldPassword = "Please enter password";
        } else if (oldPassword.length < 8) {
            isValid = false;
            error.oldPassword = "Passwords should be at least 8 chars";
        }

        if (newPassword.trim() === "") {
            isValid = false;
            error.newPassword = "Please enter password";
        } else if (newPassword.length < 8) {
            isValid = false;
            error.newPassword = "Passwords should be at least 8 chars";
        }

        if (confirmNewPassword !== newPassword) {
            isValid = false;
            error.confirmNewPassword = "Password and confirm password don't match";
        }

        if (setToState && Object.keys(error).length) {
            setErr(error);
        }

        return isValid;
    };
    return (
        <ExpansionPanel expanded={expanded === "password"} onChange={handleExpansionChange("password")}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <div className={classes.column33}>
                    <Typography className={classes.heading}>{title}</Typography>
                </div>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.contentWrap}>
                <ChangePasswordForm
                    isPasswordRequired
                    type={type}
                    //  ref={this.handleChangePasswordRef}
                    onEnter={handleSaveButtonClick}
                    onChange={handleChangePasswordFormFieldsChange}
                    error={err}
                />
            </ExpansionPanelDetails>
            <Divider />
            <ExpansionPanelActions>
                <Button size="small" onClick={handleClose}>
                    Cancel
                </Button>
                <Button size="small" color="primary" onClick={handleSaveButtonClick}>
                    Save
                </Button>
            </ExpansionPanelActions>
        </ExpansionPanel>
    );
};

export default withStyles(styles)(PasswordOptions);
