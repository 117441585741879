import React, { useContext, useEffect, useState } from "react";
import { createStyles, WithStyles } from "@material-ui/core/styles";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import withStyles from "@material-ui/core/styles/withStyles";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";

import EthernetFields from "./ethernet-fields";
import Button from "@material-ui/core/Button";
import ExpansionPanelActions from "@material-ui/core/ExpansionPanelActions";
import { updateDeviceConfig } from "../../../../api/device";
import { IEthernetFields } from "../../../../interfaces/i-network";
import { AppDispatch } from "../../../../context/main-context";
const defaultEthernet: IEthernetFields = {
    typeIp: "1",
    address: "",
    netMask: "255.255.255.0",
    gateway: "",
    dns: ""
};
const styles = createStyles({
    rootWrapper: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    root: {
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box",
        flexGrow: "inherit",
        padding: "15px 20px 0px 20px",
        width: 800,
        alignItems: "stretch"
    },
    column: {
        flex: 1
    },
    details: {
        flexDirection: "row"
    },
    enabledSwitch: {},
    heading: {},
    fieldset: {
        flexDirection: "column",
        display: "flex",
        flex: 1,
        border: "white"
    },
    form: {
        flexDirection: "column",
        display: "flex",
        flex: 1
    },
    between: {
        paddingBottom: 15
    },
    secondaryHeading: {}
});
const EthernetPanel = ({
    classes,
    device,
    expanded,
    saveAction,
    handleChange
}: WithStyles<typeof styles> & {
    device: any;
    expanded: string | false;
    saveAction: () => void;
    handleChange: (panel: string) => (event: any, newExpand: boolean) => void;
}) => {
    useEffect(() => {
        (async () => {
            try {
                if (device.data.config.ethernetNetwork !== undefined) {
                    if (device.data.config.ethernetNetwork.typeIp !== undefined) {
                        setTypeIp(device.data.config.ethernetNetwork.typeIp);
                    }

                    if (device.data.config.ethernetNetwork.address !== undefined) {
                        setAddress(device.data.config.ethernetNetwork.address);
                    }

                    if (device.data.config.ethernetNetwork.netMask !== undefined) {
                        setNetMask(device.data.config.ethernetNetwork.netMask);
                    }

                    if (device.data.config.ethernetNetwork.gateway !== undefined) {
                        setGateway(device.data.config.ethernetNetwork.gateway);
                    }

                    if (device.data.config.ethernetNetwork.dns !== undefined) {
                        setDns(device.data.config.ethernetNetwork.dns);
                    }
                }
            } catch (e) {
                console.log(e.message);
            }
        })();
    }, [device]);
    const dispatch = useContext(AppDispatch)!;
    const [typeIp, setTypeIp] = useState<string | unknown>(defaultEthernet.typeIp);
    const [address, setAddress] = useState<string>(defaultEthernet.address);
    const [netMask, setNetMask] = useState<string>(defaultEthernet.netMask);
    const [gateway, setGateway] = useState<string>(defaultEthernet.gateway);
    const [dns, setDns] = useState<string>(defaultEthernet.dns);

    const onChangeTypeIp = (e: any) => {
        setTypeIp(e.target.value);
    };
    const typeOfIpAddress = [
        {
            value: "1",
            label: "DHCP"
        },
        {
            value: "2",
            label: "Static"
        }
    ];

    const onChangeAddress = (e: any) => {
        setAddress(e.target.value);
    };
    const onChangeDNS = (e: any) => {
        setDns(e.target.value);
    };

    const onChangeNetMask = (e: any) => {
        setNetMask(e.target.value);
    };
    const onChangeGateWay = (e: any) => {
        setGateway(e.target.value);
    };
    const handleCancelClick = () => {
        if (device.data.config.ethernetNetwork.typeIp !== undefined) {
            setTypeIp(device.data.config.ethernetNetwork.typeIp);
        }

        if (device.data.config.ethernetNetwork.address !== undefined) {
            setAddress(device.data.config.ethernetNetwork.address);
        }

        if (device.data.config.ethernetNetwork.netMask !== undefined) {
            setNetMask(device.data.config.ethernetNetwork.netMask);
        }

        if (device.data.config.ethernetNetwork.gateway !== undefined) {
            setGateway(device.data.config.ethernetNetwork.gateway);
        }

        if (device.data.config.ethernetNetwork.dns !== undefined) {
            setDns(device.data.config.ethernetNetwork.dns);
        }
    };
    const handleSubmit = (e: any) => {
        e.preventDefault();

        const dataSend: any = {
            ...device.data.config,
            ethernetNetwork: {
                typeIp: typeIp,
                address: address,
                netMask: netMask,
                gateway: gateway,
                dns: dns
            }
        };
        (async () => {
            try {
                await updateDeviceConfig(device.data.deviceId, dataSend).then(() => {
                    dispatch({
                        type: "SHOW_MESSAGE_SUCCESS",
                        payload: "Ethernet updated successfully"
                    });
                });
                saveAction();
            } catch (e) {
                dispatch({ type: "SHOW_MESSAGE_ERROR", payload: e });
            }
        })();
    };

    return (
        <ExpansionPanel square expanded={expanded === "panel3"} onChange={handleChange("panel3")}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1c-content" id="panel1c-header">
                <div className={classes.column}>
                    <Typography className={classes.heading}>Ethernet</Typography>
                </div>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.details}>
                <form className={classes.form}>
                    <fieldset className={classes.fieldset}>
                        <FormControl className={classes.between}>
                            <InputLabel htmlFor="Ip-type-address">IP Address</InputLabel>

                            <Select id="IP-address" value={typeIp} onChange={onChangeTypeIp}>
                                {typeOfIpAddress.map(option => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </Select>
                            {typeIp === "2" && (
                                <EthernetFields
                                    address={address}
                                    netMask={netMask}
                                    gateway={gateway}
                                    dns={dns}
                                    onChangeAddress={onChangeAddress}
                                    onChangeDNS={onChangeDNS}
                                    onChangeGateWay={onChangeGateWay}
                                    onChangeNetMask={onChangeNetMask}
                                    device={device}
                                />
                            )}
                        </FormControl>
                    </fieldset>
                </form>
            </ExpansionPanelDetails>
            <ExpansionPanelActions>
                <Button size="small" onClick={handleCancelClick}>
                    Cancel
                </Button>
                <Button size="small" onClick={handleSubmit} color="primary">
                    Save
                </Button>
            </ExpansionPanelActions>
        </ExpansionPanel>
    );
};

export default withStyles(styles)(EthernetPanel);
