import React, { useContext, useEffect, useState } from "react";
import { createStyles, WithStyles } from "@material-ui/core/styles";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelActions from "@material-ui/core/ExpansionPanelActions";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import withStyles from "@material-ui/core/styles/withStyles";
import { TextField } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import IOSSwitch from "./ios-switch";
import MaskedInput from "react-text-mask";
import { updateDeviceConfig } from "../../../../api/device";
import { AppDispatch } from "../../../../context/main-context";
const SimMask = (props: any) => {
    const { inputRef, ...other } = props;
    return (
        <MaskedInput
            {...other}
            ref={(ref: any) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={[/\d/, /\d/, /\d/, /\d/]}
            guide={true}
        />
    );
};

const styles = createStyles({
    rootWrapper: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    root: {
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box",
        flexGrow: "inherit",
        padding: "15px 20px 0px 20px",
        width: 800,
        alignItems: "stretch"
    },
    column: {
        flex: 1
    },
    details: {
        flexDirection: "row"
    },
    enabledSwitch: {},
    heading: {},
    fieldset: {
        flexDirection: "column",
        display: "flex",
        flex: 1,
        border: "white"
    },
    form: {
        flexDirection: "column",
        display: "flex",
        flex: 1
    },
    secondaryHeading: {}
});
const defaultConfig = {
    apnName: "",
    username: "",
    cellularPassword: "",
    simCardPin: ""
};
const CellularPanel = ({
    classes,
    device,
    expanded,
    saveAction,
    handleChange
}: WithStyles<typeof styles> & {
    device: any;
    expanded: string | false;
    saveAction: () => void;
    handleChange: (panel: string) => (event: any, newExpand: boolean) => void;
}) => {
    const dispatch = useContext(AppDispatch)!;

    const [apnName, setApnName] = useState<string>(defaultConfig.apnName);
    const [username, setUsername] = useState<string>(defaultConfig.username);
    const [disable, setDisable] = useState<boolean>(false);
    const [cellularPassword, setCellularPassword] = useState<string>(defaultConfig.cellularPassword);
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [simCardPin, setSimCardPin] = useState<string>(defaultConfig.simCardPin);

    useEffect(() => {
        (async () => {
            try {
                if (device.data.config.cellularNetwork !== undefined) {
                    if (device.data.config.cellularNetwork.apnName !== undefined) {
                        setApnName(device.data.config.cellularNetwork.apnName);
                    }
                    if (device.data.config.cellularNetwork.username !== undefined)
                        setUsername(device.data.config.cellularNetwork.username);

                    if (device.data.config.cellularNetwork.cellularPassword !== undefined)
                        setCellularPassword(device.data.config.cellularNetwork.cellularPassword);

                    if (device.data.config.cellularNetwork.simCardPin !== undefined)
                        setSimCardPin(device.data.config.cellularNetwork.simCardPin);
                }
            } catch (e) {
                console.log(e);
            }
        })();
    }, [device]);

    const onChangeDisabled = () => {
        disable ? setDisable(false) : setDisable(true);
    };
    const onChangeApnName = (e: any) => {
        setApnName(e.target.value);
    };
    const onChangeUsername = (e: any) => {
        setUsername(e.target.value);
    };
    const onChangeCellularPassword = (e: any) => {
        setCellularPassword(e.target.value);
    };
    const onClickShowPassword = () => {
        showPassword ? setShowPassword(false) : setShowPassword(true);
    };
    const onChangeSimCardPin = (e: any) => {
        setSimCardPin(e.target.value);
    };
    const handleCancelClick = (e: any) => {
        if (device.data.config.cellularNetwork.apnName !== undefined)
            setApnName(device.data.config.cellularNetwork.apnName);
        if (device.data.config.cellularNetwork.username !== undefined)
            setUsername(device.data.config.cellularNetwork.username);
        if (device.data.config.cellularNetwork.cellularPassword !== undefined)
            setCellularPassword(device.data.config.cellularNetwork.cellularPassword);
        if (device.data.config.cellularNetwork.simCardPin !== undefined)
            setSimCardPin(device.data.config.cellularNetwork.simCardPin);
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();
        const senData: any = {
            ...device.data.config,
            cellularNetwork: {
                apnName: apnName,
                username: username,
                disable: disable,
                cellularPassword: cellularPassword,
                simCardPin: simCardPin
            }
        };
        (async () => {
            try {
                await updateDeviceConfig(device.data.deviceId, senData).then(() => {
                    dispatch({
                        type: "SHOW_MESSAGE_SUCCESS",
                        payload: "Cellular updated  successfully"
                    });
                });
                saveAction();
            } catch (e) {
                dispatch({ type: "SHOW_MESSAGE_ERROR", payload: e });
            }
        })();
    };
    return (
        <ExpansionPanel square expanded={expanded === "panel2"} onChange={handleChange("panel2")}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1c-content" id="panel1c-header">
                <div className={classes.column}>
                    <Typography className={classes.heading}>Cellular</Typography>
                </div>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.details}>
                <div className={classes.enabledSwitch}>
                    <FormControlLabel
                        label="Enable"
                        control={<IOSSwitch checked={disable} onChange={onChangeDisabled} value="checkE" />}
                    />
                </div>
                <form className={classes.form} onSubmit={handleSubmit}>
                    <fieldset disabled={disable} className={classes.fieldset}>
                        <TextField
                            id="Cellular-APN-name"
                            label="APN"
                            value={apnName}
                            onChange={onChangeApnName}
                            required={true}
                            autoComplete="off"
                        />
                        <TextField label="Username" value={username} onChange={onChangeUsername} autoComplete="off" />
                        <FormControl required>
                            <InputLabel>Password</InputLabel>
                            <Input
                                type={showPassword ? "text" : "password"}
                                autoComplete="new-password"
                                value={cellularPassword}
                                onChange={onChangeCellularPassword}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton onClick={onClickShowPassword} onMouseDown={e => e.preventDefault()}>
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                        <TextField
                            id="Cellular-SIM-PIN"
                            label="SIM CARD PIN"
                            InputProps={{ inputComponent: SimMask, value: simCardPin, onChange: onChangeSimCardPin }}
                            autoComplete="off"
                        />
                    </fieldset>
                </form>
            </ExpansionPanelDetails>
            <Divider />
            <ExpansionPanelActions>
                <Button size="small" onClick={handleCancelClick}>
                    Cancel
                </Button>
                <Button size="small" onClick={handleSubmit} color="primary">
                    Save
                </Button>
            </ExpansionPanelActions>
        </ExpansionPanel>
    );
};

export default withStyles(styles)(CellularPanel);
