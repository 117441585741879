import { WithStyles } from "@material-ui/core";
import createStyles from "@material-ui/core/styles/createStyles";
import withStyles from "@material-ui/core/styles/withStyles";
import TextField from "@material-ui/core/TextField";
import React, { useEffect, useState } from "react";
import DashboardChart from "./dashboard-chart";

const styles = createStyles({
    dashboardConfigs: {
        display: "flex",
        flexDirection: "column",
        width: "100%"
    }
});

const DashboardConfigs = ({
    classes,
    searchValueChange
}: WithStyles<typeof styles> & {
    searchValueChange: (v: string) => void;
}) => {
    const [isEverSearchValueChanged, setIsEverSearchValueChanged] = useState<boolean>(false);
    const [searchValue, setSearchValue] = useState<string>("");
    useEffect(() => {
        let canceled = false;
        let tId: any;
        if (isEverSearchValueChanged) {
            tId = setTimeout(() => {
                if (!canceled) {
                    searchValueChange!(searchValue.toLowerCase());
                }
            }, 1000);
        }
        return () => {
            if (tId) {
                clearTimeout(tId);
            }
            canceled = true;
        };
    }, [isEverSearchValueChanged, searchValue, searchValueChange]);

    const onSearchValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const val = e.target.value;
        setSearchValue(val);
        setIsEverSearchValueChanged(true);
    };

    return (
        <div className={classes.dashboardConfigs}>
            <TextField value={searchValue} label="Search" onChange={onSearchValueChange} />
            <DashboardChart />
        </div>
    );
};

export default withStyles(styles)(DashboardConfigs);
