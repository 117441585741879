import { ChangeEvent, useState } from "react";

export function useFormInput(initialValue: any) {
    const [value, setValue] = useState(initialValue);
    function onChange(e: ChangeEvent<any>) {
        setValue(e.target.value);
    }
    function setinputvalue(v: any) {
        setValue(v);
    }
    return {
        value,
        onChange,
        setinputvalue
    };
}
