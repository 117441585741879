import { IGroupsWithMeta, INameable } from "../../../interfaces/dto";
import { calculateSelectedGroupId } from "./functions";
// import { Action } from "../../interfaces/daos";

export type IGroupsTypes =
    | "SET_GROUPS"
    | "CREATE_GROUP"
    | "UPDATE_GROUP"
    | "DELETE_GROUP"
    | "SET_SELECTED_GROUP_ID"
    | "REFRESH_SELECTED_GROUP_ID";

interface ActionOne<T = string> {
    type: T;
}
interface Action<T = string, P = any> {
    type: T;
    payload: P;
    [key: string]: any;
}

export interface State {
    groupsWithMeta: IGroupsWithMeta | null;
    selectedGroupId?: string;
}

export type IGroupsReducerAction<T = IGroupsTypes> = T extends "SET_GROUPS"
    ? Action<T, IGroupsWithMeta> & { hideAllTab: boolean }
    : T extends "CREATE_GROUP" | "UPDATE_GROUP"
    ? Action<T, INameable> & { hideAllTab: boolean }
    : T extends "DELETE_GROUP"
    ? Action<T, string> & { hideAllTab: boolean }
    : T extends "SET_SELECTED_GROUP_ID"
    ? Action<T, string> & { hideAllTab: boolean }
    : T extends "REFRESH_SELECTED_GROUP_ID"
    ? ActionOne<T> & { hideAllTab: boolean }
    : never;

export default function groupsReducer(state: State, action: IGroupsReducerAction) {
    switch (action.type) {
        case "SET_GROUPS": {
            const { hideAllTab = false, payload } = action;
            const { selectedGroupId } = state;
            const newState = {
                ...state,
                groupsWithMeta: payload,
                selectedGroupId
            };
            if (payload) {
                const newSelectedGroupId = calculateSelectedGroupId(hideAllTab, payload, selectedGroupId);
                newState.selectedGroupId = newSelectedGroupId;
            }
            return newState;
        }
        case "CREATE_GROUP": {
            const { groupsWithMeta } = state;
            const newGroupsWithMeta = groupsWithMeta
                ? { ...groupsWithMeta }
                : {
                      groups: [],
                      dataCountWithoutGroup: 0
                  };
            const { groups } = newGroupsWithMeta;
            const { payload } = action;
            groups.push(payload);
            return {
                groupsWithMeta: newGroupsWithMeta,
                selectedGroupId: groups[groups.length - 1]._id
            };
        }
        case "UPDATE_GROUP": {
            const { groupsWithMeta } = state;
            const { groups = [] } = groupsWithMeta!;
            const groupIndex = groups.findIndex(gr => gr._id === action.payload._id);
            if (groupIndex === -1) {
                return state;
            }
            const newGroupsWithMeta = {
                ...groupsWithMeta!,
                groups: [...groups.slice(0, groupIndex), action.payload, ...groups.slice(groupIndex + 1)]
            };
            return {
                ...state,
                groupsWithMeta: newGroupsWithMeta
            };
        }
        case "DELETE_GROUP": {
            const { groupsWithMeta } = state;
            const { groups = [] } = groupsWithMeta!;
            const { payload, hideAllTab = false } = action;
            const groupIndex = groups.findIndex(gr => gr._id === payload);
            if (groupIndex === -1) {
                return state;
            }
            const newGroupsWithMeta = {
                ...groupsWithMeta!,
                groups: [...groups.slice(0, groupIndex), ...groups.slice(groupIndex + 1)]
            };
            const newSelectedGroupId = groupIndex === groups.length - 1 ? "-2" : groups[groupIndex + 1]._id;

            return {
                ...state,
                groupsWithMeta: newGroupsWithMeta,
                selectedGroupId: calculateSelectedGroupId(hideAllTab, newGroupsWithMeta, newSelectedGroupId)
            };
        }
        case "SET_SELECTED_GROUP_ID": {
            const { groupsWithMeta } = state;
            const { hideAllTab, payload } = action;
            return {
                ...state,
                selectedGroupId: groupsWithMeta
                    ? calculateSelectedGroupId(hideAllTab, groupsWithMeta, payload)
                    : payload
            };
        }
        case "REFRESH_SELECTED_GROUP_ID": {
            const { groupsWithMeta, selectedGroupId } = state;
            const { hideAllTab } = action;
            const newSelectedGroupId = groupsWithMeta
                ? calculateSelectedGroupId(hideAllTab, groupsWithMeta, selectedGroupId)
                : selectedGroupId;
            if (newSelectedGroupId === selectedGroupId) {
                return state;
            }
            return {
                ...state,
                selectedGroupId: newSelectedGroupId
            };
        }
        default:
            return state;
    }
}
