import { WithStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import createStyles from "@material-ui/core/styles/createStyles";
import withStyles from "@material-ui/core/styles/withStyles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { DragDropContext, DropResult } from "react-beautiful-dnd";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { GREEN_COLOR, RED_COLOR } from "../../constants/style-constants";
import { IMasonryItem } from "../../interfaces/daos";
import Gallery from "../shared/masonry/masonry";

import ImageIcon from "@material-ui/icons/Image";
import LinkIcon from "@material-ui/icons/Link";
import VideoIcon from "@material-ui/icons/LocalMovies";
import { getMedias } from "../../api/media";
import { addPlayList, changePlayListsGroup, getPlayListMedia, updatePlayList } from "../../api/play-list";
import { AppDispatch } from "../../context/main-context";
import usePlaylistGroup from "../hooks/playlist-groups";
import AlertDialog from "../shared/alert-delete-dialog";
import CurrentPlaylist from "./current-playlist";

const styles = createStyles({
    playlist: {
        display: "flex",
        padding: 20,
        flexDirection: "column",
        height: "calc(100vh - 72px)",
        boxSizing: "border-box",
        "@media (max-width: 600px)": {
            padding: 5
        }
    },
    playlistForm: {
        display: "flex",
        flexDirection: "column",
        "@media (max-width: 600px)": {
            flexDirection: "row",
            marginBottom: 10
        }
    },
    playlistInput: {
        marginTop: 16,
        marginBottom: 8,
        "@media (max-width: 600px)": {
            marginTop: 0,
            marginBottom: 0
        }
    },
    playlistFormInputs: {
        display: "flex",
        flexDirection: "column",
        "@media (max-width: 600px)": {
            // paddingRight: 10,
            // flexDirection: "column"
            display: "block"
        }
    },
    playlistFormActions: {
        display: "flex",
        "@media (max-width: 600px)": {
            // flexDirection: "column",
            // flex: 1,
            // justifyContent: "flex-end"
            display: "block"
        }
    },
    addPlaylistActionButton: {
        margin: 5,
        backgroundColor: GREEN_COLOR,
        color: "white",
        "@media (max-width: 600px)": {
            margin: "5px 0"
        }
    },
    deletePlaylistActionButton: {
        margin: 5,
        backgroundColor: RED_COLOR,
        color: "white",
        "@media (max-width: 600px)": {
            margin: "5px 0"
        }
    },
    playlistLibrary: {
        overflowY: "auto",
        overflowScrolling: "touch",
        WebkitOverflowScrolling: "touch"
    },
    playlistLibraryItem: {
        width: 100,
        height: 100,
        whiteSpace: "nowrap",
        margin: 2,
        border: "1px solid gray",
        padding: 5,
        backgroundColor: "#cacaca"
    },
    formAndPlaylist: {
        display: "flex",
        "@media (max-width: 600px)": {
            // flexDirection: "column"
            display: "block"
        }
    },
    playWrap: {
        display: "flex",
        flex: 1,
        flexDirection: "column",
        overflowX: "auto",
        height: 240,
        "@media (max-width: 600px)": {
            flex: "none",
            height: 180
        }
    },
    playWrapToolbar: {
        display: "flex",
        flexDirection: "row",
        alignItems: "stretch",
        paddingBottom: 8,
        height: 40
    },
    listItemInfoWrapper: {
        flex: 1,
        marginLeft: 10
    },
    totalDuration: {
        margin: "0px 10px",
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap"
    }
});

const Playlists = ({
    classes,
    location: { pathname },
    history,
    match: { params }
}: WithStyles<typeof styles> & RouteComponentProps) => {
    const playListId = (params as any).id;
    const title = pathname.split("/")[2] ? "Edit playlist" : "Add playlist";
    const dispatch = useContext(AppDispatch)!;
    const { group: storageGroup } = usePlaylistGroup();
    const [name, setName] = useState<string>("");
    const [description, setDescription] = useState<string>("");
    const [medias, setMedias] = useState<any[]>([]);
    const [playListMedias, setPlayListMedias] = useState<IMasonryItem[]>([]);
    const [mediaForDelete, setMediaForDelete] = useState<IMasonryItem | null>(null);
    const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
    const [detailedMedia, setDetailedMedia] = useState<IMasonryItem | null>(null);
    const mediaIdToUsedCountMap: any = useMemo(() => ({}), []);
    const [isDragging, setIsDragging] = useState<boolean>(false);
    const [saveButtonDisable, setSaveButtonDisable] = useState<boolean>(true);
    const [errorName, setErrorName] = useState<string>("");
    const [errorNameOn, setErrorNameOn] = useState<boolean>(false);
    const [draggingId, setDraggingId] = useState<string | null>(null);
    const genUID = useCallback(
        (media: string) => {
            if (!mediaIdToUsedCountMap.hasOwnProperty(media)) {
                mediaIdToUsedCountMap[media] = 1;
            } else {
                mediaIdToUsedCountMap[media]++;
            }
            return media + "-" + mediaIdToUsedCountMap[media];
        },
        [mediaIdToUsedCountMap]
    );
    const onCancelBtnClick = () => {
        history.replace("/playlists");
    };
    useEffect(() => {
        if (playListId) {
            setSaveButtonDisable(false);
        }
    }, [playListId]);
    useEffect(() => {
        let canceled = false;
        getMedias({}).then((data: any) => {
            if (!canceled) {
                setMedias(data.data.data);
            }
        });
        return () => {
            canceled = true;
        };
    }, []);
    useEffect(() => {
        if (playListId) {
            getPlayListMedia(playListId).then(({ data }) => {
                setName(data.playList.name);
                setDescription(data.playList.description);
                if (data.playList.name !== "") {
                    setSaveButtonDisable(false);
                }
                setPlayListMedias(
                    data.medias
                        .map((x: any) => ({
                            ...x,
                            uuid: genUID(x.mediaId)
                        }))
                        .sort((a: any, b: any) => a.order - b.order)
                );
            });
        }
    }, [playListId, genUID]);

    const onNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.target.value === "" ? setSaveButtonDisable(true) : setSaveButtonDisable(false);
        setName(e.target.value);
    };
    const handelSavePlayList = () => {
        const data: any = {
            name,
            description,
            medias: playListMedias.map(f => {
                return {
                    mediaId: f.mediaId,
                    order: f.order,
                    duration: f.defaultDuration,
                    id: f.id
                };
            })
        };
        if (playListId) {
            updatePlayList(playListId, data)
                .then(() => {
                    onCancelBtnClick();
                    dispatch({ type: "SHOW_MESSAGE_SUCCESS", payload: "Playlist updated successfully" });
                })
                .catch(e => {
                    dispatch({ type: "SHOW_MESSAGE_ERROR", payload: e });
                });
        } else {
            addPlayList(data)
                .then(d => {
                    if (storageGroup && storageGroup !== "-1") {
                        changePlayListsGroup(storageGroup, [d.data._id])
                            .then(() => {
                                onCancelBtnClick();
                                dispatch({ type: "SHOW_MESSAGE_SUCCESS", payload: "Playlist added successfully" });
                            })
                            .catch(() => {
                                onCancelBtnClick();
                                dispatch({ type: "SHOW_MESSAGE_SUCCESS", payload: "Playlist added successfully" });
                            });
                    } else {
                        onCancelBtnClick();
                        dispatch({ type: "SHOW_MESSAGE_SUCCESS", payload: "Playlist added successfully" });
                    }
                })
                .catch(e => {
                    dispatch({ type: "SHOW_MESSAGE_ERROR", payload: e });
                });
        }
    };

    const onDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setDescription(e.target.value);
    };

    const handelDurationChange = (index: number, value: number) => {
        playListMedias[index].defaultDuration = value;
        setPlayListMedias([...playListMedias]);
    };

    const updateListItemInfo = (media: IMasonryItem | null) => {
        setDetailedMedia(media);
    };

    function onDragStart() {
        setIsDragging(true);
    }

    function onBeforeDragStart(e: any) {
        setDraggingId(e.draggableId);
    }

    function onDragEnd(result: DropResult) {
        setIsDragging(false);
        setDraggingId(null);
        // dropped outside the list
        if (
            result.destination &&
            result.destination.droppableId === "droppable" &&
            result.source.droppableId === "gallerydropable"
        ) {
            const id = result.draggableId.replace("gallery", "");
            const media = { ...medias.filter(f => f._id === id)[0] };
            media.mediaId = media._id;
            media.uuid = genUID(media.mediaId);
            media.id = undefined;
            media._id = undefined;
            playListMedias.splice(result.destination.index, 0, media);
        } else if (result.destination && result.mode === "FLUID") {
            const i = playListMedias.splice(result.source.index, 1);
            playListMedias.splice(result.destination.index, 0, ...i);
        }
        setPlayListMedias([...playListMedias.map((f, index) => ({ ...f, order: index }))]);
    }

    function handleDeleteMedia(media: IMasonryItem) {
        setMediaForDelete(media);
        setShowDeleteDialog(true);
    }

    function deleteMedia(media: IMasonryItem) {
        setShowDeleteDialog(false);
        const deletedMedia = playListMedias.find(item => media.order === item.order);
        if (deletedMedia) {
            const index = playListMedias.indexOf(deletedMedia);
            playListMedias.splice(index, 1);
            setPlayListMedias([...playListMedias.map((f, ii) => ({ ...f, order: ii }))]);
        }
        setMediaForDelete(null);
    }

    function onCloseDeleteDialog() {
        setShowDeleteDialog(false);
    }
    const duration = playListMedias.reduce((sum, f) => sum + f.defaultDuration, 0);
    const durationInMinutes = parseInt((duration / 60).toString(), 10);
    const minutes = parseInt((durationInMinutes % 60).toString(), 10);
    const hours = parseInt((durationInMinutes / 60).toString(), 10);
    let timeParts = [];
    timeParts.push(hours === 1 ? hours + " hour" : hours > 0 ? hours + " hours" : "");
    timeParts.push(minutes === 1 ? minutes + " minute" : minutes > 0 ? minutes + " minutes" : "");
    timeParts = timeParts.filter(part => part.length > 0);

    const time = timeParts.join(", ");
    const onBlurName = (e: any) => {
        if (e.target.value === "") {
            setErrorNameOn(true);
            setErrorName("input some name");
        }
    };
    const onFocusName = () => {
        setErrorNameOn(false);
        setErrorName("");
    };
    return (
        <>
            <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart} onBeforeDragStart={onBeforeDragStart}>
                <div className={classes.playlist}>
                    <div className={classes.formAndPlaylist}>
                        <div className={classes.playlistForm}>
                            <div className={classes.playlistFormInputs}>
                                <Typography>{title} </Typography>
                                <TextField
                                    error={errorNameOn}
                                    helperText={errorName}
                                    label="Name"
                                    value={name}
                                    onChange={onNameChange}
                                    onBlur={onBlurName}
                                    onFocus={onFocusName}
                                    className={classes.playlistInput}
                                />
                                <TextField
                                    label="Description"
                                    value={description}
                                    onChange={onDescriptionChange}
                                    className={classes.playlistInput}
                                />
                            </div>
                            <div className={classes.playlistFormActions}>
                                <Button
                                    className={classes.addPlaylistActionButton}
                                    onClick={handelSavePlayList}
                                    variant="contained"
                                    disabled={saveButtonDisable}
                                    fullWidth
                                >
                                    Save
                                </Button>
                                <Button
                                    className={classes.deletePlaylistActionButton}
                                    variant="contained"
                                    onClick={onCancelBtnClick}
                                    fullWidth
                                >
                                    Cancel
                                </Button>
                            </div>
                        </div>
                        <div className={classes.playWrap}>
                            <div className={classes.playWrapToolbar}>
                                <div className={classes.totalDuration}>
                                    Total duration:
                                    <br />
                                    {time ? time : "Less than a minute"}
                                </div>
                                {detailedMedia && (
                                    <div className={classes.listItemInfoWrapper}>
                                        <div style={{ display: "flex" }}>
                                            {detailedMedia.type === "video" ? (
                                                <VideoIcon />
                                            ) : detailedMedia.type === "image" ? (
                                                <ImageIcon />
                                            ) : detailedMedia.type === "web" ? (
                                                <LinkIcon />
                                            ) : (
                                                ""
                                            )}
                                            <div style={{ marginLeft: 5 }} className="masonry-item-name">
                                                {detailedMedia.name}
                                            </div>
                                        </div>
                                        <div
                                            style={{
                                                fontSize: 12,
                                                color: "gray",
                                                paddingLeft: 3,
                                                textOverflow: "ellipsis",
                                                overflow: "hidden",
                                                whiteSpace: "nowrap"
                                            }}
                                        >
                                            Uploaded - {new Date(detailedMedia.createdAt!).toUTCString()}
                                        </div>
                                    </div>
                                )}
                            </div>
                            <CurrentPlaylist
                                isDragging={isDragging}
                                data={playListMedias}
                                handleDeleteMedia={handleDeleteMedia}
                                onDurationChange={handelDurationChange}
                                onItemDetail={updateListItemInfo}
                            />
                        </div>
                    </div>
                    <div className={classes.playlistLibrary}>
                        <Gallery draggingId={draggingId} elements={medias} draggable draggableIdPrefix="gallery" />
                    </div>
                </div>
            </DragDropContext>
            {showDeleteDialog && (
                <AlertDialog
                    del={() => deleteMedia(mediaForDelete!)}
                    handleCloseAlert={onCloseDeleteDialog}
                    message={"Do you  want to delete this media from playlist"}
                />
            )}
        </>
    );
};
export default withStyles(styles)(withRouter(Playlists));
