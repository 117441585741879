import { WithStyles, withStyles } from "@material-ui/core";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import IconButton from "@material-ui/core/IconButton";
import createStyles from "@material-ui/core/styles/createStyles";
import TextField from "@material-ui/core/TextField";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import React, { useEffect, useState, KeyboardEvent } from "react";
import { GREEN_COLOR, RED_COLOR } from "../../constants/style-constants";

const styles = createStyles({
    currentPlaylistActions: {
        position: "absolute",
        bottom: 0,
        left: 0,
        right: 0,
        opacity: 0.9,
        backgroundColor: "white",
        display: "flex",
        justifyContent: "space-around"
    },
    count: {
        paddingTop: 5,
        fontWeight: 500,
        width: "100%",
        textAlign: "center",
        overflow: "hidden"
    },
    editInput: {
        height: 10,
        fontWeight: 500,
        width: "100%",
        textAlign: "center"
    },
    addCount: { color: GREEN_COLOR },
    removeCount: { color: RED_COLOR }
});

const FloatGrid = ({
    classes,
    defaultDuration,
    index,
    edit,
    type
}: WithStyles<typeof styles> & {
    defaultDuration: number;
    index: number;
    type: string;
    edit: (index: number, duration: number) => void;
}) => {
    const [durationCounter, setDurationCounter] = useState<number>(defaultDuration);
    const [editMode, setEditMode] = useState(false);
    const [isChangeable, setIsChangeable] = useState(true);
    const [myLatestTap, setMyLatestTap] = useState<number>(0);
    const handleRemoveButton = (i: number) => {
        setDurationCounter(durationCounter - 1);
        edit(i, durationCounter - 1);
    };
    useEffect(() => {
        if (type === "video") {
            setIsChangeable(false);
        }
    }, [type]);
    useEffect(() => {
        if (defaultDuration === null || defaultDuration < 0) {
            setDurationCounter(0);
        }
    }, [defaultDuration]);
    const handleAddButton = (i: number) => {
        let durationInto = durationCounter;
        durationInto++;
        setDurationCounter(durationInto);
        edit(i, durationInto);
    };
    const onChangeEdit = (e: React.ChangeEvent<HTMLInputElement>, i: number) => {
        const reg = /^[0-9]*$/;
        let duration: number;
        reg.test(e.target.value) ? (duration = Number(e.target.value)) : (duration = durationCounter);
        setDurationCounter(duration);
        edit(i, duration);
    };
    const handleBlur = () => {
        if (!editMode) {
            return;
        }

        if (!durationCounter) {
            setDurationCounter(0);
        }
        edit(index, durationCounter);
        setEditMode(false);
    };

    const handleKeyDown = (e: KeyboardEvent<HTMLElement>) => {
        if (e.key === "Enter") {
            handleBlur();
        }
        if (e.key === "Escape") {
            setEditMode(false);
            setDurationCounter(defaultDuration);
        }
    };
    const handleCustomDoubleClick = () => {
        if (editMode) {
            return;
        }
        const now = new Date().getTime();
        const timeSince = now - myLatestTap;
        if (timeSince < 600 && timeSince > 0) {
            setEditMode(true);
        }
        setMyLatestTap(new Date().getTime());
    };
    const onDoubleClick = () => {
        if (editMode) {
            return;
        }
        setEditMode(true);
    };

    return (
        <>
            <ClickAwayListener onClickAway={handleBlur}>
                <div style={{ display: "flex", flexDirection: "row", flex: 1, maxWidth: 150 }}>
                    <IconButton
                        className={classes.removeCount}
                        aria-label="Delete"
                        size="small"
                        onClick={() => {
                            handleRemoveButton(index);
                        }}
                        disabled={!isChangeable || durationCounter === 0}
                    >
                        <RemoveIcon />
                    </IconButton>

                    <div className={classes.count} onDoubleClick={onDoubleClick} onTouchStart={handleCustomDoubleClick}>
                        {isChangeable && editMode ? (
                            <TextField
                                id="inputDuration"
                                label=""
                                autoFocus={true}
                                value={durationCounter}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    onChangeEdit(e, index);
                                }}
                                onFocus={event => event.target.select()}
                                onBlur={handleBlur}
                                onKeyDown={handleKeyDown}
                                InputProps={{ classes: { input: classes.editInput } }}
                            />
                        ) : (
                            durationCounter
                        )}
                    </div>
                    <IconButton
                        className={classes.addCount}
                        aria-label="Add"
                        size="small"
                        onClick={() => {
                            handleAddButton(index);
                        }}
                        disabled={!isChangeable}
                    >
                        <AddIcon />
                    </IconButton>
                </div>
            </ClickAwayListener>
        </>
    );
};
export default withStyles(styles)(FloatGrid);
