import { createStyles, WithStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";

import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import withStyles from "@material-ui/core/styles/withStyles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import distanceInWordsToNow from "date-fns/formatDistanceToNow";
const styles = createStyles({
    rootWrapper: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    root: {
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box",
        flexGrow: "inherit",
        padding: "15px 20px 0px 20px",
        width: "90%",
        alignItems: "stretch",
        "@media (max-width: 600px)": {
            width: "100%",
            padding: 0
        }
    },
    column: {
        flex: 1
    },
    details: {
        flexDirection: "row"
    },
    enabledSwitch: {},
    heading: {},
    fieldset: {
        flexDirection: "column",
        display: "flex",
        flex: 1,
        border: "white"
    },
    form: {
        flexDirection: "column",
        display: "flex",
        flex: 1
    },
    pre: {
        overflowY: "auto"
    },
    secondaryHeading: {}
});

const StatusMetrica = ({
    classes,
    device,
    lastScreenShot
}: WithStyles<typeof styles> & { device: any; lastScreenShot: any }) => {
    const [expanded, setExpanded] = useState<string | false>("usb");
    const [statusAndMetrics, setStatusAndMetrics] = useState<any>(null);
    const [lastUpdateTime, setLastUpdateTime] = useState<any>(null);

    useEffect(() => {
        if (lastScreenShot && lastScreenShot.timestamps) {
            setLastUpdateTime(distanceInWordsToNow(lastScreenShot.timestamps));
        }
        try {
            const statusAndMetricsData = JSON.parse(lastScreenShot.data);
            if (statusAndMetricsData) {
                statusAndMetricsData.tempAndVolt =
                    statusAndMetricsData.temperature + statusAndMetricsData.voltage || "No data";
                statusAndMetricsData.monitorStatus =
                    statusAndMetricsData.tvService + statusAndMetricsData.cecScan || "No data";
            }
            setStatusAndMetrics(statusAndMetricsData);
            // tslint:disable-next-line:no-empty
        } catch (e) {}
    }, [lastScreenShot]);

    const handleChange = (panel: string) => (event: any, newExpand: boolean) => {
        setExpanded(newExpand ? panel : false);
    };
    const dataModel = [
        {
            dataKey: "usb",
            label: "USB Devices"
        },
        {
            dataKey: "users",
            label: "Users"
        },
        {
            dataKey: "tempAndVolt",
            label: "Temperature and Voltage"
        },
        {
            dataKey: "processes",
            label: "Processes"
        },
        {
            dataKey: "storage",
            label: "Storage"
        },

        {
            dataKey: "connection",
            label: "Connection"
        },

        {
            dataKey: "monitorStatus",
            label: "Monitor Status"
        }
    ];

    return (
        <div className={classes.rootWrapper}>
            <div className={classes.root}>
                {lastUpdateTime && (
                    <Typography variant="subtitle1">
                        Data have been taken <strong>{lastUpdateTime}</strong> ago
                    </Typography>
                )}
                {dataModel.map(option => (
                    <ExpansionPanel
                        key={option.dataKey}
                        square
                        expanded={expanded === option.dataKey}
                        onChange={handleChange(option.dataKey)}
                    >
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1c-content"
                            id="panel1c-header"
                        >
                            <div className={classes.column}>
                                <Typography className={classes.heading}>{option.label}</Typography>
                            </div>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails className={classes.details}>
                            <pre className={classes.pre}>
                                {statusAndMetrics && statusAndMetrics[option.dataKey]
                                    ? typeof statusAndMetrics[option.dataKey] === "string"
                                        ? statusAndMetrics[option.dataKey]
                                        : JSON.stringify(statusAndMetrics[option.dataKey])
                                    : `No data`}
                            </pre>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                ))}
            </div>
        </div>
    );
};
export default withStyles(styles)(StatusMetrica);
