import { createStyles, TextField, WithStyles } from "@material-ui/core";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import withStyles from "@material-ui/core/styles/withStyles";
import RemoveIcon from "@material-ui/icons/RemoveCircleOutline";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import React, { useState } from "react";
import EthernetFields from "./ethernet-fields";

import Switch from "@material-ui/core/Switch";
import { IWifiConfig } from "../../../../interfaces/i-network";
const styles = createStyles({
    column: {
        flex: 1
    },
    details: {
        flexDirection: "row"
    },
    enabledSwitch: {},
    heading: {},
    fieldset: {
        flexDirection: "column",
        display: "flex",
        flex: 1,
        border: "white"
    },
    form: {
        flexDirection: "column",
        display: "flex",
        flex: 1
    },
    del: {},
    delWrap: {},
    between: {
        paddingBottom: 15
    }
});

const WiFiDetails = ({
    classes,
    disable,
    onDeleteWifi,
    device,
    data,
    data: {
        wifiName,
        wifiSecurity,
        wifiPassword,
        wifiIpAddress,
        wifiIpAddress: { typeIp, address, netMask, gateway, dns },
        checkHiddenNetwork
    },
    onDataChange,
    index
}: WithStyles<typeof styles> & {
    disable: boolean;
    onDeleteWifi: () => void;
    onDataChange: (data: IWifiConfig) => void;
    data: IWifiConfig;
    device: any;
    index: number;
}) => {
    const typeOfSecWifi = [
        { value: "6", label: "None" },
        {
            value: "1",
            label: "WEP"
        },
        {
            value: "2",
            label: "WPA/WPA2 PSK"
        },
        {
            value: "3",
            label: "802.1x EAP"
        },
        {
            value: "4",
            label: "WAPI PSK"
        },
        {
            value: "5",
            label: "WAPI CERT"
        }
    ];
    const typeOfIpAddress = [
        {
            value: "1",
            label: "DHCP"
        },
        {
            value: "2",
            label: "Static"
        }
    ];
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const onClickShowPassword = () => {
        setShowPassword(!showPassword);
    };
    const onChangeHiddenNetwork = () => {
        data.checkHiddenNetwork = !data.checkHiddenNetwork;
        onDataChange(data);
    };
    const onChangeName = (e: any) => {
        data.wifiName = e.target.value;
        onDataChange(data);
    };
    const onChangeNetworkSecurity = (e: any) => {
        data.wifiSecurity = e.target.value;
        if (e.target.value === "6") {
            data.wifiPassword = "";
        }
        onDataChange(data);
    };
    const onChangeWifiPassword = (e: any) => {
        data.wifiPassword = e.target.value;
        onDataChange(data);
    };

    const onChangeWifiIpAddress = (e: any) => {
        data.wifiIpAddress.typeIp = e.target.value;
        if (data.wifiIpAddress.typeIp === "1") {
            data.wifiIpAddress.address = "";
            data.wifiIpAddress.dns = "";
            data.wifiIpAddress.netMask = "";
            data.wifiIpAddress.gateway = "";
        }
        onDataChange(data);
    };
    const onChangeAddress = (e: any) => {
        data.wifiIpAddress.address = e.target.value;
        onDataChange(data);
    };
    const onChangeDNS = (e: any) => {
        data.wifiIpAddress.dns = e.target.value;
        onDataChange(data);
    };

    const onChangeNetMask = (e: any) => {
        data.wifiIpAddress.netMask = e.target.value;
        onDataChange(data);
    };
    const onChangeGateWay = (e: any) => {
        data.wifiIpAddress.gateway = e.target.value;
        onDataChange(data);
    };
    return (
        <ExpansionPanelDetails className={classes.details}>
            <div className={classes.form}>
                <form autoComplete="off" className={classes.fieldset}>
                    <TextField
                        className={classes.between}
                        label="Network Name"
                        value={wifiName}
                        required={true}
                        onChange={onChangeName}
                        autoComplete="off"
                    />
                    <FormControl className={classes.between}>
                        <InputLabel>Security</InputLabel>
                        <Select value={wifiSecurity} onChange={onChangeNetworkSecurity} disabled={disable}>
                            {typeOfSecWifi.map(option => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    {wifiSecurity !== 6 ? (
                        <FormControl required={true} className={classes.between}>
                            <InputLabel>Password</InputLabel>
                            <Input
                                type={showPassword ? "text" : "password"}
                                autoComplete="new-password"
                                value={wifiPassword}
                                onChange={onChangeWifiPassword}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton onClick={onClickShowPassword} onMouseDown={e => e.preventDefault()}>
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                    ) : (
                        ""
                    )}
                    <FormControl className={classes.between}>
                        <InputLabel htmlFor="ip-address">Ip Address</InputLabel>
                        <Select
                            id={"WifiIpAddress" + index}
                            value={typeIp}
                            onChange={onChangeWifiIpAddress}
                            disabled={disable}
                        >
                            {typeOfIpAddress.map(option => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </Select>
                        {typeIp === "2" && (
                            <EthernetFields
                                address={address}
                                netMask={netMask}
                                gateway={gateway}
                                dns={dns}
                                onChangeAddress={onChangeAddress}
                                onChangeDNS={onChangeDNS}
                                onChangeGateWay={onChangeGateWay}
                                onChangeNetMask={onChangeNetMask}
                                device={device}
                            />
                        )}
                    </FormControl>
                    <FormControlLabel
                        disabled={disable}
                        control={
                            <Switch checked={checkHiddenNetwork} onChange={onChangeHiddenNetwork} value="checkH" />
                        }
                        label="Hidden Network"
                    />
                </form>
            </div>
            <div className={classes.delWrap}>
                <IconButton className={classes.del} onClick={onDeleteWifi}>
                    <RemoveIcon />
                </IconButton>
            </div>
        </ExpansionPanelDetails>
    );
};

export default withStyles(styles)(WiFiDetails);
