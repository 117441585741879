import { WithStyles, Hidden, CircularProgress } from "@material-ui/core";
import createStyles from "@material-ui/core/styles/createStyles";
import withStyles from "@material-ui/core/styles/withStyles";
import distanceInWordsToNow from "date-fns/formatDistanceToNow";
import useWindowWidth from "../../../hooks/window-size";
import React, { useEffect, useMemo, useState } from "react";
import Coverflow from "react-coverflow";
import { getRotatedSrc } from "../../../../utils/rotateImage";

const styles = createStyles({
    root: {
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        justifyContent: "stretch"
    },
    noSnapshot: {
        flex: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: 30
    },
    screenshotContainer: {
        position: "relative",
        display: "flex",
        flexDirection: "column"
    },
    screenshotWrapper: {
        display: "flex"
    },
    dateWrapper: {
        position: "absolute",
        width: "100%",
        textAlign: "center",
        top: "-25px"
    },
    date: {
        color: "#fff",
        fontSize: 11,
        backgroundColor: "gray",
        padding: "0px 5px 2px 5px"
    },
    img: {
        width: "100%"
    },
    loaderWrapper: {
        display: "flex",
        justifyContent: "center",
        padding: "30px"
    }
});

const DeviceHistory = ({
    classes,
    screenshots,
    additionalOptions
}: WithStyles<typeof styles> & {
    screenshots: { timestamps: any; src: any }[];
    additionalOptions: { verticalOrientation: boolean; rotateDirection: "clockwise" | "counterclockwise" | undefined };
}) => {
    const [active, setActive] = useState(-1);
    const { windowWidth, windowHeight } = useWindowWidth();
    const [finalScreenshots, setFinalScreenshots] = useState<{ timestamps: any; src: any }[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        if (finalScreenshots && finalScreenshots.length) {
            setTimeout(() => setActive(finalScreenshots.length - 1), 0);
        }
    }, [finalScreenshots]);

    const getScreenshotsWithRotatedImages = (screenshots: { timestamps: any; src: any }[], deg: number) => {
        const images: Promise<any>[] = screenshots.map(s => {
            return getRotatedSrc(s.src, deg);
        });
        return Promise.all(images);
    };

    useEffect(() => {
        if (!additionalOptions || !additionalOptions.verticalOrientation) {
            setFinalScreenshots(screenshots);
        } else {
            (async function() {
                try {
                    setIsLoading(true);
                    const deg = additionalOptions.rotateDirection === "counterclockwise" ? -90 : 90;
                    const rotatedImages = await getScreenshotsWithRotatedImages(screenshots, deg);
                    setFinalScreenshots(screenshots.map((s, i) => ({ ...s, src: rotatedImages[i] })));
                } catch (e) {
                } finally {
                    setIsLoading(false);
                }
            })();
        }
    }, [screenshots, additionalOptions]);

    const landscape: boolean = useMemo(() => windowHeight < windowWidth, [windowWidth, windowHeight]);
    return (
        <div className={classes.root}>
            {finalScreenshots.length ? (
                <>
                    <Hidden smDown>
                        <Coverflow
                            width="100vw"
                            height="500"
                            displayQuantityOfSide={additionalOptions && additionalOptions.verticalOrientation ? 5 : 2}
                            navigation={false}
                            enableHeading={false}
                            infiniteScroll={false}
                            active={active}
                        >
                            {finalScreenshots.map((data, index) => (
                                <div key={data.src + index} className={classes.screenshotContainer}>
                                    <div className={classes.dateWrapper}>
                                        <span className={classes.date}>
                                            {distanceInWordsToNow(data.timestamps)} ago
                                        </span>
                                    </div>
                                    <div className={classes.screenshotWrapper}>
                                        <img alt="screenshot" className={classes.img} src={data.src} />
                                    </div>
                                </div>
                            ))}
                        </Coverflow>
                    </Hidden>
                    <Hidden mdUp>
                        {landscape ? (
                            <div style={{ display: "flex", flexDirection: "row" }}>
                                {[...finalScreenshots].reverse().map((data, index) => (
                                    <div key={data.src + index} style={{ margin: "0 10px", flex: 1, minWidth: 200 }}>
                                        <div style={{ textAlign: "center", padding: 5 }}>
                                            <span>{distanceInWordsToNow(data.timestamps)} ago</span>
                                        </div>
                                        <img alt="screenshot" style={{ width: "100%" }} src={data.src} />
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <div style={{ display: "flex", flexDirection: "column" }}>
                                {[...finalScreenshots].reverse().map((data, index) => (
                                    <div key={data.src + index} style={{ margin: "0 10px" }}>
                                        <div style={{ textAlign: "center", padding: 5 }}>
                                            <span>{distanceInWordsToNow(data.timestamps)} ago</span>
                                        </div>
                                        <img alt="screenshot" style={{ width: "100%" }} src={data.src} />
                                    </div>
                                ))}
                            </div>
                        )}
                    </Hidden>
                </>
            ) : isLoading ? (
                <div className={classes.loaderWrapper}>
                    <CircularProgress size={50} />
                </div>
            ) : (
                <div className={classes.noSnapshot}>No screenshots</div>
            )}
        </div>
    );
};

export default withStyles(styles)(DeviceHistory);
