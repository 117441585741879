import { useState, useEffect, useCallback, useReducer } from "react";
import { IGroupsWithMeta, INameable } from "../../../interfaces/dto";
import { useIdHash } from "../user-id-hash";
import groupsReducer, { IGroupsReducerAction } from "./groups-reducer";
import { Action, IUser } from "../../../interfaces/daos";

export function useGroupsWithMeta(
    apiFn: () => Promise<IGroupsWithMeta>,
    appDispatch: React.Dispatch<Action>,
    groupsDispatch: React.Dispatch<IGroupsReducerAction>,
    hideAllTab: boolean
) {
    const [tmpGroups, setTmpGroups] = useState<IGroupsWithMeta | null>(null);
    //get groups, once
    useEffect(() => {
        let canceled = false;
        (async () => {
            try {
                const data = await apiFn();
                if (!canceled) {
                    setTmpGroups(data);
                }
            } catch (e) {
                appDispatch({
                    type: "SHOW_MESSAGE_ERROR",
                    payload: "Unable to get groups"
                });
            }
        })();

        return () => {
            canceled = true;
        };
        // apiFn and appDispatch actually don't change, but lint plugin doesn't know that, so it safe
    }, [apiFn, appDispatch]);

    // refresh our state, once hideAllTab changes
    useEffect(() => {
        if (tmpGroups) {
            setTmpGroups(null);
            groupsDispatch({
                type: "SET_GROUPS",
                payload: tmpGroups,
                hideAllTab
            });
        }
    }, [tmpGroups, hideAllTab, groupsDispatch]);
}

export function useGroupsState(groupName: string, loggedInUser: IUser) {
    const userIdHash = useIdHash(loggedInUser._id);
    const currentOrganization = loggedInUser.orgs.find((f: any) => f.default)._id;
    const setStorageGroup = useCallback(
        (id: string) => {
            if (userIdHash && currentOrganization) {
                localStorage.setItem(`${groupName}-${userIdHash}-${currentOrganization}`, id);
            }
            // setSavedGroupId(id);
        },
        [currentOrganization, userIdHash, groupName]
    );
    const [groupsState, groupsDispatch] = useReducer(groupsReducer, { groupsWithMeta: null }, () => {
        const selectedGroupId = localStorage.getItem(`${groupName}-${userIdHash}-${currentOrganization}`) || undefined;
        return {
            groupsWithMeta: null,
            selectedGroupId
        };
    });
    const { selectedGroupId } = groupsState;
    const hideAllTab = !!loggedInUser.options.hideAllTab;

    //update group id in localstorage
    useEffect(() => {
        //we don't have circ loop, as react will handle this.
        if (selectedGroupId) {
            setStorageGroup(selectedGroupId);
        }
    }, [selectedGroupId, setStorageGroup]);

    useEffect(() => {
        groupsDispatch({
            type: "REFRESH_SELECTED_GROUP_ID",
            hideAllTab
        });
    }, [hideAllTab]);

    return { groupsState, groupsDispatch };
}

export function useMutateGroup(hideAllTab: boolean, groupsDispatch: React.Dispatch<IGroupsReducerAction>) {
    // we keep this temp states, to not being trapped by stale closures, in create, update delete group calls
    // as this calls are dependent from hideAllTab property, which can be changed during the mutation process.
    const [createdGroup, setCreatedGroup] = useState<INameable | null>(null);
    const [updatedGroup, setUpdatedGroup] = useState<INameable | null>(null);
    const [deletedGroupId, setDeletedGroupId] = useState<string | null>(null);
    useEffect(() => {
        if (createdGroup) {
            setCreatedGroup(null);
            groupsDispatch({
                type: "CREATE_GROUP",
                payload: createdGroup,
                hideAllTab
            });
        }
    }, [hideAllTab, createdGroup, groupsDispatch]);
    useEffect(() => {
        if (updatedGroup) {
            setUpdatedGroup(null);
            groupsDispatch({
                type: "UPDATE_GROUP",
                payload: updatedGroup,
                hideAllTab
            });
        }
    }, [hideAllTab, updatedGroup, groupsDispatch]);
    useEffect(() => {
        if (deletedGroupId) {
            setDeletedGroupId(null);
            groupsDispatch({
                type: "DELETE_GROUP",
                payload: deletedGroupId,
                hideAllTab
            });
        }
    }, [hideAllTab, deletedGroupId, groupsDispatch]);
    return {
        setCreatedGroup,
        setUpdatedGroup,
        setDeletedGroupId
    };
}
