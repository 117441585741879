import { BASE_DOMAIN } from "../constants/app";
import { makeGet, makePost } from "./main-api";

const API_URL = {
    GET_ORG: () => `${BASE_DOMAIN}/v1/organizations`,
    ADD_ORG: () => `${BASE_DOMAIN}/v1/admin/organization`
};

export function getOrganizations() {
    return makeGet(API_URL.GET_ORG());
}
export function createOrganization(data: { name: string; description: string }) {
    return makePost(API_URL.ADD_ORG(), data);
}
