import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Toolbar from "@material-ui/core/Toolbar";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import React, { useState } from "react";
import { INameable } from "../../../interfaces/dto";

const styles = () =>
    createStyles({
        tabs: {
            flex: 1
        },
        toolbar: {
            width: "100vw",
            boxSizing: "border-box"
        },
        dropDownMenuItem: {
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            maxWidth: 264
        }
    });

const AppToolbar = ({
    classes,
    tabs,
    activeTab,
    tabChangeHandler,
    menuItems,
    selectedGridToolbarElements
}: {
    classes: Partial<ClassNameMap<string>>;
    tabs: INameable[];
    activeTab: number | null;
    tabChangeHandler: (e: React.ChangeEvent<{}>, index: number, value: INameable) => void;
    menuItems: { name: string; handler: any }[];
    selectedGridToolbarElements?: React.ReactNode;
}) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const renderTab = (item: INameable) => {
        const { name } = item;
        return <Tab label={name} key={name} />;
    };

    const handleMenuOnAction = (handler: any) => () => {
        setAnchorEl(null);
        handler();
    };
    const renderMenuItem = (item: { name: string; handler: any }) => {
        const { name, handler } = item;
        return (
            <MenuItem key={name} onClick={handleMenuOnAction(handler)}>
                <div className={classes.dropDownMenuItem}>{name}</div>
            </MenuItem>
        );
    };
    const handleMenuClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const onTabChange = (event: React.ChangeEvent<{}>, index: number) => {
        if (tabChangeHandler) {
            tabChangeHandler(event, index, tabs[index]);
        }
    };

    return (
        <Toolbar className={classes.toolbar}>
            <Tabs
                className={classes.tabs}
                value={activeTab}
                onChange={onTabChange}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
            >
                {tabs.map(item => renderTab(item))}
            </Tabs>
            {selectedGridToolbarElements}
            <IconButton
                aria-label="More"
                aria-owns={anchorEl ? "options-menu" : undefined}
                aria-haspopup="true"
                onClick={handleMenuClick}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu id="options-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
                {menuItems.map(item => renderMenuItem(item))}
            </Menu>
        </Toolbar>
    );
};

export default withStyles(styles)(AppToolbar);
