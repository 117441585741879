import React, { useContext } from "react";
import { LoaderContext } from "../../context/main-context";
import "./helper.css";
const BrandIcon = () => {
    const isAppLoading = useContext(LoaderContext);
    return (
        <svg height="45" width="50">
            <g transform="scale(0.1 0.1)" fill="white">
                <g>
                    <path d="m460.42426,364.5665l-420.8485,0c-20.71176,0 -37.57576,-16.84897 -37.57576,-37.57576l0,-285.56074c0,-20.72679 16.864,-37.57576 37.57576,-37.57576l420.8485,0c20.71176,0 37.57576,16.84897 37.57576,37.57576l0,285.56074c0,20.72679 -16.864,37.57576 -37.57576,37.57576zm-420.8485,-345.68195c-12.43006,0 -22.54546,10.11539 -22.54546,22.54546l0,285.56074c0,12.43006 10.11539,22.54546 22.54546,22.54546l420.8485,0c12.43006,0 22.54546,-10.11539 22.54546,-22.54546l0,-285.56074c0,-12.43006 -10.11539,-22.54546 -22.54546,-22.54546l-420.8485,0z" />
                    <path d="m345.81819,436.42638l-191.63637,0c-4.14836,0 -7.51515,-3.36679 -7.51515,-7.51515s3.36679,-7.51515 7.51515,-7.51515l191.63637,0c4.14836,0 7.51515,3.36679 7.51515,7.51515s-3.36679,7.51515 -7.51515,7.51515z" />
                    <path d="m250.00001,436.42638c-4.14836,0 -7.51515,-3.36679 -7.51515,-7.51515l0,-71.85988c0,-4.14836 3.36679,-7.51515 7.51515,-7.51515s7.51515,3.36679 7.51515,7.51515l0,71.85988c0,4.16339 -3.36679,7.51515 -7.51515,7.51515z" />
                </g>
                <g id={isAppLoading ? "ani" : "noRot"} stroke="null">
                    <path
                        d="m341.15518,54.74645l-19.27075,20.81671c41.12899,26.96784 65.02827,76.37774 56.90616,128.02018c-5.35247,34.03907 -23.64707,63.95871 -51.50232,84.24297c-24.31496,17.70611 -53.38455,26.22522 -83.02863,24.52981l5.09092,-5.34313c-0.09808,-0.00467 -0.2055,0.00934 -0.30359,0.00467l19.28476,-20.23289l-17.80886,-16.99618l-38.24258,40.14817l-0.00934,-0.01401l-16.5011,17.32779l17.79952,16.98217l0.00934,-0.00467l40.13416,38.24725l16.51512,-17.32779l-25.99169,-24.76333c35.57102,1.6814 70.36673,-8.65456 99.55308,-29.90563c33.91297,-24.6886 56.17755,-61.10967 62.69766,-102.54692c9.60735,-61.19841 -17.68743,-119.83268 -65.33186,-153.18518l0,0.00001z"
                        stroke="null"
                    />
                    <path
                        d="m124.99678,163.58462c5.35247,-34.03907 23.64707,-63.95871 51.50232,-84.24297c24.31496,-17.70611 53.38455,-26.22522 83.02863,-24.52981l-5.09092,5.34313c0.09808,0.00467 0.2055,-0.00934 0.30359,-0.00467l-19.28476,20.23289l17.80886,16.99618l38.24258,-40.14817l0.00934,0.01401l16.5011,-17.32779l-17.79952,-16.98217l-0.00934,0.00467l-40.13416,-38.24725l-16.51512,17.32779l25.99169,24.76333c-35.57102,-1.6814 -70.36673,8.65456 -99.55308,29.90563c-33.91297,24.6886 -56.17755,61.10967 -62.69766,102.54692c-9.62136,61.20308 17.67809,119.83735 65.31785,153.18985l19.27075,-20.81671c-41.11498,-26.97718 -65.01426,-76.38241 -56.89215,-128.02485l0,-0.00001z"
                        stroke="null"
                    />
                </g>
            </g>
        </svg>
    );
};

export default BrandIcon;
