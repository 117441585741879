import Button from "@material-ui/core/Button";
import createStyles from "@material-ui/core/styles/createStyles";
import withStyles, { ClassNameMap } from "@material-ui/core/styles/withStyles";
import CloudUpload from "@material-ui/icons/CloudUpload";
import React from "react";
import { GREEN_COLOR } from "../../constants/style-constants";

const styles = createStyles({
    mediaActions: {
        display: "flex"
    },
    addMediaActionButton: {
        margin: 5,
        backgroundColor: GREEN_COLOR,
        color: "white"
    }
});

const MediaActions = ({
    classes,
    handleUpload
}: {
    classes: Partial<ClassNameMap<string>>;
    handleUpload: () => void;
}) => {
    return (
        <div className={classes.mediaActions}>
            <Button variant="contained" className={classes.addMediaActionButton} onClick={handleUpload}>
                <CloudUpload style={{ marginRight: 6 }} />
                Upload
            </Button>
        </div>
    );
};

export default withStyles(styles)(MediaActions);
