import React, { useContext, useEffect, useState } from "react";
import { Hidden, WithStyles } from "@material-ui/core";
import createStyles from "@material-ui/core/styles/createStyles";
import withStyles from "@material-ui/core/styles/withStyles";
import { addDevicesGroup, deleteDevicesGroup, getDevicesGroups, updateDevicesGroup } from "../../api/device-group";
import { AppDispatch, UserContext } from "../../context/main-context";
import useDeviceGroup from "../hooks/device-groups";
import AlertDialog from "../shared/alert-delete-dialog";
import GroupDialog from "../shared/group/Dialog";
import AppToolbar from "../shared/toolbar";
import DevicesCards from "./card/cards";
import DevicesGrid from "./grid/devices-grid";

const styles = createStyles({
    devices: {
        display: "flex",
        flexDirection: "column",
        height: "calc(100vh - 176px)"
    },
    devicesMobile: {
        display: "flex",
        flexDirection: "column",
        height: "calc(100vh - 128px)"
    },
    deviceGrid: {
        flex: 1,
        overflow: "hidden",
        padding: 20
    },
    deviceCards: {
        overflowY: "auto",
        overflowScrolling: "touch",
        WebkitOverflowScrolling: "touch"
    }
});

const Devices = (props: WithStyles<typeof styles>) => {
    const { classes } = props;
    const { group: storageGroup, setStorageGroup } = useDeviceGroup();
    const dispatch = useContext(AppDispatch)!;
    const loggedInUser = useContext(UserContext)!;
    const [firstTab] = useState<{ name: string; _id: string }>({ name: "All", _id: "-1" });
    const [tabs, setTabs] = useState<{ name: string; _id: string }[]>([]);
    const [activeTab, setActiveTab] = useState<number>(0);
    const [activeGroup, setActiveGroup] = useState<{ name: string; _id: string } | null>(null);
    // const [groups, setGroups] = useState<{ name: string; _id: string }[]>([]);
    const [groupMode, setGroupMode] = useState<"add" | "edit" | "delete" | null>(null);
    const [deletedGroup, setDeletedGroup] = useState<{ name: string; _id: string } | null>(null);
    const [groupForUpdate, setGroupForUpdate] = useState<{ name: string; _id: string } | null>(null);
    const [groupNameForAdd, setGroupNameForAdd] = useState<string | null>(null);

    const hideAllTab = loggedInUser.options.hideAllTab;

    const handleCloseGroupDialog = () => {
        setGroupMode(null);
    };

    useEffect(() => {
        let canceled = false;
        (async () => {
            let data = [];
            try {
                data = await getDevicesGroups();
                data = data.data || [];
            } catch (e) {
                data = [];
            }
            if (!canceled) {
                if (hideAllTab) {
                    setTabs([...data]);
                } else {
                    setTabs([firstTab, ...data]);
                }
            }
        })();
        return () => {
            canceled = true;
        };
    }, [firstTab, hideAllTab]);

    useEffect(() => {
        if (storageGroup) {
            const currentGroup = tabs.find(g => g._id === storageGroup);
            if (currentGroup) {
                setActiveTab(tabs.indexOf(currentGroup));
            }
        }
    }, [storageGroup, tabs]);

    useEffect(() => {
        setActiveGroup(tabs[activeTab]);
    }, [activeTab, tabs]);

    useEffect(() => {
        let canceled = false;
        if (deletedGroup && deletedGroup._id !== "-1") {
            deleteDevicesGroup(deletedGroup._id)
                .then(() => {
                    if (!canceled) {
                        setActiveTab(0);
                        setStorageGroup("-1");
                        setTabs(oldTabs => {
                            const group = oldTabs.find(gr => gr._id === deletedGroup._id);
                            if (group) {
                                oldTabs.splice(oldTabs.indexOf(group), 1);
                                return [...oldTabs];
                            }
                            return oldTabs;
                        });
                        setDeletedGroup(null);
                        dispatch({
                            type: "SHOW_MESSAGE_SUCCESS",
                            payload: "Group " + deletedGroup.name + " deleted successfully"
                        });
                    }
                })
                .catch(e => {
                    dispatch({ type: "SHOW_MESSAGE_ERROR", payload: e });
                });
        }
        return () => {
            canceled = true;
        };
    }, [dispatch, deletedGroup, setStorageGroup]);

    useEffect(() => {
        let canceled = false;
        if (groupForUpdate && groupForUpdate._id !== "-1") {
            const { _id, name } = groupForUpdate;
            updateDevicesGroup(_id, name)
                .then(() => {
                    if (!canceled) {
                        setTabs(oldTabs => {
                            const group = oldTabs.find(gr => gr._id === _id);
                            if (group) {
                                oldTabs.splice(oldTabs.indexOf(group), 1, { ...group, name });
                                return [...oldTabs];
                            }
                            return oldTabs;
                        });
                        setGroupForUpdate(null);
                        dispatch({ type: "SHOW_MESSAGE_SUCCESS", payload: "Group " + name + " updated successfully" });
                    }
                })
                .catch(e => {
                    dispatch({ type: "SHOW_MESSAGE_ERROR", payload: e });
                });
        }
        return () => {
            canceled = true;
        };
    }, [dispatch, groupForUpdate]);

    useEffect(() => {
        let canceled = false;
        if (groupNameForAdd) {
            (async () => {
                try {
                    await addDevicesGroup(groupNameForAdd);
                    getDevicesGroups()
                        .then(data => {
                            if (!canceled) {
                                setTabs([firstTab, ...data.data]);
                                setGroupNameForAdd(null);
                                dispatch({
                                    type: "SHOW_MESSAGE_SUCCESS",
                                    payload: "Group " + groupNameForAdd + " added successfully"
                                });
                            }
                        })
                        .catch(() => {});
                } catch (e) {
                    dispatch({ type: "SHOW_MESSAGE_ERROR", payload: e });
                }
            })();
        }
        return () => {
            canceled = true;
        };
    }, [dispatch, firstTab, groupNameForAdd]);

    const handleAddGroup = () => {
        setGroupMode("add");
    };

    const handleEditGroup = () => {
        setGroupMode("edit");
    };

    const handleDeleteGroup = () => {
        if (activeGroup) {
            setGroupMode("delete");
        }
    };

    const onDeleteGroup = () => {
        if (activeGroup) {
            setDeletedGroup(activeGroup);
        }
        handleCloseGroupDialog();
    };

    const addOrUpdate = (name: string, id?: string) => {
        if (id) {
            setGroupForUpdate({ name, _id: id });
        } else {
            setGroupNameForAdd(name);
        }
        handleCloseGroupDialog();
    };

    const tabChangeHandler = (event: React.ChangeEvent<{}>, value: number) => {
        setActiveTab(value);
        setStorageGroup(tabs[value]._id);
    };

    const menuItems = [
        {
            name: "Add Group",
            handler: handleAddGroup,
            hiddenIndex: -1000
        },
        {
            name: "Edit Group",
            handler: handleEditGroup,
            hiddenIndex: 0
        },
        {
            name: "Delete Group",
            handler: handleDeleteGroup,
            hiddenIndex: 0
        }
    ].filter((f: { name: string; handler: any; hiddenIndex: number }, index: number) => {
        return (
            ((loggedInUser.defaultGroup === (activeGroup && activeGroup._id) && index !== 2) ||
                loggedInUser.defaultGroup !== (activeGroup && activeGroup._id)) &&
            (f.hiddenIndex !== activeTab || hideAllTab)
        );
    });

    return (
        <div>
            <AppToolbar activeTab={activeTab} tabChangeHandler={tabChangeHandler} tabs={tabs} menuItems={menuItems} />
            <Hidden smDown>
                <div className={classes.devices}>
                    <div className={classes.deviceGrid}>
                        <DevicesGrid
                            groups={hideAllTab ? tabs : tabs.slice(1)}
                            activeGroup={activeGroup && activeGroup._id !== "-1" ? activeGroup._id : null}
                        />
                    </div>
                </div>
            </Hidden>
            <Hidden mdUp>
                <div className={classes.devicesMobile}>
                    <div className={classes.deviceCards}>
                        <DevicesCards
                            groups={hideAllTab ? tabs : tabs.slice(1)}
                            activeGroup={activeGroup && activeGroup._id !== "-1" ? activeGroup._id : null}
                        />
                    </div>
                </div>
            </Hidden>
            {activeGroup && (groupMode === "edit" || groupMode === "add") && (
                <GroupDialog
                    add={addOrUpdate}
                    mode={groupMode}
                    handleClose={handleCloseGroupDialog}
                    group={activeGroup}
                />
            )}
            {groupMode === "delete" && (
                <AlertDialog
                    del={onDeleteGroup}
                    message="Are you sure you want to delete group?"
                    handleCloseAlert={handleCloseGroupDialog}
                />
            )}
        </div>
    );
};

export default withStyles(styles)(Devices);
