import createStyles from "@material-ui/core/styles/createStyles";
import withStyles from "@material-ui/core/styles/withStyles";
import React, { useEffect, useState } from "react";
import { Map, Marker, Popup, TileLayer } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-markercluster";
import * as d3 from "d3";
import { DivIcon, Point, MarkerCluster } from "leaflet";
import useWindowWidth from "../hooks/window-size";

import "./dashboard.css";

const styles = createStyles({});
const rmax = 30; //Maximum radius for cluster pies

function defineMarkerIcon(device: any) {
    const classes = "marker category-" + (device.status && device.status.toLowerCase()) + " icon-monitor";
    const myIcon = new DivIcon({
        className: classes
    });
    return myIcon;
}

function defineClusterIcon(cluster: MarkerCluster, devices: any[]) {
    const children = cluster.getAllChildMarkers();
    const n = children.length; //Get number of markers in cluster
    const strokeWidth = 1; //Set clusterpie stroke width
    const r = rmax - 2 * strokeWidth - (n < 10 ? 12 : n < 100 ? 8 : n < 1000 ? 4 : 0); //Calculate clusterpie radius...
    const iconDim = (r + strokeWidth) * 2; //...and divIcon dimensions (leaflet really want to know the size)
    const statusToCountMap: any = {};
    children.forEach((child: any) => {
        const deviceId = child.options.deviceId;
        const device = devices.find(d => d._id === deviceId);
        const status = device && device.status;
        if (!statusToCountMap.hasOwnProperty(status)) {
            statusToCountMap[status] = 0;
        }
        statusToCountMap[status]++;
    });

    const data = [];
    for (const status in statusToCountMap) {
        data.push({ key: status, value: statusToCountMap[status] });
    }
    //bake some svg markup
    const html = bakeThePie({
            data: data,
            valueFunc: function(d: any) {
                return d.value;
            },
            strokeWidth: 1,
            outerRadius: r,
            innerRadius: 0.000001,
            pieClass: "cluster-pie",
            pieLabel: n,
            pieLabelClass: "marker-cluster-pie-label",
            pathClassFunc: function(d: any) {
                return "category-" + (d.data.key && d.data.key.toLowerCase());
            },
            pathTitleFunc: function(d: any) {
                return "" + d.data.value + " " + d.data.key + " devices";
            }
        }),
        //Create a new divIcon and assign the svg markup to the html property
        myIcon = new DivIcon({
            html: html,
            className: "marker-cluster",
            iconSize: new Point(iconDim, iconDim)
        });
    return myIcon;
}

/*function that generates a svg markup for the pie chart*/
function bakeThePie(options: any) {
    /*data and valueFunc are required*/
    if (!options.data || !options.valueFunc) {
        return "";
    }
    const data = options.data,
        valueFunc = options.valueFunc,
        r = options.outerRadius ? options.outerRadius : 28, //Default outer radius = 28px
        rInner = options.innerRadius ? options.innerRadius : r - 10, //Default inner radius = r-10
        strokeWidth = options.strokeWidth ? options.strokeWidth : 1, //Default stroke is 1
        pathClassFunc = options.pathClassFunc
            ? options.pathClassFunc
            : function() {
                  return "";
              }, //Class for each path
        pathTitleFunc = options.pathTitleFunc
            ? options.pathTitleFunc
            : function() {
                  return "";
              }, //Title for each path
        pieClass = options.pieClass ? options.pieClass : "marker-cluster-pie", //Class for the whole pie
        pieLabel = options.pieLabel ? options.pieLabel : d3.sum(data, valueFunc), //Label for the whole pie
        pieLabelClass = options.pieLabelClass ? options.pieLabelClass : "marker-cluster-pie-label", //Class for the pie label
        origo = r + strokeWidth, //Center coordinate
        w = origo * 2, //width and height of the svg element
        h = w,
        donut = d3.layout.pie(),
        arc = d3.svg
            .arc()
            .innerRadius(rInner)
            .outerRadius(r);

    //Create an svg element
    const svg = document.createElementNS(d3.ns.prefix.svg, "svg");
    //Create the pie chart
    const vis = d3
        .select(svg)
        .data([data])
        .attr("class", pieClass)
        .attr("width", w)
        .attr("height", h);

    const arcs = vis
        .selectAll("g.arc")
        .data(donut.value(valueFunc))
        .enter()
        .append("svg:g")
        .attr("class", "arc")
        .attr("transform", "translate(" + origo + "," + origo + ")");

    arcs.append("svg:path")
        .attr("class", pathClassFunc)
        .attr("stroke-width", strokeWidth)
        .attr("d", arc as any)
        .append("svg:title")
        .text(pathTitleFunc);

    vis.append("text")
        .attr("x", origo)
        .attr("y", origo)
        .attr("class", pieLabelClass)
        .attr("text-anchor", "middle")
        //.attr('dominant-baseline', 'central')
        /*IE doesn't seem to support dominant-baseline, but setting dy to .3em does the trick*/
        .attr("dy", ".3em")
        .text(pieLabel);
    //Return the svg-markup rather than the actual element
    return serializeXmlNode(svg);
}

/*Helper function*/
function serializeXmlNode(xmlNode: any) {
    if (typeof (window as any).XMLSerializer != "undefined") {
        return new (window as any).XMLSerializer().serializeToString(xmlNode);
    } else if (typeof xmlNode.xml != "undefined") {
        return xmlNode.xml;
    }
    return "";
}

const DashboardMap = ({ devices = [] }: { devices: any }) => {
    const { windowWidth } = useWindowWidth();
    const position: any = [40.1775, 44.51265];
    const [mapProps, setMapProps] = useState({
        center: position,
        zoom: 15
    });
    const [mapPropsInitiated, setMapPropsInitiated] = useState(false);

    useEffect(() => {
        if (devices.length && !mapPropsInitiated) {
            const res: any =
                windowWidth < 960
                    ? {
                          dragging: false
                      }
                    : {};

            setMapPropsInitiated(true);
            const latitudes: any = devices.map((device: { latitude: any }) => {
                return device.latitude || position[0];
            });
            const longitudes: any = devices.map((device: { longitude: any }) => {
                return device.longitude || position[1];
            });
            res.bounds = [
                [Math.min(...latitudes), Math.min(...longitudes)],
                [Math.max(...latitudes), Math.max(...longitudes)]
            ];
            res.boundsOptions = { maxZoom: 16 };
            setMapProps(res);
        }
    }, [devices, mapPropsInitiated, position, windowWidth]);

    return (
        <Map {...mapProps}>
            <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            />
            <MarkerClusterGroup
                key={JSON.stringify(devices)}
                iconCreateFunction={(cluster: MarkerCluster) => defineClusterIcon(cluster, devices)}
                maxClusterRadius={2 * rmax}
            >
                {devices.map(
                    (device: { _id: string; latitude: any; longitude: any; name: any; status: any }, index: any) => (
                        <Marker
                            icon={defineMarkerIcon(device)}
                            key={index}
                            position={[device.latitude || position[0], device.longitude || position[1]]}
                            deviceId={device._id}
                        >
                            <Popup>
                                <>
                                    {device.name}
                                    <br />
                                    Status:{device.status}
                                </>
                            </Popup>
                        </Marker>
                    )
                )}
            </MarkerClusterGroup>
        </Map>
    );
};

export default withStyles(styles)(DashboardMap);
