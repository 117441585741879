import { WithStyles } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import createStyles from "@material-ui/core/styles/createStyles";
import withStyles from "@material-ui/core/styles/withStyles";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import CopyIcon from "@material-ui/icons/FileCopy";
import ImageIcon from "@material-ui/icons/Image";
import LinkIcon from "@material-ui/icons/Link";
import VideoIcon from "@material-ui/icons/LocalMovies";
import AssignToGroupIcon from "@material-ui/icons/SwapHoriz";
import classNames from "classnames";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { TableCellProps } from "react-virtualized";
import { changePlayListsGroup, copyPlayList, deletePlaylists, getPlayList } from "../../api/play-list";
import { BLUE_COLOR, GREEN_COLOR, RED_COLOR } from "../../constants/style-constants";
import { AppDispatch } from "../../context/main-context";
import { determineUpdatedAt } from "../../utils/date-parser-util";
import AlertDialog from "../shared/alert-delete-dialog";
import Grid, { IGridColumn, IGridDataItem } from "../shared/grid/grid";
import { IGroupsWithMeta } from "../../interfaces/dto";

const styles = createStyles({
    gridDefaultContextRenderer: {
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis"
    },
    actionIcon: {
        marginRight: 10
    },
    editButton: {
        color: BLUE_COLOR
    },
    copyButton: {
        color: GREEN_COLOR
    },
    deleteButton: {
        color: RED_COLOR
    },
    mediaIcon: {
        color: BLUE_COLOR
    }
});

const PlaylistsGrid = (
    props: WithStyles<typeof styles> & {
        groupsWithMeta: IGroupsWithMeta;
        selectedGroupId: string | null;
        isSmallViewport?: boolean;
    }
) => {
    const { classes, groupsWithMeta, selectedGroupId, isSmallViewport } = props;
    const { groups = [] } = groupsWithMeta;
    const history = useHistory();
    const dispatch = useContext(AppDispatch)!;
    const [page, setPage] = useState<number>(0);
    const [playLists, setPlayLists] = useState<any[]>([]);
    const [reloadData, setReloadData] = useState<boolean>(false);
    const [rowsPerPage, setRowsPerPage] = useState<number>(10);
    const [count, setCount] = useState<number>(30);
    const [searchValue, setSearchValue] = useState<string>("");
    const [isDataLoading, setIsDataLoading] = useState<boolean>(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
    const [showDeletePlaylistsDialog, setShowDeletePlaylistsDialog] = useState(false);
    const [idPlaylistForDelete, setIdPlaylistForDelete] = useState<string>("");
    const [assignToGroupAnchorEl, setAssignToGroupAnchorEl] = useState<null | HTMLElement>(null);
    const [selectedRows, setSelectedRows] = useState<IGridDataItem[]>([]);
    useEffect(() => {
        setIsDataLoading(true);
        const filters = [];
        if (selectedGroupId && selectedGroupId !== "-1") {
            if (selectedGroupId === "-2") {
                filters.push({ property: "groupId" });
            } else {
                filters.push({ property: "groupId", value: selectedGroupId });
            }
        }
        getPlayList({ searchValue, limit: rowsPerPage, start: page * rowsPerPage, filters })
            .then(data => {
                setPlayLists(data.data.data);
                setCount(data.data.totalCount);
                setSelectedRows([]);
                setIsDataLoading(false);
            })
            .catch(() => {
                setSelectedRows([]);
                setIsDataLoading(false);
            });
    }, [reloadData, searchValue, page, rowsPerPage, selectedGroupId]);

    const searchValueChange = (value: string) => {
        setPage(0);
        setSearchValue(value);
    };
    const onChangePage = (e: React.MouseEvent<HTMLButtonElement> | null, pageNum: number) => {
        setPage(pageNum);
    };
    const onChangeRowsPerPage = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setPage(0);
        setRowsPerPage(parseInt(event.target.value));
    };
    const handelEdit = (id: string) => () => {
        history.replace(`/playlist/${id}`);
    };
    const handelCopy = (id: string) => () => {
        copyPlayList(id)
            .then(() => setReloadData(!reloadData))
            .then(() => {
                dispatch({
                    type: "SHOW_MESSAGE_SUCCESS",
                    payload: "Playlist copied successfully"
                });
            })
            .catch(e => {
                dispatch({ type: "SHOW_MESSAGE_ERROR", payload: e });
            });
    };
    const handelDelete = (id: string) => () => {
        setIdPlaylistForDelete(id);
        setShowDeleteDialog(true);
    };
    const deletePlayListById = (id: string) => {
        setShowDeleteDialog(false);
        deletePlaylists([id])
            .then(result => {
                if (!result.data[0].success) {
                    dispatch({ type: "SHOW_MESSAGE_ERROR", payload: "Can't delete media." });
                } else {
                    dispatch({
                        type: "SHOW_MESSAGE_SUCCESS",
                        payload: "Playlist deleted successfully"
                    });
                }
                setReloadData(!reloadData);
            })
            .catch(e => {
                dispatch({ type: "SHOW_MESSAGE_ERROR", payload: e });
            });
    };

    const onDeletePlayLists = () => {
        setShowDeletePlaylistsDialog(false);
        const selectedPlaylistsIds = selectedRows.map(row => row._id);
        deletePlaylists(selectedPlaylistsIds)
            .then(result => {
                const errors: any[] = [];
                result.data.forEach((d: any) => {
                    if (!d.success) {
                        const matchedPlaylist = playLists.find(pl => pl._id === d.id);
                        if (matchedPlaylist) {
                            errors.push(matchedPlaylist.name);
                        }
                    }
                });
                if (errors.length) {
                    dispatch({
                        type: "SHOW_MESSAGE_ERROR",
                        payload: "Can't delete " + errors.join(", ") + " playlists."
                    });
                } else {
                    dispatch({
                        type: "SHOW_MESSAGE_SUCCESS",
                        payload: "Playlists deleted successfully"
                    });
                }
                setReloadData(!reloadData);
            })
            .catch((e: any) => {
                dispatch({ type: "SHOW_MESSAGE_ERROR", payload: e });
            });
    };

    const handleDeletePlaylistsIconClick = (event: React.MouseEvent<HTMLElement>) => {
        setShowDeletePlaylistsDialog(true);
    };

    const onCloseDeleteDialog = () => {
        setShowDeleteDialog(false);
        setShowDeletePlaylistsDialog(false);
    };
    const gridActionsContextRenderer: (cellRendererProps: TableCellProps) => React.ReactNode = p => {
        return (
            <div className={classes.gridDefaultContextRenderer}>
                <Tooltip title="Edit">
                    <IconButton
                        color="primary"
                        className={classes.editButton}
                        aria-label="Edit"
                        onClick={handelEdit(p.rowData._id)}
                    >
                        <EditIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Copy">
                    <IconButton
                        color="primary"
                        onClick={handelCopy(p.rowData._id)}
                        className={classes.copyButton}
                        aria-label="Copy"
                    >
                        <CopyIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                    <IconButton
                        color="primary"
                        onClick={handelDelete(p.rowData._id)}
                        className={classes.deleteButton}
                        aria-label="Delete"
                    >
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            </div>
        );
    };

    const gridMobileActionsContextRenderer: (cellRendererProps: TableCellProps) => React.ReactNode = p => {
        return (
            <div className={classes.gridDefaultContextRenderer}>
                <Tooltip title="Edit">
                    <EditIcon
                        color="primary"
                        className={classNames(classes.actionIcon, {
                            [classes.editButton as string]: true
                        })}
                        aria-label="Edit"
                        onClick={handelEdit(p.rowData._id)}
                    />
                </Tooltip>
                <Tooltip title="Copy">
                    <CopyIcon
                        color="primary"
                        onClick={handelCopy(p.rowData._id)}
                        className={classNames(classes.actionIcon, {
                            [classes.copyButton as string]: true
                        })}
                        aria-label="Copy"
                    />
                </Tooltip>
                <Tooltip title="Delete">
                    <DeleteIcon
                        color="primary"
                        onClick={handelDelete(p.rowData._id)}
                        className={classNames(classes.actionIcon, {
                            [classes.deleteButton as string]: true
                        })}
                        aria-label="Delete"
                    />
                </Tooltip>
            </div>
        );
    };

    const gridDefaultContextRenderer: (cellRendererProps: TableCellProps) => React.ReactNode = p => {
        const data = <div className={classes.gridDefaultContextRenderer}>{p.cellData}</div>;
        if (window.innerWidth < 768) {
            return (
                <Tooltip title={p.cellData || ""} placement="bottom-end">
                    {data}
                </Tooltip>
            );
        }
        return data;
    };

    const gridLastChangedContextRenderer: (cellRendererProps: TableCellProps) => React.ReactNode = properties => {
        let content = null;
        if (properties.cellData) {
            content = determineUpdatedAt(properties.cellData);
        }
        content = content || null;
        const data = <div className={classes.gridDefaultContextRenderer}>{content}</div>;
        if (window.innerWidth < 768) {
            return (
                <Tooltip title={properties.cellData || "N/A"} placement="bottom-end">
                    {data}
                </Tooltip>
            );
        }
        return data;
    };

    const gridMediaTypesContextRenderer: (cellRendererProps: TableCellProps) => React.ReactNode = p => {
        return (
            <div className={classes.gridDefaultContextRenderer}>
                {p.cellData.map((mediaType: string) => {
                    switch (mediaType) {
                        case "video":
                            return <VideoIcon key={mediaType} className={classes.mediaIcon} />;
                        case "image":
                            return <ImageIcon key={mediaType} className={classes.mediaIcon} />;
                        case "web":
                            return <LinkIcon key={mediaType} className={classes.mediaIcon} />;
                        default:
                            return "";
                    }
                })}
            </div>
        );
    };
    const columns: IGridColumn[] = [
        {
            dataKey: "name",
            width: 200,
            label: "Name",
            flexGrow: 1,
            flexShrink: 1,
            cellContentRenderer: gridDefaultContextRenderer
        },
        {
            dataKey: "description",
            width: 200,
            label: "Description",
            flexGrow: 1,
            flexShrink: 1,
            cellContentRenderer: gridDefaultContextRenderer
        },
        {
            dataKey: "updatedAt",
            width: 200,
            label: "Last changed",
            flexGrow: 1,
            flexShrink: 1,
            cellContentRenderer: gridLastChangedContextRenderer
        },
        {
            dataKey: "type",
            width: 200,
            label: "Media types",
            flexGrow: 1,
            flexShrink: 1,
            cellContentRenderer: gridMediaTypesContextRenderer
        },
        {
            dataKey: "actions",
            width: 150,
            label: "Actions",
            flexGrow: 1,
            flexShrink: 1,
            cellContentRenderer: gridActionsContextRenderer
        }
    ];

    const mobileColumns: IGridColumn[] = [
        {
            dataKey: "name",
            width: 200,
            label: "Name",
            flexGrow: 1,
            flexShrink: 1,
            cellContentRenderer: gridDefaultContextRenderer
        },
        {
            dataKey: "actions",
            width: 150,
            label: "Actions",
            flexGrow: 1,
            flexShrink: 1,
            cellContentRenderer: gridMobileActionsContextRenderer
        }
    ];

    function handleAssignToGroupIconClick(event: React.MouseEvent<HTMLElement>) {
        setAssignToGroupAnchorEl(event.currentTarget);
    }
    function handleAssignToGroupMenuClose() {
        setAssignToGroupAnchorEl(null);
    }
    function assignToGroup(groupId: string) {
        const selectedPlaylistsIds = selectedRows.map(row => row._id);
        changePlayListsGroup(groupId, selectedPlaylistsIds).then(() => {
            setReloadData(!reloadData);
            setSelectedRows([]);
        });
        handleAssignToGroupMenuClose();
    }
    function getSelectedGridToolbarElements() {
        const selectedGroup = groups.find(gr => gr._id === selectedGroupId);
        const availableGroups = groups.filter(group => group._id !== (selectedGroup && selectedGroup._id));
        const selectedGridToolbarElements: React.ReactNode = (
            <>
                {!!selectedRows.length && (
                    <Tooltip title="Delete playlists">
                        <IconButton aria-label="Delete playlists" onClick={handleDeletePlaylistsIconClick}>
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                )}
                {!!(availableGroups.length && selectedRows.length) && (
                    <>
                        <Tooltip title="Assign to group">
                            <IconButton aria-label="assign to group" onClick={handleAssignToGroupIconClick}>
                                <AssignToGroupIcon />
                            </IconButton>
                        </Tooltip>
                        <Menu
                            id="groups-to-assign"
                            anchorEl={assignToGroupAnchorEl}
                            keepMounted
                            open={!!assignToGroupAnchorEl}
                            onClose={handleAssignToGroupMenuClose}
                        >
                            {availableGroups.map(group => (
                                <MenuItem key={group._id} onClick={() => assignToGroup(group._id)}>
                                    {group.name}
                                </MenuItem>
                            ))}
                        </Menu>
                    </>
                )}
            </>
        );
        return selectedGridToolbarElements;
    }
    const rowSelectionChange = (selectedRowsFromGrid: IGridDataItem[]) => {
        setSelectedRows(selectedRowsFromGrid);
    };
    return (
        <div style={{ height: "calc(100vh - 200px)" }}>
            <Grid
                enablePagination
                page={page}
                onChangePage={onChangePage}
                onChangeRowsPerPage={onChangeRowsPerPage}
                rowsPerPage={rowsPerPage}
                searchValueChange={searchValueChange}
                rowsPerPageOptions={[10, 25, 50]}
                showCheckBoxes
                data={playLists.map((d: any) => ({ ...d, id: d._id }))}
                columns={isSmallViewport ? mobileColumns : columns}
                allDataCount={count}
                gridToolbarTitle="Playlists"
                isDataLoading={isDataLoading}
                selectedGridToolbarElements={getSelectedGridToolbarElements()}
                rowSelectionChange={rowSelectionChange}
                selected={selectedRows}
            />
            {showDeleteDialog && (
                <AlertDialog
                    del={() => {
                        deletePlayListById(idPlaylistForDelete);
                    }}
                    handleCloseAlert={onCloseDeleteDialog}
                    message={"Do you really want to delete this playlist?"}
                />
            )}
            {showDeletePlaylistsDialog && (
                <AlertDialog
                    del={() => {
                        onDeletePlayLists();
                    }}
                    handleCloseAlert={onCloseDeleteDialog}
                    message={"Do you really want to delete this playlists?"}
                />
            )}
        </div>
    );
};
export default withStyles(styles)(PlaylistsGrid);
