import { BASE_DOMAIN } from "../constants/app";
import { makeGet, makePatch, makePost } from "./main-api";

const API_URL = {
    GET_DEVICES: (query?: string) => `${BASE_DOMAIN}/v1/ms/devices${query ? query : ""}`,
    GET_SCREENSHOTS: (id: string, query?: string) => `${BASE_DOMAIN}/v1/ms/snapshot/${id}${query ? query : ""}`,
    GET_SCREENSHOT: (id: string) => `${BASE_DOMAIN}/v1/asset/screenshot/${id}`,
    GET: (id: string) => `${BASE_DOMAIN}/v1/ms/device/${id}`,
    UPDATE: (id: string) => `${BASE_DOMAIN}/v1/devices/${id}`,
    GET_PATCH_POST_DELETE_CONFIG: (deviceId: string) => `${BASE_DOMAIN}/v1/ms/device/config/${deviceId}`,
    BULK_ASSIGN_TO_DEVICES: () => `${BASE_DOMAIN}/v1/ms/devices`,
    PRESENTATION_ACTION: (deviceId: string, action: "start" | "stop" | "next" | "previous") =>
        `${BASE_DOMAIN}/v1/ms/action/device/${deviceId}/${action}`,
    REMOTE_ACTION: (deviceId: string, action: "monitor_on" | "monitor_off" | "reboot") =>
        `${BASE_DOMAIN}/v1/ms/action/device/${deviceId}/${action}`
};

export const getScreenshots = ({ id = "", start = 0, limit = 20 }: { id: string; start?: number; limit?: number }) => {
    const query = `?start=${start}&limit=${limit}`;
    return makeGet(API_URL.GET_SCREENSHOTS(id, query));
};
export const getScreenshot = (id: string) => {
    return makeGet(API_URL.GET_SCREENSHOT(id), { responseType: "blob" });
};
export const updateDevice = (device: any) => {
    return makePatch(API_URL.UPDATE(device._id), device);
};
export const getDevice = (id: string) => {
    return makeGet(API_URL.GET(id));
};
export const getDevices = ({
    searchValue = "",
    start = 0,
    limit = 1000
}: {
    searchValue?: string;
    start?: number;
    limit?: number;
}) => {
    let query = `?start=${start}&limit=${limit}`;
    if (searchValue) {
        query += `&search=${searchValue}`;
    }
    return makeGet(API_URL.GET_DEVICES(query));
};
export const getDeviceConfig = (id: string) => {
    return makeGet(API_URL.GET_PATCH_POST_DELETE_CONFIG(id));
};
export const updateDeviceConfig = (id: string, config: any) => {
    return makePatch(API_URL.GET_PATCH_POST_DELETE_CONFIG(id), config);
};
export const addDeviceConfig = (id: string, config: any) => {
    return makePost(API_URL.GET_PATCH_POST_DELETE_CONFIG(id), config);
};
export interface IBulkAssignBody {
    devices: string[];
    playListId?: string | undefined;
    scheduleId?: string | undefined;
    groupId?: string | undefined;
}
export function bulkAssignToDevices(body: IBulkAssignBody) {
    return makePatch(API_URL.BULK_ASSIGN_TO_DEVICES(), body);
}
export function updateDevicesSchedule(devices: string[], scheduleId: string) {
    return makePatch(API_URL.BULK_ASSIGN_TO_DEVICES(), { devices, scheduleId });
}
export function presentationAction(deviceId: string, action: "start" | "stop" | "next" | "previous") {
    return makeGet(API_URL.PRESENTATION_ACTION(deviceId, action));
}
export function remoteAction(deviceId: string, action: "monitor_on" | "monitor_off" | "reboot") {
    return makeGet(API_URL.REMOTE_ACTION(deviceId, action));
}
