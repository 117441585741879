export const getRotatedSrc = (src: string, deg: number) => {
    return new Promise((res, rej) => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        const hiddenImg = document.createElement("img");
        hiddenImg.onload = function() {
            if (ctx) {
                ctx.canvas.width = hiddenImg.width;
                ctx.canvas.height = hiddenImg.height;
                ctx.save();
                ctx.drawImage(
                    hiddenImg,
                    canvas.width / 2 - hiddenImg.width / 2,
                    canvas.height / 2 - hiddenImg.height / 2
                );
                ctx.canvas.width = hiddenImg.height;
                ctx.canvas.height = hiddenImg.width;
                ctx.clearRect(0, 0, canvas.width, canvas.height);
                ctx.translate(canvas.width / 2, canvas.height / 2);
                ctx.rotate((deg * Math.PI) / 180);
                ctx.drawImage(hiddenImg, -canvas.height / 2, -canvas.width / 2);
                ctx.restore();
                ctx.save();
                res(canvas.toDataURL("image/png", 1.0));
            }
        };
        hiddenImg.onerror = function(e) {
            rej(e);
        };
        hiddenImg.src = src;
    });
};
