import MaskedInput from "react-text-mask";
import React from "react";
import { createStyles } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";

const styles = createStyles({});

const IpAddressField = (props: any) => {
    const props1 = {
        guide: false,
        mask: (value: any) => {
            let result = [];
            const chunks = value.split(".");

            for (let i = 0; i < 4; ++i) {
                const chunk = (chunks[i] || "").replace(/_/gi, "");

                if (chunk === "") {
                    result.push(/\d/, /\d/, /\d/, ".");
                } else if (+chunk === 0) {
                    result.push(/\d/, ".");
                } else if (chunks.length < 4 || (chunk.length < 3 && chunks[i].indexOf("_") !== -1)) {
                    if ((chunk.length < 2 && +`${chunk}00` > 255) || (chunk.length < 3 && +`${chunk}0` > 255)) {
                        result.push(/\d/, /\d/, ".");
                    } else {
                        result.push(/\d/, /\d/, /\d/, ".");
                    }
                } else {
                    result.push(...new Array(chunk.length).fill(/\d/), ".");
                }
            }

            result = result.slice(0, -1);
            return result;
        },
        pipe: (value: any) => {
            if (value === "." || value.endsWith("..")) return false;

            const parts = value.split(".");

            if (parts.length > 4 || parts.some((part: any) => part === "00" || part < 0 || part > 255)) {
                return false;
            }

            return value;
        }
    };
    const { inputRef, ...other } = props;

    return (
        <MaskedInput
            {...other}
            ref={(ref: any) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            {...props1}
        />
    );
};

export default withStyles(styles)(IpAddressField);
