import { Checkbox, IconButton, ListItemIcon, Menu, MenuItem, Tooltip } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import ImageIcon from "@material-ui/icons/Image";
import LinkIcon from "@material-ui/icons/Link";
import VideoIcon from "@material-ui/icons/LocalMovies";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import TranscodingIcon from "@material-ui/icons/SwitchVideo";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import Masonry from "react-masonry-component";
import { IMasonryItem, IMasonryMenuItem } from "../../../interfaces/daos";
import "./masonry.css";

const masonryOptions = {
    transitionDuration: 0
};
interface IGalleryProps {
    elements: IMasonryItem[];
    draggable?: boolean;
    draggableIdPrefix?: string;
    editable?: boolean;
    onEditMenuClick?: (mansoryItem: IMasonryItem, masonryMenuItem: IMasonryMenuItem) => any;
    onDeleteMenuClick?: (mansoryItem: IMasonryItem, masonryMenuItem: IMasonryMenuItem) => any;
    showCheckboxes?: boolean;
    selectElementHandler?: (id: string) => void;
    openMediaClick?: (mansoryItem: IMasonryItem) => any;
    draggingId?: string | null;
}
const Gallery = (
    props: IGalleryProps = { elements: [], draggable: false, draggableIdPrefix: "", editable: false, draggingId: null }
) => {
    const { showCheckboxes, selectElementHandler, elements } = props;
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [open, setOpen] = useState<null | string>(null);
    const [checkedCheckboxes, setCheckedCheckboxes] = useState<any>({});

    useEffect(() => {
        setCheckedCheckboxes({});
    }, [elements]);
    function handleClick(event: React.MouseEvent<HTMLElement>, id: string) {
        setAnchorEl(event.currentTarget);
        setOpen(id);
    }

    function handleClose() {
        setAnchorEl(null);
        setOpen(null);
    }

    const getTreeDotMenuItems = () => {
        const editMenu: IMasonryMenuItem = {
            text: "Edit",
            icon: <EditIcon />,
            onClick: props.onEditMenuClick
        };
        const deleteMenu: IMasonryMenuItem = {
            text: "Delete",
            icon: <DeleteIcon />,
            onClick: props.onDeleteMenuClick
        };

        return [editMenu, deleteMenu];
    };
    function selectElement(e: React.ChangeEvent<HTMLInputElement>, id: string) {
        const newCheckedCheckboxes = { ...checkedCheckboxes };
        newCheckedCheckboxes[id] = e.target.checked;
        setCheckedCheckboxes(newCheckedCheckboxes);
        if (selectElementHandler && typeof selectElementHandler === "function") {
            selectElementHandler(id);
        }
    }

    function renderItem(elem: IMasonryItem, index: number) {
        const id = elem._id;
        const checked = checkedCheckboxes[id];
        const style: any = {};
        if (checked) {
            style.display = "block";
        }
        const item = (
            <div key={id} className="masonry-item-wrapper">
                {showCheckboxes && (
                    <div className="masonry-item-checkbox-wrapper" style={style}>
                        <Checkbox
                            checked={!!checked}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => selectElement(e, id)}
                            color="primary"
                        />
                    </div>
                )}
                {elem.transcodingStatus === "Failed" ? (
                    <Tooltip title="Transcoding Failed">
                        <div className="masonry-item-transcoding-icon-wrapper">
                            <TranscodingIcon style={{ color: "#f50057" }} />
                        </div>
                    </Tooltip>
                ) : elem.transcodingStatus && elem.transcodingStatus !== "Complete" ? (
                    <Tooltip title="Transcoding">
                        <div className="masonry-item-transcoding-icon-wrapper">
                            <TranscodingIcon style={{ color: "#f29d04" }} />
                        </div>
                    </Tooltip>
                ) : null}
                {elem.thumb ? (
                    <img
                        onClick={() => {
                            if (typeof props.openMediaClick === "function") {
                                props.openMediaClick(elem);
                            }
                        }}
                        src={elem.thumb}
                        style={{ width: "100%", borderRadius: 10, minHeight: 100 }}
                        alt={elem.name}
                    />
                ) : (
                    <svg width="120" height="100" xmlns="http://www.w3.org/2000/svg">
                        <g>
                            <title>background</title>
                            <rect fill="none" id="canvas_background" height="102" width="122" y="-1" x="-1" />
                        </g>
                        <g>
                            <title>Layer 1</title>
                            <path id="svg_1" fill="none" d="m0,0l24,0l0,24l-24,0l0,-24z" />
                            <path
                                stroke="null"
                                id="svg_2"
                                d="m85.63889,39.5625l0,-26.10417c0,-4.10208 -2.65625,-7.45833 -5.90278,-7.45833l-70.83333,0c-3.24653,0 -5.90278,3.35625 -5.90278,7.45833l0,74.58333c0,4.10208 2.65625,7.45833 5.90278,7.45833l70.83333,0c3.24653,0 5.90278,-3.35625 5.90278,-7.45833l0,-26.10417l23.61111,29.83333l0,-82.04166l-23.61111,29.83333z"
                            />
                        </g>
                    </svg>
                )}
                <div style={{ display: "flex" }}>
                    <div>
                        <div style={{ display: "flex" }}>
                            {elem.type === "video" ? (
                                <VideoIcon />
                            ) : elem.type === "image" ? (
                                <ImageIcon />
                            ) : elem.type === "web" ? (
                                <LinkIcon />
                            ) : (
                                ""
                            )}
                            <div
                                style={{ marginLeft: 5 }}
                                className="masonry-item-name"
                                onClick={() => {
                                    if (typeof props.openMediaClick === "function") {
                                        props.openMediaClick(elem);
                                    }
                                }}
                            >
                                {elem.name}
                            </div>
                        </div>
                        <div className="masonry-item-additional-info">
                            Uploaded - {new Date(elem.createdAt!).toUTCString()}
                        </div>
                    </div>
                    {props.editable && (
                        <div>
                            <IconButton
                                aria-label="More"
                                aria-controls="long-menu"
                                aria-haspopup="true"
                                onClick={e => {
                                    handleClick(e, elem._id as string);
                                }}
                            >
                                <MoreVertIcon />
                            </IconButton>
                            <Menu
                                id="long-menu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={open === elem._id}
                                onClose={handleClose}
                            >
                                {getTreeDotMenuItems().map((menuItem, i) => (
                                    <MenuItem
                                        key={`${elem.id}${i}`}
                                        onClick={() => {
                                            if (typeof menuItem.onClick === "function") {
                                                menuItem.onClick(elem, menuItem);
                                            }
                                            handleClose();
                                        }}
                                    >
                                        {menuItem.icon && <ListItemIcon>{menuItem.icon}</ListItemIcon>}
                                        {menuItem.text}
                                    </MenuItem>
                                ))}
                            </Menu>
                        </div>
                    )}
                </div>
            </div>
        );

        if (props.draggable) {
            return (
                <Droppable droppableId={props.draggableIdPrefix + "dropable"} key={id}>
                    {provided1 => (
                        <div style={{ overflow: "hidden" }} ref={provided1.innerRef} {...provided1.droppableProps}>
                            <Draggable draggableId={props.draggableIdPrefix + elem._id} index={index}>
                                {(provided, snapshot) => {
                                    return (
                                        <>
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={{ ...provided.draggableProps.style }}
                                                className={classNames("draggable-item", {
                                                    "fixed-draggable-item":
                                                        props.draggingId &&
                                                        props.draggingId.slice(
                                                            props.draggableIdPrefix ? props.draggableIdPrefix.length : 0
                                                        ) !== id
                                                })}
                                            >
                                                {item}
                                            </div>
                                            {snapshot.isDragging && item}
                                        </>
                                    );
                                }}
                            </Draggable>
                            {/*{provided1.placeholder}*/}
                        </div>
                    )}
                </Droppable>
            );
        }

        return React.cloneElement(item, { key: elem._id });
    }

    const childElements: any = props.elements.map((elem, index) => renderItem(elem, index));

    return (
        <Masonry
            className={"my-gallery-class"} // default ''
            elementType={"div"} // default 'div'
            options={masonryOptions} // default {}
            disableImagesLoaded={false} // default false
            updateOnEachImageLoad={false} // default false and works only if disableImagesLoaded is false
        >
            {childElements}
        </Masonry>
    );
};

export default Gallery;
