import { createStyles, WithStyles, withStyles } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import CircularProgress from "@material-ui/core/CircularProgress";
import Hidden from "@material-ui/core/Hidden";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { ColumnProps, TableCellProps } from "react-virtualized";
import GridToolbar from "./gridToolbar";
import { TablePaginationActions } from "./paginationActions";
import MuiVirtualizedTable from "./wrappedVirtualizedTable";

const styles = () =>
    createStyles({
        pagination: {
            position: "absolute",
            right: 10,
            bottom: 10,
            padding: 0,
            border: 0
        },
        paginationMobile: {
            paddingRight: "0px !important",
            right: 0,
            "&>div": {
                paddingLeft: 0,
                paddingRight: 0
            }
        },
        loaderWrapper: {
            display: "flex",
            justifyContent: "center",
            padding: "30px"
        }
    });
export interface IGridDataItem {
    id: string;
    [key: string]: any;
}

interface IGridProps {
    // data items must contain "id" field
    data: IGridDataItem[];
    isDataLoading: boolean;
    columns: IGridColumn[];
    enablePagination?: boolean;
    rowsPerPageOptions?: number[];
    allDataCount?: number;
    rowsPerPage?: number;
    onChangeRowsPerPage?: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
    page?: number;
    onChangePage?: (e: React.MouseEvent<HTMLButtonElement> | null, page: number) => void;
    gridToolbarTitle?: string | undefined;
    searchValueChange?: (value: string) => void;
    searchValueChangeTimeOutForCall?: number;
    labelRowsPerPage?: string | null;
    showCheckBoxes?: boolean;
    providedTableRowHeight?: number;
    selectedGridToolbarElements?: React.ReactNode;
    rowSelectionChange?: (row: IGridDataItem[]) => void;
    selected?: IGridDataItem[];
    enableToolbar?: boolean;
    style?: any;
    paper?: boolean;
}

export interface IGridColumn extends ColumnProps {
    flexGrow?: number;
    label: React.ReactNode;
    dataKey: string;
    cellContentRenderer?: (cellRendererProps: TableCellProps) => React.ReactNode;
    numeric?: boolean;
    sortFn?: () => void;
    orderByCmp?: React.ReactNode;
    sortDirection?: "ASC" | "DESC";
}

const Grid = ({
    classes,
    data = [],
    isDataLoading,
    columns,
    allDataCount = 0,
    enablePagination,
    rowsPerPage = 0,
    rowsPerPageOptions,
    page = 0,
    // tslint:disable-next-line:no-empty
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onChangePage = () => {},
    onChangeRowsPerPage,
    gridToolbarTitle,
    searchValueChange,
    searchValueChangeTimeOutForCall = 1000,
    labelRowsPerPage = "Rows Per Page",
    showCheckBoxes = false,
    providedTableRowHeight,
    selectedGridToolbarElements,
    rowSelectionChange,
    enableToolbar = true,
    style = {
        height: "calc(100% - 124px)",
        width: "100%"
    },
    paper = true,
    selected = []
}: WithStyles<typeof styles> & IGridProps) => {
    const [isEverSearchValueChanged, setIsEverSearchValueChanged] = useState<boolean>(false);
    const [searchValue, setSearchValue] = useState<string>("");
    const [tableRowHeight, setTableRowHeight] = useState<number>(0);
    useEffect(() => {
        const handleResize = () => {
            if (providedTableRowHeight) {
                setTableRowHeight(providedTableRowHeight);
            } else if (window.innerWidth < 768) {
                setTableRowHeight(35);
            } else {
                setTableRowHeight(50);
            }
        };
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [providedTableRowHeight]);

    useEffect(() => {
        let canceled = false;
        let tId: any;
        if (isEverSearchValueChanged) {
            tId = setTimeout(() => {
                if (!canceled) {
                    searchValueChange!(searchValue.toLowerCase());
                }
            }, searchValueChangeTimeOutForCall);
        }
        return () => {
            if (tId) {
                clearTimeout(tId);
            }
            canceled = true;
        };
    }, [isEverSearchValueChanged, searchValue, searchValueChange, searchValueChangeTimeOutForCall]);

    const checkboxCellRenderer: (cellRendererProps: TableCellProps) => React.ReactNode = props => (
        <Checkbox
            checked={selected.indexOf(selected.find(r => r.id === props.rowData.id) as IGridDataItem) > -1}
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            onChange={() => selectRow(props)}
            value="checkedB"
            color="primary"
        />
    );
    const firstColumn: IGridColumn = {
        dataKey: "checkboxes",
        width: 100,
        label: (
            <Checkbox
                checked={!data.length ? false : selected.length === data.length}
                disabled={data.length === 0}
                // eslint-disable-next-line @typescript-eslint/no-use-before-define
                onChange={() => selectAllRows()}
                value="checkedB"
                color="primary"
            />
        ),
        cellContentRenderer: checkboxCellRenderer
    };
    const rowGetter = ({ index }: { index: number }) => data[index];
    // tslint:disable-next-line:no-empty
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    const rowClick = () => {};
    function selectAllRows() {
        let newSelected = [...selected];
        if (selected.length === data.length) {
            newSelected = [];
        } else {
            for (const row of data) {
                const selectedRow = selected.find(r => r.id === row.id);
                if (!selectedRow) {
                    newSelected.push(row);
                }
            }
        }
        if (rowSelectionChange && typeof rowSelectionChange === "function") {
            rowSelectionChange(newSelected);
        }
    }
    function selectRow(props: TableCellProps) {
        const { rowData } = props;
        const selectedRow = selected.find(r => r.id === rowData.id);
        const selectedIndex = selected.indexOf(selectedRow as IGridDataItem);
        const newSelected = [...selected];

        if (selectedIndex === -1) {
            newSelected.push(rowData);
        } else {
            newSelected.splice(selectedIndex, 1);
        }
        if (rowSelectionChange && typeof rowSelectionChange === "function") {
            rowSelectionChange(newSelected);
        }
    }
    const paddingBottom = enablePagination ? "60px" : 0;
    const paddingTop = enableToolbar ? "64px" : 0;
    const onSearchValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const val = e.target.value;
        setSearchValue(val);
        setIsEverSearchValueChanged(true);
    };

    if (paper) {
        return (
            <div style={{ ...style, paddingBottom, paddingTop }}>
                {enableToolbar && (
                    <GridToolbar
                        numSelected={selected.length}
                        title={gridToolbarTitle}
                        searchValue={searchValue}
                        onSearchValueChange={onSearchValueChange}
                        selectedGridToolbarElements={selectedGridToolbarElements}
                    />
                )}
                {isDataLoading ? (
                    <div className={classes.loaderWrapper}>
                        <CircularProgress size={50} />
                    </div>
                ) : (
                    <MuiVirtualizedTable
                        rowHeight={tableRowHeight}
                        headerHeight={tableRowHeight}
                        rowCount={data.length}
                        rowGetter={rowGetter}
                        onRowClick={rowClick}
                        columns={showCheckBoxes ? [firstColumn, ...columns] : columns}
                    />
                )}
                {enablePagination && (
                    <table>
                        <tbody>
                            <tr>
                                <Hidden mdUp>
                                    <TablePagination
                                        labelRowsPerPage=""
                                        className={classNames(classes.pagination, {
                                            [classes.paginationMobile as string]: true
                                        })}
                                        rowsPerPageOptions={rowsPerPageOptions || [5, 10, 25]}
                                        colSpan={3}
                                        count={allDataCount}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onChangeRowsPerPage={onChangeRowsPerPage}
                                        onChangePage={onChangePage}
                                        ActionsComponent={TablePaginationActions as any}
                                    />
                                </Hidden>
                                <Hidden smDown>
                                    <TablePagination
                                        labelRowsPerPage={labelRowsPerPage}
                                        className={classes.pagination}
                                        rowsPerPageOptions={rowsPerPageOptions || [5, 10, 25]}
                                        colSpan={3}
                                        count={allDataCount}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onChangeRowsPerPage={onChangeRowsPerPage}
                                        onChangePage={onChangePage}
                                        ActionsComponent={TablePaginationActions as any}
                                    />
                                </Hidden>
                            </tr>
                        </tbody>
                    </table>
                )}
            </div>
        );
    }

    return (
        <Paper style={{ ...style, paddingBottom, paddingTop }}>
            {enableToolbar && (
                <GridToolbar
                    numSelected={selected.length}
                    title={gridToolbarTitle}
                    searchValue={searchValue}
                    onSearchValueChange={onSearchValueChange}
                    selectedGridToolbarElements={selectedGridToolbarElements}
                />
            )}
            {isDataLoading ? (
                <div className={classes.loaderWrapper}>
                    <CircularProgress size={50} />
                </div>
            ) : (
                <MuiVirtualizedTable
                    rowHeight={tableRowHeight}
                    headerHeight={tableRowHeight}
                    rowCount={data.length}
                    rowGetter={rowGetter}
                    onRowClick={rowClick}
                    columns={showCheckBoxes ? [firstColumn, ...columns] : columns}
                />
            )}
            {enablePagination && (
                <table>
                    <tbody>
                        <tr>
                            <Hidden mdUp>
                                <TablePagination
                                    labelRowsPerPage=""
                                    className={classNames(classes.pagination, {
                                        [classes.paginationMobile as string]: true
                                    })}
                                    rowsPerPageOptions={rowsPerPageOptions || [5, 10, 25]}
                                    colSpan={3}
                                    count={allDataCount}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onChangeRowsPerPage={onChangeRowsPerPage}
                                    onChangePage={onChangePage}
                                    ActionsComponent={TablePaginationActions as any}
                                />
                            </Hidden>
                            <Hidden smDown>
                                <TablePagination
                                    labelRowsPerPage={labelRowsPerPage}
                                    className={classes.pagination}
                                    rowsPerPageOptions={rowsPerPageOptions || [5, 10, 25]}
                                    colSpan={3}
                                    count={allDataCount}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onChangeRowsPerPage={onChangeRowsPerPage}
                                    onChangePage={onChangePage}
                                    ActionsComponent={TablePaginationActions as any}
                                />
                            </Hidden>
                        </tr>
                    </tbody>
                </table>
            )}
        </Paper>
    );
};

export default withStyles(styles)(Grid);
