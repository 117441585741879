import { useCallback, useContext, useEffect, useState } from "react";
import { UserContext } from "../../context/main-context";
import { useIdHash } from "./user-id-hash";

export default function usePlaylistGroup() {
    const [group, setGroup] = useState<string | null>(null);
    const loggedInUser = useContext(UserContext)!;
    const userIdHash = useIdHash(loggedInUser._id);
    const currentOrganization = loggedInUser.orgs.find((f: any) => f.default)._id;

    const setStorageGroup = useCallback(
        (id: string) => {
            if (userIdHash && currentOrganization) {
                localStorage.setItem(`activePlaylistGroupId-${userIdHash}-${currentOrganization}`, id);
            }
            setGroup(id);
        },
        [currentOrganization, userIdHash]
    );

    useEffect(() => {
        const storageGroup = localStorage.getItem(`activePlaylistGroupId-${userIdHash}-${currentOrganization}`) || null;
        setGroup(storageGroup);
    }, [userIdHash, currentOrganization]);

    return { group, setStorageGroup };
}
