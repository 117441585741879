import { createStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import Switch from "@material-ui/core/Switch";
import React, { useContext, useEffect, useState } from "react";
import { updateDeviceConfig } from "../../../../api/device";
import { AppDispatch } from "../../../../context/main-context";

const styles = createStyles({
    rootWrapper: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    root: {
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box",
        flexGrow: "inherit",
        padding: "15px 20px 0px 20px",
        width: "60%",
        alignItems: "stretch",
        "@media (max-width: 600px)": {
            width: "100%",
            padding: 0
        }
    },
    column: {
        flex: 1
    },
    details: {
        flexDirection: "row"
    },
    enabledSwitch: {},
    heading: {},
    fieldset: {
        flexDirection: "column",
        display: "flex",
        flex: 1,
        border: "white"
    },
    form: {
        flexDirection: "column",
        display: "flex",
        flex: 1
    },
    buttonAction: { display: "flex", flex: 1, justifyContent: "center", paddingTop: 20 },
    secondaryHeading: {}
});
const resolutionMap = [
    { value: 1, label: "Auto" },
    { value: 2, label: "1920 x 1080 60HZ" },
    { value: 3, label: "1920 x 1080 50HZ" }
];
const audioMap = [
    { value: 1, label: "Auto" },
    { value: 2, label: "HDMI" },
    { value: 3, label: "Analog" },
    { value: 4, label: "Muted" }
];

const defaultConfig = {
    resolution: "1",
    overscan: false,
    audio: "1"
};
const AudioVideoPanel = ({
    classes,
    device,
    updateAction
}: WithStyles<typeof styles> & {
    device: any;
    updateAction: () => void;
}) => {
    const dispatch = useContext(AppDispatch)!;

    const [resolutionValue, setResolutionValue] = useState<string | unknown>(defaultConfig.resolution);
    const [overscan, setOverscan] = useState<boolean>(defaultConfig.overscan);
    const [audioValue, setAudioValue] = useState<string | unknown>(defaultConfig.audio);

    useEffect(() => {
        (async () => {
            try {
                device.data.config.audioVideo.resolution !== undefined
                    ? setResolutionValue(device.data.config.audioVideo.resolution)
                    : setResolutionValue(defaultConfig.resolution);
                device.data.config.audioVideo.overscan !== undefined
                    ? setOverscan(device.data.config.audioVideo.overscan)
                    : setOverscan(defaultConfig.overscan);
                device.data.config.audioVideo.audio !== undefined
                    ? setAudioValue(device.data.config.audioVideo.audio)
                    : setAudioValue(defaultConfig.audio);
            } catch (e) {
                console.log(e);
            }
        })();
    }, [device]);

    const onChangeResolution = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
        setResolutionValue(event.target.value);
    };
    const onChangeOverscan = () => {
        overscan ? setOverscan(false) : setOverscan(true);
    };
    const onChangeAudio = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
        setAudioValue(event.target.value);
    };
    const onClickCancel = (e: any) => {
        if (device.data.config.audioVideo.resolution !== undefined) {
            setResolutionValue(device.data.config.audioVideo.resolution);
        } else {
            setResolutionValue(defaultConfig.resolution);
        }

        if (device.data.config.audioVideo.overscan !== undefined) {
            setOverscan(device.data.config.audioVideo.overscan);
        } else {
            setOverscan(defaultConfig.overscan);
        }
        if (device.data.config.audioVideo.audio !== undefined) {
            setAudioValue(device.data.config.audioVideo.audio);
        } else {
            setAudioValue(defaultConfig.audio);
        }
        // return evr to the divece stat
    };
    const handleSubmit = (e: any) => {
        e.preventDefault();
        const sendData: any = {
            ...device.data.config,
            audioVideo: {
                resolution: resolutionValue,
                overscan,
                audio: audioValue
            }
        };

        (async () => {
            try {
                await updateDeviceConfig(device.data.deviceId, sendData).then(() => {
                    dispatch({
                        type: "SHOW_MESSAGE_SUCCESS",
                        payload: "Audio&Video configuration updated successfully"
                    });
                });
                updateAction();
            } catch (e) {
                dispatch({ type: "SHOW_MESSAGE_ERROR", payload: e });
            }
        })();
        // do smth
    };
    return (
        <div className={classes.rootWrapper}>
            <div className={classes.root}>
                <form onSubmit={handleSubmit} className={classes.form}>
                    <fieldset className={classes.fieldset}>
                        <FormControl>
                            <InputLabel htmlFor="resolution-value">Resolution</InputLabel>
                            <Select id="ResolutionSelect" value={resolutionValue} onChange={onChangeResolution}>
                                {resolutionMap.map(option => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControlLabel
                            control={<Switch checked={overscan} onChange={onChangeOverscan} value="checkH" />}
                            label="Overscan"
                        />
                        <FormControl>
                            <InputLabel htmlFor="audio">Audio</InputLabel>
                            <Select id="AudioSelect" value={audioValue} onChange={onChangeAudio}>
                                {audioMap.map(option => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </fieldset>
                    <div className={classes.buttonAction}>
                        <Button type="submit" color="primary">
                            Save
                        </Button>
                        <Button onClick={onClickCancel} color="primary">
                            Cancel
                        </Button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default withStyles(styles)(AudioVideoPanel);
