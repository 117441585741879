import { DialogActions } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Theme, withStyles } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import { ClassNameMap } from "@material-ui/styles/withStyles";
import React from "react";

const styles = (theme: Theme) => createStyles({});

const AlertDialog = ({
    handleCloseAlert,
    del,
    message
}: {
    classes: Partial<ClassNameMap<string>>;
    del: () => void;
    handleCloseAlert: () => void;
    message?: string;
}) => {
    const handleDeleteAlert = () => {
        del();
    };

    return (
        <Dialog open={true} onClose={handleCloseAlert} aria-labelledby="alert-dialog-delete-confirm">
            <DialogTitle id="alert-dialog-delete-confirm">Attention</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-delte-conf">{message}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleDeleteAlert} color="primary">
                    agree
                </Button>
                <Button onClick={handleCloseAlert} color="primary">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default withStyles(styles)(AlertDialog);
