import { Action } from "../interfaces/daos";
import { constractGlobalErrorMessage, parseErrorMessage } from "../utils/globalMessageUtil";

export interface State {
    errorMessage: string;
    successMessage: string;
    loggedInUser: any;
    loadingInitialData: boolean;
    insufficientlyAuthenticationDetails: string[];
    uploadMediaInfo: Resumable.ResumableFile[];
}

export const initialState: State = {
    errorMessage: "",
    successMessage: "",
    loggedInUser: null,
    loadingInitialData: true,
    insufficientlyAuthenticationDetails: [],
    uploadMediaInfo: []
};

export default function reducer(state: State, action: Action) {
    switch (action.type) {
        case "INIT": {
            return {
                ...state,
                loadingInitialData: true
            };
        }
        case "INITIAL_DATA_READY": {
            return {
                ...state,
                ...action.payload,
                loadingInitialData: false
            };
        }
        case "LOGOUT": {
            return {
                ...state,
                loggedInUser: null,
                loggedInUserIdHash: null
            };
        }
        case "SHOW_MESSAGE_SUCCESS": {
            return {
                ...state,
                successMessage: action.payload,
                errorMessage: ""
            };
        }
        case "SHOW_MESSAGE_ERROR": {
            return {
                ...state,
                errorMessage: constractGlobalErrorMessage(parseErrorMessage(action.payload)),
                successMessage: ""
            };
        }
        case "CLOSE_MESSAGE_SUCCESS": {
            return {
                ...state,
                successMessage: ""
            };
        }
        case "CLOSE_MESSAGE_ERROR": {
            return {
                ...state,
                errorMessage: ""
            };
        }
        case "LOGGED_IN_USER": {
            return {
                ...state,
                loggedInUser: {
                    ...action.payload,
                    options: {
                        ...action.payload?.options
                    }
                },
                loadingInitialData: false
            };
        }
        case "UPDATE_TWO_FACTOR_AUTH": {
            return {
                ...state,
                loggedInUser: {
                    ...state.loggedInUser,
                    twoFactorAuth: action.payload
                }
            };
        }
        case "SIGN_UP_USER": {
            return {
                ...state,
                loggedInUser: {
                    ...action.payload,
                    options: {
                        ...action.payload?.options
                    }
                },
                loadingInitialData: false
            };
        }
        case "UPDATE_HIDE_ALL_TAB": {
            return {
                ...state,
                loggedInUser: {
                    ...state.loggedInUser,
                    options: {
                        ...state.loggedInUser.options,
                        hideAllTab: action.payload
                    }
                }
            };
        }
        case "EMAIL_VERIFIED": {
            return {
                ...state,
                loggedInUser: {
                    ...state.loggedInUser,
                    emailVerified: true
                }
            };
        }
        case "SET_INSUFFICIENTLY_AUTHENTICATION_DETAILS": {
            return {
                ...state,
                insufficientlyAuthenticationDetails: action.payload
            };
        }
        case "UPDATE_UPLOAD_MEDIA_INFO": {
            if (action.payload && action.payload.files && action.payload.files.length) {
                const newData = [...state.uploadMediaInfo];
                action.payload.files.map((f: Resumable.ResumableFile) => newData.push(f));
                return {
                    ...state,
                    uploadMediaInfo: newData
                };
            } else {
                return state;
            }
        }
        case "UPDATE_UPLOAD_MEDIA_INFO_PROGRESS": {
            const oldData = state.uploadMediaInfo;
            if (action.payload) {
                const index = oldData.indexOf(
                    oldData.filter(i => i.uniqueIdentifier === action.payload.uniqueIdentifier)[0]
                );
                if (index > -1) {
                    const newData = [...oldData];
                    newData.splice(index, 1, action.payload);
                    return {
                        ...state,
                        uploadMediaInfo: newData
                    };
                } else {
                    return state;
                }
            } else {
                return state;
            }
        }
        case "UPDATE_UPLOAD_MEDIA_INFO_SUCCESS": {
            const oldData = state.uploadMediaInfo;
            if (action.payload) {
                const index = oldData.indexOf(
                    oldData.filter(i => i.uniqueIdentifier === action.payload.uniqueIdentifier)[0]
                );
                if (index > -1) {
                    const newData = [...oldData];
                    newData.splice(index, 1);
                    return {
                        ...state,
                        uploadMediaInfo: newData
                    };
                } else {
                    return state;
                }
            } else {
                return state;
            }
        }
        default:
            return state;
    }
}
