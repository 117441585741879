import { Grid, WithStyles } from "@material-ui/core";
import createStyles from "@material-ui/core/styles/createStyles";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import CircleIcon from "@material-ui/icons/FiberManualRecord";
import classNames from "classnames";
// import { distanceInWordsToNow } from "date-fns";
import distanceInWordsToNow from "date-fns/formatDistanceToNow";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { getScreenshot } from "../../../../api/device";
import { GREEN_COLOR, RED_COLOR, YELLOW_COLOR } from "../../../../constants/style-constants";
import DeviceMap from "../map";
import DeviceGeneralForm from "./general-form";
import { getRotatedSrc } from "../../../../utils/rotateImage";

const styles = createStyles({
    labelWrapper: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        fontSize: 13,
        margin: "10px 20px"
    },
    labelIcon: {
        marginRight: 5,
        fontSize: 14
    },
    colorGreen: {
        fill: GREEN_COLOR
    },
    colorYellow: {
        fill: YELLOW_COLOR
    },
    colorRed: {
        fill: RED_COLOR
    },
    screenshotDiv: {
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center center",
        height: 280,
        "@media (min-width: 960px)": {
            height: "230px"
        },
        "@media (min-width: 1280px)": {
            height: "265px"
        },
        "@media (min-width: 1920px)": {
            height: "330px"
        },
        "@media (max-width: 959px)": {
            display: "none"
        }
    },
    screenshotImg: {
        width: "100%",
        "@media (min-width: 960px)": {
            display: "none"
        }
    },
    gridItem: {
        width: "100%"
    }
});

const DeviceGeneral = ({
    classes,
    device,
    lastScreenShot
}: WithStyles<typeof styles> & { device: any; lastScreenShot: any }) => {
    const [lastSnapshotUrl, setLastSnapshotUrl] = useState<any>(null);
    const [rawScreenshotResponse, setRawScreenshotResponse] = useState<any>(null);
    const history = useHistory();
    const { status, syncStatus, additionalOptions } = device;

    const verticalOrientation = !!(additionalOptions && additionalOptions.verticalOrientation);
    const rotateDirection = additionalOptions && additionalOptions.rotateDirection === "counterclockwise" ? -90 : 90;
    const lastUpdateTime = lastScreenShot?.timestamps && distanceInWordsToNow(lastScreenShot.timestamps);

    useEffect(() => {
        let canceled = false;
        (async () => {
            if (!lastScreenShot) {
                return;
            }
            try {
                const response = await getScreenshot(lastScreenShot._id);
                if (!canceled) {
                    setRawScreenshotResponse(response);
                }
                // tslint:disable-next-line:no-empty
            } catch (e) {}
        })();
        return () => {
            canceled = true;
        };
    }, [lastScreenShot]);

    useEffect(() => {
        let canceled = false;
        (async () => {
            if (!rawScreenshotResponse) {
                return;
            }
            try {
                const url = URL.createObjectURL(rawScreenshotResponse);
                if (verticalOrientation) {
                    const src = await getRotatedSrc(url, rotateDirection);
                    if (!canceled) {
                        setLastSnapshotUrl(src);
                    }
                } else {
                    setLastSnapshotUrl(url);
                }
                // tslint:disable-next-line:no-empty
            } catch (e) {}
        })();
        return () => {
            canceled = true;
        };
    }, [rawScreenshotResponse, rotateDirection, verticalOrientation]);

    function handleCancel() {
        history.replace(`/devices`);
    }

    let lastSeenIndicatorClassName = classes.colorRed;
    if (status && status.toLowerCase() === "online") {
        lastSeenIndicatorClassName = classes.colorGreen;
    } else if (status && status.toLowerCase() === "away") {
        lastSeenIndicatorClassName = classes.colorYellow;
    }

    // tslint:disable-next-line:variable-name
    const _syncStatus = syncStatus && syncStatus.toLowerCase();

    let syncingIndicatorClassName = classes.colorRed;

    if (_syncStatus === "synced") {
        syncingIndicatorClassName = classes.colorGreen;
    }

    if (_syncStatus === "syncing" || _syncStatus === "out of sync") {
        syncingIndicatorClassName = classes.colorYellow;
    }

    if (_syncStatus === "error") {
        syncingIndicatorClassName = classes.colorRed;
    }

    return (
        <div>
            <Grid container justify="space-around">
                <Grid item md={verticalOrientation && !!lastSnapshotUrl ? 4 : 7} sm={12} className={classes.gridItem}>
                    <div style={{ display: "flex" }}>
                        <div className={classNames(classes.labelWrapper)}>
                            <CircleIcon
                                fontSize="small"
                                className={`${lastSeenIndicatorClassName} ${classes.labelIcon}`}
                            />
                            <span>{status || "Offline"}</span>
                        </div>
                        <div className={classNames(classes.labelWrapper)}>
                            <CircleIcon
                                fontSize="small"
                                className={`${syncingIndicatorClassName} ${classes.labelIcon}`}
                            />
                            <span>
                                {_syncStatus === "synced"
                                    ? "Synced"
                                    : _syncStatus === "syncing"
                                    ? "Syncing"
                                    : _syncStatus === "out of sync"
                                    ? "Out of sync"
                                    : "Syncing error"}
                            </span>
                        </div>
                    </div>
                    <div>
                        {lastSnapshotUrl ? (
                            <>
                                <div
                                    style={
                                        verticalOrientation
                                            ? {
                                                  minHeight: 560,
                                                  marginRight: 10,
                                                  backgroundImage: `url(${lastSnapshotUrl})`
                                              }
                                            : { backgroundImage: `url(${lastSnapshotUrl})` }
                                    }
                                    className={classes.screenshotDiv}
                                />
                                <img
                                    className={classes.screenshotImg}
                                    src={verticalOrientation ? lastSnapshotUrl : lastSnapshotUrl}
                                    alt="Screenshot"
                                />
                                {lastUpdateTime && (
                                    <Typography variant="subtitle1" style={{ marginBottom: 10 }}>
                                        Screenshot has been taken <strong>{lastUpdateTime}</strong> ago
                                    </Typography>
                                )}
                            </>
                        ) : (
                            <div style={{ fontSize: 30 }}>No Screenshot</div>
                        )}
                        {!(verticalOrientation && !!lastSnapshotUrl) && <DeviceMap />}
                    </div>
                </Grid>
                {verticalOrientation && !!lastSnapshotUrl && (
                    <Grid item md={4} sm={12} className={classes.gridItem}>
                        <DeviceMap />
                    </Grid>
                )}
                <Grid item md={4} sm={12} className={classes.gridItem}>
                    <DeviceGeneralForm handleCancel={handleCancel} />
                </Grid>
            </Grid>
        </div>
    );
};
export default withStyles(styles)(DeviceGeneral);
