import { WithStyles } from "@material-ui/core";
import Hidden from "@material-ui/core/Hidden";
import createStyles from "@material-ui/core/styles/createStyles";
import withStyles from "@material-ui/core/styles/withStyles";
import React, { useContext, useEffect, useState } from "react";
import { addScheduleGroup, deleteScheduleGroup, getScheduleGroup, updateScheduleGroup } from "../../api/schedule-group";
import { AppDispatch, UserContext } from "../../context/main-context";
import useScheduleGroup from "../hooks/schedule-groups";
import AlertDialog from "../shared/alert-delete-dialog";
import GroupDialog from "../shared/group/Dialog";
import AppToolbar from "../shared/toolbar";
import ScheduleActions from "./schedule-actions";
import ScheduleGrid from "./schedule-grid";
const styles = createStyles({
    schedules: {
        display: "flex",
        flexDirection: "column",
        padding: 20,
        height: "calc(100vh - 176px)"
    },
    schedulesGrid: {
        flex: 1,
        overflow: "hidden"
    }
});

const Schedules = (props: WithStyles<typeof styles>) => {
    const { classes } = props;
    const { group: storageGroup, setStorageGroup } = useScheduleGroup();
    const dispatch = useContext(AppDispatch)!;
    const loggedInUser = useContext(UserContext)!;
    const [firstTab] = useState<{ name: string; _id: string }>({ name: "All", _id: "-1" });
    const [lastTab] = useState<{ name: string; _id: string }>({ name: "Without group", _id: "-2" });
    const [activeTab, setActiveTab] = useState<number>(0);
    const [tabs, setTabs] = useState<{ name: string; _id: string }[]>([]);
    const [mode, setMode] = useState<"add" | "edit" | "delete" | null>(null);
    const [selectedGroup, setSelectedGroup] = useState<{ name: string; _id: string } | null>(null);
    const [deletedGroup, setDeletedGroup] = useState<{ name: string; _id: string } | null>(null);
    const [groupForUpdate, setGroupForUpdate] = useState<{ name: string; _id: string } | null>(null);
    const [groupNameForAdd, setGroupNameForAdd] = useState<string | null>(null);

    const [groups, setGroups] = useState<any[]>([]);
    const [dataCountWithoutGroup, setDataCountWithoutGroup] = useState<number>(0);

    const hideAllTab = loggedInUser.options.hideAllTab;

    const handleClose = () => {
        setMode(null);
    };

    useEffect(() => {
        if (hideAllTab === false) {
            setTabs([firstTab, ...groups]);
        } else if (hideAllTab === true) {
            if (!!dataCountWithoutGroup) {
                setTabs([...groups, lastTab]);
            } else {
                setTabs([...groups]);
            }
        }
    }, [groups, dataCountWithoutGroup, hideAllTab, firstTab, lastTab]);

    useEffect(() => {
        let canceled = false;
        getScheduleGroup()
            .then(data => {
                if (!canceled) {
                    const groups = data.data && data.data.groups;
                    const dataCountWithoutGroup = data.data && data.data.dataCountWithoutGroup;
                    setGroups(groups);
                    setDataCountWithoutGroup(dataCountWithoutGroup);
                }
            })
            // tslint:disable-next-line:no-empty
            .catch(() => {});
        return () => {
            canceled = true;
        };
    }, [firstTab]);

    useEffect(() => {
        if (storageGroup) {
            const currentGroup = tabs.find(g => g._id === storageGroup);
            if (currentGroup) {
                setActiveTab(tabs.indexOf(currentGroup));
            }
        }
    }, [storageGroup, tabs]);

    useEffect(() => {
        setSelectedGroup(tabs[activeTab]);
    }, [activeTab, tabs]);

    useEffect(() => {
        let canceled = false;
        if (deletedGroup && deletedGroup._id !== "-1") {
            deleteScheduleGroup(deletedGroup._id)
                .then(() => {
                    if (!canceled) {
                        setActiveTab(0);
                        setGroups(oldGroups => {
                            const group = oldGroups.find(gr => gr._id === deletedGroup._id);
                            if (group) {
                                oldGroups.splice(oldGroups.indexOf(group), 1);
                                setStorageGroup((oldGroups[0] && oldGroups[0]._id) || "-1");
                                return [...oldGroups];
                            }
                            return oldGroups;
                        });
                        setDeletedGroup(null);
                        dispatch({
                            type: "SHOW_MESSAGE_SUCCESS",
                            payload: "Group " + deletedGroup.name + " deleted successfully"
                        });
                    }
                })
                .catch(e => {
                    dispatch({
                        type: "SHOW_MESSAGE_ERROR",
                        payload: "Move schedule(s) to other groups before deleting the group."
                    });
                });
        }
        return () => {
            canceled = true;
        };
    }, [dispatch, deletedGroup, setStorageGroup]);

    useEffect(() => {
        let canceled = false;
        if (groupForUpdate && groupForUpdate._id !== "-1") {
            const { _id, name } = groupForUpdate!;
            updateScheduleGroup(_id, name)
                .then(() => {
                    if (!canceled) {
                        setGroups(oldGroups => {
                            const group = oldGroups.find(gr => gr._id === _id);
                            if (group) {
                                oldGroups.splice(oldGroups.indexOf(group), 1, { ...group, name });
                                return [...oldGroups];
                            }
                            return oldGroups;
                        });
                        setGroupForUpdate(null);
                        dispatch({ type: "SHOW_MESSAGE_SUCCESS", payload: "Group " + name + " updated successfully" });
                    }
                })
                .catch(e => {
                    dispatch({ type: "SHOW_MESSAGE_ERROR", payload: e });
                });
        }
        return () => {
            canceled = true;
        };
    }, [dispatch, groupForUpdate]);

    useEffect(() => {
        let canceled = false;
        if (groupNameForAdd) {
            (async () => {
                try {
                    await addScheduleGroup(groupNameForAdd);
                    getScheduleGroup()
                        .then(data => {
                            if (!canceled) {
                                const groups = data.data && data.data.groups;
                                const dataCountWithoutGroup = data.data && data.data.dataCountWithoutGroup;
                                setGroups(groups);
                                setDataCountWithoutGroup(dataCountWithoutGroup);
                                setGroupNameForAdd(null);
                                dispatch({
                                    type: "SHOW_MESSAGE_SUCCESS",
                                    payload: "Group " + groupNameForAdd + " added successfully"
                                });
                            }
                        })
                        // tslint:disable-next-line:no-empty
                        .catch(() => {});
                } catch (e) {
                    dispatch({ type: "SHOW_MESSAGE_ERROR", payload: e });
                }
            })();
        }
        return () => {
            canceled = true;
        };
    }, [dispatch, groupNameForAdd]);

    const handleAddGroup = () => {
        setMode("add");
    };

    const handleEditGroup = () => {
        setMode("edit");
    };

    const handleDeleteGroup = () => {
        setMode("delete");
    };

    const onDeleteGroup = () => {
        if (selectedGroup) {
            setDeletedGroup(selectedGroup);
        }
        handleClose();
    };

    const addOrUpdate = (name: string, id?: string) => {
        if (id) {
            setGroupForUpdate({ name, _id: id });
        } else {
            setGroupNameForAdd(name);
        }
        handleClose();
    };

    const menuItems = [
        {
            name: "Add Group",
            handler: handleAddGroup,
            hiddenIndex: -10000
        },
        {
            name: "Edit Group",
            handler: handleEditGroup,
            hiddenIndex: 0
        },
        {
            name: "Delete Group",
            handler: handleDeleteGroup,
            hiddenIndex: 0
        }
    ].filter((f, i) => {
        if (selectedGroup && (selectedGroup._id === "-1" || selectedGroup._id === "-2")) {
            if (i === 0) {
                return true;
            }
        } else {
            return true;
        }
        return false;
    });

    const tabChangeHandler = (event: React.ChangeEvent<{}>, value: number) => {
        setActiveTab(value);
        setStorageGroup(tabs[value]._id);
    };
    return (
        <div>
            <AppToolbar activeTab={activeTab} tabChangeHandler={tabChangeHandler} tabs={tabs} menuItems={menuItems} />
            <div className={classes.schedules}>
                <div className={classes.schedulesGrid}>
                    <Hidden smDown>
                        <ScheduleGrid
                            groups={tabs.slice(1)}
                            selectedGroupId={selectedGroup && selectedGroup._id}
                            isSmallViewport={false}
                        />
                    </Hidden>
                    <Hidden mdUp>
                        <ScheduleGrid
                            groups={tabs.slice(1)}
                            selectedGroupId={selectedGroup && selectedGroup._id}
                            isSmallViewport={true}
                        />
                    </Hidden>
                </div>
                <div>
                    <ScheduleActions />
                </div>
            </div>
            {selectedGroup && (mode === "add" || mode === "edit") && (
                <GroupDialog add={addOrUpdate} mode={mode} handleClose={handleClose} group={selectedGroup} />
            )}
            {mode === "delete" && (
                <AlertDialog
                    del={onDeleteGroup}
                    message="Are you sure you want to delete group?"
                    handleCloseAlert={handleClose}
                />
            )}
        </div>
    );
};

export default withStyles(styles)(Schedules);
