import { Theme, Checkbox, FormControlLabel } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import {
    ExpansionPanelActions,
    Tooltip,
    ExpansionPanelDetails,
    ExpansionPanelSummary,
    Button,
    Divider,
    ExpansionPanel,
    Typography
} from "@material-ui/core";
import createStyles from "@material-ui/core/styles/createStyles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { toggleHideAllTab } from "../../../../api/main-api";
import { AppDispatch, UserContext } from "../../../../context/main-context";
import HelpIcon from "@material-ui/icons/Help";
import { BLUE_COLOR } from "../../../../constants/style-constants";

const styles = (theme: Theme) =>
    createStyles({
        hideAllTabPanelDetails: {
            display: "flex",
            alignItems: "center",
            position: "relative",
            margin: "0 auto"
        },
        heading: {
            fontSize: theme.typography.pxToRem(15)
        }
    });
const HideAllTab = ({
    classes,
    expanded,
    handleClose,
    handleExpansionChange
}: WithStyles<typeof styles> & {
    expanded: string | false;
    handleClose: () => void;
    handleExpansionChange: (panel: string) => (event: any, newExpand: boolean) => void;
}) => {
    const dispatch = useContext(AppDispatch)!;
    const loggedInUser = useContext(UserContext)!;
    const [hideAllTab, setHideAllTab] = useState<boolean>(!!loggedInUser.options.hideAllTab);
    const [toggleAllTab, setToggleAllTab] = useState<boolean | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    useEffect(() => {
        let canceled = false;
        if (typeof toggleAllTab === "boolean") {
            setIsLoading(true);
            toggleHideAllTab(toggleAllTab)
                .then(d => {
                    if (!canceled) {
                        setIsLoading(false);
                    }
                    const payload = !!(d.data.msConfigs && d.data.msConfigs.hideAllTab);
                    dispatch({ type: "UPDATE_HIDE_ALL_TAB", payload });
                    dispatch({
                        type: "SHOW_MESSAGE_SUCCESS",
                        payload: "Updated successfully"
                    });
                })
                .catch(e => {
                    if (!canceled) {
                        setIsLoading(false);
                    }
                    dispatch({ type: "SHOW_MESSAGE_ERROR", payload: e });
                });
        }
        return () => {
            canceled = true;
        };
    }, [dispatch, toggleAllTab]);

    const handleHideAllTabChange = (e: { target: { checked: React.SetStateAction<boolean> } }) => {
        setHideAllTab(e.target.checked);
        setToggleAllTab(!!e.target.checked);
    };

    return (
        <ExpansionPanel expanded={expanded === "hideAllTab"} onChange={handleExpansionChange("hideAllTab")}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <div>
                    <Typography className={classes.heading}>Hide "All" tab Options</Typography>
                </div>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <div className={classes.hideAllTabPanelDetails}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                disabled={isLoading}
                                checked={hideAllTab}
                                onChange={handleHideAllTabChange}
                                value="checkedB"
                                color="primary"
                                inputProps={{
                                    "aria-label": "secondary checkbox"
                                }}
                            />
                        }
                        label='Hide "All" tab'
                    />
                    <Tooltip title='If checked,  Groups panel will not show the tab "All".'>
                        <HelpIcon style={{ color: BLUE_COLOR }} />
                    </Tooltip>
                </div>
            </ExpansionPanelDetails>
            <Divider />
            <ExpansionPanelActions>
                <Button size="small" onClick={handleClose}>
                    Close
                </Button>
            </ExpansionPanelActions>
        </ExpansionPanel>
    );
};

export default withStyles(styles)(HideAllTab);
