import MD5 from "crypto-js/md5";
import { BASE_DOMAIN } from "../constants/app";
import { InsufficientlyAuthenticationError } from "../errors/insufficientlyAuthentication";

const API_URL = {
    LOGIN: () => `${BASE_DOMAIN}/v1/login`,
    GET_LOGGED_IN_USER: () => `${BASE_DOMAIN}/v1/ms/me`,
    GET_SUGGESTED_USERNAME: () => `${BASE_DOMAIN}/v1/get-suggested-username`,
    CHECK_USERNAME: () => `${BASE_DOMAIN}/v1/username-exists`,
    SIGNUP: () => `${BASE_DOMAIN}/v1/signup`,
    VERIFY_EMAIL: () => `${BASE_DOMAIN}/v1/verify-email`,
    SEND_ACTIVATION_EMAIL: () => `${BASE_DOMAIN}/v1/me/send-activation-email`,
    GET_LOCATION: (query?: string) => `${BASE_DOMAIN}/v1/geolocation${query ? query : ""}`,
    GET_DEVICES: () => `${BASE_DOMAIN}/v1/devices`,
    GET_DEVICES_STATUSES: (query?: string) => `${BASE_DOMAIN}/v1/ms/devices/statuses${query ? query : ""}`,
    GET_MEDIAS_GROUPS: () => `${BASE_DOMAIN}/v1/devices`,
    GET_PLAYLISTS: () => `${BASE_DOMAIN}/v1/groups`,
    GET_PLAYLISTS_GROUPS: () => `${BASE_DOMAIN}/v1/devices`,
    GET_SCHEDULES: () => `${BASE_DOMAIN}/v1/groups`,
    GET_SCHEDULES_GROUPS: () => `${BASE_DOMAIN}/v1/devices`,
    SYNC_DEVICES: () => `${BASE_DOMAIN}/v1/ms/sync`,
    CHANGE_PASSWORD: () => `${BASE_DOMAIN}/v1/me/change-password`,
    HAS_GRAVATAR: () => `${BASE_DOMAIN}/v1/general/has-gravatar`,
    TOGGLE_TWO_FACTOR_AUTH: () => `${BASE_DOMAIN}/v1/toggle-two-factor-authentication`,
    REGENERATE_TWO_FACTOR_AUTHENTICATION_QR: () => `${BASE_DOMAIN}/v1/regenerate-two-factor-authentication-qr`,
    CHANGE_ORGANIZATION: (orgId: string) => `${BASE_DOMAIN}/v1/users/change-organization/${orgId}`,
    USER_OPTIONS: () => `${BASE_DOMAIN}/v1/ms-user-options`
};
export const handlersFor401: Function[] = [];
export function getTokens() {
    let tokens;
    try {
        tokens = JSON.parse(localStorage.getItem("tokens") || "{}");
    } catch (e) {
        tokens = {};
    }
    return tokens || {};
}
export function saveAuthToken(authToken: string) {
    const tokens = getTokens();
    tokens.authToken = authToken;
    localStorage.setItem("tokens", JSON.stringify(tokens));
}
export function clearAuthToken() {
    const tokens = getTokens();
    delete tokens.authToken;
    if (Object.keys(tokens).length) {
        localStorage.setItem("tokens", JSON.stringify(tokens));
    } else {
        localStorage.removeItem("tokens");
    }
}
export function getAuthToken() {
    const { authToken } = getTokens();

    return authToken || false;
}
export function getBearer() {
    const authToken = getAuthToken();

    if (!authToken) {
        return false;
    }
    return {
        Authorization: `Bearer ${authToken}`
    };
}
export function getHeaders() {
    const bearer = getBearer();
    if (!bearer) {
        return {
            "Content-Type": "application/json"
        };
    }

    return {
        ...bearer,
        "Content-Type": "application/json"
    };
}
export const request = async <T = any>(url: string, body: any, headers: any = {}, method = "GET") => {
    const params: any = {
        method,
        headers: { ...getHeaders(), ...headers }
    };
    if (body) {
        params.body = JSON.stringify(body);
    }
    try {
        const res = await fetch(url, params);
        const { responseType } = headers || { responseType: undefined };
        const resp = responseType === "blob" ? await res.blob() : await res.json();
        // const json = await res.json();
        if (res.status === 403) {
            throw new Error("403");
        }
        if (res.status === 401) {
            if (handlersFor401.length) {
                handlersFor401.forEach((handler: any) => handler(res));
            } else {
                logout();
            }
        }
        if (res.status === 412) {
            const error = new InsufficientlyAuthenticationError("412", resp && resp.details);
            throw error;
        }
        if (!res.ok) {
            throw new Error(resp);
        }
        return resp as Promise<T>;
    } catch (e) {
        if (e && e.message === "403") {
            throw new Error("You don't have access to resource.");
        }
        if (e && e.message === "412") {
            throw e;
        }
        throw new Error("Something went wrong.");
    }
};

export const upload = async (url: string, body: any, headers?: any) => {
    const params: any = {
        method: "POST",
        headers: { ...getBearer(), ...headers },
        body
    };
    try {
        const res = await fetch(url, params);
        const json = await res.json();
        if (res.status === 403) {
            throw new Error("403");
        }
        if (res.status === 401) {
            logout();
        }
        if (!res.ok) {
            throw new Error();
        }
        return json;
    } catch (e) {
        if (e && e.message === "403") {
            throw new Error("You don't have access to resource.");
        }
        throw new Error("Something went wrong.");
    }
};

export const makePost = <T = any>(url: string, body: any, headers?: any) => request<T>(url, body, headers, "POST");
export const makePatch = <T = any>(url: string, body: any, headers?: any) => request<T>(url, body, headers, "PATCH");
export const makeGet = <T = any>(url: string, headers: any = undefined) => request<T>(url, null, headers, "GET");
export const makeDelete = <T = any>(url: string, body?: any, headers?: any) => request<T>(url, body, headers, "DELETE");
export function login(email: string, password: string, twoFactorAuthStr: string) {
    return makePost(API_URL.LOGIN(), { email, password, twoFactorAuthStr }, {});
}
export function getLoggedInUser() {
    return makeGet(API_URL.GET_LOGGED_IN_USER(), {});
}
export function logout() {
    clearAuthToken();
    window.location.reload();
}
export function getSuggestedUsername(body: { fullName?: string; email?: string }) {
    return makePost(API_URL.GET_SUGGESTED_USERNAME(), body, null);
}
export function checkUsernameExisting(body: { username?: string }) {
    return makePost(API_URL.CHECK_USERNAME(), body, null);
}
export function signup(data: {
    fullName: string;
    email: string;
    username: string;
    password: string;
    confirmPassword: string;
}) {
    return makePost(API_URL.SIGNUP(), data, null);
}
export function verifyEmail(headers: any) {
    return makePost(API_URL.VERIFY_EMAIL(), null, headers);
}
export function getDevices() {
    return makeGet(API_URL.GET_DEVICES());
}
export const getDevicesStatuses = (deviceId?: any) => {
    const query = deviceId ? `?deviceId=${deviceId}` : "";
    return makeGet(API_URL.GET_DEVICES_STATUSES(query));
};
export function getMediasGroups() {
    return makeGet(API_URL.GET_MEDIAS_GROUPS());
}
export function getPlaylists() {
    return makeGet(API_URL.GET_PLAYLISTS());
}
export function getPlaylistsGroups() {
    return makeGet(API_URL.GET_PLAYLISTS_GROUPS());
}
export function getSchedules() {
    return makeGet(API_URL.GET_SCHEDULES());
}
export function GET_SCHEDULES_GROUPS() {
    return makeGet(API_URL.GET_SCHEDULES_GROUPS());
}
export function patchUser(data: any) {
    console.log(data);
    return makePatch(API_URL.GET_LOGGED_IN_USER(), data);
}

export function changePassword(oldPassword: string, newPassword: string) {
    return makePost(API_URL.CHANGE_PASSWORD(), {
        oldPassword,
        newPassword
    });
}
export function changeOrg(id: string) {
    return makePost(API_URL.CHANGE_ORGANIZATION(id), null);
}
export function hasGravatar(email: string) {
    return makePost(API_URL.HAS_GRAVATAR(), { email });
}
const GRAVATAR_PREFIX = "https://gravatar.com/avatar/";
const GRAVATAR_QUERY_DEFAULT = "d=404";

export function getGravatarUrl(email: string, size = 40) {
    return `${GRAVATAR_PREFIX + MD5(email).toString()}?${GRAVATAR_QUERY_DEFAULT}&s=${size}`;
}
export function sendAccountActivationEmail() {
    return makeGet(API_URL.SEND_ACTIVATION_EMAIL(), null);
}

export function syncDevices(devices: string[]) {
    return makePost(API_URL.SYNC_DEVICES(), { devices });
}

export const getGeoLocation = ({ ip = "" }: { ip?: string }) => {
    const query = `?ip=${ip}`;
    return makeGet(API_URL.GET_LOCATION(query));
};

export function toggleTwoFactorAuth(enabled: any) {
    return makePost(API_URL.TOGGLE_TWO_FACTOR_AUTH(), { enabled });
}

export function regenerateTwoFactorAuthQr() {
    return makePost(API_URL.REGENERATE_TWO_FACTOR_AUTHENTICATION_QR(), {});
}

export function toggleHideAllTab(checked: boolean) {
    return makePatch(API_URL.USER_OPTIONS(), {
        msConfigs: {
            hideAllTab: checked
        }
    });
}
