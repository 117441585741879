import React from "react";
import { createStyles, Theme, WithStyles, withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
const styles = (theme: Theme) =>
    createStyles({
        root: {},

        switchBase: {
            "&$checked": {
                color: theme.palette.common.white,
                "& + $track": {
                    backgroundColor: "#52d869",
                    opacity: 1,
                    border: "none"
                }
            },
            "&$focusVisible $thumb": {
                color: "#52d869",
                border: "6px solid #fff"
            }
        },
        thumb: {},
        track: {
            border: `1px solid ${theme.palette.grey[400]}`,
            backgroundColor: theme.palette.grey[50],

            transition: theme.transitions.create(["background-color", "border"])
        },
        checked: {},
        focusVisible: {}
    });

const IOSSwitch = ({
    classes,
    checked,
    onChange,
    value
}: WithStyles<typeof styles> & {
    checked: boolean;
    onChange: () => void;
    value: string;
}) => {
    return (
        <Switch
            checked={checked}
            onChange={onChange}
            value={value}
            focusVisibleClassName={classes.focusVisible}
            disableRipple
            classes={{
                root: classes.root,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked
            }}
        />
    );
};
export default withStyles(styles)(IOSSwitch);
