import { FormControl, MenuItem, Select } from "@material-ui/core";
import React, { useState } from "react";
interface IPlaylistRendererProps {
    deviceId: string;
    playlistId: string;
    playlists: any[];
    updatePlaylistForDevice: (deviceId: string, playlistId: string) => void;
    mode: "grid" | "card";
}

const PlaylistRenderer = ({
    deviceId,
    playlistId,
    playlists,
    updatePlaylistForDevice,
    mode
}: IPlaylistRendererProps) => {
    const [open, setOpen] = useState<boolean>(false);

    function handlePlaylistSelectChange(event: React.ChangeEvent<{ name?: string; value: unknown }>) {
        updatePlaylistForDevice(deviceId, event.target.value as string);
    }

    function handlePlaylistSelectClose() {
        setOpen(false);
    }

    function handlePlaylistSelectDoubleClick() {
        if (mode === "grid") {
            setOpen(true);
        }
    }
    function handlePlaylistSelectClick() {
        if (mode === "card") {
            setOpen(true);
        }
    }
    return (
        <FormControl style={{ width: "100%" }}>
            <Select
                open={open}
                onOpen={handlePlaylistSelectClick}
                onClose={handlePlaylistSelectClose}
                value={playlistId}
                onChange={handlePlaylistSelectChange}
                inputProps={{
                    name: "age",
                    id: "demo-controlled-open-select"
                }}
                onDoubleClick={handlePlaylistSelectDoubleClick}
            >
                {playlists.map((p, i) => (
                    <MenuItem key={p._id} value={p._id}>
                        {p.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};
export default PlaylistRenderer;
