import React, { useState, Fragment } from "react";

import PasswordField from "./PasswordField";
import { Theme, WithStyles } from "@material-ui/core";
import createStyles from "@material-ui/core/styles/createStyles";
import withStyles from "@material-ui/core/styles/withStyles";
const styles = (theme: Theme) =>
    createStyles({
        contentWrap: {
            display: "flex",
            flexDirection: "row",
            alignItems: "stretch",
            width: "100%"
        },
        avatarWrap: {
            width: "100px"
        },
        infoWrap: {
            flex: "1 1 0%",
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
            justifyContent: "space-between"
        },
        column33: {
            flexBasis: "33.33%"
        },
        heading: {
            fontSize: theme.typography.pxToRem(15)
        },
        details: {
            alignItems: "center"
        },
        formControl: {
            marginTop: 8,
            marginBottom: 8
        }
    });

const ChangePasswordForm = ({
    isPasswordRequired,
    type,
    onEnter,
    onChange,
    error
}: WithStyles<typeof styles> & {
    isPasswordRequired: boolean;
    type: string;
    error: any;
    onEnter: () => void;
    onChange: (oldPassword: string, newPassword: string, confirmNewPassword: string) => void;
}) => {
    const [oldPassword, setOldPassword] = useState<string>("");
    const [newPassword, setNewPassword] = useState<string>("");
    const [confirmNewPassword, setConfirmPassword] = useState<string>("");
    const handleOldPasswordChange = (value: string) => {
        setOldPassword(value);
        if (onChange) {
            onChange(value, newPassword, confirmNewPassword);
        }
    };
    const handleNewPasswordChange = (value: string) => {
        setNewPassword(value);
        if (onChange) {
            onChange(oldPassword, value, confirmNewPassword);
        }
    };
    const handleConfirmNewPasswordChange = (value: string) => {
        setConfirmPassword(value);
        if (onChange) {
            onChange(oldPassword, newPassword, value);
        }
    };
    return (
        <Fragment>
            <PasswordField
                isPasswordRequired={isPasswordRequired}
                label="Old password"
                type={`${type}-old-password`}
                autoComplete="current-password"
                error={error.oldPassword}
                password={oldPassword}
                onChange={handleOldPasswordChange}
                onEnter={onEnter}
            />
            <PasswordField
                isPasswordRequired={isPasswordRequired}
                label="New password"
                type={`${type}-new-password`}
                autoComplete="new-password"
                error={error.newPassword}
                password={newPassword}
                onChange={handleNewPasswordChange}
                onEnter={onEnter}
            />
            <PasswordField
                isPasswordRequired={isPasswordRequired}
                label="Confirm new password"
                type={`${type}-confirm-new-password`}
                autoComplete="new-password"
                error={error.confirmNewPassword}
                password={confirmNewPassword}
                onChange={handleConfirmNewPasswordChange}
                onEnter={onEnter}
            />
        </Fragment>
    );
};

export default withStyles(styles)(ChangePasswordForm);
