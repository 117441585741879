import { Dialog, withStyles } from "@material-ui/core";
import createStyles from "@material-ui/core/styles/createStyles";
import { ClassNameMap } from "@material-ui/styles/withStyles";
import React from "react";
import ReactPlayer from "react-player";
const styles = () =>
    createStyles({
        imageWrap: {},
        image: {
            height: "auto",
            width: "100%"
        },
        vjsPlayer: {
            width: "100%",
            height: "auto",
            display: "flex"
        },
        wrapImage: {
            display: "flex"
        }
    });
/*imageWrap: {
  width: 500,
    height: 400,
    background: "#808080"
},
image: {
  width: "100%",
    height: "100%",
    objectFit: "contain",
    minWidth: 300,
    minHeight: 300,
    backgroundColor: "transparent"
},*/
const OpenMedia = ({
    classes,
    media,
    handleClose
}: {
    classes: Partial<ClassNameMap<string>>;
    handleClose: () => void;
    media?: any;
}) => {
    const handleOpenWebUrl = () => {
        window.open(media.url, "_blank");
        handleClose();
        return <div></div>;
    };
    const OpenVideo = () => {
        return (
            <div style={{ minWidth: 500, display: "flex" }}>
                <ReactPlayer
                    url={media.url}
                    controls={true}
                    width="100%"
                    height="auto"
                    style={{ display: "flex" }}
                    playing
                    config={{
                        file: {
                            attributes: {
                                disablepictureinpicture: "true",
                                controlsList: "nodownload",
                                onContextMenu: (e: { preventDefault: () => void }) => e.preventDefault()
                            }
                        }
                    }}
                />
            </div>
        );
    };
    function ImageOrWeb() {
        if (media.type === "image") {
            return (
                <div className={classes.wrapImage}>
                    <img className={classes.image} src={media.url} alt={media.name} />
                </div>
            );
        } else if (media.type === "web") {
            return handleOpenWebUrl();
        } else if (media.type === "video") {
            return OpenVideo();
        }
        handleClose();
        return <div>{}</div>;
    }
    return (
        <Dialog
            open={true}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            fullScreen={false}
            fullWidth={true}
        >
            <ImageOrWeb />
        </Dialog>
    );
};

export default withStyles(styles)(OpenMedia);
