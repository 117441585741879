import { BASE_DOMAIN } from "../constants/app";
import { makeDelete, makeGet, makePatch, makePost } from "./main-api";

const API_URL = {
    ADD_MEDIA: () => `${BASE_DOMAIN}/v1/media`,
    GET_MEDIAS: (query: string) => `${BASE_DOMAIN}/v1/media?${query}`,
    UPDATE_DELETE: (id: string) => `${BASE_DOMAIN}/v1/media/${id}`,
    UPDATE_MEDIA: (id: string, name: string, di: string) => `${BASE_DOMAIN}/v1/media/${id}`,
    CHANGE_MEDIAS_GROUP: (groupId: string) => `${BASE_DOMAIN}/v1/media/groups/${groupId}`,
    DELETE_MEDIAS: () => `${BASE_DOMAIN}/v1/media`
};

export const addMedia = (data: { media: any }) => {
    return makePost(API_URL.ADD_MEDIA(), data);
};

export const getMedias = ({
    searchValue,
    start = 0,
    limit = 20000,
    filters
}: {
    searchValue?: string;
    start?: number;
    limit?: number;
    filters?: { property: string; value?: string | string[] | boolean }[] | null;
}) => {
    let query = `start=${start}&limit=${limit}`;
    if (searchValue) {
        query += `&search=${searchValue}`;
    }
    if (filters && Array.isArray(filters) && filters.length) {
        for (let i = 0; i < filters.length; i++) {
            query += `&filters[${i}][property]=${filters[i].property}`;
            if (filters[i].value) {
                query += `&filters[${i}][value]=${filters[i].value}`;
            }
        }
    }
    return makeGet(API_URL.GET_MEDIAS(query));
};
export const deleteMedia = (id: string) => {
    return makeDelete(API_URL.UPDATE_DELETE(id));
};

export const deleteMedias = (medias: string[]) => {
    return makeDelete(API_URL.DELETE_MEDIAS(), medias);
};

export const updateMedia = (id: string, data: { media: any }) => {
    return makePatch(API_URL.UPDATE_DELETE(id), data);
};

export const changeMediasGroup = (groupId: string, medias: string[]) => {
    return makePost(API_URL.CHANGE_MEDIAS_GROUP(groupId), medias);
};
