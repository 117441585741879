import MD5 from "crypto-js/md5";

let loggedInUserHash: string | null = null;

export function getLoggedInUserIdHash() {
    return loggedInUserHash;
}

export function constructLoggedInUserIdHash(id: string) {
    loggedInUserHash = id ? MD5(id).toString() : null;
    return loggedInUserHash;
}
