import { Theme } from "@material-ui/core";
import React, { useContext, useState } from "react";

import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelActions from "@material-ui/core/ExpansionPanelActions";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import createStyles from "@material-ui/core/styles/createStyles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { patchUser } from "../../../../api/main-api";
import { AppDispatch, UserContext } from "../../../../context/main-context";
import { validateEmail } from "../../../../utils/validationsUtil";

const styles = (theme: Theme) =>
    createStyles({
        contentWrap: {
            display: "flex",
            flexDirection: "row",
            alignItems: "stretch",
            width: "100%"
        },
        avatarWrap: {
            width: "100px"
        },
        infoWrap: {
            flex: "1 1 0%",
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
            justifyContent: "space-between"
        },
        column33: {
            flexBasis: "33.33%"
        },
        heading: {
            fontSize: theme.typography.pxToRem(15)
        },
        details: {
            alignItems: "center"
        },
        formControl: {
            marginTop: 8,
            marginBottom: 8
        }
    });

const EmailOptions = ({
    classes,
    expanded,
    handleClose,
    handleExpansionChange,
    changeUserData
}: WithStyles<typeof styles> & {
    expanded: string | false;
    handleClose: () => void;
    handleExpansionChange: (panel: string) => (event: any, newExpand: boolean) => void;
    changeUserData?: any;
}) => {
    const userData = useContext(UserContext);
    const dispatch = useContext(AppDispatch)!;
    const [email, setEmail] = useState<string>(userData!.email);
    const [emailError, setEmailError] = useState<string>("");
    const handleEmailChange = (e: any) => {
        setEmail(e.target.value);
    };
    const handleSaveButtonClick = (e: any) => {
        if (validEmailOptions() && email !== userData!.email) {
            const objSend = userData;
            objSend!.email = email;
            patchUser(objSend)
                .then(response => {
                    // TODO
                })
                .catch(event => {
                    dispatch({ type: "SHOW_MESSAGE_ERROR", payload: event });
                });
        }
    };
    const validEmailOptions = (setToState = true) => {
        let errors = {
            emailError: ""
        };
        let isValid = true;
        const { isValid: isValidEmail, errors: emailErrors } = validateEmail(email);
        isValid = isValid && isValidEmail;
        errors = {
            ...errors,
            ...emailErrors
        };
        if (setToState && Object.keys(errors).length) {
            setEmailError(errors.emailError);
        }
        return isValid;
    };
    return (
        <ExpansionPanel expanded={expanded === "email"} onChange={handleExpansionChange("email")}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <div className={classes.column33}>
                    <Typography className={classes.heading}>Emails</Typography>
                </div>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.infoWrap}>
                <FormControl className={classes.formControl} required error={!!emailError}>
                    <InputLabel htmlFor="profile-primary-email-field">Primary Email</InputLabel>
                    <Input
                        id="profile-primary-email-field"
                        autoComplete="email"
                        value={email}
                        type="email"
                        onChange={handleEmailChange}
                    />
                    {emailError && <FormHelperText error>{emailError}</FormHelperText>}
                </FormControl>
            </ExpansionPanelDetails>
            <Divider />
            <ExpansionPanelActions>
                <Button size="small" onClick={handleClose}>
                    Cancel
                </Button>
                <Button size="small" color="primary" onClick={handleSaveButtonClick}>
                    Save
                </Button>
            </ExpansionPanelActions>
        </ExpansionPanel>
    );
};

export default withStyles(styles)(EmailOptions);
