import React, { useContext, useState } from "react";
import { AppDispatch, UserContext } from "../../context/main-context";
import MediaHeader from "./media-header";
import MediaBody from "./media-body";
import { useGroupsWithMeta, useGroupsState } from "../hooks/groups/groups-hooks";
import { getMediaGroups } from "../../api/media-group";

const Media = () => {
    const appDispatch = useContext(AppDispatch)!;
    const loggedInUser = useContext(UserContext)!;
    const { groupsState, groupsDispatch } = useGroupsState("activeMediaGroupId", loggedInUser);

    const { groupsWithMeta, selectedGroupId } = groupsState;
    useGroupsWithMeta(getMediaGroups, appDispatch, groupsDispatch, !!loggedInUser.options.hideAllTab);

    const [deleteMediasMode, setDeleteMediasMode] = useState(false);

    const [selectedMediaIds, setSelectedMediaIds] = useState<string[]>([]);
    const [reloadData, setReloadData] = useState<boolean>(false);

    function handleDeleteMediasIconClick() {
        setDeleteMediasMode(true);
    }

    //rendering

    if (!groupsWithMeta) {
        return null;
    }

    return (
        <div>
            <MediaHeader
                selectedGroupId={selectedGroupId}
                groupsDispatch={groupsDispatch}
                groupsWithMeta={groupsWithMeta}
                selectedMediaIds={selectedMediaIds}
                handleDeleteMediasIconClick={handleDeleteMediasIconClick}
                mediasGroupChanged={() => {
                    setReloadData(oldData => !oldData);
                    setSelectedMediaIds([]);
                }}
            />
            {selectedGroupId && (
                <MediaBody
                    selectedGroupId={selectedGroupId}
                    deleteMediasMode={deleteMediasMode}
                    setDeleteMediasMode={setDeleteMediasMode}
                    reloadData={reloadData}
                    setReloadData={setReloadData}
                    selectedMediaIds={selectedMediaIds}
                    setSelectedMediaIds={setSelectedMediaIds}
                />
            )}
        </div>
    );
};

export default Media;
