import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import withStyles from "@material-ui/core/styles/withStyles";
import createStyles from "@material-ui/core/styles/createStyles";
import { WithStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import { GREEN_COLOR, RED_COLOR } from "../../constants/style-constants";

const styles = createStyles({
    playlistActions: {
        display: "flex",
        justifyContent: "start"
    },
    addScheduleActionButton: {
        margin: 5,
        backgroundColor: GREEN_COLOR,
        color: "white"
    },
    deleteScheduleActionButton: {
        margin: 5,
        backgroundColor: RED_COLOR,
        color: "white"
    }
});

const PlaylistActions = (props: WithStyles<typeof styles> & RouteComponentProps) => {
    const { classes, history } = props;
    const createPlayList = () => {
        history.replace("/playlist/");
    };
    return (
        <div className={classes.playlistActions}>
            <Button variant="contained" className={classes.addScheduleActionButton} onClick={createPlayList}>
                <AddIcon />
                Add Playlist
            </Button>
        </div>
    );
};
export default withStyles(styles)(withRouter(PlaylistActions));
