import { Dialog, DialogActions, DialogContent, DialogTitle, Hidden, WithStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import MonitorOnIcon from "@material-ui/icons/LiveTv";
import RebootIcon from "@material-ui/icons/Refresh";
import MonitorOffIcon from "@material-ui/icons/TvOff";
import React, { useEffect, useState } from "react";
import { remoteAction } from "../../api/device";
const styles = () =>
    createStyles({
        presentationActionButton: {
            margin: 5,
            width: 170
        },
        mobileViewButtonsWrapper: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
        },
        iconBtn: {
            marginRight: 5
        }
    });

interface IRemoteControlProps {
    device: any;
    handleClose: () => void;
}

const RemoteControlDialog = ({ classes, device, handleClose }: WithStyles<typeof styles> & IRemoteControlProps) => {
    const [remoteActionName, setRemoteActionName] = useState<"monitor_on" | "monitor_off" | "reboot" | "">("");
    useEffect(() => {
        let canceled = false;
        if (remoteActionName) {
            remoteAction(device._id, remoteActionName)
                .then(() => {
                    if (!canceled) {
                        setRemoteActionName("");
                    }
                })
                .catch(() => {
                    if (!canceled) {
                        setRemoteActionName("");
                    }
                });
        }
        return () => {
            canceled = true;
        };
    }, [remoteActionName, device._id]);
    const onOnBtnClick = () => setRemoteActionName("monitor_on");
    const onOffBtnClick = () => setRemoteActionName("monitor_off");
    const onRebootBtnClick = () => setRemoteActionName("reboot");
    const dialogContent: React.ReactNode = (
        <>
            <DialogTitle id="form-dialog-title">Remote Contol {device.name}</DialogTitle>
            <DialogContent>
                <Hidden smDown>
                    <Button className={classes.presentationActionButton} variant="contained" onClick={onOnBtnClick}>
                        <MonitorOnIcon className={classes.iconBtn} />
                        Monitor On
                    </Button>
                    <Button className={classes.presentationActionButton} variant="contained" onClick={onOffBtnClick}>
                        <MonitorOffIcon className={classes.iconBtn} />
                        Monitor Off
                    </Button>
                    <Button className={classes.presentationActionButton} variant="contained" onClick={onRebootBtnClick}>
                        <RebootIcon className={classes.iconBtn} />
                        Reboot
                    </Button>
                </Hidden>
                <Hidden mdUp>
                    <div className={classes.mobileViewButtonsWrapper}>
                        <Button className={classes.presentationActionButton} variant="contained" onClick={onOnBtnClick}>
                            <MonitorOnIcon className={classes.iconBtn} />
                            Monitor On
                        </Button>
                        <Button
                            className={classes.presentationActionButton}
                            variant="contained"
                            onClick={onOffBtnClick}
                        >
                            <MonitorOffIcon className={classes.iconBtn} />
                            Monitor Off
                        </Button>
                        <Button
                            className={classes.presentationActionButton}
                            variant="contained"
                            onClick={onRebootBtnClick}
                        >
                            <RebootIcon className={classes.iconBtn} />
                            Reboot
                        </Button>
                    </div>
                </Hidden>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
            </DialogActions>
        </>
    );
    return (
        <>
            <Hidden smDown>
                <Dialog open onClose={handleClose} aria-labelledby="form-dialog-title">
                    {dialogContent}
                </Dialog>
            </Hidden>
            <Hidden mdUp>
                <Dialog open fullScreen onClose={handleClose} aria-labelledby="form-dialog-title">
                    {dialogContent}
                </Dialog>
            </Hidden>
        </>
    );
};

export default withStyles(styles)(RemoteControlDialog);
