import React, { useContext, useEffect, useState } from "react";
import { createStyles, WithStyles } from "@material-ui/core/styles";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelActions from "@material-ui/core/ExpansionPanelActions";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import withStyles from "@material-ui/core/styles/withStyles";
import AddIcon from "@material-ui/icons/AddCircleOutline";

import { IconButton } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import IOSSwitch from "./ios-switch";
import { updateDeviceConfig } from "../../../../api/device";
import WiFiDetails from "./wifi-details";
import { AppDispatch } from "../../../../context/main-context";
const styles = createStyles({
    column: {
        flex: 1
    },
    details: {
        flexDirection: "row"
    },
    enabledSwitch: {
        display: "flex"
    },
    heading: {},
    fieldset: {
        flexDirection: "column",
        display: "flex",
        flex: 1,
        border: "white"
    },
    form: {
        flexDirection: "column",
        display: "flex",
        flex: 1
    },
    add: { marginRight: 25 },
    EnableAddWrap: { flex: 1 },
    labelEnable: { marginLeft: 25 }
});

const WiFiPanel = ({
    classes,
    device,
    expanded,
    saveAction,
    handleChange
}: WithStyles<typeof styles> & {
    device: any;
    expanded: string | false;
    saveAction: () => void;
    handleChange: (panel: string) => (event: any, newExpand: boolean) => void;
}) => {
    const dispatch = useContext(AppDispatch)!;

    const [disable, setDisable] = useState<boolean>(false);
    const [hideDetailsWhileLoading, setHideDetailsWhileLoading] = useState<boolean>(true);
    const defaultValueWifi = {
        wifiName: "",
        wifiSecurity: "2",
        showPassword: false,
        wifiPassword: "",
        wifiIpAddress: { typeIp: "1", address: "", netMask: "", gateway: "", dns: "" },
        checkHiddenNetwork: false,
        disable: disable
    };
    const [wifiValue, setWifiValue] = useState<any[]>([defaultValueWifi]);

    useEffect(() => {
        (async () => {
            try {
                setHideDetailsWhileLoading(false);
                if (device.data.config.wifiValue) {
                    setWifiValue(JSON.parse(JSON.stringify(device.data.config.wifiValue)));
                }
            } catch (e) {
                console.log(e.message);
            }
        })();
    }, [device]);
    const onChangeDisabledGlobal = () => {
        const d = wifiValue;

        for (const i of d) {
            i.disable = !disable;
        }
        setWifiValue([...d]);
        setDisable(!disable);
    };
    const onDataChange = () => {
        setWifiValue([...wifiValue]);
    };

    const handleAddWifi = () => {
        setWifiValue([...wifiValue, { ...defaultValueWifi }]);
    };
    const onDeleteWifi = (ind: any) => () => {
        if (wifiValue.length > 1) {
            const l = wifiValue;
            l.splice(ind, 1);
            setWifiValue([...l]);
        }
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();

        const dat: any = {
            ...device.data.config,
            wifiValue
        };
        (async () => {
            try {
                await updateDeviceConfig(device.data.deviceId, dat).then(() => {
                    dispatch({
                        type: "SHOW_MESSAGE_SUCCESS",
                        payload: "Wifi configuration updated successfully"
                    });
                });

                saveAction();
            } catch (e) {
                dispatch({ type: "SHOW_MESSAGE_ERROR", payload: e });
            }
        })();
    };
    const handleCancelClick = (e: any) => {
        setWifiValue(JSON.parse(JSON.stringify(device.data.config.wifiValue)));
    };

    return (
        <ExpansionPanel square expanded={expanded === "panel1"} onChange={handleChange("panel1")}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1c-content" id="panel1c-header">
                <div className={classes.column}>
                    <Typography className={classes.heading}>Wi-Fi</Typography>
                </div>
            </ExpansionPanelSummary>
            <div className={classes.enabledSwitch}>
                <FormControlLabel
                    label="Enable"
                    className={classes.labelEnable}
                    control={<IOSSwitch checked={disable} onChange={onChangeDisabledGlobal} value="checkE" />}
                />
                <div className={classes.EnableAddWrap}></div>
                <IconButton className={classes.add} onClick={handleAddWifi}>
                    <AddIcon />
                </IconButton>
            </div>

            {!hideDetailsWhileLoading
                ? wifiValue.map((f, index) => (
                      <WiFiDetails
                          key={index}
                          data={f}
                          disable={f.disable}
                          onDataChange={onDataChange}
                          onDeleteWifi={onDeleteWifi(index)}
                          device={f.device}
                          index={index}
                      />
                  ))
                : ""}

            <Divider />
            <ExpansionPanelActions>
                <Button size="small" onClick={handleCancelClick}>
                    Cancel
                </Button>
                <Button size="small" onClick={handleSubmit} color="primary">
                    Save
                </Button>
            </ExpansionPanelActions>
        </ExpansionPanel>
    );
};

export default withStyles(styles)(WiFiPanel);
