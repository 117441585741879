import { IconButton, Theme, withStyles } from "@material-ui/core";
import Hidden from "@material-ui/core/Hidden";
import createStyles from "@material-ui/core/styles/createStyles";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import React from "react";

const styles = (theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            overflowY: "auto"
        },
        table: {
            minWidth: 1020
        },
        tableWrapper: {
            overflowX: "auto"
        },
        deviceOrTunnelsLength: {
            width: 50,
            fontWeight: 800,
            float: "left",
            paddingTop: 15
        },
        pagingActionsStyles: {
            flexShrink: 0,
            color: theme.palette.text.secondary,
            marginLeft: 5 * 2.5
        },
        pagingActionsMobileStyles: {
            flexShrink: 0,
            color: theme.palette.text.secondary,
            marginLeft: 2.5
        }
    });

// tslint:disable-next-line:no-shadowed-variable
export const TablePaginationActions = withStyles(styles, { withTheme: true })(function TablePaginationActions({
    classes,
    count,
    page,
    rowsPerPage,
    onChangePage
}: any) {
    const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onChangePage(event, 0);
    };
    const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onChangePage(event, page - 1);
    };
    const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onChangePage(event, page + 1);
    };
    const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <>
            <Hidden mdUp>
                <div className={classes.pagingActionsMobileStyles}>
                    <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="First Page">
                        <FirstPageIcon />
                    </IconButton>
                    <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="Previous Page">
                        <KeyboardArrowLeft />
                    </IconButton>
                    <IconButton
                        onClick={handleNextButtonClick}
                        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                        aria-label="Next Page"
                    >
                        <KeyboardArrowRight />
                    </IconButton>
                    <IconButton
                        onClick={handleLastPageButtonClick}
                        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                        aria-label="Last Page"
                    >
                        <LastPageIcon />
                    </IconButton>
                </div>
            </Hidden>
            <Hidden smDown>
                <div className={classes.pagingActionsStyles}>
                    <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="First Page">
                        <FirstPageIcon />
                    </IconButton>
                    <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="Previous Page">
                        <KeyboardArrowLeft />
                    </IconButton>
                    <IconButton
                        onClick={handleNextButtonClick}
                        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                        aria-label="Next Page"
                    >
                        <KeyboardArrowRight />
                    </IconButton>
                    <IconButton
                        onClick={handleLastPageButtonClick}
                        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                        aria-label="Last Page"
                    >
                        <LastPageIcon />
                    </IconButton>
                </div>
            </Hidden>
        </>
    );
});
