import { WithStyles } from "@material-ui/core";
import Hidden from "@material-ui/core/Hidden";
import createStyles from "@material-ui/core/styles/createStyles";
import withStyles from "@material-ui/core/styles/withStyles";
import React from "react";

import PlaylistActions from "./playlist-actions";
import PlaylistsGrid from "./playlists-grid";
import { IGroupsWithMeta } from "../../interfaces/dto";

const styles = createStyles({
    playlists: {
        display: "flex",
        flexDirection: "column",
        height: "calc(100vh - 176px)",
        padding: 20
    },
    playlistsGrid: {
        flex: 1,
        overflow: "hidden"
    }
});

export interface PlaylistsBodyProps {
    selectedGroupId: string;
    groupsWithMeta: IGroupsWithMeta;
}

const PlaylistsBody = (props: WithStyles<typeof styles> & PlaylistsBodyProps) => {
    const { classes, ...rest } = props;

    return (
        <div className={classes.playlists}>
            <div className={classes.playlistsGrid}>
                <Hidden smDown>
                    <PlaylistsGrid {...rest} isSmallViewport={false} />
                </Hidden>
                <Hidden mdUp>
                    <PlaylistsGrid {...rest} isSmallViewport={true} />
                </Hidden>
            </div>
            <div>
                <PlaylistActions />
            </div>
        </div>
    );
};

export default withStyles(styles)(PlaylistsBody);
