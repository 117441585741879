import { BASE_DOMAIN } from "../constants/app";
import { makeDelete, makeGet, makePost } from "./main-api";

const API_URL = {
    ADD_ORG_USER: () => `${BASE_DOMAIN}/v1/users/organization`,
    GET_ORG_USERS: () => `${BASE_DOMAIN}/v1/organization/users`,
    UPDATE_ORG_USERS_ACCESS: () => `${BASE_DOMAIN}/v1/organization/access`
};

export function inviteOrgUser(data: any) {
    return makePost(API_URL.ADD_ORG_USER(), data);
}

export function getOrganizationUser() {
    return makeGet(API_URL.GET_ORG_USERS());
}

export function deleteOrganizationUser(email: string) {
    return makeDelete(API_URL.GET_ORG_USERS(), { email });
}
export function updateOrgUserAccess(data: any) {
    return makePost(API_URL.UPDATE_ORG_USERS_ACCESS(), data);
}
