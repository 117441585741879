import { Menu, WithStyles } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import createStyles from "@material-ui/core/styles/createStyles";
import withStyles from "@material-ui/core/styles/withStyles";
import Tooltip from "@material-ui/core/Tooltip";
import {
    DateRange as DateRangeIcon,
    PlaylistPlay as PlaylistPlayIcon,
    SwapHoriz as AssignToGroupIcon,
    Sync as SyncIcon
} from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { TableCellDataGetterParams, TableCellProps } from "react-virtualized";
import { bulkAssignToDevices, updateDevice } from "../../../api/device";
import { getDevicesStatuses, syncDevices } from "../../../api/main-api";
import { getPlayList } from "../../../api/play-list";
import { getSchedules } from "../../../api/schedule";
import { warn } from "../../../utils/console-logs";
import useDevicesWithStatuses from "../../hooks/devices";
import { updateStatusesOfDevices } from "../../hooks/statuses";
import Grid, { IGridColumn, IGridDataItem } from "../../shared/grid/grid";
import Presentation from "../presentation";
import RemoteControl from "../remote-contol";
import ActionsRenderer from "../renderers/actions";
import PlaylistRenderer from "../renderers/playlist";
import ScheduleRenderer from "../renderers/schedule";
import StatusRenderer from "../renderers/status";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import FormControl from "@material-ui/core/FormControl";

const styles = createStyles({
    gridDefaultContextRenderer: {
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis"
    },
    statusIconsWrapper: {
        display: "flex",
        alignItems: "center"
    },
    orderByCmp: {
        fontSize: 14
    }
});
export interface IDeviceGridProp extends WithStyles<typeof styles>, RouteComponentProps {
    groups: { name: string; _id: string }[];
    activeGroup?: string | null;
}
const DevicesGrid = ({ classes, history, groups, activeGroup }: IDeviceGridProp) => {
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(10);
    // const [count, setCount] = useState<number>(30);
    const [searchValue, setSearchValue] = useState<string>("");
    const [reloadData, setReloadData] = useState<boolean>(false);
    const { devices, setDevices, isDeviceLoading } = useDevicesWithStatuses(searchValue, activeGroup, reloadData);
    const [playlists, setPlaylists] = useState<any[]>([]);
    const [schedules, setSchedules] = useState<any[]>([]);
    const [assignMenuAnchorEl, setAssignMenuAnchorEl] = useState<null | HTMLElement>(null);
    const [selectedRows, setSelectedRows] = useState<IGridDataItem[]>([]);
    const [bulkMode, setBulkMode] = useState<"playlist" | "schedule" | "group" | null>(null);
    const [selectedPlaylistId, setSelectedPlaylistId] = useState<string | undefined>(undefined);
    const [selectedScheduleId, setSelectedScheduleId] = useState<string | undefined>(undefined);
    const [selectedGroupId, setSelectedGroupId] = useState<string | undefined>(undefined);
    const [doSyncDevices, setDoSyncDevices] = useState<boolean>(false);
    const [deviceForPresentationMode, setDeviceForPresentationMode] = useState<any>(null);
    const [deviceForRemote, setDeviceForRemote] = useState<any>(null);
    const [updatePlaylistsAndSchedules, setUpdatePlaylistsAndSchedules] = useState<boolean>(false);

    const [orderedDevices, setOrderedDevices] = useState(devices);
    const [orderDirections, setOrderDirections] = useState<{ [key: string]: "ASC" | "DESC" }>({
        name: "DESC",
        status: "DESC",
        originUptime: "DESC"
    });
    const [currentOrderInfo, setCurrentOrderInfo] = useState<{ dataKey: string; direction: string } | null>(null);
    const [statusOrderBy, setStatusOrderBy] = useState<"status" | "syncStatus" | "wifiSpeed" | "usedMem">("status");

    const statusOrderByItems: { key: string; value: string }[] = [
        {
            key: "Status",
            value: "status"
        },
        {
            key: "Sync status",
            value: "syncStatus"
        },
        {
            key: "Wifi speed",
            value: "wifiSpeed"
        },
        {
            key: "Used memory",
            value: "usedMem"
        }
    ];

    const statusOrderByComparatorsMap: { [key: string]: any[] } = {
        status: ["online", "away", "offline"],
        syncStatus: ["synced", "syncing", "out of sync", "error"],
        wifiSpeed: ["fast", "medium", "slow"],
        usedMem: []
    };

    const updateOrderInfo = (dataKey: string) => {
        setCurrentOrderInfo({ dataKey, direction: orderDirections[dataKey] });
        if (orderDirections[dataKey] === "DESC") {
            setOrderDirections({
                ...orderDirections,
                [dataKey]: "ASC"
            });
        } else {
            setOrderDirections({
                ...orderDirections,
                [dataKey]: "DESC"
            });
        }
    };

    const doDefaultOrder = (order: { dataKey: string; direction: string }) => {
        const d = [...devices];
        const { dataKey, direction } = order;
        const result = d.sort((a: any, b: any) => ("" + a[dataKey]).localeCompare(b[dataKey]));
        if (direction === "ASC") {
            result.reverse();
        }
        setOrderedDevices(result);
    };

    const doUptimeOrder = (order: { dataKey: string; direction: string }) => {
        const d = [...devices];
        const { dataKey, direction } = order;
        const result = d.sort((a, b) => a[dataKey] - b[dataKey]);
        if (direction === "ASC") {
            result.reverse();
        }
        setOrderedDevices(result);
    };

    const doStatusOrder = (order: { dataKey: string; direction: string }) => {
        const d = [...devices];
        const { direction } = order;
        let result: any[] = [];
        if (!statusOrderByComparatorsMap[statusOrderBy].length) {
            result = d.sort((a, b) => a[statusOrderBy] - b[statusOrderBy]);
        } else {
            for (const key of statusOrderByComparatorsMap[statusOrderBy]) {
                result = result.concat(
                    d.filter(device => device[statusOrderBy] && device[statusOrderBy].toLowerCase() === key)
                );
            }
            result = result.concat(
                d.filter(
                    device =>
                        !device[statusOrderBy] ||
                        !statusOrderByComparatorsMap[statusOrderBy].includes(device[statusOrderBy].toLowerCase())
                )
            );
        }
        if (direction === "ASC") {
            result.reverse();
        }
        setOrderedDevices(result);
    };

    const orderFunctionsMap: { [key: string]: (order: { dataKey: string; direction: string }) => void } = {
        name: doDefaultOrder,
        status: doStatusOrder,
        originUptime: doUptimeOrder
    };

    useEffect(() => {
        if (!currentOrderInfo) {
            setOrderedDevices(devices);
        } else {
            const { dataKey } = currentOrderInfo;
            if (orderFunctionsMap[dataKey]) orderFunctionsMap[dataKey](currentOrderInfo);
        }
    }, [devices, currentOrderInfo]);

    useEffect(() => {
        setSelectedRows([]);
    }, [activeGroup]);

    useEffect(() => {
        let canceled = false;
        let tId: any;
        (async () => {
            let playlistData = [];
            let scheduletData = [];
            try {
                playlistData = await getPlayList({ limit: 1000 });
                playlistData = playlistData.data.data || [];
                scheduletData = await getSchedules({ limit: 1000 });
                scheduletData = scheduletData.data.data || [];
            } catch (e) {
                playlistData = [];
                scheduletData = [];
            }
            if (!canceled) {
                setPlaylists(playlistData);
                setSchedules(scheduletData);
            }
            tId = setTimeout(() => {
                if (!canceled) {
                    setUpdatePlaylistsAndSchedules(!updatePlaylistsAndSchedules);
                }
            }, 60000);
        })();
        return () => {
            if (tId) {
                clearTimeout(tId);
            }
            canceled = true;
        };
    }, [updatePlaylistsAndSchedules]);

    useEffect(() => {
        let canceled = false;
        if (selectedPlaylistId && selectedRows.length !== 0) {
            const doBulkAssignPlaylistToDevices = () =>
                new Promise((resolve, reject) => {
                    if (selectedRows.length === 0) {
                        resolve(true);
                    }
                    bulkAssignToDevices({
                        devices: selectedRows.map((row: IGridDataItem) => row.id),
                        playListId: selectedPlaylistId
                    })
                        .then(d => {
                            resolve(d);
                        })
                        .catch(e => {
                            reject(e);
                        });
                });
            doBulkAssignPlaylistToDevices()
                .then(() => {
                    if (!canceled) {
                        setReloadData(oldState => !oldState);
                        setSelectedPlaylistId(undefined);
                    }
                })
                .catch(() => {
                    setSelectedPlaylistId(undefined);
                });
        }
        return () => {
            canceled = true;
        };
    }, [selectedPlaylistId, selectedRows]);

    useEffect(() => {
        let canceled = false;
        if (selectedScheduleId && selectedRows.length !== 0) {
            const doBulkAssignScheduleToDevices = () =>
                new Promise((resolve, reject) => {
                    if (selectedRows.length === 0) {
                        resolve(true);
                    }
                    bulkAssignToDevices({
                        devices: selectedRows.map((row: IGridDataItem) => row.id),
                        scheduleId: selectedScheduleId
                    })
                        .then(d => {
                            resolve(d);
                        })
                        .catch(e => {
                            reject(e);
                        });
                });
            doBulkAssignScheduleToDevices()
                .then(() => {
                    if (!canceled) {
                        setReloadData(oldState => !oldState);
                        setSelectedScheduleId(undefined);
                    }
                })
                .catch(() => {
                    setSelectedScheduleId(undefined);
                });
        }
        return () => {
            canceled = true;
        };
    }, [selectedScheduleId, selectedRows]);

    useEffect(() => {
        let canceled = false;
        if (selectedGroupId && selectedRows.length !== 0) {
            const doBulkAssignToGroupDevices = () =>
                new Promise((resolve, reject) => {
                    if (selectedRows.length === 0) {
                        resolve(true);
                    }
                    bulkAssignToDevices({
                        devices: selectedRows.map((row: IGridDataItem) => row.id),
                        groupId: selectedGroupId
                    })
                        .then(d => {
                            resolve(d);
                        })
                        .catch(e => {
                            reject(e);
                        });
                });
            doBulkAssignToGroupDevices()
                .then(() => {
                    if (!canceled) {
                        setReloadData(oldState => !oldState);
                        setSelectedGroupId(undefined);
                    }
                })
                .catch(() => {
                    setSelectedGroupId(undefined);
                });
        }
        return () => {
            canceled = true;
        };
    }, [selectedGroupId, selectedRows]);

    useEffect(() => {
        let canceled = false;
        if (doSyncDevices) {
            const bulkSyncDevices = () =>
                new Promise((resolve, reject) => {
                    if (selectedRows.length === 0) {
                        resolve(true);
                    }
                    syncDevices(selectedRows.map((row: IGridDataItem) => row.id))
                        .then(d => {
                            resolve(d);
                        })
                        .catch(e => {
                            reject(e);
                        });
                });
            bulkSyncDevices()
                .then(() => {
                    if (!canceled) {
                        setReloadData(oldState => !oldState);
                        setDoSyncDevices(false);
                    }
                })
                .catch(() => {
                    setDoSyncDevices(false);
                });
        }
        return () => {
            canceled = true;
        };
    }, [doSyncDevices, selectedRows]);

    const searchValueChange = (value: string) => {
        setPage(0);
        setSearchValue(value);
    };

    const handelEditClick = (id: string) => {
        history.replace(`/device/${id}`);
    };
    const onPresentationClick = (id: string) => {
        const device = devices.find((d: any) => d._id === id);
        setDeviceForPresentationMode(device);
    };
    const onRemoteControlClick = (id: string) => {
        const device = devices.find((d: any) => d._id === id);
        setDeviceForRemote(device);
    };
    const onChangePage = (e: React.MouseEvent<HTMLButtonElement> | null, p: number) => {
        setPage(p);
    };
    const onChangeRowsPerPage = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value));
    };
    const handleSyncClick = (id: string) => {
        syncDevices([id]).then(() =>
            getDevicesStatuses().then(res => setDevices(updateStatusesOfDevices(res.data, devices)))
        );
    };
    const gridStatusContextRenderer: (cellRendererProps: TableCellProps) => React.ReactNode = contextProps => {
        const device = devices.filter((d: { _id: any }) => d._id === contextProps.rowData._id)[0];
        const data = (
            <div className={`${classes.gridDefaultContextRenderer} ${classes.statusIconsWrapper}`}>
                <StatusRenderer device={device} mode="grid" />
            </div>
        );
        return data;
    };
    const gridActionsContextRenderer: (cellRendererProps: TableCellProps) => React.ReactNode = contextProps => {
        const { presentationPlayListId, playlistId, scheduleId } = contextProps.rowData;
        const presentationPlaylist = presentationPlayListId && playlists.find(p => p._id === presentationPlayListId);
        const playlist = playlistId && playlists.find(p => p._id === playlistId);
        const schedule = scheduleId && schedules.find(s => s._id === scheduleId);
        const presentationPlaylistTranscodingStatus = presentationPlaylist && presentationPlaylist.transcodingStatus;
        const playlistTranscodingStatus = playlist && playlist.transcodingStatus;
        const scheduleTranscodingStatus = schedule && schedule.transcodingStatus;
        let syncDisabled = false;
        if (
            !syncDisabled &&
            presentationPlaylistTranscodingStatus &&
            (presentationPlaylistTranscodingStatus.length > 1 ||
                (presentationPlaylistTranscodingStatus.length === 1 &&
                    presentationPlaylistTranscodingStatus[0] !== "Complete"))
        ) {
            syncDisabled = true;
        }
        if (
            !syncDisabled &&
            playlistTranscodingStatus &&
            (playlistTranscodingStatus.length > 1 ||
                (playlistTranscodingStatus.length === 1 && playlistTranscodingStatus[0] !== "Complete"))
        ) {
            syncDisabled = true;
        }
        if (
            !syncDisabled &&
            scheduleTranscodingStatus &&
            (scheduleTranscodingStatus.length > 1 ||
                (scheduleTranscodingStatus.length === 1 && scheduleTranscodingStatus[0] !== "Complete"))
        ) {
            syncDisabled = true;
        }
        const data = (
            <div className={classes.gridDefaultContextRenderer}>
                <ActionsRenderer
                    deviceId={contextProps.rowData._id}
                    handleSyncClick={handleSyncClick}
                    handelEditClick={handelEditClick}
                    onPresentationClick={onPresentationClick}
                    presentationPlayListId={presentationPlayListId}
                    onRemoteControlClick={onRemoteControlClick}
                    syncDisabled={syncDisabled}
                />
            </div>
        );
        return data;
    };
    const updatePlaylistForDevice = (deviceId: string, playlistId: string) => {
        const device = devices.find((d: any) => d._id === deviceId);
        updateDevice({ ...device, playlistId })
            .then(() => setReloadData(!reloadData))
            // tslint:disable-next-line:no-empty
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            .catch(() => {});
    };
    const updateScheduleForDevice = (deviceId: string, scheduleId: string) => {
        const device = devices.find((d: any) => d._id === deviceId);
        const id = scheduleId === "none" ? null : scheduleId;
        updateDevice({ ...device, scheduleId: id })
            .then(() => setReloadData(!reloadData))
            // tslint:disable-next-line:no-empty
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            .catch(() => {});
    };

    const gridPlaylistContextRenderer: (cellRendererProps: TableCellProps) => React.ReactNode = contextProps => {
        return (
            <div className={classes.gridDefaultContextRenderer} style={{ width: "100%" }}>
                <PlaylistRenderer
                    deviceId={contextProps.rowData._id}
                    playlistId={contextProps.cellData}
                    playlists={playlists}
                    updatePlaylistForDevice={updatePlaylistForDevice}
                    mode="grid"
                />
            </div>
        );
    };
    const gridScheduleContextRenderer: (cellRendererProps: TableCellProps) => React.ReactNode = contextProps => {
        return (
            <div className={classes.gridDefaultContextRenderer} style={{ width: "100%" }}>
                <ScheduleRenderer
                    deviceId={contextProps.rowData._id}
                    scheduleId={contextProps.cellData}
                    schedules={schedules}
                    updateScheduleForDevice={updateScheduleForDevice}
                    mode="grid"
                />
            </div>
        );
    };
    const gridDefaultContextRenderer: (cellRendererProps: TableCellProps) => React.ReactNode = contextProps => {
        const data = <div className={classes.gridDefaultContextRenderer}>{contextProps.cellData}</div>;
        if (window.innerWidth < 768) {
            return (
                <Tooltip title={contextProps.cellData || ""} placement="bottom-end">
                    {data}
                </Tooltip>
            );
        }
        return data;
    };

    const handleChangeStatusOrderByClick = (e: any) => {
        setStatusOrderBy(e.target.value);
        updateOrderInfo("status");
    };

    const _getStatusOrderByCmp = () => (
        <FormControl>
            <Select className={classes.orderByCmp} value={statusOrderBy} onChange={handleChangeStatusOrderByClick}>
                {statusOrderByItems.map(item => (
                    <MenuItem key={item.value} value={item.value}>
                        {item.key}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );

    const columns: IGridColumn[] = [
        {
            dataKey: "name",
            width: 300,
            label: "Name",
            flexGrow: 1,
            flexShrink: 1,
            sortDirection: orderDirections["name"],
            sortFn: () => updateOrderInfo("name"),
            cellContentRenderer: gridDefaultContextRenderer
        },
        {
            dataKey: "status",
            width: 250,
            label: "Status",
            orderByCmp: _getStatusOrderByCmp(),
            flexGrow: 1,
            flexShrink: 1,
            sortDirection: orderDirections["status"],
            sortFn: () => updateOrderInfo("status"),
            cellContentRenderer: gridStatusContextRenderer
        },
        {
            dataKey: "uptime",
            width: 150,
            label: "Uptime",
            flexGrow: 1,
            flexShrink: 1,
            sortDirection: orderDirections["originUptime"],
            sortFn: () => updateOrderInfo("originUptime"),
            cellContentRenderer: gridDefaultContextRenderer
        },
        {
            dataKey: "playlistId",
            width: 200,
            label: "Playlist",
            flexGrow: 1,
            flexShrink: 1,
            cellContentRenderer: gridPlaylistContextRenderer,
            cellDataGetter: ({ dataKey, rowData }: TableCellDataGetterParams) => {
                const cellData = rowData[dataKey];
                const playlist = playlists.find(p => p._id === cellData);
                if (process.env.NODE_ENV === "development") {
                    if (!playlist && playlists.length && cellData) {
                        warn(
                            "Wrong backend data, no playlist for id: " +
                                cellData +
                                " in playlists " +
                                playlists.map(p => JSON.stringify(p, undefined, 2)).join(", ") +
                                "This is a development only message. It will be removed in production builds."
                        );
                    }
                }
                return playlist ? playlist._id : "N/A";
            }
        },
        {
            dataKey: "scheduleId",
            width: 200,
            label: "Schedule",
            flexGrow: 1,
            flexShrink: 1,
            cellContentRenderer: gridScheduleContextRenderer,
            cellDataGetter: ({ dataKey, rowData }: TableCellDataGetterParams) => {
                const cellData = rowData[dataKey];
                const schedule = schedules.find(s => s._id === cellData);
                if (process.env.NODE_ENV === "development") {
                    if (!schedule && schedules.length && cellData) {
                        warn(
                            "Wrong backend data, no playlist for id: " +
                                cellData +
                                " in playlists " +
                                schedules.map(s => JSON.stringify(s, undefined, 2)).join(", ") +
                                "This is a development only message. It will be removed in production builds."
                        );
                    }
                }
                return schedule ? schedule._id : "none";
            }
        },
        {
            dataKey: "actions",
            width: 225,
            label: "Actions",
            flexGrow: 1,
            flexShrink: 0,
            cellContentRenderer: gridActionsContextRenderer
        }
    ];

    const handleAssignPlaylistIconClick = (event: React.MouseEvent<HTMLElement>) => {
        setBulkMode("playlist");
        setAssignMenuAnchorEl(event.currentTarget);
    };

    const handleAssignScheduleIconClick = (event: React.MouseEvent<HTMLElement>) => {
        setBulkMode("schedule");
        setAssignMenuAnchorEl(event.currentTarget);
    };

    const handleAssignToGroupIconClick = (event: React.MouseEvent<HTMLElement>) => {
        setBulkMode("group");
        setAssignMenuAnchorEl(event.currentTarget);
    };

    const handleSyncDevicesClick = () => {
        setDoSyncDevices(true);
    };

    const handleAssignMenuClose = () => {
        setAssignMenuAnchorEl(null);
        setBulkMode(null);
    };

    const assignToGroup = (groupId: string) => {
        handleAssignMenuClose();
        setSelectedGroupId(groupId);
    };

    const assignPlaylistToDevices = (playListId: string) => {
        handleAssignMenuClose();
        setSelectedPlaylistId(playListId);
    };

    const assignScheduleToDevices = (scheduleId: string) => {
        handleAssignMenuClose();
        setSelectedScheduleId(scheduleId);
    };

    const getSelectedGridToolbarElements = () => {
        const availableGroups = groups.filter(group => group._id !== activeGroup);
        if (!availableGroups.length) {
            return <></>;
        }
        const selectedGridToolbarElements: React.ReactNode = (
            <>
                <Tooltip title="Assign playlist">
                    <IconButton aria-label="Assign playlist" onClick={handleAssignPlaylistIconClick}>
                        <PlaylistPlayIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Assign schedule">
                    <IconButton aria-label="Assign schedule" onClick={handleAssignScheduleIconClick}>
                        <DateRangeIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Sync">
                    <IconButton aria-label="Sync" onClick={handleSyncDevicesClick}>
                        <SyncIcon className="rotated" />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Assign to group">
                    <IconButton aria-label="Assign To Group" onClick={handleAssignToGroupIconClick}>
                        <AssignToGroupIcon />
                    </IconButton>
                </Tooltip>
                <Menu
                    id="groups-to-assign"
                    anchorEl={assignMenuAnchorEl}
                    keepMounted
                    open={!!assignMenuAnchorEl}
                    onClose={handleAssignMenuClose}
                >
                    {bulkMode === "group" &&
                        availableGroups.map(group => (
                            <MenuItem key={group._id} onClick={() => assignToGroup(group._id)}>
                                {group.name}
                            </MenuItem>
                        ))}
                    {bulkMode === "schedule" &&
                        schedules.map(s => (
                            <MenuItem key={s._id} onClick={() => assignScheduleToDevices(s._id)}>
                                {s.name}
                            </MenuItem>
                        ))}
                    {bulkMode === "playlist" &&
                        playlists.map(pl => (
                            <MenuItem key={pl._id} onClick={() => assignPlaylistToDevices(pl._id)}>
                                {pl.name}
                            </MenuItem>
                        ))}
                </Menu>
            </>
        );
        return selectedGridToolbarElements;
    };

    const rowSelectionChange = (selectedRowsFromGrid: IGridDataItem[]) => {
        setSelectedRows(selectedRowsFromGrid);
    };
    const handleClosePresentation = () => {
        setDeviceForPresentationMode(null);
    };
    const handleCloseRemote = () => {
        setDeviceForRemote(null);
    };
    return (
        <div style={{ height: "calc(100vh - 150px)", overflow: "hidden" }}>
            <Grid
                enablePagination
                page={page}
                onChangePage={onChangePage}
                onChangeRowsPerPage={onChangeRowsPerPage}
                rowsPerPage={rowsPerPage}
                searchValueChange={searchValueChange}
                rowsPerPageOptions={[10, 25, 50]}
                showCheckBoxes
                data={orderedDevices.map((d: any) => ({ ...d, id: d._id }))}
                columns={columns}
                // allDataCount={count}
                allDataCount={30}
                gridToolbarTitle="Devices"
                isDataLoading={isDeviceLoading}
                selectedGridToolbarElements={getSelectedGridToolbarElements()}
                rowSelectionChange={rowSelectionChange}
                selected={selectedRows}
            />
            {!!deviceForPresentationMode && (
                <Presentation
                    device={deviceForPresentationMode}
                    playlist={playlists.find((p: any) => p._id === deviceForPresentationMode.presentationPlayListId)}
                    handleClose={handleClosePresentation}
                />
            )}
            {!!deviceForRemote && <RemoteControl device={deviceForRemote} handleClose={handleCloseRemote} />}
        </div>
    );
};

export default withStyles(styles)(withRouter(DevicesGrid));
