import React, { useContext, useEffect, useState } from "react";
import { v1 } from "uuid";
import { WithStyles } from "@material-ui/core";
import createStyles from "@material-ui/core/styles/createStyles";
import withStyles from "@material-ui/core/styles/withStyles";
import { addMedia, deleteMedias, getMedias, updateMedia } from "../../api/media";
import { AppDispatch, LoaderUpdateContext } from "../../context/main-context";
import { IMasonryItem, IMasonryMenuItem } from "../../interfaces/daos";
import AlertDialog from "../shared/alert-delete-dialog";

import Gallery from "../shared/masonry/masonry";
import MediaActions from "./media-actions";
import MediaAddDialog from "./media-add-dialog";
import OpenMedia from "./openMedia";
import { uploadFile, IUploadFileCallbacks } from "../../utils/chunk-upload";
import { BASE_DOMAIN_UPLOAD } from "../../constants/app";
import { getBearer } from "../../api/main-api";

const styles = createStyles({
    media: {
        display: "flex",
        padding: 20,
        flexDirection: "column",
        height: "calc(100vh - 176px)",
        "@media (max-width: 600px)": {
            padding: 5
        }
    },
    mediaGrid: {
        flex: 1,
        overflow: "auto",
        overflowScrolling: "touch",
        WebkitOverflowScrolling: "touch"
    }
});

export interface MediaBodyProps {
    selectedGroupId: string;
    deleteMediasMode: boolean;
    setDeleteMediasMode: React.Dispatch<React.SetStateAction<boolean>>;
    reloadData: boolean;
    setReloadData: React.Dispatch<React.SetStateAction<boolean>>;
    selectedMediaIds: string[];
    setSelectedMediaIds: React.Dispatch<React.SetStateAction<string[]>>;
}

const MediaBody = (props: WithStyles<typeof styles> & MediaBodyProps) => {
    const {
        classes,
        selectedGroupId,
        deleteMediasMode,
        setDeleteMediasMode,
        reloadData,
        setReloadData,
        selectedMediaIds,
        setSelectedMediaIds
    } = props;
    const setLoading = useContext(LoaderUpdateContext)!;
    const appDispatch = useContext(AppDispatch)!;

    const [medias, setMedias] = useState<any[]>([]);
    const [mediaMode, setMediaMode] = useState<"add" | "edit" | null>(null);
    const [deleteMediaMode, setDeleteMediaMode] = useState(false);

    const [mediaEdit, setMediaEdit] = useState<any>(undefined);
    const [media, setMedia] = useState<any>(undefined);
    const [imagePreviewOn, setImagePreviewOn] = useState<boolean>(false);

    useEffect(() => {
        let canceled = false;
        const filters = [];
        if (selectedGroupId && selectedGroupId !== "-1") {
            if (selectedGroupId === "-2") {
                filters.push({ property: "mediaGroupId" });
            } else {
                filters.push({ property: "mediaGroupId", value: selectedGroupId });
            }
        }
        getMedias({ filters })
            .then(data => {
                if (!canceled) {
                    setMedias(data.data.data);
                }
            })
            .catch(() => {});
        setSelectedMediaIds([]);
        return () => {
            canceled = true;
        };
    }, [reloadData, selectedGroupId, setSelectedMediaIds]);

    const handleCloseMediaWindow = () => {
        setMediaMode(null);
    };

    const handleAddMedia = () => {
        setMediaMode("add");
    };

    const updateMediaData = async (id: string, name: string, description: string) => {
        const m: any = {
            name,
            description
        };
        try {
            await updateMedia(id, m);
            appDispatch({ type: "SHOW_MESSAGE_SUCCESS", payload: name + " updated successfully" });
        } catch (e) {
            // TODO
        }
    };

    const addMediaData = async (
        name: string,
        type: "video" | "image" | "web" | "widget" | "ticker",
        description: string,
        url: string,
        mediaGroupId?: string
    ) => {
        try {
            setLoading(true);
            const result = await addMedia({
                media: {
                    name,
                    type,
                    description,
                    url,
                    mediaGroupId,
                    defaultDuration: 600
                }
            });
            return result;
        } catch (e) {
            // TODO
        } finally {
            setLoading(false);
        }
    };

    const addOrUpdateMedia = (name: string, type: string, description: string, url: File | string, id?: string) => {
        if (id !== undefined) {
            updateMediaData(id, name, description)
                .then(() => setReloadData(oldData => !oldData))
                .catch((e: any) => {
                    appDispatch({ type: "SHOW_MESSAGE_ERROR", payload: e });
                });
        } else {
            if (type === "video" || type === "image") {
                const target = `${BASE_DOMAIN_UPLOAD}/v1/media/upload`;
                const headers: any = { ...getBearer(), name, description, type };
                if (selectedGroupId !== "-1" && selectedGroupId !== "-2") {
                    headers.mediaGroupId = selectedGroupId;
                }
                const file = url;
                const cbs: IUploadFileCallbacks = {
                    error: (m: any, f: any) => {
                        //TODO once upload component in header will have error status, fire error.
                        appDispatch({ type: "UPDATE_UPLOAD_MEDIA_INFO_SUCCESS", payload: f });
                        appDispatch({ type: "SHOW_MESSAGE_ERROR", payload: new Error(m) });
                    },
                    fileSuccess: (fileNew: Resumable.ResumableFile) => {
                        appDispatch({ type: "UPDATE_UPLOAD_MEDIA_INFO_SUCCESS", payload: fileNew });
                        setReloadData(oldData => !oldData);
                    },
                    complete: () => {},
                    fileAdded: (resumableObj?: Resumable.Resumable) =>
                        appDispatch({ type: "UPDATE_UPLOAD_MEDIA_INFO", payload: resumableObj }),
                    fileProgress: (fileNew?: Resumable.ResumableFile) =>
                        appDispatch({ type: "UPDATE_UPLOAD_MEDIA_INFO_PROGRESS", payload: fileNew })
                };
                uploadFile(target, headers, file as File, cbs, {}, v1);
            }
            if (type === "web") {
                addMediaData(
                    name,
                    type,
                    description,
                    url as string,
                    selectedGroupId !== "-1" && selectedGroupId !== "-2" ? selectedGroupId : undefined
                )
                    .then(() => {
                        setReloadData(oldData => !oldData);
                        setLoading(false);
                        appDispatch({ type: "SHOW_MESSAGE_SUCCESS", payload: "Web uploaded successfully" });
                    })
                    .catch((e: any) => {
                        appDispatch({ type: "SHOW_MESSAGE_ERROR", payload: e });
                    });
            }
        }
        handleCloseMediaWindow();
    };

    function onEditMenuClick(mansoryItem: IMasonryItem, masonryMenuItem: IMasonryMenuItem) {
        setMedia(mansoryItem);
        setMediaMode("edit");
    }

    function onDeleteMenuClick(mansoryItem: IMasonryItem, masonryMenuItem: IMasonryMenuItem) {
        setMediaEdit(mansoryItem);
        setDeleteMediaMode(true);
    }

    const delMedia = () => {
        setDeleteMediaMode(false);
        deleteMedias([mediaEdit._id])
            .then(result => {
                if (!result.data[0].success) {
                    appDispatch({ type: "SHOW_MESSAGE_ERROR", payload: "Can't delete " + mediaEdit.name + " media." });
                } else {
                    appDispatch({
                        type: "SHOW_MESSAGE_SUCCESS",
                        payload: "Medias deleted successfully"
                    });
                }
                setReloadData(oldData => !oldData);
            })
            .catch(e => {
                // TODO
                appDispatch({ type: "SHOW_MESSAGE_ERROR", payload: e });
            });
    };

    const delMedias = () => {
        setDeleteMediasMode(false);
        deleteMedias(selectedMediaIds)
            .then(result => {
                const errors: any[] = [];
                result.data.forEach((d: any) => {
                    if (!d.success) {
                        const matchedMedia = medias.find(m => m._id === d.id);
                        if (matchedMedia) {
                            errors.push(matchedMedia.name);
                        }
                    }
                });
                if (errors.length) {
                    appDispatch({
                        type: "SHOW_MESSAGE_ERROR",
                        payload: "Can't delete " + errors.join(", ") + " medias."
                    });
                } else {
                    appDispatch({
                        type: "SHOW_MESSAGE_SUCCESS",
                        payload: "Medias deleted successfully"
                    });
                }
                setReloadData(oldData => !oldData);
            })
            .catch(e => {
                // TODO
                appDispatch({ type: "SHOW_MESSAGE_ERROR", payload: e });
            });
    };

    const handleCloseDeleteMediaWindow = () => {
        setDeleteMediaMode(false);
        setDeleteMediasMode(false);
    };

    function selectElementHandler(id: string) {
        const index = selectedMediaIds.indexOf(id);
        const newSelectedMediaIds = [...selectedMediaIds];
        if (index === -1) {
            newSelectedMediaIds.push(id);
        } else {
            newSelectedMediaIds.splice(index, 1);
        }

        setSelectedMediaIds(newSelectedMediaIds);
    }

    function onOpenMediaClose() {
        setImagePreviewOn(false);
    }
    function onImageClick(mansoryItem: IMasonryItem) {
        setMedia(mansoryItem);
        setImagePreviewOn(true);
    }

    return (
        <>
            <div className={classes.media}>
                <div className={classes.mediaGrid}>
                    <Gallery
                        elements={medias}
                        editable
                        onEditMenuClick={onEditMenuClick}
                        onDeleteMenuClick={onDeleteMenuClick}
                        showCheckboxes
                        selectElementHandler={selectElementHandler}
                        openMediaClick={onImageClick}
                    />
                </div>
                <div>
                    <MediaActions handleUpload={handleAddMedia} />
                </div>
            </div>
            {mediaMode && (
                <MediaAddDialog
                    add={addOrUpdateMedia}
                    mode={mediaMode}
                    handleClose={handleCloseMediaWindow}
                    media={media}
                />
            )}
            {deleteMediaMode && (
                <AlertDialog
                    del={delMedia}
                    handleCloseAlert={handleCloseDeleteMediaWindow}
                    message={"Do you want to delete this media?"}
                />
            )}
            {deleteMediasMode && (
                <AlertDialog
                    del={delMedias}
                    handleCloseAlert={handleCloseDeleteMediaWindow}
                    message={"Do you want to delete selected medias?"}
                />
            )}
            {imagePreviewOn && <OpenMedia media={media} handleClose={onOpenMediaClose} />}
        </>
    );
};

export default withStyles(styles)(MediaBody);
