import { WithStyles } from "@material-ui/core";
import createStyles from "@material-ui/core/styles/createStyles";
import withStyles from "@material-ui/core/styles/withStyles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Toolbar from "@material-ui/core/Toolbar";
import React, { useEffect, useMemo, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { getDeviceConfig, getScreenshot, getScreenshots } from "../../api/device";
import { IDeviceUpdaterAPI } from "../../interfaces/daos";
import useDeviceWithStatus from "../hooks/device";
import { updateStatusesOfDevices } from "../hooks/statuses";
import AudioVideo from "./details/audio&video/index";
import { DeviceContext, DeviceUpdaterContext } from "./details/context";
import DeviceGeneral from "./details/general/index";
import DeviceHistory from "./details/history";
import NetworkPanel from "./details/networking/index";
import StatusMetrica from "./details/Status/index";
import Configurations from "./details/configurations";
const styles = createStyles({
    root: {
        display: "flex",
        padding: "0px 20px 20px 20px",
        flexDirection: "column",
        height: "calc(100vh - 72px)",
        boxSizing: "border-box"
    },
    toolbar: {
        boxSizing: "border-box",
        paddingLeft: 0,
        paddingRight: 0,
        minHeight: 48
    },
    tabs: {
        flex: 1
    },
    body: {
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        justifyContent: "stretch",
        overflowY: "auto",
        overflowScrolling: "touch",
        WebkitOverflowScrolling: "touch"
    }
});

const DetailTabs: string[] = [
    "General",
    "History",
    "Status & Metrics",
    "Network Configuration",
    "Audio & Video",
    "Configurations"
];

const Device = ({ classes, match: { params } }: WithStyles<typeof styles> & RouteComponentProps) => {
    const deviceId = (params as any).id;
    const { device, setDevice } = useDeviceWithStatus(deviceId);
    const [activeTab, setActiveTab] = useState<number>(0);
    const [screenshots, setScreenshots] = useState<any[]>([]);
    const [lastScreenShot, setLastScreenShot] = useState<any>(null);
    const [deviceConfigData, setDeviceConfigData] = useState<any>(null);
    const deviceUpdaterAPI: IDeviceUpdaterAPI | null = useMemo<IDeviceUpdaterAPI | null>(() => {
        if (!device) {
            return null;
        }
        return {
            updateLocation: (latitude: number, longitude: number) => {
                setDevice({
                    ...device,
                    latitude,
                    longitude
                });
            },
            updateDescription: (description: string) => {
                setDevice({
                    ...device,
                    description
                });
            },
            updateName: (name: string) => {
                setDevice({
                    ...device,
                    name
                });
            },
            updateStatusses: (statusses: any, d: any) => {
                setDevice(updateStatusesOfDevices(statusses, [d])[0]);
            },
            // tslint:disable-next-line:no-empty
            updatePlaylist: () => {},
            updateaVerticalMode: (additionalOptions: {
                verticalOrientation: boolean;
                rotateDirection: "clockwise" | "counterclockwise" | undefined;
            }) => {
                setDevice({
                    ...device,
                    additionalOptions
                });
            }
        };
    }, [device, setDevice]);

    useEffect(() => {
        let canceled = false;
        (async () => {
            try {
                const dataConfigToGet = await getDeviceConfig(deviceId);
                setDeviceConfigData(dataConfigToGet);
                const deviceScreenshots = await getScreenshots({ id: deviceId });
                const requests = deviceScreenshots.data.map((d: { _id: string; timestamps: any }) =>
                    getScreenshot(d._id).then(data => {
                        return {
                            timestamps: d.timestamps,
                            data
                        };
                    })
                );
                Promise.all(requests)
                    .then(responses => {
                        if (!canceled) {
                            setScreenshots(
                                responses
                                    .map((r: any) => {
                                        return {
                                            timestamps: r.timestamps,
                                            src: URL.createObjectURL(r.data)
                                        };
                                    })
                                    .reverse()
                            );
                        }
                    })
                    // tslint:disable-next-line:no-empty
                    .catch(() => {});
                // tslint:disable-next-line:no-empty
            } catch (e) {}
        })();
        return () => {
            canceled = true;
        };
    }, [deviceId]);

    useEffect(() => {
        let canceled = false;
        (async () => {
            try {
                const deviceLastScreenShot = await getScreenshots({ id: deviceId, limit: 1 });
                if (!canceled) {
                    const { data } = deviceLastScreenShot;
                    setLastScreenShot(data[0]);
                }
                // tslint:disable-next-line:no-empty
            } catch (e) {}
        })();
        return () => {
            canceled = true;
        };
    }, [deviceId]);

    const renderTab = (item: string) => {
        return <Tab fullWidth label={item} key={item} />;
    };

    const handleTabChange = (event: React.ChangeEvent<{}>, value: number) => {
        setActiveTab(value);
    };

    if (!device) {
        return null;
    }
    const updateConfig = () => {
        (async () => {
            try {
                const dataConfigToGet = await getDeviceConfig(deviceId);
                setDeviceConfigData(dataConfigToGet);
                // tslint:disable-next-line:no-empty
            } catch (e) {}
        })();
    };

    return (
        <div className={classes.root}>
            <Toolbar className={classes.toolbar}>
                <Tabs
                    className={classes.tabs}
                    value={activeTab}
                    onChange={handleTabChange}
                    indicatorColor="primary"
                    textColor="primary"
                    scrollButtons="auto"
                    variant="scrollable"
                >
                    {DetailTabs.map((item: string) => renderTab(item))}
                </Tabs>
            </Toolbar>
            <div className={classes.body}>
                <DeviceContext.Provider value={device}>
                    <DeviceUpdaterContext.Provider value={deviceUpdaterAPI}>
                        {activeTab === 0 && <DeviceGeneral device={device} lastScreenShot={lastScreenShot} />}
                        {activeTab === 1 && (
                            <DeviceHistory screenshots={screenshots} additionalOptions={device.additionalOptions} />
                        )}
                        {activeTab === 2 && <StatusMetrica device={device} lastScreenShot={lastScreenShot} />}
                        {activeTab === 3 && <NetworkPanel device={deviceConfigData} updateAction={updateConfig} />}
                        {activeTab === 4 && <AudioVideo device={deviceConfigData} updateAction={updateConfig} />}
                        {activeTab === 5 && (
                            <Configurations
                                deviceId={deviceId}
                                deviceConfigs={deviceConfigData}
                                updateAction={updateConfig}
                            />
                        )}
                    </DeviceUpdaterContext.Provider>
                </DeviceContext.Provider>
            </div>
        </div>
    );
};
export default withStyles(styles)(withRouter(Device));
