import { WithStyles } from "@material-ui/core";
import createStyles from "@material-ui/core/styles/createStyles";
import withStyles from "@material-ui/core/styles/withStyles";
import React, { useContext, useMemo } from "react";
import { AutoSizer } from "react-virtualized";
import { Cell, Legend, Pie, PieChart, Tooltip } from "recharts";
import { GREEN_COLOR, RED_COLOR, YELLOW_COLOR } from "../../constants/style-constants";
import { DevicesContext } from "./dashboard-device-context";

const styles = createStyles({
    dashboardChart: {}
});

const statusChartKnownColors: any = { online: GREEN_COLOR, offline: RED_COLOR, away: YELLOW_COLOR };
const syncedChartKnownColors: any = {
    synced: GREEN_COLOR,
    "out of sync": RED_COLOR,
    syncing: YELLOW_COLOR,
    error: RED_COLOR
};
const chartOtherColors: any = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

function getStatusPieColor(status: string) {
    if (statusChartKnownColors.hasOwnProperty(status.toLowerCase())) {
        return statusChartKnownColors[status.toLowerCase()];
    }
    return chartOtherColors[Math.floor(chartOtherColors.length * Math.random())];
}

function getSyncedPieColor(status: string) {
    if (syncedChartKnownColors.hasOwnProperty(status.toLowerCase())) {
        return syncedChartKnownColors[status.toLowerCase()];
    }
    return chartOtherColors[Math.floor(chartOtherColors.length * Math.random())];
}

const DashboardChart = (props: WithStyles<typeof styles>) => {
    const { classes } = props;
    const devices = useContext(DevicesContext);
    const statusData = useMemo(
        function() {
            const deviceStatusToCountMap: any = {};
            if (!devices) {
                return null;
            }
            devices.forEach(device => {
                if (!deviceStatusToCountMap.hasOwnProperty(device.status)) {
                    deviceStatusToCountMap[device.status] = 0;
                }
                deviceStatusToCountMap[device.status]++;
            });
            const result = [];
            for (const status in deviceStatusToCountMap) {
                result.push({ name: status, value: deviceStatusToCountMap[status], color: getStatusPieColor(status) });
            }
            return result;
        },
        [devices]
    );

    const syncedData = useMemo(
        function() {
            const syncStatusToCountMap: any = {};
            if (!devices) {
                return null;
            }
            devices.forEach(device => {
                if (!syncStatusToCountMap.hasOwnProperty(device.syncStatus)) {
                    syncStatusToCountMap[device.syncStatus] = 0;
                }
                syncStatusToCountMap[device.syncStatus]++;
            });
            const result = [];
            for (const syncStatus in syncStatusToCountMap) {
                result.push({
                    name: syncStatus,
                    value: syncStatusToCountMap[syncStatus],
                    color: getSyncedPieColor(syncStatus)
                });
            }
            return result;
        },
        [devices]
    );

    if (!statusData || !syncedData) {
        return <div>Loading...</div>;
    }
    return (
        <div className={classes.dashboardChart}>
            <AutoSizer>
                {({ width }) => (
                    <>
                        <PieChart width={width} height={250}>
                            <Pie
                                data={statusData}
                                dataKey="value"
                                cx={width / 2}
                                cy={150}
                                startAngle={210}
                                endAngle={-30}
                                innerRadius={90}
                                outerRadius={120}
                                fill="#8884d8"
                                paddingAngle={5}
                            >
                                {statusData.map((entry, index) => (
                                    <Cell key={index} fill={entry.color} />
                                ))}
                            </Pie>
                            <Tooltip />
                            <Legend />
                        </PieChart>
                        <PieChart width={width} height={250}>
                            <Pie
                                data={syncedData}
                                dataKey="value"
                                cx={width / 2}
                                cy={150}
                                startAngle={210}
                                endAngle={-30}
                                innerRadius={90}
                                outerRadius={120}
                                fill="#8884d8"
                                paddingAngle={5}
                            >
                                {syncedData.map((entry, index) => (
                                    <Cell key={index} fill={entry.color} />
                                ))}
                            </Pie>
                            <Tooltip />
                            <Legend />
                        </PieChart>
                    </>
                )}
            </AutoSizer>
        </div>
    );
};

export default withStyles(styles)(DashboardChart);
