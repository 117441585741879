import Resumable from "resumablejs";

export interface IUploadFileCallbacks {
    fileSuccess?: (uploadedFile: Resumable.ResumableFile, response: string) => void;
    fileAdded?: (resumableObj?: Resumable.Resumable) => void;
    error?: (message?: any, file?: any) => void;
    fileProgress?: (file?: Resumable.ResumableFile, message?: string) => void;
    complete?: () => void;
}

function getResumableObject(target: string, headers: any, query: any, generateUniqueIdentifier?: () => string) {
    return new Resumable({
        target,
        headers,
        query,
        testChunks: false,
        permanentErrors: [400, 404, 500, 501],
        maxChunkRetries: 1,
        chunkRetryInterval: 5000,
        simultaneousUploads: 4,
        maxFiles: 1,
        // allowDuplicateUploads: true,
        chunkSize: 1 * 256 * 1024,
        generateUniqueIdentifier
    } as any);
}

// function readablizeBytes(bytes: number) {
//     const s = ['bytes', 'kB', 'MB', 'GB', 'TB', 'PB'];
//     const e = Math.floor(Math.log(bytes) / Math.log(1024));
//     return `${(bytes / Math.pow(1024, e)).toFixed(2)} ${s[e]}`;
// }

// function secondsToStr(getTemp: number) {
//     let temp = getTemp;

//     function numberEnding(number: number) {
//         const r = number === 1 ? '' : 's';
//         return r;
//     }

//     const years = Math.floor(temp / 31536000);
//     if (years) {
//         return `${years} year${numberEnding(years)}`;
//     }
//     const days = Math.floor((temp %= 31536000) / 86400);
//     if (days) {
//         return `${days} day${numberEnding(days)}`;
//     }
//     const hours = Math.floor((temp %= 86400) / 3600);
//     if (hours) {
//         return `${hours} hour${numberEnding(hours)}`;
//     }
//     const minutes = Math.floor((temp %= 3600) / 60);
//     if (minutes) {
//         return `${minutes} minute${numberEnding(minutes)}`;
//     }
//     const seconds = temp % 60;
//     if (seconds) {
//         return `${seconds} second${numberEnding(seconds)}`;
//     }
//     return 'less than 1 second';
// }

export function chunkUploadSupported() {
    return getResumableObject("", {}, {}).support;
}

export function uploadFile(
    target: string,
    headers: any,
    file: File,
    cbs: IUploadFileCallbacks = {},
    query: any = {},
    generateUniqueIdentifier?: () => string
) {
    const resumableObj = getResumableObject(target, headers, query, generateUniqueIdentifier);
    if (!resumableObj.support) {
        cbs.error && cbs.error("chunk upload not supported by browser");
        return null;
    }
    resumableObj.on("complete", (fileNew: Resumable.Resumable) => {
        if (cbs.complete) cbs.complete();
    });
    resumableObj.on("error", (message: string, file: any) => {
        if (cbs.error) cbs.error(message, file);
    });
    resumableObj.on("fileAdded", (fileNew: Resumable.Resumable) => {
        cbs.fileAdded && cbs.fileAdded(resumableObj);
        resumableObj.upload();
    });
    resumableObj.on("fileProgress", (file: Resumable.ResumableFile, message: string) => {
        if (cbs.fileProgress) {
            // const percentComplete = Math.floor(fileNew.progress() * 100);
            // const bytesPerSec = readablizeBytes(fileNew.averageSpeed);
            // const timeRemainingNum = fileNew.timeRemaining();
            // const timeRemaining = timeRemainingNum
            //     ? secondsToStr(timeRemainingNum)
            //     : '';
            cbs.fileProgress(file, message);
        }
    });
    resumableObj.on("fileSuccess", (fileUploaded: any, msg: string) => {
        if (cbs.fileSuccess) cbs.fileSuccess(fileUploaded, msg);
    });
    resumableObj.addFile(file);

    return resumableObj;
}

export function retryUpload(resumableObj: Resumable) {
    resumableObj.files[0].retry();
}

export function cancelUpload(resumableObj: Resumable) {
    resumableObj.cancel();
    // resumableObj.off();
}

export function off(resumableObj: Resumable) {
    // return resumableObj.off();
}

export function cancel(resumableObj: Resumable) {
    return resumableObj.cancel();
}
