import { BASE_DOMAIN } from "../constants/app";
import { makeDelete, makeGet, makePatch, makePost } from "./main-api";

const API_URL = {
    GET_ADD: (query?: string) => `${BASE_DOMAIN}/v1/play-list${query ? query : ""}`,
    COPY_MEDIA: (id: string) => `${BASE_DOMAIN}/v1/play-list/copy/${id}`,
    UPDATE_DELETE: (id: string) => `${BASE_DOMAIN}/v1/play-list/${id}`,
    CHANGE_PLAYLISTS_GROUP: (groupId: string) => `${BASE_DOMAIN}/v1/play-list/groups/${groupId}`,
    DELETE_PLAYLISTS: () => `${BASE_DOMAIN}/v1/play-list`
};

export const getPlayList = ({
    searchValue,
    start = 0,
    limit = 20,
    filters
}: {
    searchValue?: string;
    start?: number;
    limit?: number;
    filters?: { property: string; value?: string | string[] | boolean }[] | null;
}) => {
    let query = `?start=${start}&limit=${limit}`;
    if (searchValue) {
        query += `&search=${searchValue}`;
    }
    if (filters && Array.isArray(filters) && filters.length) {
        for (let i = 0; i < filters.length; i++) {
            query += `&filters[${i}][property]=${filters[i].property}`;
            if (filters[i].value) {
                query += `&filters[${i}][value]=${filters[i].value}`;
            }
        }
    }
    return makeGet(API_URL.GET_ADD(query));
};
export const addPlayList = (data: any) => {
    return makePost(API_URL.GET_ADD(), { playList: data });
};
export const deletePlayList = (id: string) => {
    return makeDelete(API_URL.UPDATE_DELETE(id));
};
export const updatePlayList = (id: string, name: string) => {
    return makePatch(API_URL.UPDATE_DELETE(id), { name });
};
export const getPlayListMedia = (id: string) => {
    return makeGet(API_URL.UPDATE_DELETE(id));
};

export const deletePlaylists = (playlists: string[]) => {
    return makeDelete(API_URL.DELETE_PLAYLISTS(), playlists);
};

export const copyPlayList = (id: string) => {
    return makePost(API_URL.COPY_MEDIA(id), {});
};
export const changePlayListsGroup = (groupId: string, playLists: string[]) => {
    return makePost(API_URL.CHANGE_PLAYLISTS_GROUP(groupId), playLists);
};
