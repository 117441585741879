import { FormControl, MenuItem, Select } from "@material-ui/core";
import React, { useState } from "react";
interface IPlaylistRendererProps {
    deviceId: string;
    scheduleId: string;
    schedules: any[];
    updateScheduleForDevice: (deviceId: string, scheduleId: string) => void;
    mode: "grid" | "card";
}

const ScheduleRenderer = ({
    deviceId,
    scheduleId,
    schedules,
    updateScheduleForDevice,
    mode
}: IPlaylistRendererProps) => {
    const [open, setOpen] = useState<boolean>(false);

    function handleScheduleSelectChange(event: React.ChangeEvent<{ name?: string; value: unknown }>) {
        updateScheduleForDevice(deviceId, event.target.value as string);
    }

    function handleScheduleSelectClose() {
        setOpen(false);
    }

    function handleScheduleSelectDoubleClick() {
        if (mode === "grid") {
            setOpen(true);
        }
    }
    function handlePlaylistSelectClick() {
        if (mode === "card") {
            setOpen(true);
        }
    }

    return (
        <FormControl style={{ width: "100%" }}>
            <Select
                open={open}
                onClose={handleScheduleSelectClose}
                onOpen={handlePlaylistSelectClick}
                value={scheduleId || "none"}
                onChange={handleScheduleSelectChange}
                inputProps={{
                    name: "age",
                    id: "demo-controlled-open-select"
                }}
                onDoubleClick={handleScheduleSelectDoubleClick}
            >
                <MenuItem key={"none"} value={"none"}>
                    None
                </MenuItem>
                {schedules.map((s, i) => (
                    <MenuItem key={s._id} value={s._id}>
                        {s.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};
export default ScheduleRenderer;
