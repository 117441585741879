import { WithStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from "@material-ui/core/InputLabel";
import { withStyles } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import Typography from "@material-ui/core/Typography";
import CheckedIcon from "@material-ui/icons/CheckOutlined";
import ErrorIcon from "@material-ui/icons/Error";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useFormInput } from "../../utils/hooks";

import { checkUsernameExisting, getSuggestedUsername, saveAuthToken, signup } from "../../api/main-api";
import { AppDispatch } from "../../context/main-context";
import { validateEmail, validateFullname, validateUsername } from "../../utils/validationsUtil";
const styles = () =>
    createStyles({
        root: {
            display: "flex",
            width: "100%",
            alignItems: "center",
            justifyContent: "center"
        },
        card: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center"
        },
        content: {
            padding: "20px"
        },
        contentBody: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center"
        },
        actionsBar: {
            display: "flex",
            flexDirection: "row",
            padding: "5px 20px"
        },
        flex1: {
            flex: "1"
        },
        formControl: {
            marginTop: 8,
            marginBottom: 8
        },
        button: {
            marginRight: 0,
            marginLeft: 0
        },
        privacy: {
            marginTop: "15px"
        },
        userNameAdornment: {
            marginRight: 12
        }
    });

function Signup(props: WithStyles<typeof styles> & { mode?: string }) {
    const { classes, mode } = props;
    const fullName = useFormInput("");
    const email = useFormInput("");
    const username = useFormInput("");
    const password = useFormInput("");
    const confirmPassword = useFormInput("");
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);
    const [fullNameError, setFullNameError] = useState<string>("");
    const [usernameError, setUsernameError] = useState<string>("");
    const [emailError, setEmailError] = useState<string>("");
    const [passwordError, setPasswordError] = useState<string>("");
    const [confirmPasswordError, setConfirmPasswordError] = useState<string>("");
    const [isCheckingUsername, setIsCheckingUsername] = useState<boolean>(false);
    const [userNameNotExists, setUserNameNotExists] = useState<boolean>(false);
    const [signupInProgress, setSignupInProgress] = useState<boolean>(false);
    const dispatch = useContext(AppDispatch)!;
    const checkUserName = useCallback(() => {
        setIsCheckingUsername(true);
        setUserNameNotExists(false);
        checkUsernameExisting({
            username: username.value
        })
            .then((d: any) => {
                if (d && !d.data) {
                    setUserNameNotExists(true);
                    setUsernameError("");
                } else {
                    setUsernameError("Username exists");
                }
                setIsCheckingUsername(false);
            })
            .catch(e => {
                dispatch({ type: "SHOW_MESSAGE_ERROR", payload: e });
                setIsCheckingUsername(false);
            });
    }, [username.value, dispatch]);
    useEffect(() => {
        let timerId: NodeJS.Timeout;
        if (username.value) {
            timerId = setTimeout(checkUserName, 100);
        }
        return () => {
            if (timerId) {
                clearTimeout(timerId);
            }
        };
    }, [username.value, checkUserName]);

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const handleClickShowPassword = (prop: string) => () => {
        if (prop === "showPassword") {
            setShowPassword(!showPassword);
        } else if (prop === "showConfirmPassword") {
            setShowConfirmPassword(!showPassword);
        }
    };

    const handleSignup = () => {
        if (validateFormFields() && !isCheckingUsername && userNameNotExists) {
            setSignupInProgress(true);
            signup({
                fullName: fullName.value.trim(),
                email: email.value.trim(),
                username: username.value.trim(),
                password: password.value.trim(),
                confirmPassword: confirmPassword.value.trim()
            })
                .then(response => {
                    const { data } = response;
                    saveAuthToken(data.authToken);
                    setSignupInProgress(false);
                    dispatch({ type: "SIGN_UP_USER", payload: data.user });
                })
                .catch(e => {
                    dispatch({ type: "SHOW_MESSAGE_ERROR", payload: e });
                    setIsCheckingUsername(false);
                    setSignupInProgress(false);
                });
        }
    };

    const validateFormFields = (setToState = true) => {
        let errors = {
            fullNameError: "",
            emailError: "",
            usernameError: "",
            passwordError: "",
            confirmPasswordError: ""
        };
        let isValid = true;

        const { isValid: isValidFullname, errors: fullNameErrors } = validateFullname(fullName.value);
        isValid = isValid && isValidFullname;
        errors = {
            ...errors,
            ...fullNameErrors
        };

        const { isValid: isValidEmail, errors: emailErrors } = validateEmail(email.value);
        isValid = isValid && isValidEmail;
        errors = {
            ...errors,
            ...emailErrors
        };

        const { isValid: isValidUserName, errors: userNameErrors } = validateUsername(username.value);
        isValid = isValid && isValidUserName;
        errors = {
            ...errors,
            ...userNameErrors
        };

        const { isValid: areValidPasswords, errors: passwordsErrors } = validatePasswords();
        isValid = isValid && areValidPasswords;
        errors = {
            ...errors,
            ...passwordsErrors
        };

        if (setToState && Object.keys(errors).length) {
            setFullNameError(fullNameError);
            setEmailError(emailError);
            setUsernameError(usernameError);
            setPasswordError(passwordError);
            setConfirmPasswordError(confirmPasswordError);
        }
        return isValid;
    };

    const validatePasswords = (fromField?: string) => {
        let isValid = true;
        const errors = {
            passwordError: "",
            confirmPasswordError
        };
        if (password.value.trim() === "") {
            isValid = false;
            errors.passwordError = "Please enter password";
        } else if (password.value.trim().length < 8) {
            isValid = false;
            errors.passwordError = "Passwords should be at least 8 chars";
        }
        if (fromField === "signup-password-field") {
            if (confirmPassword.value.trim() && confirmPassword.value.trim() !== password.value.trim()) {
                isValid = false;
                errors.confirmPasswordError = "Password and confirm password don't match";
            } else if (confirmPassword.value.trim()) {
                errors.confirmPasswordError = "";
            }
        } else if (confirmPassword.value.trim() !== password.value.trim()) {
            isValid = false;
            errors.confirmPasswordError = "Password and confirm password don't match";
        } else {
            errors.confirmPasswordError = "";
        }
        return {
            isValid,
            errors
        };
    };

    const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        switch (event.keyCode) {
            case 13: {
                handleSignup();
                break;
            }
            default:
                break;
        }
    };

    const constructSuggestedUsername = () => {
        setIsCheckingUsername(true);
        setUserNameNotExists(false);
        getSuggestedUsername({
            fullName: fullName.value.trim(),
            email: email.value.trim()
        })
            .then((d: any) => {
                if (d && d.data) {
                    username.setinputvalue(d.data);
                    setIsCheckingUsername(false);
                    setUserNameNotExists(true);
                }
            })
            .catch(e => {
                dispatch({ type: "SHOW_MESSAGE_ERROR", payload: e });
                setIsCheckingUsername(false);
            });
    };

    const onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        if (event.target.id === "signup-fullName-field") {
            const { isValid, errors } = validateFullname(fullName.value);
            setFullNameError(errors.fullNameError);
            if (isValid && !username.value && fullName.value) {
                constructSuggestedUsername();
            }
        }
        if (event.target.id === "signup-email-field") {
            const { isValid, errors } = validateEmail(email.value);
            setEmailError(errors.emailError);
            if (isValid && !username.value && fullName.value) {
                constructSuggestedUsername();
            }
        }
        if (event.target.id === "signup-username-field") {
            const { errors } = validateUsername(username.value);
            setUsernameError(errors.usernameError);
        }
        if (event.target.id === "signup-confirm-password-field" || event.target.id === "signup-password-field") {
            const { errors } = validatePasswords(event.target.id);
            setPasswordError(errors.passwordError);
            setConfirmPasswordError(errors.confirmPasswordError);
        }
    };

    const inputProps = {
        onKeyDown
    };

    return (
        <div className={classes.root}>
            <Card className={classes.card}>
                <CardContent className={classes.content}>
                    <Typography>Sign up</Typography>
                    <div className={classes.contentBody}>
                        <FormControl className={classes.formControl} required error={!!fullNameError} onBlur={onBlur}>
                            <InputLabel htmlFor="signup-fullName-field">Full Name</InputLabel>
                            <Input
                                id="signup-fullName-field"
                                autoComplete="name"
                                value={fullName.value}
                                autoFocus
                                onChange={fullName.onChange}
                                inputProps={inputProps}
                            />
                            {fullNameError && <FormHelperText>{fullNameError}</FormHelperText>}
                        </FormControl>
                        <FormControl className={classes.formControl} required error={!!emailError} onBlur={onBlur}>
                            <InputLabel htmlFor="signup-email-field">Email</InputLabel>
                            <Input
                                id="signup-email-field"
                                autoComplete="email"
                                value={email.value}
                                type="email"
                                onChange={email.onChange}
                                inputProps={inputProps}
                            />
                            {emailError && <FormHelperText>{emailError}</FormHelperText>}
                        </FormControl>
                        <FormControl className={classes.formControl} required error={!!usernameError} onBlur={onBlur}>
                            <InputLabel htmlFor="signup-username-field">Username</InputLabel>
                            <Input
                                id="signup-username-field"
                                value={username.value}
                                onChange={username.onChange}
                                inputProps={inputProps}
                                endAdornment={
                                    username && (
                                        <InputAdornment className={classes.userNameAdornment} position="end">
                                            {isCheckingUsername ? (
                                                <CircularProgress size={18} />
                                            ) : userNameNotExists ? (
                                                <CheckedIcon color="primary" />
                                            ) : (
                                                <ErrorIcon color="error" />
                                            )}
                                        </InputAdornment>
                                    )
                                }
                            />
                            {usernameError && <FormHelperText>{usernameError}</FormHelperText>}
                        </FormControl>
                        <FormControl className={classes.formControl} required error={!!passwordError} onBlur={onBlur}>
                            <InputLabel htmlFor="signup-password-field">Password</InputLabel>
                            <Input
                                id="signup-password-field"
                                type={showPassword ? "text" : "password"}
                                value={password.value}
                                autoComplete="new-password"
                                onChange={password.onChange}
                                inputProps={inputProps}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={handleClickShowPassword("showPassword")}
                                            tabIndex={-1}
                                            onMouseDown={handleMouseDownPassword}
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                            {passwordError && <FormHelperText>{passwordError}</FormHelperText>}
                        </FormControl>
                        <FormControl
                            className={classes.formControl}
                            required
                            error={!!confirmPasswordError}
                            onBlur={onBlur}
                        >
                            <InputLabel htmlFor="signup-confirm-password-field">Confirm Password</InputLabel>
                            <Input
                                id="signup-confirm-password-field"
                                type={showConfirmPassword ? "text" : "password"}
                                value={confirmPassword.value}
                                autoComplete="new-password"
                                onChange={confirmPassword.onChange}
                                inputProps={inputProps}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            tabIndex={-1}
                                            onClick={handleClickShowPassword("showConfirmPassword")}
                                            onMouseDown={handleMouseDownPassword}
                                        >
                                            {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                            {confirmPasswordError && <FormHelperText>{confirmPasswordError}</FormHelperText>}
                        </FormControl>
                    </div>
                    <Typography className={classes.privacy}>
                        By registering an account, you agree with our Terms of Use and Privacy Policy.
                    </Typography>
                </CardContent>
                <CardActions className={classes.actionsBar}>
                    {mode !== "forDeviceOwnership" && (
                        <Button color="primary" component={RouterLink} to="/login">
                            Go To Login
                        </Button>
                    )}
                    <div className={classes.flex1} />
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        disabled={signupInProgress}
                        onClick={handleSignup}
                    >
                        Join
                    </Button>
                </CardActions>
            </Card>
        </div>
    );
}

export default withStyles(styles)(Signup);
